import React from 'react'
import { TradeDrawerListItemSection } from '../styles'
import { useInfo24H } from 'app/hooks/trade/info24H'
import ListCoin from './ListCoin'
import PriceHigh from './PriceHigh'
import { useIndexPrice } from 'app/hooks/trade/indexprice'

// soe thet drawer
const DrawerListItem = ({ item, index, setDisplayDrawer, ep_id }: any) => {
	const { data: info24H } = useInfo24H(item?.pairs)
	let { getPrice } = useIndexPrice(item?.id)
	return (
		<TradeDrawerListItemSection>
			<ListCoin
				item={item}
				volume={info24H?.volume}
				setDisplayDrawer={setDisplayDrawer}
				ep_id={ep_id}
			/>

			<PriceHigh
				indexPrice={getPrice(item?.id)}
				info24H={info24H}
				setDisplayDrawer={setDisplayDrawer}
				item={item}
			/>
		</TradeDrawerListItemSection>
	)
}

export default DrawerListItem
