import React from 'react'

import AnnouncementBody from './AnnouncementBody'
import AnnouncementDetailHeader from './AnnouncementDetailHeader'
import { AnnouncementDetailSection } from './styles'

const AnnouncementDetail = ({ show, setShow, searchshow }) => {
	return (
		<AnnouncementDetailSection>
			<AnnouncementDetailHeader show={show} setShow={setShow} searchshow={searchshow}/>
			<AnnouncementBody show={show} searchshow={searchshow}/>
		</AnnouncementDetailSection>
	)
}

export default AnnouncementDetail
