import React, { useContext } from 'react'
import { Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { Button, Col, Row } from 'antd'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { IconArrowsMove, IconMaximize, IconRefresh } from '@tabler/icons-react'
import { useTradeStore } from '../../../../hooks/trade/tradeStore'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'

const TradeDetailListHeader = ({ dataType, popup = false, pos }) => {
	const { COLORS } = useContext(ThemeContext).theme
	const { data: tradeModalStore, change: muateTradeModalStore } =
		useTradeModalStore()

	const { mutate } = useTradePosition()

	return (
		<>
			{!popup && (
				<>
					<Button
						size={'small'}
						style={{
							position: 'absolute',
							top: 0,
							right: 40,

							zIndex: 1000,
						}}
						icon={<IconRefresh size={15} color={'#888'} />}
						onClick={() => {
							mutate()
						}}
					/>
					<Button
						className={'drag-handle'}
						style={{
							position: 'absolute',
							top: 0,
							right: 20,

							zIndex: 1000,
						}}
						size={'small'}
						icon={<IconArrowsMove size={15} color={'#888'} />}
					/>
					<Button
						className="widgetClose"
						size={'small'}
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
							zIndex: 1000,
						}}
						onClick={() => {
							if (pos == 'right') {
								muateTradeModalStore({
									tradeshow: tradeModalStore?.tradeshow
										? false
										: true,
								})
							} else {
								muateTradeModalStore({
									detailshow: tradeModalStore?.detailshow
										? false
										: true,
								})
							}
						}}>
						X
					</Button>
				</>
			)}

			<Row
				style={{
					padding: '10px 0',
					borderBottom: `1px solid ${COLORS.stroke}`,
					borderTop: `1px solid ${COLORS.stroke}`,
				}}>
				{dataType?.map((item: any, index: any) => (
					<Col
						key={index.toString()}
						style={{
							color: COLORS.Secondary,
							flex: 1,
							justifyContent: 'center',
							display: 'flex',
						}}>
						<Text>{item && item}</Text>
					</Col>
				))}
			</Row>
		</>
	)
}

export default React.memo(TradeDetailListHeader)
