import styled from 'styled-components'

export const SectionContainer = styled.div`
	width: 60%;
	flex: 1;
`

export const SectionWrap = styled.div`
	width: 100%;
	background-color: transparent;
	display: flex;
	align-self: center;
	justify-content: center;
	align-items: center;
`

export const MarketTableSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`
