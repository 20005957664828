import { IconArrowLeft, IconShare } from '@tabler/icons-react'
import { CButton } from 'app/components/Common'
import dayjs from 'dayjs'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const AnnouncementDetailHeader = ({ show, setShow, searchshow }) => {
	const { textsilver, textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
		//거래소 이름 치환
		const list = ['TheBIT', 'THEBIT', 'TheBit', '[[거래소]]']

		const currentname = t('GLOBALTITLE2')
	
		const title = show?.title?.replace(
			new RegExp(list.join('|'), 'gi'),
			currentname
		)
	
	return (
		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				justifyContent: 'space-between',
				height: 100,
				padding: 20,
			}}>
			<View
				style={{
					alignItems: 'center',
					justifyContent: 'space-between',
					height: '100%',
					paddingBlock: 10,
					flexDirection: 'row',
				}}>
				<CButton
					icon={
						<IconArrowLeft color={searchshow ? 'black' : textw} />
					}
					onClick={() => {
						setShow({
							...show,
							show: !show?.show,
						})
					}}
					style={{
						marginTop: 10,
						paddingRight: 20,
						height: 30,
					}}
				/>
				<View
					style={{
						alignItems: 'flex-start',
						marginTop: 5,
					}}>
					<Text
						size={18}
						style={{
							color: searchshow ? 'black' : textw,
							marginTop: 5,
						}}>
						{title}
					</Text>
					<Text size={14} style={{ color: textsilver, marginTop: 5 }}>
						{dayjs(show.rdate).format('YYYY-MM-DD')}
					</Text>
				</View>
			</View>
			<CButton icon={<IconShare color={textw} />} />
		</View>
	)
}

export default AnnouncementDetailHeader
