import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTradeModalStore } from '../hooks/trade/modalStore'
import { useToken } from '../hooks'
import { Button, Col, Input, Modal, Row, Table, Tag, message } from 'antd'
import API from '../../utils/api'
import { useFetcher } from '../hooks/fetcher'
import TableAPI from '../components/TableAPI'
import { View } from '../../styles/reactnative'
import EntryTime from '../components/EntryTime'
import { ThemeContext } from '../../theme/ThemeProvider'

interface IProps {
	buy?: boolean
	setBuy?: any
}
const TicketModal = ({ buy, setBuy }: IProps) => {
	const { t } = useTranslation()
	const { theme } = useContext(ThemeContext)
	const [content, setContent] = useState<string>('')
	const [refresh, setRefresh] = useState<boolean>(false)
	const { data: token } = useToken()
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()

	return (
		<Modal
			open={modalStore?.qna}
			title={t('QNA')}
			onCancel={() => mutateModalStore({ ...modalStore, qna: false })}
			onOk={() => mutateModalStore({ ...modalStore, qna: false })}
			width={'50%'}
			footer={null}
			centered={true}>
			<TableAPI
				apikey={'/ticket/my?refresh=' + (refresh ? 1 : 0) + 't='}
				columns={() => [
					{
						title: '번호',
						dataIndex: 'id',
						width: 60,
						key: 'id',
						render: (text: any) => <span>{text}</span>,
					},
					{
						title: '상태',
						dataIndex: 'process',
						width: 100,
						key: 'status',
						render: (text: any) => (
							<Tag
								color={
									text == 1
										? 'red'
										: text == 2
										? 'green'
										: 'blue'
								}>
								{text == 0
									? '접수'
									: text == 1
									? '검토중'
									: '완료'}
							</Tag>
						),
					},
					{
						title: '문의시간',
						dataIndex: 'created_at',
						width: 100,
						key: 'created_at',
						render: (text: any) => <EntryTime time={text} />,
					},
					{
						title: '문의내용',
						dataIndex: 'content',
						key: 'content',
						render: (text: any, record) => (
							<View style={{ width: '100%' }}>
								<Row style={{ width: '100%' }}>
									<Col span={4}>문의내용</Col>
									<Col span={20}>{text}</Col>
								</Row>
								{record?.reply && (
									<Row
										style={{
											width: '100%',
											borderTop: '1px solid #ececec',
											background: '#eee',
										}}>
										<Col span={4}>답변내용</Col>
										<Col span={20}>{record?.reply}</Col>
									</Row>
								)}
							</View>
						),
					},
				]}
			/>
			<Input.TextArea
				size="large"
				placeholder="내용을 입력해주세요. (우측 하단 드래그로 입력창크기 조절가능)"
				value={content}
				onChange={e => setContent(e.target.value)}
				style={{
					minHeight: 130,
					marginTop: 10,
					marginBottom: 10,
				}}></Input.TextArea>
			<Button
				type="primary"
				style={{ width: '100%' }}
				onClick={() => {
					if (content.length < 10) {
						message.error('문의내용은 최소 10자 이상 입력해주세요.')
						return
					}
					API.post('/ticket/post', token + '', {
						type: 2,
						content: content,
					}).then(res => {
						if (res?.result?.success) {
							message.success('1:1 상담이 완료되었습니다.')
							setRefresh(!refresh)
						} else {
							message.error(
								res?.result?.message ||
									'1:1 상담에 실패하였습니다.'
							)
						}

						setContent('')
					})
				}}>
				보내기
			</Button>
		</Modal>
	)
}

export default React.memo(TicketModal)
