import { Tag, Tooltip } from 'antd'
import React from 'react'
import { Text } from 'styles/reactnative'
import EntryTime from '../../../../../components/EntryTime'
import KrwPrice from '../../../../../components/KrwPrice'
import UserTag from '../../../../../components/UserTag'
import { TagWrap } from '../../../../../components/styles'
import { COLORS } from '../../../../../constants'

export const AllDeposit: any = (mutate, openModal) => {
	return [
		{
			title: '번호',
			dataIndex: 'id',
			align: 'center',
			width: 100,
			render: (text, data) => <Text>{text}</Text>,
		},
		{
			title: '구분',
			dataIndex: 'type',
			align: 'center',
			width: 50,
			render: (text, data) => {
				return text == 'D' ? (
					<Tag
						style={{
							backgroundColor: COLORS.lightGreen,
							color: 'white',
						}}>
						입금
					</Tag>
				) : (
					<Tag
						style={{
							backgroundColor: COLORS.lightRed,
							color: 'white',
						}}>
						출금
					</Tag>
				)
			},
		},
		{
			title: '생성일',
			dataIndex: 'created_at',
			align: 'center',
			width: 130,
			render: (text, data) => <EntryTime time={text} offset={9}/>,
		},
		{
			title: '승인일',
			dataIndex: 'confirm_at',
			width: 130,
			align: 'center',
			render: (text, data) => {
				{
					return text ? <EntryTime time={text} offset={9}/> : <Text>-</Text>
				}
			},
		},
		{
			title: '그룹',
			dataIndex: 'referral_code',
			align: 'center',
			render: (text, data) => <Text>{text}</Text>,
		},
		{
			title: '회원',
			dataIndex: 'user_id',
			width: 150,
			align: 'center',
			render: (text, data) => (
				<UserTag
					userid={data?.user_id}
					email={data?.email}
					nickname={data?.fullname || data?.nickname}
					phone={data?.phone}
					onClick={() => {
						openModal(data?.user_id)
					}}
				/>
			),
		},
		{
			title: '진행',
			dataIndex: 'process',
			width: 50,
			align: 'center',
			render: (text, data) => (
				<TagWrap>
					{text == 1 ? (
						<Tag
							style={{
								backgroundColor: '#f79336',
								color: 'white',
							}}>
							대기
						</Tag>
					) : text == 2 ? (
						<Tag
							style={{
								backgroundColor: COLORS.lightGreen,
								color: 'white',
							}}>
							{data?.confirm_amount ? '지급완료' : '입금승인'}
						</Tag>
					) : text == 3 ? (
						<Tag
							style={{ backgroundColor: '#444', color: 'white' }}>
							검토중
						</Tag>
					) : text == 4 ? (
						<Tag style={{ color: 'red' }}>거절됨</Tag>
					) : (
						<Tag>취소</Tag>
					)}
				</TagWrap>
			),
		},
		{
			title: '요청',
			dataIndex: 'amount',
			align: 'center',
			sorter: (a, b) => a.amount - b.amount,
			render: (text, data) => <KrwPrice price={text || 0} dp={0} />,
		},
		{
			title: '처리',
			dataIndex: 'confirm_amount',
			align: 'center',
			sorter: (a, b) => a.confirm_amount - b.confirm_amount,
			render: (text, data) => <KrwPrice price={text || 0} dp={0} />,
		},
		{
			title: '실명',
			align: 'center',
			dataIndex: 'name',
			render: (text, data) => <Text>{text}</Text>,
		},
		{
			title: '계좌',
			align: 'center',
			dataIndex: 'bankaccount',
			render: (text, data) => {
				return (
					<Tooltip title={text}>
						<Text
							style={{
								maxWidth: 250,
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}>
							{text == 'undefined/undefined/undefined'
								? '-'
								: text}
						</Text>
					</Tooltip>
				)
			},
		},
		{
			title: '관리메모',
			align: 'center',
			dataIndex: 'memo',
			render: (text, data) => <Text>{text}</Text>,
		},
	]
}
