import React, { useState } from 'react'
import { COLORS, IMAGES } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { Text } from 'styles/reactnative'
import { VerfiyHeader, VerifyContainer, VerifyWrapper } from './styles'
import VerifyCode from './VerifyCode'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { CImage } from '../../../components/Common'

const VerifySection = ({ authSuccess, setAuthSuccess }) => {
	const { t } = useTranslation()
	const [verifyError, setVerifyError] = useState()

	const { isDarkMode, theme } = React.useContext(ThemeContext)
	const background = isDarkMode ? COLORS.white : 'rgba(32, 25, 56, 0.8)'

	return (
		<VerifyContainer
			style={{
				background,
				display: 'flex',
				alignContent: 'center',
				alignItems: 'center',
				borderRadius: '10px',
				justifyContent: 'center',
				width: '90%',
			}}>
			<VerifyWrapper style={{ background: 'transparent' }}>
				<VerfiyHeader
					style={{
						justifyItems: 'center',
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<CImage
						src={
							isDarkMode ? IMAGES.shieldblack : IMAGES.shieldcheck
						}
						alt={''}
					/>
					<Text
						size={24}
						style={{
							fontWeight: 'bold',
							color: theme.COLORS.textw,
							justifyContent: 'center',
							textAlign: 'center',
							letterSpacing: 0.5,
						}}>
						{t('authentication')}
					</Text>
				</VerfiyHeader>
				{verifyError ? (
					<Text
						style={{
							fontSize: 12,
							fontWeight: '300',
							color: COLORS.lightRed,
							lineHeight: 1.3,
						}}>
						{t('verificationCodeError')}
					</Text>
				) : null}
				<VerifyCode
					authSuccess={authSuccess}
					setAuthSuccess={setAuthSuccess}
					setVerifyError={setVerifyError}
				/>
				<VerfiyHeader style={{ marginBlock: 15 }}>
					<Text
						size={14}
						style={{
							color: COLORS.primary,
							fontWeight: '500',
						}}>
						{t('havingProblemsWithVerification')}
					</Text>
				</VerfiyHeader>
			</VerifyWrapper>
		</VerifyContainer>
	)
}

export default VerifySection
