import { COLORS } from 'app/constants'
import { goTo } from 'app/hooks/navigation'
import { useLogQueue } from 'app/hooks/trade/logQueue'
import { useTradePosition } from 'app/hooks/trade/tradePosition'
import { useUserSocket } from 'app/hooks/trade/userSocket'
import AssetMobile from 'app/pages/AssetPage/AssetMobile'
import HomeMobile from 'app/pages/HomePage/HomeMobile'
import MTradeModal from 'app/pages/MTradeModal'
import TradeMobile from 'app/pages/TradePage/TradeMobile'
import asset from 'assets/icons/asset.png'
import graph from 'assets/icons/graph.png'
import tools from 'assets/icons/tools.png'
import trade from 'assets/icons/trade.png'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { MText } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { CToast } from '../../components/Common'
import { COINHIDE, COIN_URL } from '../../constants/APIKEYS'
import { useToken } from '../../hooks'
import { useExchangeStore } from '../../hooks/trade/exchangeStore'
import { OrdersPage } from '../../pages/OrdersPage/Loadable'
import OrdersMobile from '../../pages/OrdersPage/OrdersMobile'
import BottomLogo from './BottomLogo'
import { BottomTabContainer, BottomTabSection, ButtonTabItems } from './styles'
import { useTradeStore } from 'app/hooks/trade/tradeStore'

const BottomTabs = () => {
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const location = useLocation()
	const navigation = useHistory()
	const { data: token } = useToken()
	const { saveLog } = useLogQueue()


	let pathname = '홈'
	if (location.pathname === '/') {
		pathname = '홈'
	} else if (location.pathname.includes('trade')) {
		pathname = '거래'
	} else if (location.pathname.includes('assets')) {
		pathname = '입출금'
	} else if (location.pathname.includes('login')) {
		if (token) {
			pathname = '홈'
		} else {
			pathname = 'login'
		}
	} else if (location.pathname.includes('signup')) {
		pathname = 'signup'
	} else if (location.pathname.includes('settings')) {
		pathname = 'settings'
	} else if (location.pathname.includes('orders')) {
		pathname = 'orders'
	}

	const { ep_id} = useTradeStore()
 
	const { EPs } = useExchangeStore()

	const [tab, setTab] = useState(pathname)
	const tabUrls = {
		home: '/',
		trade: '/trade/' + (ep_id || EPs?.[0]?.id),
		assets: '/assets',
		홈: '/',
		거래: '/trade/' + (ep_id || EPs?.[0]?.id ),
		입출금: '/assets',
		"체결/손익": "/orders",
		orders: '/orders',
		signup: '/signup',
		login: '/login',
	}

	useEffect(() => {
		setTab(pathname)


	}, [pathname])

	const tabs = [
		{
			title: '홈',
			icon: graph,
		},
		{
			title: '거래',
			icon: trade,
		},
		{
			title: '입출금',
			icon: asset,
		},
		{
			title: '체결/손익',
			icon: tools,
		},
		{
			title: '코인선물',
			icon: tools,
		},
	]

	if (COINHIDE) {
		tabs.pop()
	}
	const tabComponents = {
		홈: <HomeMobile />,
		거래: <TradeMobile />,
		입출금: <AssetMobile />,
		'체결/손익': <OrdersPage />,
		orders: <OrdersMobile />,
	}


	return (
		<BottomTabContainer className="mobile">
			{tabComponents[tab]}
			<BottomTabSection>
				{tabs.map((item, index) => (
					<ButtonTabItems
						key={index.toString()}
						className={'customBtn'}
						type="button"
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							alignSelf: 'center',
							justifyContent: 'center',
							justifyItems: 'center',
							borderWidth: 0,
							minWidth: 50,
						}}
						onClick={() => {
							if (item?.title === '코인선물') {
								window.location.href =
									COIN_URL +
									'?ssotoken=' +
									token +
									'&darkmode=' +
									(isDarkMode ? 'dark' : 'light')
								return
							}
							setTab(item?.title)
											
							saveLog({
								log: 'FR008',
								data: item?.title,
							}, 'MTS')
						
							goTo(navigation, tabUrls[item?.title])
						}}>
						<BottomLogo
							src={item?.icon}
							isTrue={tab === item.title}
						/>
						<MText
							style={{
								color:
									tab === item.title
										? COLORS.secondary
										: textsilver,
								marginTop: 3,
							}}>
							{item?.title}
						</MText>
					</ButtonTabItems>
				))}
			</BottomTabSection>
			<MTradeModal />
			<CToast />
		</BottomTabContainer>
	)
}

export default React.memo(BottomTabs)
