import React, { useState } from 'react'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { PreferenceDrawerBodyWrapper } from './styles'
import { useTranslation } from 'react-i18next'
import { IconChevronDown, IconCircleCheck, IconReload } from '@tabler/icons-react'
import { CButton } from 'app/components/Common'

const SHOW24HOUR = 'show24HChange'
const SHOWLASTTRADE = 'showLastTradedPrice'
const PreferenceDrawerTheme = () => {
	const [displayChange, setDisplayChange] = useState(SHOW24HOUR)
	const [dropdown, setDropdown] = useState(false)
	const { t } = useTranslation()
	const data = [SHOW24HOUR, SHOWLASTTRADE]
	return (
		<PreferenceDrawerBodyWrapper>
			<Text
				style={{
					color: COLORS.lightGray5,
					marginBlock: 15,
				}}>
				{t('favoriteTradingPairs')}
			</Text>

			<CButton
				icon={<IconChevronDown />}
				title={t(displayChange)}
				style={{
					background: `${COLORS.gray}99`,
					color: COLORS.lightGray2,
					width: '100%',
					padding: 10,
					flexDirection: 'row',
					justifyContent: 'space-between',
					borderRadius: 0,
					borderTopRightRadius: 10,
					borderTopLeftRadius: 10,
				}}
				onClick={() => {
					setDropdown(!dropdown)
				}}
			/>
			{dropdown ? (
				<View
					style={{
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
						background: COLORS.gray,
						width: '100%',
						borderBottomRightRadius: 10,
						borderBottomLeftRadius: 10,
					}}>
					{data.map((item, index) => (
						<CButton
							onClick={() => {
								setDisplayChange(item)
							}}
							icon={<IconCircleCheck size={18} />}
							title={t(item)}
							key={index.toString()}
							style={{
								padding: 10,
								alignSelf: 'flex-start',
								color:
									item === displayChange
										? COLORS.BTN_HOVER
										: COLORS.lightGray2,
								flexDirection: 'row-reverse',
							}}
							textStyle={{
								marginLeft: 5,
								marginTop: 1,
							}}
						/>
					))}
				</View>
			) : null}
			<CButton
				icon={<IconReload size={16} />}
				title={t('revertToDefault')}
				style={{
					color: COLORS.BTN_HOVER,
					padding: 10,
					paddingLeft: 0,
					flexDirection: 'row-reverse',
					alignSelf: 'flex-start',
				}}
				textStyle={{ marginLeft: 5, fontSize: 14 }}
			/>
		</PreferenceDrawerBodyWrapper>
	)
}

export default PreferenceDrawerTheme
