import styled from 'styled-components'

export const TradeChartContainer = styled.div`
	background-color: ${props => props.theme.COLORS.bgColor};
	height: 100%;
	width: 100%;
	@media (max-width: 900px) {
		width: 100%;
		height: 100%;
	}
`
