import React, { useContext } from 'react'

import { OrderValueInputSection } from '../styles'
import { CInput } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import { useTradeStore } from '../../../../../../../hooks/trade/tradeStore'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { ThemeContext } from 'theme/ThemeProvider'
import { useOrderStore } from '../../../../../../../hooks/trade/orderStore'
import { Button, Space } from 'antd'
import { IconMinus, IconPlus } from '@tabler/icons-react'
import { FONTS } from '../../../../../../../constants'

const OrderValueInput = () => {
	const { textw, onSecondary, point } = useContext(ThemeContext).theme.COLORS

	const { data: tradeStore, mutate: mutateTradeStore } = useTradeStore()
	const { data: orderStore, change: mutateOrderStore } = useOrderStore()
	const { change: mutateModalStore } = useTradeModalStore()

	if (orderStore?.amount === undefined) {
		mutateOrderStore({ amount: 1 })
	}
	const setOrderAmount = data => {
		if (isNaN(data)) return

		const min = tradeStore?.min_amount || 1

		let temp = data
		if (temp < min) temp = min

		mutateOrderStore({
			amount: temp,
		})
	}

	return (
		<OrderValueInputSection>
			<Text style={{
				color: textw,
				...FONTS.h7,
			}}>주문수량</Text>
			<Space size="small" style={{ alignItems: 'center' }}>
				<Button
					size="small"
					icon={<IconMinus />}
					onClick={() => {
						if (!orderStore?.amount || isNaN(orderStore?.amount)) {
							return
						}

						if (+orderStore?.amount <= 1) {
							return
						}

						setOrderAmount(orderStore?.amount - 1)
					}}
					style={{
						color: point,
						justifyContent: 'center',
						fontSize: 15,
						alignItems: 'center',
						minHeight: 34,
					}}
				/>
				<CInput
					placeholder={''}
					value={orderStore?.amount}
					type={'text'}
					onChange={(e: any) =>
						setOrderAmount(parseInt(e.target.value + ''))
					}
					vStyle={{ padding: 0, flexDirection: 'row', width: '100%' }}
					style={{
						background: onSecondary,
						color: '#2B2E33',
						border: 'none',
						flex: 1,
						padding: 0,
						fontSize: 15,
						width: '100%',
						minHeight: 34,
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center',
						marginTop: 10,
					}}
				/>

				<Button
					size="small"
					icon={<IconPlus />}
					onClick={() => {
						setOrderAmount((orderStore?.amount || 0) + 1)
					}}
					style={{
						color: point,
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: 34,
					}}
				/>
				<Button
					size="small"
					type={undefined}
					style={{
						color: textw,
						whiteSpace: 'nowrap',
						fontSize: 12,
						letterSpacing: -0.5,
						minHeight: 34,
					}}>
					{tradeStore?.coin_type}
				</Button>
			</Space>
		</OrderValueInputSection>
	)
}

export default OrderValueInput
