import React, { useContext } from 'react'
import { HomeEventSection, SectionWrap } from './styles'
// import HomeRightSides from './HomeRightSide'
// import HomeLeftSide from './HomeLeftSide'
import { ThemeContext } from 'theme/ThemeProvider'
import HomeLeftSide from './HomeLeftSide'
import { useTranslation } from 'react-i18next'
import mainbggrow from 'assets/images/mainbggrow.png'

const HomeEvent = () => {
	const { isDarkMode, theme } = useContext(ThemeContext)
	const { t } = useTranslation()

	return (
		<HomeEventSection
			isDarkMode={isDarkMode}
			style={{ flexDirection: 'column' }}>
			<SectionWrap
				style={{
					marginTop: 50,
					background: `url(${mainbggrow}) center no-repeat`,
				}}>
				<HomeLeftSide />
			</SectionWrap>
		</HomeEventSection>
	)
}

export default HomeEvent
