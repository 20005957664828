import React, { useContext } from 'react'
import { View } from 'styles/reactnative'
import PopUpConfirmation from './PopUpConfirmation'
import PreferenceNotification from './PreferenceNotification'
import { ThemeContext } from 'theme/ThemeProvider'
import PreferenceListItems from './PreferenceListItems'

const PreferenceGeneral = () => {
	const { gray } = useContext(ThemeContext).theme.COLORS
	
	return (
		<View
			style={{
				width: '100%',
				borderBottom: `1px solid ${gray}`,
				paddingBottom: 15,
			}}>
			<PopUpConfirmation />
			<PreferenceNotification />
		</View>
	)
}

export default PreferenceGeneral
