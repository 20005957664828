import BigNumber from 'bignumber.js'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { MView } from '../../../../../../styles/mobilePageStyles'
import { ThemeContext } from '../../../../../../theme/ThemeProvider'

const AssetDerivativesTableList = ({ data }) => {
	BigNumber.clone() // new line
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				width: '100%',
			}}>
			{data?.map((item: any, index: number) => (
				<View
					key={index.toString()}
					style={{
						width: '100%',
						minHeight: 50,
						flexDirection: 'row',
					}}>
					<Text
						style={{
							fontSize: 11,
							width: `${100 / data?.length}%`,
							color: textw,
						}}>
						{item?.coin}
					</Text>
					<MView
						style={{
							width: `${100 / data?.length}%`,
							color: textw,
							alignItems: 'center',
						}}>
						<Text
							style={{
								fontSize: 11,
								color: textw,
								flex:1,
							}}>
							{BigNumber(item?.availableBalance || 0).toFormat(0)}
							원
						</Text>
					</MView>
					<Text
						style={{
							fontSize: 11,
							width: `${100 / data?.length}%`,
							color: textw,
						}}>
						{item?.lockBalance}
							원
					</Text>
				</View>
			))}
		</View>
	)
}

export default AssetDerivativesTableList
