import useSWR from 'swr'
import { awsUploadImage } from 'utils/awsImageStorage'
import API from '../../utils/api'
import { useToken } from './auth/token'
import { getPersistData } from './persist'
import { POPUP } from '../constants/APIKEYS'

const KEY = POPUP

let swrData: any = getPersistData(KEY)

export function usePopup() {
	const { data: token } = useToken()

	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			const response = await API.get(KEY, token + '', {}, false)
			// console.log(response)

			swrData = response?.result?.list

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		data,
		mutate: (value?: any) => {
			swrData = value
			return mutate()
		},
	}
}
