import { IconEye } from '@tabler/icons-react'
import BigNumber from 'bignumber.js'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { SETTING_TYPE_SYSTEM } from '../../../../constants/SETTING'
import { useFetcherPublic } from '../../../../hooks/fetcher'
import { useBalance } from '../../../../hooks/user/mybalance'
import { getSetting, useSetting } from '../../../../hooks/user/mysetting'
import { AssetsHeaderSection } from './styles'
import { Badge, Divider } from 'antd'
import { CButton, CModal } from '../../../../components/Common'
import { COLORS } from '../../../../constants'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { TransferModal } from '../../../../components'
import { COINHIDE } from '../../../../constants/APIKEYS'

const AssetsHeader = () => {
	const { textsilver, textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()

	const { data: spot } = useBalance('USDT', 0)
	const { data: futures } = useBalance('USDT', 1)
	const { data: global } = useBalance('KRW', 9)

	const total = BigNumber(global?.balance || 0).toFormat(0)

	const { data: setting } = useSetting(SETTING_TYPE_SYSTEM)
	const currency = getSetting(setting, 'CURRENCY', 'KRW')

	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()

	const { data: result } = useFetcherPublic('/system/currency')
	const currentCurrency = result?.result?.result?.find(
		(item: any) => item.code === currency
	)
	const total2 = BigNumber(spot?.balance || 0).plus(futures?.balance || 0)

	const equivalent = BigNumber(total2.toNumber())
		.multipliedBy(currentCurrency?.price || 1)
		.toFormat(0)

	return (
		<AssetsHeaderSection onClick={() => {}}>
			<View style={{ flexDirection: 'row' }}>
				<Text size={16} style={{ color: textsilver, marginRight: 10 }}>
					{t('assetsOverview')}
				</Text>
			</View>
			<View
				style={{
					flexDirection: 'row',
					marginTop: 20,
					marginBottom: 10,
				}}>
				<Text size={16}>{total}</Text>
				<Text size={14} style={{ marginLeft: 10 }}>
					원
				</Text>
			</View>

			{!COINHIDE && (
				<>
					<Badge.Ribbon
						text={t('Coin asset')}
						color="gray"
						placement="start">
						<View
							style={{
								background: '#efefef',
								padding: 10,
								width: '100%',
								borderRadius: 10,
								marginTop: 20,
								paddingTop: 15,
							}}>
							<View
								style={{ flexDirection: 'row', marginTop: 5 }}>
								<Text size={13} style={{ color: '#555' }}>
									{total2.toFormat(2)}
								</Text>
								<Text
									size={13}
									style={{ color: '#555', marginLeft: 10 }}>
									USDT
								</Text>
							</View>
							<View
								style={{ flexDirection: 'row', marginTop: 5 }}>
								<Text size={12} style={{ color: '#555' }}>
									≈ {equivalent}
								</Text>
								<Text
									size={12}
									style={{ marginLeft: 10, color: '#555' }}>
									원
								</Text>
							</View>
						</View>
					</Badge.Ribbon>

					<CButton
						title={t('transfer')}
						textStyle={{ color: COLORS.black }}
						onClick={() => {
							muatateModalStore({
								...modalStore,
								transferModal: true,
							})
						}}
						// icon={<IconArrowBarToDown size={18} />}
						style={{
							borderWidth: 1,
							background: COLORS.secondary,
							color: COLORS.white,
							paddingBlock: 3,
							paddingInline: 8,
						}}
						iconLeft={true}
						textHover={COLORS.white}
					/>
				</>
			)}
		</AssetsHeaderSection>
	)
}

export default React.memo(AssetsHeader)
