import React from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { ReferralCodeDetailSection } from './styles'
import { IconCopy } from '@tabler/icons-react'
import { CButton } from 'app/components/Common'
import { useFetcher } from '../../../../hooks/fetcher'
import { toast } from 'react-toastify'
import { copyTextToClipboard } from '../../../../../utils/ciutils'

const ReferralCodeDetail = ({ referralCode }) => {
	const { t } = useTranslation()
	const { data } = useFetcher('/referral/code', false)
	const referral = data?.result?.result?.code
	return (
		<ReferralCodeDetailSection>
			<Text size={15} style={{ color: COLORS.black }}>
				{t('referralCode')}
			</Text>
			<View
				style={{
					width: '100%',
					background: '#f1f1f1',
					border: '1px solid #ddd',
					alignItems: 'flex-start',
					padding: 7,
					borderRadius: 5,
					marginTop: 5,
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
				<Text size={14} style={{ color: COLORS.black }}>
					{referral}
				</Text>
				<CButton
					onClick={() => {
						toast(`${t('copied')}`)
						copyTextToClipboard(referralCode)
					}}
					icon={<IconCopy size={16} color={COLORS.secondary} />}
				/>
			</View>
		</ReferralCodeDetailSection>
	)
}

export default ReferralCodeDetail
