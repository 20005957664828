import BigNumber from 'bignumber.js'
import React, { useEffect, useState } from 'react'
import { useInfo24H } from '../../../hooks/trade/info24H'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import DefaultKrw from './DefaultKrw'

const LowPrice = () => {
	const { symbol, dp } = useTradeStore()
	const { data, getinfo24, refresh } = useInfo24H(symbol)
	
	const [low, setLow] = useState<any>(getinfo24(symbol)?.low)
	
	const info24 = getinfo24(symbol)
	const beforeclose = BigNumber(info24?.open).minus(info24?.change).toNumber()

	useEffect(() => {
		setLow(getinfo24(symbol)?.low)
	}, [refresh, data])

	return (
		<DefaultKrw
			title={'저가'}
			price={low || 0}
			dp={dp}
			krw={false}
			color={true}
			comma={false}
			center={true}
			colordiff={
				beforeclose < info24?.low
					? 'up'
					: beforeclose > info24?.low
					? 'down'
					: 'same'
			}
		/>
	)
}

export default LowPrice
