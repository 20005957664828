import { throttle } from '@rowsncolumns/grid'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useFps } from 'react-fps'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import CanvasList2 from '../../../../components/Common/CFlatList/CanvasList2'
import { useIndexPrice } from '../../../../hooks/trade/indexprice'
import { useInfo24H } from '../../../../hooks/trade/info24H'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { useOrderBook } from '../../../../hooks/trade/orderBook'
import { useTPLStore } from '../../../../hooks/trade/tplStore'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'
import { useTradeStore } from '../../../../hooks/trade/tradeStore'

let worker = new Worker('/worker.js')

const OrderBookTableMobile3 = ({
	newwindow,
	parentHeight,
	parentWidth,
	isblur = false,
}) => {
	const [data, setData] = useState<any>([])
	const [sumdata, setSumdata] = useState<any>([])

	const { textw } = React.useContext(ThemeContext).theme.COLORS

	const [maxSize, setMaxSize] = useState(40)
	const [max, setMax] = useState(0)

	const { currentposition, waitlist } = useTradePosition()
	const { data: tradeStore, unit, dp, symbol } = useTradeStore()
	const ep_id = tradeStore?.ep_id

	const { currentFps } = useFps(15)
	const [fps, setFps] = useState(0)
	useEffect(() => {
		if (currentFps >= 120) {
			setFps(10)
		} else if (currentFps >= 50) {
			setFps(50)
		} else if (currentFps > 40) {
			setFps(100)
		} else if (currentFps > 20) {
			setFps(350)
		} else {
			setFps(500)
		}
	}, [currentFps])
	const { data: info24h } = useInfo24H(symbol)

	const { getPrice } = useIndexPrice(ep_id)
	const { data: tplStore } = useTPLStore()

	const { data: modalStore, change } = useTradeModalStore()

	const { data: orderBook, long } = useOrderBook(ep_id)

	const longPrev = useRef<any>(null)
	const [currentprice, setCurrentprice] = useState(0)

	if (modalStore?.fixed_orderbook == undefined) {
		change({ fixed_orderbook: true })
	}

	useEffect(() => {}, [orderBook])

	useEffect(() => {
		if (modalStore?.fixed_orderbook) {
			setMaxSize(parseInt((parentHeight - 170) / 100 + ''))
			setCurrentprice(getPrice(ep_id))
		} else {
			setMaxSize(Math.max(maxSize, 150))
			setCurrentprice(getPrice(ep_id))
		}
	}, [tradeStore?.ep_id, modalStore?.fixed_orderbook, parentHeight, ep_id])

	if (long) {
		if (longPrev.current !== long) {
			longPrev.current = long
		}
	}
	useEffect(() => {
		const price = longPrev?.current?.length
			? longPrev?.current[0]?.price
			: getPrice(ep_id)
		if (!modalStore?.fixed_orderbook) return
		setCurrentprice(price)
	}, [parentHeight, long, ep_id])

	// 웹 워커 메시지 이벤트 핸들러
	const workerMessageHandler = useCallback(evt => {
		const recvdata = evt.data

		//헤더 넣기

		// undefined 제거
		const temp = [
			{
				header: true,
			},
			...recvdata.data?.filter(item => item !== undefined),
		]

		setData(temp)
		setSumdata(recvdata.sumdata)
		setMax(recvdata.max)
	}, [])

	useEffect(() => {
		worker.addEventListener('message', workerMessageHandler)
		return () => {
			// 컴포넌트 언마운트 시 이벤트 핸들러 제거
			worker.removeEventListener('message', workerMessageHandler)
		}
	}, [workerMessageHandler])

	const sendOrder = useCallback(
		throttle(
			(
				orderBook,
				waitlist,
				currentposition,
				tplStore,
				modalStore,
				currentprice,
				unit,
				dp,
				maxSize,
				price,
				info24h,
				ep_id
			) => {
				if (!worker) return

				const data = tplStore
					?.filter((i: any) => i.key == 'TPL' + ep_id)
					?.map(d => {
						const [profit, loss, p, l] = d?.value?.split(':') || [
							5, 5, 0, 0,
						]
						return {
							profit: p == 1 ? true : false,
							profittick: +profit || 5,
							loss: l == 1 ? true : false,
							losstick: +loss || 5,
						}
					}) || [
					{
						profit: false,
						profittick: 5,
						loss: false,
						losstick: 5,
					},
				]

				worker.postMessage({
					orderBook,
					waitlist,
					currentposition,
					orderStore: data[0],
					modalStore,
					currentprice,
					unit,
					dp,
					maxSize,
					price,
					info24h,
				})
			},
			fps
		),
		[ep_id]
	)

	if (worker != null) {
		sendOrder(
			orderBook,
			waitlist,
			currentposition,
			tplStore,
			modalStore,
			currentprice,
			unit,
			dp,
			maxSize,
			getPrice(ep_id),
			info24h,
			ep_id
		)
	}

	return (
		<>
			{data?.length && parentHeight > 100 && (
				<CanvasList2
					max={max}
					data={data}
					parentHeight={parentHeight}
					maxSize={maxSize}
					setMaxSize={setMaxSize}
					parentWidth={Math.max(
						parentWidth +
							5 -
							(modalStore?.fixed_orderbook ? 3 : 10),
						100
					)}
					is_mobile={true}
				/>
			)}
		</>
	)
}

export default React.memo(OrderBookTableMobile3)
