import React from 'react'
import { DepositLeftSectionWrapper } from '../styles'
import DepositAutoChanneled from './DepositAutoChanneled'
import DepositChainType from './DepositChainType'
import DepositCoins from './DepositCoins'
import DepositQrCode from './DepositQrCode'
import DepositWalletAddress from './DepositWalletAddress'
import GDeposit from '../../../../organisms/Header/GDeposit'

const DepositLeftSection = () => {
	return (
		<DepositLeftSectionWrapper>
			<GDeposit mobile={true} />
		</DepositLeftSectionWrapper>
	)
}

export default DepositLeftSection
