import React, { useContext } from 'react'
import { MText } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { MAnnouncementItemSection } from './styles'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const MAnnouncementItems = ({ item, show, setShow }: any) => {
	const { textsilver } = useContext(ThemeContext).theme.COLORS
	return (
		<MAnnouncementItemSection
			onClick={() => {
				setShow &&
					setShow({
						show: true,
						...item,
					})
			}}>
			<MText style={{ fontWeight: '300' }}>{item?.title}</MText>
			<MText style={{ color: textsilver, fontSize: 10, paddingTop: 8 }}>
				{dayjs(item?.rdate)
					.tz('Asia/Seoul')
					.format('YYYY-MM-DD HH:mm:ss')}
			</MText>
		</MAnnouncementItemSection>
	)
}

export default MAnnouncementItems
