import { IconDeviceMobile, IconTrash } from '@tabler/icons-react'
import { MButton } from 'app/mobile/components'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'

const TrustedDeviceList = () => {
	const { textw, borderColor, cardOpacity } =
		useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<MView
			style={{
				width: '100%',
				background: cardOpacity,
				marginTop: 5,
				borderRadius: 5,
			}}>
			<MView
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					borderBottom: `1px solid ${borderColor}`,
					width: '100%',
					padding: 7,
				}}>
				<MView style={{ flexDirection: 'row', alignItems: 'center' }}>
					<IconDeviceMobile size={14} color={textw} />
					<MText style={{ fontWeight: '300' }}>
						Chrome/109.0.0.0. Safari/537.36
					</MText>
				</MView>
				<MButton
					onClick={() => {
						toast(t('notWorkingNow') + '')
					}}
					icon={<IconTrash size={16} color={textw} />}
				/>
			</MView>
			<MView style={{ padding: 5 }}>
				<MView
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						paddingTop: 5,
					}}>
					<MText>{t('time')}</MText>
					<MText style={{ marginLeft: 5, fontWeight: '300' }}>
						2023-02-08 10:17:50
					</MText>
				</MView>
				<MView
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						paddingTop: 10,
					}}>
					<MText>{t('loginLocation')}</MText>
					<MText style={{ marginLeft: 5 }}>South Korea</MText>
				</MView>
				<MView
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						paddingTop: 10,
					}}>
					<MText>{t('ipAddress')}</MText>
					<MText style={{ marginLeft: 5 }}>211.19.234.115</MText>
				</MView>
			</MView>
		</MView>
	)
}

export default TrustedDeviceList
