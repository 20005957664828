import BigNumber from 'bignumber.js'
import React from 'react'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { useWindowDimensions } from '../../../hooks'
import { useFetcherPublic } from '../../../hooks/fetcher'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import DefaultKrw from './DefaultKrw'

const TickValue = ({row='column'}) => {
	const { width } = useWindowDimensions()
	const { isMobile } = React.useContext(ThemeContext)
	const { data: tradeStore } = useTradeStore()
	const currencyList = useFetcherPublic('/system/currency')

	const equivusd = currencyList?.data?.result?.result?.find(
		(item: any) => item?.code === 'USD'
	)

	const equiv = currencyList?.data?.result?.result?.find(
		(item: any) => item?.code === tradeStore?.currency
	)

	const equivkrw = currencyList?.data?.result?.result?.find(
		(item: any) => item?.code === 'KRW'
	)

	const currencyrate = BigNumber(equivusd?.price || 0)
		.multipliedBy(equivkrw?.price || 0)
		.dividedBy(equiv?.price || 0)
		.toFixed(2)

	const tickvalue = BigNumber(+tradeStore?.contract_amount || 0)
		.multipliedBy(+currencyrate)
		.multipliedBy(+tradeStore?.unit)
		.toNumber()

	return (
		<DefaultKrw
			title={'틱가치'}
			price={tickvalue}
			dp={0}
			row={row}
			krw={true}
			color={false}
			center={isMobile ? true : false}
		/>
	)
}

export default React.memo(TickValue)
