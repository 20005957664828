import React from 'react'
import { View } from 'styles/reactnative'
import { TitleText, TradeListTitleSection } from './styles'
import DownUpBtns from './DownUpBtns'
import { useTranslation } from 'react-i18next'

const TradeListTitle = ({ top, setTop }) => {
	const { t } = useTranslation()
	return (
		<TradeListTitleSection>
			<View
				onClick={() => {
					setTop(prev => (prev === 1 ? 2 : prev === 2 ? 0 : 1))
				}}
				style={{
					width: '65%',
					cursor: 'pointer',
					alignItems: 'center',
					justifyContent: 'flex-start',
					flexDirection: 'row',
					paddingLeft: 10,
				}}>
				<TitleText>{t('tradingPairs')}</TitleText>
				<DownUpBtns top={top} setTop={setTop} />
			</View>
			<View
				style={{
					width: '35%',
					alignItems: 'center',
					justifyContent: 'flex-end',
					flexDirection: 'row',
				}}>
				<TitleText>{t('price')}</TitleText>
			</View>
			{/* <View
				style={{
					width: '20%',
					alignItems: 'center',
					justifyContent: 'flex-end',
					flexDirection: 'row',
				}}>
				<TitleText style={{ fontSize: 12 }}>{t('volume')}</TitleText>
				<DownUpBtns top={top} setTop={setTop} />
			</View> */}
		</TradeListTitleSection>
	)
}

export default TradeListTitle
