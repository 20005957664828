import React, { useState, useContext } from 'react'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import PreferenceListItems from './PreferenceListItems'
import { ThemeContext } from 'theme/ThemeProvider'

const PopUpConfirmation = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const [display, setDisplay] = useState(false)
	const { t } = useTranslation()

	const icons = display ? (
		<IconChevronUp color={COLORS.lightGray} size={16} />
	) : (
		<IconChevronDown color={COLORS.lightGray} size={16} />
	)
	return (
		<View style={{ width: '100%' }}>
			<CButton
				title={t('popUpConfirmationWindows')}
				onClick={() => {
					setDisplay(!display)
				}}
				style={{
					color: textw,
					justifyContent: 'space-between',
					width: '100%',
					marginTop: 20,
				}}
				icon={icons}
			/>
			{display ? (
				<View
					style={{
						width: '100%',
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
					}}>
					<PreferenceListItems
						title={'orderConfirmation'}
						content={'orderConfirmationDesc'}
					/>
					<PreferenceListItems
						title={'canelAllOrders'}
						content={'canelAllOrdersDesc'}
					/>
					<PreferenceListItems
						title={'closeAll'}
						content={'closeAllDesc'}
					/>
					<PreferenceListItems
						title={'orderBookFlashAnimation'}
						content={'orderBookFlashAnimationDesc'}
					/>
					<PreferenceListItems
						title={'quickClose'}
						content={'quickCloseDesc'}
					/>
					<PreferenceListItems
						title={'quickCancel'}
						content={'quickCancelDesc'}
					/>
					<PreferenceListItems
						title={'quickReversal'}
						content={'quickReversalDesc'}
					/>
					<PreferenceListItems content={'theQuickCancelFeature'} />
				</View>
			) : null}
		</View>
	)
}

export default PopUpConfirmation
