import { IconArrowsMove, IconMaximize } from '@tabler/icons-react'
import { Button } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { useWindowDimensions } from '../../../hooks'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'
import { View } from '../../../../styles/reactnative'

const DesktopTop = ({}) => {
	const { isDarkMode } = useContext(ThemeContext)
	const { stroke } = useContext(ThemeContext).theme.COLORS
	const { data: store, mutate } = useTradeStore()

	const { data: tradeModalStore, mutate: muateTradeModalStore } =
		useTradeModalStore()
	return (
		<View style={{ flexDirection: 'row' }} className="widgetCloseWrap">
			<View style={{ flexDirection: 'row' }} className="widgetClose2">
				<Button
					size={'small'}
					className="drag-handle"
					icon={<IconArrowsMove size={14} />}
					style={{
						fontSize: 12,
						paddingBottom: 3,
					}}></Button>
				<Button
					size={'small'}
					style={{
						display: 'flex !important',
						marginLeft: 3,
						fontSize: 14,
					}}
					onClick={() => {
						muateTradeModalStore({
							...tradeModalStore,
							orderbookshow: tradeModalStore?.orderbookshow
								? false
								: true,
						})
					}}>
					X
				</Button>
			</View>
			<Button
				size={'small'}
				icon={<IconMaximize size={14} />}
				style={{
					fontSize: 12,
					marginLeft: 3,
					paddingBottom: 3,
				}}
				onClick={() => {
					mutate(store)
					window.open(
						'/trade/orderbook/' +
							store?.ep_id +
							'/' +
							(!isDarkMode ? 'dark' : 'light'),
						'_blank',
						'width=480,height=760,resizable=yes, status=no, toolbar=no, menubar=no, location=no'
					)
				}}></Button>
		</View>
	)
}

export default React.memo(DesktopTop)
