import { Col } from 'antd'
import throttle from 'lodash/throttle' // Lodash 추가
import React, { useCallback, useEffect, useState } from 'react'
import { Text } from '../../../../styles/reactnative'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { useWindowDimensions } from '../../../hooks'
import KrwPrice from '../../KrwPrice'

const DefaultKrw = ({
	title,
	price,
	dp,
	krw = false,
	color = false,
	text = false,
	percent = false,
	row = 'column',
	center = false,
	comma = true,
	colordiff = '',
	rate = '',
}) => {

	const { isMobile } = React.useContext(ThemeContext)
	const { width } = useWindowDimensions()
	const { textw, bgColor, Tertiary, borderColor } =
		React.useContext(ThemeContext).theme.COLORS

	let fontSize =
		width >= 1600
			? '1.2rem'
			: width >= 1400
				? '1.1rem'
				: width >= 1250
					? '1rem'
					: width >= 900 ?
						'1rem'
						: '1.2rem'

	if (width < 900 && title == '틱가치') {
		fontSize = '0.9rem'

	}

	const [throttledPrice, setThrottledPrice] = useState(price)

	// useCallback을 사용하여 함수 메모이제이션
	const throttledUpdatePrice = useCallback(
		throttle(newPrice => {
			// 여기서 가격 업데이트 로직을 실행합니다.
			setThrottledPrice(newPrice) // 쓰로틀된 가격을 상태로 업데이트
		}, 300), // 2초마다 최대 1번 실행
		[] // 의존성 배열 비움
	)

	useEffect(() => {
		// price 값이 변경될 때 쓰로틀링된 함수 실행
		throttledUpdatePrice(price)
	}, [price, throttledUpdatePrice]) // 함수와 price를 의존성 배열에 포함

	let row2: any = row

	return (
		<Col
			style={{
				height: '100%',
				flex: 1,
				padding: '0px',
				flexDirection: row2,
				display: 'flex',
			}}>
			{isMobile ? (

				<Text
					style={{
						fontSize: '1.1rem',
						color: Tertiary,
						whiteSpace: 'nowrap',
						padding: '1px 0',
					}}>
					{title}
				</Text>
			) : (

				<Text
					style={{
						fontSize:
							width >= 1600
								? '1.2rem'
								: width >= 1400
									? '0.9rem'
									: '0.8rem',
						color: Tertiary,
						whiteSpace: 'nowrap',
						padding: '3px 0',
					}}>
					{title}
				</Text>
			)}

			<div
				style={{
					padding: width >= 1300 ? '3px 0' : '1px 0',
					display: 'flex',
					flexDirection: row2,
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				{text ? (
					<Text
						style={{
							fontSize: fontSize,
							whiteSpace: 'nowrap',
							fontWeight: 500,
							letterSpacing: -0.6,
							color: textw,
							padding: '0',
						}}>
						{throttledPrice}
						{percent ? '%' : ''}
					</Text>
				) : (
					<KrwPrice
						margin={true}
						price={throttledPrice}
						dp={dp || 0}
						krw={krw}
						color={color}
						fontSize={fontSize}
						percent={percent}
						center={center}
						colordiff={colordiff}
						rate={rate}
						comma={comma}
					/>
				)}
			</div>
		</Col>
	)
}

export default React.memo(DefaultKrw)
