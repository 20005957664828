import React from 'react'

import { ReferralEventSection, RefferalSection } from '../styles'
import EventTitle from './EventTitle'
import ReferralCode from './ReferralCode'
import { useMyProfile } from '../../../hooks/user/myprofile'
import { Button, Space } from 'antd'

const ReferralEvent = () => {
	return (
		<RefferalSection>
			<ReferralEventSection>
				<EventTitle />
				<ReferralCode />
			</ReferralEventSection>
		</RefferalSection>
	)
}

export default ReferralEvent
