import React, { useState } from 'react'
import SubDropdownLists from '../SubDropdownLists'

import { UsdcPerpetualContent } from './styles'

interface IProps {
	label?: string
	data?: any
}
const UsdcPerpetualTrading = ({ label, data }: IProps) => {
	const [headerTab, setHeaderTab] = useState('all')
	const types = ['all']
	return (
		<UsdcPerpetualContent>
			<SubDropdownLists
				headerTab={headerTab}
				setHeaderTab={setHeaderTab}
				types={types}
				pairs={2}
			/>
		</UsdcPerpetualContent>
	)
}

export default UsdcPerpetualTrading
