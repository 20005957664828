import useSWR from 'swr'
import API from '../../../utils/api'
import { MYBALANCE, MYLOCKBALANCE } from '../../constants/APIKEYS'
import { useToken } from '../auth/token'
// import { getPersistData } from '../persist'

// const KEY = MYBALANCE

export function useLockBalance(symbol?: string, type = 9) {
	const { data: token } = useToken()
	if (symbol === undefined) symbol = 'KRW'
	let SWRKEY: any = MYLOCKBALANCE + '?symbol=' + symbol + '&type=' + type

	if (!token) SWRKEY = null

	const { data, mutate } = useSWR<any>(
		SWRKEY,
		async () => {
			if (symbol === undefined) return 0
			const response = await API.get(SWRKEY, token + '', {}, false)
			return response.result.result
		},
		{
			revalidateOnFocus: true,
			revalidateOnReconnect: true,
			revalidateIfStale: true,
			dedupingInterval: 0,
		}
	)

	return {
		data,
		mutate: (value?: any) => {
			return mutate()
		},
	}
}
