import React, { useContext, useEffect, useState } from 'react'

import { Button, Checkbox, Input, Space, Switch } from 'antd'
import { useOrderStore } from '../../../hooks/trade/orderStore'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'
import { ThemeContext } from '../../../../theme/ThemeProvider'

const MobileTop = ({}) => {
	const { data: tradeModalStore, mutate: muateTradeModalStore } =
		useTradeModalStore()

	const { textw } = useContext(ThemeContext).theme.COLORS

	const { data: orderStore, change: mutateOrderStore } = useOrderStore()

	const [amt, setAmt] = useState(orderStore?.amount || 1)

	useEffect(() => {
		setAmt(orderStore?.amount)
	}, [orderStore?.amount])

	return (
		<Space
			style={{
				justifyContent: 'center',
				alignContent: 'center',
			}}>
			<Space.Compact
				direction="horizontal"
				style={{ width: '100%', gap: 3 }}>
				<label style={{ color: textw }}>
					<Checkbox
						checked={tradeModalStore?.oneClick ? true : false}
						onChange={e => {
							setImmediate(() => {
								muateTradeModalStore({
									...tradeModalStore,
									oneClick: e.target.checked,
								})
							})
						}}
					/>
					원클릭
				</label>
				<label style={{ color: textw }}>
					<Checkbox
						checked={tradeModalStore?.skip ? true : false}
						onChange={e => {
							setImmediate(() => {
								muateTradeModalStore({
									...tradeModalStore,
									skip: e.target.checked,
								})
							})
						}}
					/>
					확인생략
				</label>
				<label style={{ color: textw }}>
					<Checkbox
						checked={
							tradeModalStore?.fixed_orderbook ? true : false
						}
						onChange={e => {
							setImmediate(() => {
								muateTradeModalStore({
									...tradeModalStore,
									fixed_orderbook: e.target.checked,
								})
							})
						}}
					/>
					호가고정
				</label>
			</Space.Compact>
			<label>수량</label>
			<Space.Compact
				size="small"
				style={{
					alignItems: 'center',
					marginRight: 5,
				}}>
				<Input
					size="small"
					style={{ width: 50, fontWeight: 'bold' }}
					type="number"
					value={amt}
					onChange={e => {
						setAmt(e.target.value)

						mutateOrderStore({
							amount: e.target.value,
						})
					}}
				/>
			</Space.Compact>
			<Space.Compact style={{ alignItems: 'center' }} size="small">
				<Button
					size="small"
					style={{ minWidth: 25 }}
					onClick={() => {
						mutateOrderStore({
							amount: orderStore?.order_short1 || 1,
						})
					}}>
					{isNaN(orderStore?.order_short1)
						? 1
						: orderStore?.order_short1}
				</Button>
				<Button
					size="small"
					style={{ minWidth: 25 }}
					onClick={() => {
						mutateOrderStore({
							...orderStore,
							amount: orderStore?.order_short2 || 2,
						})
					}}>
					{isNaN(orderStore?.order_short2)
						? 2
						: orderStore?.order_short2}
				</Button>
				<Button
					size="small"
					style={{ minWidth: 25 }}
					onClick={() => {
						mutateOrderStore({
							amount: orderStore?.order_short3 || 3,
						})
					}}>
					{isNaN(orderStore?.order_short3)
						? 3
						: orderStore?.order_short3}
				</Button>
				<Button
					size="small"
					style={{ minWidth: 25 }}
					onClick={() => {
						mutateOrderStore({
							amount: orderStore?.order_short6 || 10,
						})
					}}>
					{isNaN(orderStore?.order_short6)
						? 10
						: orderStore?.order_short6}
				</Button>
				<Button
					size="small"
					style={{ background: '#eee' }}
					onClick={() => {
						muateTradeModalStore({
							...tradeModalStore,
							setOrder: true,
						})
					}}>
					설정
				</Button>
			</Space.Compact>
		</Space>
	)
}

export default React.memo(MobileTop)
