import { STORE_EXCHANGE } from 'app/constants/LOCALKEYS'
import useSWR from 'swr'
import { getPersistData } from '../persist'
import { useToken } from '../auth/token'
import API from '../../../utils/api'
import { useMemo } from 'react'
import { API_COINURL } from '../../constants/APIKEYS'
import { useCoinGlobal } from '../stateStore/coinGlobal'

const KEY = STORE_EXCHANGE

let swrData: any = getPersistData(KEY+"@ALL")

export function useExchangeStoreAll() {
	const { data: coinglobal } = useCoinGlobal()

	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY + '@ALL',
		async () => {
			const result = await API.get(
				'/exchanges?id=0&isall=1',
				token + '',
				{},
				false,
				coinglobal == 'C' ? API_COINURL : undefined
			)
			swrData = result.result
			return swrData
		},
		{
			refreshInterval: 1000 * 60 * 60,
			dedupingInterval: 1000 * 60,
		}
	)

	const EPs = useMemo(
		() =>
			data?.filter((item: any) => {
				const now = new Date()
				// - 를 / 로 변경
				const enddate = new Date(
					item?.enddate?.replace(/-/g, '/') + ' 23:59:59'
				)

				return enddate >= now
			}),
		[data, coinglobal]
	)

	return {
		data,
		EPs: EPs,
		mutate: (value?: any) => {
			if (value !== undefined) swrData = value
			return mutate()
		},
	}
}
