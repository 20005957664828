import React, { useEffect, useState } from 'react'
import { useTradeStore } from '../../../../hooks/trade/tradeStore'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { Button, Checkbox, Col, Input, Modal, Row, Space } from 'antd'
import { TradeListItemSection } from '../../../../components/Trade/TradeListItem/styles'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { useOrderStore } from '../../../../hooks/trade/orderStore'
import OvernightSetting from '../../TradeTransaction/DerivativesTransaction/DerivativesBody/OvernightSetting'
import ColAva from './ColAva'
import { useLogQueue } from '../../../../hooks/trade/logQueue'
import { useTPLStore } from '../../../../hooks/trade/tplStore'
import { trade } from '../../../../constants/UIData/HEADER'
import { set } from 'shelljs'

const OrderSetting = ({ type = 0 }) => {
	const { isDarkMode, theme, isMobile } = React.useContext(ThemeContext)
	const textw = theme.COLORS.textw

	const { data: tradeStore } = useTradeStore()
	const { data: orderStore, change: mutateOrderStore } = useOrderStore()

	const { data: tplStore, changeTPL } = useTPLStore()

	const [profit, setProfit] = useState(false)
	const [loss, setLoss] = useState(false)
	const [profittick, setProfittick] = useState(5)
	const [losstick, setLosstick] = useState(5)

	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()
	const [amt, setAmt] = useState(orderStore?.amount || 1)

	const { mutate: mutateLog } = useLogQueue()
	const profittimer = React.useRef<any>()
	const losstimer = React.useRef<any>()


	useEffect(() => {
		if (!tradeStore?.ep_id) return

		let data = tplStore
			?.filter((i: any) => i.key == 'TPL' + tradeStore?.ep_id)
			?.map(d => {
				const [profit, loss, p, l] = d?.value?.split(':') || [
					5, 5, 0, 0,
				]
				return {
					profit: p == 1 ? true : false,
					profittick: +profit || 5,
					loss: l == 1 ? true : false,
					losstick: +loss || 5,
				}
			}) || [
				{
					profit: false,
					profittick: 5,
					loss: false,
					losstick: 5,
				},
			]

		if (data.length == 0) {
			data = [
				{
					profit: false,
					profittick: 5,
					loss: false,
					losstick: 5,
				},
			]
		}

		setProfit(data?.[0]?.profit)
		setLoss(data?.[0]?.loss)
		setProfittick(data?.[0]?.profittick)
		setLosstick(data?.[0]?.losstick)
	}, [tplStore, tradeStore?.ep_id])

	useEffect(() => {
		setAmt(orderStore?.amount)
	}, [amt, isMobile, orderStore?.amount, tradeStore?.ep_id])

	let tempMutateOrder = { ...orderStore }
	let mutateCheck = false

	if (!orderStore?.order_short1) {
		tempMutateOrder = { ...tempMutateOrder, order_short1: 1 }
		mutateCheck = true
	}
	if (!orderStore?.order_short2) {
		tempMutateOrder = { ...tempMutateOrder, order_short2: 2 }
		mutateCheck = true
	}
	if (!orderStore?.order_short3) {
		tempMutateOrder = { ...tempMutateOrder, order_short3: 3 }
		mutateCheck = true
	}
	if (!orderStore?.order_short4) {
		tempMutateOrder = { ...tempMutateOrder, order_short4: 4 }
		mutateCheck = true
	}
	if (!orderStore?.order_short5) {
		tempMutateOrder = { ...tempMutateOrder, order_short5: 5 }
		mutateCheck = true
	}
	if (!orderStore?.order_short6) {
		tempMutateOrder = { ...tempMutateOrder, order_short6: 10 }
		mutateCheck = true
	}
	if (
		!orderStore?.amount ||
		orderStore?.amount < 1 ||
		isNaN(orderStore?.amount)
	) {
		tempMutateOrder = { ...tempMutateOrder, amount: 1 }
		mutateCheck = true
	}

	if (mutateCheck) {
		mutateOrderStore(tempMutateOrder)
	}

	return (
		<>
			<TradeListItemSection
				style={{
					flexDirection: 'column',
					alignContent: 'center',
					justifyContent: 'center',
					justifyItems: 'center',
					alignItems: 'flex-start',
					minWidth: 450,
					flexGrow: 0,
					...(type == 1 && {
						background: !isDarkMode ? '#0D0E0F' : '#ffffff',
						width: '100%',
						paddingTop: 10,
					}),
				}}>
				<Row
					gutter={12}
					justify="space-evenly"
					align={'middle'}
					className={'ordersmall'}
					style={{
						margin: 0,
						alignItems: 'center',
						height: '100%',
						whiteSpace: 'nowrap',
						flexWrap: 'nowrap',
						justifyContent: 'center',
					}}>
					<Col>
						<div style={{ alignItems: 'center' }}>
							<Checkbox
								checked={profit}
								onChange={e => {
									changeTPL(
										{
											profit: e.target.checked,
										},
										tradeStore?.ep_id
									)
									setProfit(e.target.checked)

									mutateLog({
										log: 'ST005',
										data: {
											current_ep_id: tradeStore?.ep_id,
											onoff: e.target.checked ? 1 : 0,
											tick: profittick,
											device: 'WTS',
											isMobile: isMobile,
										},
									})
								}}
								style={{
									color: textw,
									whiteSpace: 'nowrap',
								}}>
								익절
							</Checkbox>
							<Input
								type="number"
								size="small"
								style={{
									width: 40,
									padding: 0,
									textAlign: 'right',
								}}
								min={0}
								value={profittick}
								onBlur={e => {
									let data: any = e.target.value
									if (data == 0 || data == '') {
										changeTPL(
											{
												profittick: 5,
											},
											tradeStore?.ep_id
										)
									} else {
										changeTPL(
											{
												profittick: data,
											},
											tradeStore?.ep_id
										)
									} mutateLog({
										log: 'ST005',
										data: {
											current_ep_id:
												tradeStore?.ep_id,
											onoff: profit ? 1 : 0,
											tick: data,
											device: 'WTS',
										},
									})
								}}
								onChange={e => {
									let data: any = e.target.value

									if (data == 0 || data == '') {
										data = ''
										setProfittick(data)
									} else {
										setProfittick(data)
									}


									if (profittimer.current) {
										clearTimeout(profittimer.current)
										profittimer.current = null
									}

									profittimer.current = setTimeout(() => {
										if (data == 0 || data == '') {
											changeTPL(
												{
													profittick: 5,
												},
												tradeStore?.ep_id
											)
										} else {
											changeTPL(
												{
													profittick: data,
												},
												tradeStore?.ep_id
											)
										}

										mutateLog({
											log: 'ST005',
											data: {
												current_ep_id:
													tradeStore?.ep_id,
												onoff: profit ? 1 : 0,
												tick: data,
												device: 'WTS',
											},
										})

										profittimer.current = null
									}, 500)

								}}
							/>
							틱
						</div>
					</Col>
					<ColAva />
				</Row>
				<Row
					gutter={12}
					justify="space-evenly"
					align={'middle'}
					className={'ordersmall'}
					style={{
						alignItems: 'flex-end',
						margin: 0,
						marginTop: 5,
						marginBottom: 10,
						height: '100%',
						justifyContent: 'flex-end',
					}}>
					<Col>
						<Space.Compact
							style={{
								alignItems: 'center',
								whiteSpace: 'nowrap',
								flexWrap: 'nowrap',
							}}>
							<Checkbox
								checked={loss}
								onChange={e => {
									setLoss(e.target.checked)
									changeTPL(
										{
											loss: e.target.checked,
										},
										tradeStore?.ep_id
									)
									mutateLog({
										log: 'ST006',
										data: {
											current_ep_id: tradeStore?.ep_id,
											onoff: e.target.checked ? 1 : 0,
											tick: orderStore?.losstick,
											device: 'WTS',
											isMobile: isMobile,
										},
									})
								}}
								style={{
									color: textw,
									whiteSpace: 'nowrap',
								}}>
								손절
							</Checkbox>
							<Input
								type="number"
								size="small"
								style={{
									width: 40,
									padding: 0,
									textAlign: 'right',
								}}
								value={losstick}
								min={0}
								onBlur={e => {
									let data: any = e.target.value
									if (data == 0 || data == '') {
										changeTPL(
											{
												losstick: 5,
											},
											tradeStore?.ep_id
										)
									} else {
										changeTPL(
											{
												losstick: data,
											},
											tradeStore?.ep_id
										)
									}
									mutateLog({
										log: 'ST006',
										data: {
											current_ep_id:
												tradeStore?.ep_id,
											onoff: loss ? 1 : 0,
											tick: data,
											device: 'WTS',
										},
									})
								}}
								onChange={e => {
									let data: any = e.target.value

									if (data == 0 || data == '') {
										data = ''
										setLosstick(data)
									} else {
										setLosstick(data)
									}

									if (losstimer.current) {
										clearTimeout(losstimer.current)
										losstimer.current = null
									}

									losstimer.current = setTimeout(() => {
										if (data == 0 || data == '') {
											changeTPL(
												{
													losstick: 5,
												},
												tradeStore?.ep_id
											)
										} else {
											changeTPL(
												{
													losstick: data,
												},
												tradeStore?.ep_id
											)
										}
										mutateLog({
											log: 'ST006',
											data: {
												current_ep_id:
													tradeStore?.ep_id,
												onoff: loss ? 1 : 0,
												tick: data,
												device: 'WTS',
											},
										})

										losstimer.current = null
									}, 500)
								}}
							/>
							틱
						</Space.Compact>
					</Col>
					<Col>
						<label>주문수량</label>
						<Space.Compact
							style={{
								alignItems: 'center',
								marginRight: 5,
							}}>
							<Input
								size="small"
								style={{
									width: 45,
									fontWeight: 'bold',
									textAlign: 'right',
									padding: 0,
									marginLeft: 9,
								}}
								type="number"
								value={orderStore?.amount || amt}
								onChange={e => {
									let temp = parseInt(e.target.value + '')
									setAmt(temp)

									const min = tradeStore?.min_amount || 1
									if (temp < min) temp = min

									mutateOrderStore({
										amount: temp,
									})

																
									mutateLog({
										log: 'GT006',
										data: {
											amount: temp,
											ep_id: tradeStore?.ep_id,
											isMobile: isMobile,
										},
									})

								}}
							/>
						</Space.Compact>
						<Space.Compact
							style={{
								alignItems: 'center',
								flex: 1,
								width: '50%',
							}}
							size={'small'}>
							<Button
								size="small"
								style={{}}
								onClick={() => {
									mutateOrderStore({
										amount: orderStore?.order_short1,
									})
								}}>
								{orderStore?.order_short1}
							</Button>
							<Button
								size="small"
								style={{}}
								onClick={() => {
									mutateOrderStore({
										amount: orderStore?.order_short2,
									})
								}}>
								{orderStore?.order_short2}
							</Button>
							<Button
								style={{}}
								size="small"
								onClick={() => {
									mutateOrderStore({
										amount: orderStore?.order_short3,
									})
								}}>
								{orderStore?.order_short3}
							</Button>
							<Button
								style={{}}
								size="small"
								onClick={() => {
									mutateOrderStore({
										amount: orderStore?.order_short4,
									})
								}}>
								{orderStore?.order_short4}
							</Button>
							<Button
								style={{}}
								size="small"
								onClick={() => {
									mutateOrderStore({
										amount: orderStore?.order_short5,
									})
								}}>
								{orderStore?.order_short5}
							</Button>
							<Button
								style={{}}
								size="small"
								onClick={() => {
									mutateOrderStore({
										amount: orderStore?.order_short6,
									})
								}}>
								{orderStore?.order_short6}
							</Button>
							<Button
								style={{ background: '#eee' }}
								size="small"
								onClick={() => {
									mutateModalStore({
										...modalStore,
										setOrder: true,
									})
								}}>
								설정
							</Button>
						</Space.Compact>
					</Col>
				</Row>
			</TradeListItemSection>

			<Modal
				open={modalStore?.overnightsetting}
				title={'오버나잇설정'}
				cancelText={'닫기'}
				onCancel={() => {
					mutateModalStore({
						...modalStore,
						overnightsetting: false,
					})
				}}
				footer={null}>
				<Space style={{ alignItems: 'center' }}>
					<OvernightSetting />
				</Space>
				<div style={{ textAlign: 'center', marginTop: 10 }}>
					<Button
						size="small"
						onClick={() => {
							mutateModalStore({
								...modalStore,
								overnightsetting: false,
							})
						}}>
						닫기
					</Button>
				</div>
			</Modal>

			<Modal
				open={modalStore?.setOrder}
				title={'주문수량버튼설정'}
				cancelText={'닫기'}
				onCancel={() => {
					mutateModalStore({
						...modalStore,
						setOrder: false,
					})
				}}
				footer={null}>
				<Space.Compact style={{ alignItems: 'center' }}>
					<Input
						size="small"
						value={orderStore?.order_short1}
						onChange={e => {
							mutateOrderStore({
								order_short1: e.target.value,
							})
						}}
						type={'number'}
					/>
					<Input
						size="small"
						value={orderStore?.order_short2}
						onChange={e => {
							mutateOrderStore({
								order_short2: e.target.value,
							})
						}}
						type={'number'}
					/>
					<Input
						size="small"
						value={orderStore?.order_short3}
						onChange={e => {
							mutateOrderStore({
								order_short3: e.target.value,
							})
						}}
						type={'number'}
					/>
					<Input
						size="small"
						value={orderStore?.order_short4}
						onChange={e => {
							mutateOrderStore({
								order_short4: e.target.value,
							})
						}}
						type={'number'}
					/>
					<Input
						size="small"
						value={orderStore?.order_short5}
						onChange={e => {
							mutateOrderStore({
								order_short5: e.target.value,
							})
						}}
						type={'number'}
					/>
					<Input
						size="small"
						value={orderStore?.order_short6}
						onChange={e => {
							mutateOrderStore({
								order_short6: e.target.value,
							})
						}}
						type={'number'}
					/>
				</Space.Compact>
				<div style={{ textAlign: 'center', marginTop: 10 }}>
					<Button
						size="small"
						onClick={() => {
							mutateModalStore({
								...modalStore,
								setOrder: false,
							})
						}}>
						닫기
					</Button>
				</div>
			</Modal>
		</>
	)
}

export default OrderSetting
