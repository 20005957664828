import { debounce } from '@rowsncolumns/grid'
import BigNumber from 'bignumber.js'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from '../../theme/ThemeProvider'
import { useWindowDimensions } from '../hooks'
import { useFetcherPublic } from '../hooks/fetcher'
import { useCurrency } from 'app/hooks/trade/useCurrency'

const KrwPrice = ({
	price = 0,
	dp = 0,
	color = true,
	krw = true,
	margin = false,
	theme = true,
	fontSize = undefined,
	style = {},
	percent = false,
	won = '원',
	coinglobal = 'G',
	center = false,
	colordiff = '',
	comma = true,
	rate = '',
	w = 'auto',
}: any) => {
	BigNumber.clone() // new line
	const { isDarkMode, theme: themecolor } = useContext(ThemeContext)

	const redColor = !isDarkMode ? '#F05551' : '#F05551'
	const blueColor = !isDarkMode ? '#007EDA' : '#007EDA'

	const currencyList = useCurrency()
	let equivusd = 1
	if (coinglobal == 'C') {
		equivusd =
			currencyList?.data?.result?.result?.find(
				(item: any) => item?.code == 'KRW'
			)?.price || 1
	}

	const { width } = useWindowDimensions()

	if (price && !fontSize) fontSize = width < 1300 ? '0.9em' : '1em'

	const [throttledPrice, setThrottledPrice] = useState(price)

	// useCallback을 사용하여 함수 메모이제이션
	const throttledUpdatePrice = useCallback(
		debounce(newPrice => {
			setThrottledPrice(newPrice)
		}, 100),
		[]
	)

	useEffect(() => {
		// price 값이 변경될 때 쓰로틀링된 함수 실행
		throttledUpdatePrice(price)
	}, [price, throttledUpdatePrice]) // 함수와 price를 의존성 배열에 포함

	return (
		<View
			style={{
				alignItems: center ? 'center' : 'flex-end',
				fontSize: fontSize,
				fontWeight: 500,
				paddingInline: center ? 5 : 0,
				width: w,
			}}>
			<Text
				style={{
					justifyContent: center ? 'center' : 'flex-end',
					marginRight: center ? 0 : margin ? 10 : 0,
					fontSize: fontSize,
					fontWeight: 500,
					whiteSpace: 'nowrap',
					letterSpacing: -0.6,
					color: color
						? colordiff
							? colordiff == 'up'
								? redColor
								: colordiff == 'down'
									? blueColor
									: theme
							: throttledPrice < 0
								? blueColor
								: price > 0
									? redColor
									: theme
										? themecolor.COLORS.textw
										: 'black'
						: theme
							? themecolor.COLORS.textw
							: 'black',
					...style,
				}}>
				{throttledPrice
					? BigNumber(throttledPrice).toFormat(
						dp ? dp : coinglobal == 'G' ? 0 : 2, BigNumber.ROUND_DOWN
					) + (coinglobal == 'C' ? '$' : krw ? won : '')
					: '-'}{' '}
				{percent ? '%' : ''}
			</Text>

			{coinglobal == 'C' && (
				<Text
					style={{
						justifyContent: center ? 'center' : 'flex-end',
						marginRight: center ? 0 : margin ? 10 : 0,
						fontSize: '0.85em',
						fontWeight: 500,
						whiteSpace: 'nowrap',
						letterSpacing: -0.6,
						color: themecolor.COLORS.textsilver,
					}}>
					{price
						? '≒ ' +
						BigNumber(price).multipliedBy(equivusd).toFormat(0, BigNumber.ROUND_DOWN) +
						' ' +
						'원'
						: '-'}
				</Text>
			)}
		</View>
	)
}

export default React.memo(KrwPrice)
