import React from 'react'
import {
	IconBox,
	IconCoinBitcoin,
	IconMenu,
	IconMenuOrder,
	IconMoneybag,
} from '@tabler/icons-react'
import {
	TradeListItemSection,
	TradeTextInnerContent,
	TradeTextInnerTitle,
	TradeTextWrapper,
} from './styles'
import { COLORS } from 'app/constants/STYLES'
import { useWindowDimensions } from '../../../hooks'
import { Button } from 'antd'

interface IProps {
	title?: string
	content?: string
	titleColor?: string
	contentColor?: string
	titleBold?: boolean
	contentBold?: boolean
	icon?: boolean
	onPress?: () => void
}

const TradeListItem = ({
	title,
	content,
	icon,
	titleColor,
	contentColor,
	titleBold,
	contentBold,
	onPress,
}: IProps) => {
	const { width } = useWindowDimensions()

	return (
		<TradeListItemSection onClick={onPress ? onPress : () => {}}>
			{icon ? <IconBox color={titleColor} /> : null}
			<TradeTextWrapper>
				<TradeTextInnerTitle
					style={
						width <= 1100
							? {
									fontSize: '1em',
							  }
							: {}
					}
					titleColor={titleColor}
					titleBold={titleBold}>
					{title}
					<Button
						size="small"
						type="primary"
						style={{
							fontSize: 12,
							padding: 2,
							backgroundColor: '#007eda',
						}}>
						종목변경
					</Button>
				</TradeTextInnerTitle>
				{width > 1600 && (
					<TradeTextInnerContent
						style={{
							fontSize: '1em',
							letterSpacing: -0.5,
							whiteSpace: 'nowrap',
						}}
						contentColor={contentColor}
						contentBold={contentBold}>
						{content}
					</TradeTextInnerContent>
				)}
			</TradeTextWrapper>
		</TradeListItemSection>
	)
}

export default React.memo(TradeListItem)
