import { Button } from 'antd'
import React from 'react'
import useSWR from 'swr'
import { View } from '../../../styles/reactnative'
import API from '../../../utils/api'
import { COLORS } from '../../constants'
import { ENGONLY, VERSION } from '../../constants/APIKEYS'

const VersionChecker = () => {
	const { data } = useSWR('/api/version', async () => {
		const res = await API.get('/system/version', '', {})
		return res?.version || ''
	}) // useSWR을 사용하여 버전을 가져온다.

	if (VERSION == data?.web) {
		return null
	}

	return (
		<View
			style={{
				color: '#fff',
				fontSize: 16,
				borderBottom: '1px solid #fff',
				textAlign: 'center',
				flexDirection: 'row',
				position: 'fixed',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				background: 'rgba(0,0,0,0.5)',
				height: '100%',
				top: 0,
				width: '100%',
				zIndex: 100,
			}}>
			<View
				style={{
					flex: 1,
					textAlign: 'center',
					height: 100,
					width: '100%',
					gap: 10,
					background: COLORS.primary,
				}}>
					{ENGONLY ? 'New version is updated. Please refresh.' : '새로운 버전이 업데이트 되었습니다. 새로고침을 눌러주세요.'}
				<Button onClick={() => window.location.reload()}>
					{ENGONLY ? 'Refresh' : '새로고침'}
				</Button>
			</View>
		</View>
	)
}

export default React.memo(VersionChecker)
