import React, { useEffect, useState } from 'react'
import { CFlatList } from 'app/components/Common'
import { TradeDrawerListSection } from '../styles'
import TradeListTitle from '../TradeListTitle'
import { useFetcherPublic } from 'app/hooks/fetcher'
import DrawerListItem from '../DrawerListItem'
import { useExchangeStore } from '../../../../../hooks/trade/exchangeStore'
import CRenderEmpty from '../../../../../components/CRenderEmpty'
import { useTradeStore } from '../../../../../hooks/trade/tradeStore'
import { set } from 'shelljs'

const TradeDataList = ({ search, setDisplayDrawer, tab }) => {
	const [top, setTop] = useState(0)
	const { EPs } = useExchangeStore()
	const { ep_id } = useTradeStore()

	const [list, setList] = useState([])

	useEffect(() => {
		// pairs 첫글자 기준 알파벳 오른차순
		let temp = []

		if (top == 1) {
			temp = EPs.sort((a, b) => {
				return a.pair > b.pair ? 1 : -1
			})
			//내림차순
		} else if (top == 2) {
			temp = EPs.sort((a, b) => {
				return a.pair < b.pair ? 1 : -1
			})
		} else {
			temp = EPs
		}

		setList(temp)
	}, [top, EPs])

	const renderItem = (item: any, index: any) => {
		return (
			<DrawerListItem
				item={item}
				index={index}
				setDisplayDrawer={setDisplayDrawer}
				ep_id={ep_id}
			/>
		)
	}
	return (
		<TradeDrawerListSection>
			<TradeListTitle top={top} setTop={setTop} />
			<CFlatList data={list} renderItem={renderItem} minusheight={130} />
			<CRenderEmpty id={0} />
		</TradeDrawerListSection>
	)
}

export default TradeDataList
