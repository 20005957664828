import React, { useContext, useEffect, useState } from 'react'

import { CButton, CDrawer } from 'app/components/Common/'
import { TradeDrawer, TradeNavBarListItem } from 'app/mobile/components/Trade'
import {
	TradeNavBarContainer,
	TradeNavBarLeft,
	TradeNavBarRight,
	TradeNavBarRightListsMobile,
} from './styles'

import { useToken } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import { useTradeStore } from '../../../../hooks/trade/tradeStore'
import PreferenceDrawer from './TradeSettingPreference/'
// import { useIndexPrice } from '../../../../hooks/trade/indexprice'
import { ThemeContext } from 'theme/ThemeProvider'

import { IconUserCircle } from '@tabler/icons-react'
import { Popover, message } from 'antd'
import { Text, View } from '../../../../../styles/reactnative'
import { useIndexPrice } from '../../../../hooks/trade/indexprice'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { useMyProfile } from '../../../../hooks/user/myprofile'
import DarkModeToggle from '../../../../organisms/Header/DarkModeToggle/mobile'
import MobileToggle from '../../../../organisms/Header/MobileToggle/mobile'
import { MButton } from '../../../components'

const TradeNavBar = () => {
	const { textw, textsilver } = React.useContext(ThemeContext).theme.COLORS
	const [displayDrawer, setDisplayDrawer] = useState('none')
	const [displayPreference, setDisplayPreference] = useState('none')
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()
	const { data: tradeStore } = useTradeStore()
	const toggleNavBar = () => {
		setDisplayPreference(prevState =>
			prevState === 'none' ? 'flex' : 'none'
		)
	}
	const { data: profile, mutate: mutateProfile } = useMyProfile()
	const { mutate } = useToken()
	const { t } = useTranslation()

	const { isDarkMode, theme } = useContext(ThemeContext)

	let isTradeChart = tradeStore?.isTradeChart
	if (isTradeChart == undefined) isTradeChart = true
	let { data: lastPrice } = useIndexPrice(tradeStore?.ep_id)

	if (!lastPrice) lastPrice = [{ price: 0 }, { price: 0 }]

	useEffect(() => {
		if (profile?.level == -9 || profile?.level == -2) {
			message.error('접속 권한이 없습니다.')
			setTimeout(() => {
				mutateProfile(null)
				mutate(null)
			}, 1000)
		}
	}, [profile?.level])

	const openDrawer = () =>
		setDisplayDrawer(prevState => (prevState === 'none' ? 'flex' : 'none'))

	const renderDrawer = () => (
		<TradeDrawer setDisplayDrawer={() => openDrawer()} />
	)

	return (
		<TradeNavBarContainer>
			<CDrawer
				renderDrawer={renderDrawer}
				displayDrawer={displayDrawer}
			/>
			<TradeNavBarLeft>
				<TradeNavBarListItem
					title={tradeStore?.pairs?.toUpperCase()}
					onPress={() => {
						openDrawer()
					}}
					content={tradeStore?.name}
					icon={tradeStore?.image}
					titleColor={textw}
					contentColor={textsilver}
					titleBold={true}
				/>
			</TradeNavBarLeft>
			<PreferenceDrawer
				displayPreference={displayPreference}
				setDisplayPreference={() => toggleNavBar()}
			/>
			<TradeNavBarRight>
				<TradeNavBarRightListsMobile>
					{/* <Switch
						checkedChildren={'호가'}
						unCheckedChildren={'일반'}
						checked={modalStore?.mobiletype ? true : false}
						style={{ minWidth: 70 }}
						onChange={e => {
							setImmediate(() => {
								mutateModalStore({
									...modalStore,
									mobiletype: e,
								})
							})
						}}
					/> */}
					<DarkModeToggle size={18} />
					<MobileToggle size={18} />
					<Text
						style={{
							position: 'absolute',
							background: 'black',
							color: 'white',
							borderRadius: 5,
							padding: '3px 5px',
							top: 0,
							right: 0,
						}}>
						{profile?.level == 6 ? '모의' : ''}
					</Text>

					<Popover
						trigger={'click'}
						content={
							<View style={{ minWidth: 100 }}>
								<Text
									style={{
										color: theme.COLORS.point,
										fontWeight: 'bold',
										marginBottom: 20,
									}}>
									{profile?.nickname} (400{profile?.id})
								</Text>
								<MButton
									title={t('logout')}
									style={{
										width: '100%',
										alignItems: 'flex-start',
										paddingBlock: 10,
										color: 'black',
									}}
									textStyle={{ color: 'black', fontSize: 16 }}
									onClick={() => {
										setTimeout(
											() =>
												(window.location.href =
													'/logout'),
											100
										)
									}}
								/>
							</View>
						}>
						<CButton
							icon={<IconUserCircle size={20} color={textw} />}
						/>
					</Popover>
				</TradeNavBarRightListsMobile>
			</TradeNavBarRight>
		</TradeNavBarContainer>
	)
}

export default React.memo(TradeNavBar)
