import { useCallback, useContext } from 'react'
import { ThemeContext } from '../../../theme/ThemeProvider'
import { useToken } from '../auth/token'
import { useTradeModalStore } from './modalStore'
import { useOrderStore } from './orderStore'
import { useTradePosition } from './tradePosition'
import { useTradeStore } from './tradeStore'
import { useUserSocket } from './userSocket'

export function useOrderSend() {
	const { data: token } = useToken()
	const { ep_id } = useTradeStore()
	const { amount, change } = useOrderStore()
	const { oneClick, skip, change: changeModalStore } = useTradeModalStore()
	const { tempmutate, requestStatus } = useTradePosition()
	const { sendOrder } = useUserSocket()

	const { isMobile } = useContext(ThemeContext)

	const send = useCallback(
		async (type, price, onetwo) => {
			const order = {
				status: 1,
				exchange_pairs_id: ep_id,
				order_price: price,
				long_short: type,
				amount: amount,
			}

			if (!skip) {
				change({
					long_short: type,
					limit_market: 'L',
					price: price,
					type: '',
				})
				changeModalStore({ showconfirmorder: true })
			} else {
				change({
					long_short: type,
					limit_market: 'L',
					price: price,
					type: '',
				})

				sendOrder(
					isMobile ? 1 : 0,
					ep_id,
					type,
					type,
					price,
					amount,
					type,
					token,
					res => {
						if (res?.result?.order) {
							tempmutate('SP', res?.result?.order)
							requestStatus && requestStatus(undefined, true)
						}
					}
				)
			}
		},
		[ep_id, oneClick, skip, amount, token]
	)

	return {
		send: send,
	}
}
