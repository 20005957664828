import { useThemeStore } from 'app/hooks'
import React, { useState, createContext, ReactNode, useEffect } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { theme } from './theme'

type ThemeContextType = {
	isDarkMode: boolean
	isMobile: boolean | null
	toggleMobile: (set?: any) => void
	toggleTheme: (set?: any) => void
	changeResize: (set?: any) => void
	theme: {
		COLORS: {
			textw: string
			stroke: string
			Secondary: string
			Tertiary: string
			onSecondary: string
			onTertiary: string
			Prinary: string
			complementary: string
			point: string
			fail: string
			raise: string
			fail_sub: string
			raise_sub: string
			background: string
			gray: string
			grey: string
			bgColor: string
			descBox: string
			drawerBgColor: string
			opacityBgColor: string
			textsilver: string
			textGrey: string
			textGray: string
			borderColor: string
			descBorder: string
			header: string
			subHeader: string
			shadow: string
			cardOpacity: string
			mdrawerBgColor: string
			tradeBG: string
			mitBG: string
		}
	}
}
type ThemeProviderProps = {
	children: ReactNode
}
export const ThemeContext = createContext<ThemeContextType>(
	{} as ThemeContextType
)
const ThemeProvider = ({ children }: ThemeProviderProps) => {
	const { data: themeStore, mutate } = useThemeStore()
	const [themeCheck, setThemeCheck] = useState<any>(
		themeStore?.darkMode ? theme.light : theme.dark
	)

	//도메인에 mts.가 있으면 무조건 모바일로 간주
	const ismts = window.location.href.indexOf('mts.') > -1

	const [isMobile, setIsMobile] = useState<any>(
		/iphone|ipad|ipod|android|mobile/i.test(
			navigator.userAgent?.toLowerCase()
		) || ismts
	)

	const toggleMobile = set => {
		if(ismts) return setIsMobile(true)
		setIsMobile(set !== undefined ? set : isMobile ? false : true)
	}

	const toggleTheme = set => {
		const change = set !== undefined ? set : !themeStore?.darkMode

		mutate({ darkMode: change })
	}

	const changeResize = set => {
		mutate({ isResize: set })
	}

	useEffect(() => {
		setThemeCheck(themeStore?.darkMode ? theme.light : theme.dark)
	}, [themeStore?.darkMode])

	return (
		<ThemeContext.Provider
			value={{
				isDarkMode: !themeStore?.darkMode,
				isMobile,
				toggleMobile,
				toggleTheme,
				theme: themeCheck,
				changeResize,
			}}>
			<StyledThemeProvider theme={themeCheck}>
				{children}
			</StyledThemeProvider>
		</ThemeContext.Provider>
	)
}

export default ThemeProvider
