import { IconChevronsLeft } from '@tabler/icons-react'
import { Button, Space } from 'antd'
import { Header } from 'app/mobile/components'
import React, { useContext, useState } from 'react'
import { MContentContainer } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { useToken } from '../../hooks'
import CalcList from '../../organisms/Header/CalcList'
import TradeList from '../../organisms/Header/TradeList'
import LoginMobile from '../AuthenticationPages/LoginPage/LoginMobile'

const OrdersMobile = () => {
	const { isDarkMode, theme } = useContext(ThemeContext)

	const param = new URLSearchParams(window.location.search)

	const [tab, setTab] = useState(0)

	const { data: token } = useToken()

	if (!token) {
		return <LoginMobile />
	}

	return (
		<MContentContainer isDarkMode={isDarkMode}>
			<Header />

			<div style={{ marginTop: 50 }}></div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
					marginBottom: 10,
					paddingLeft: 5,
				}}>
				<Button
					size="small"
					onClick={() => window.history.back()}
					color="primary"
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: 10,
						minHeight: 30,
						color: theme.COLORS.textw,
						border: '1px solid ' + theme.COLORS.textw,
						borderRadius: 5,
					}}
					ghost={true}>
					<IconChevronsLeft size={20} />
					이전
				</Button>
				<Space.Compact>
					<Button
						onClick={() => setTab(0)}
						type={tab === 0 ? 'primary' : 'default'}>
						체결내역
					</Button>
					<Button
						onClick={() => setTab(1)}
						type={tab === 1 ? 'primary' : 'default'}>
						일일손익
					</Button>
				</Space.Compact>
				<div style={{ width: 70 }}> </div>
			</div>
			{tab === 0 ? (
				<TradeList mobile={true} />
			) : (
				<CalcList footer={4} mobile={true} />
			)}
			<div style={{ marginBottom: 30 }}></div>
		</MContentContainer>
	)
}

export default OrdersMobile
