import { Button, Input, message, Space } from 'antd'
import React from 'react'
import { useFps } from 'react-fps'
import { View } from 'styles/reactnative'
import API from 'utils/api'
import { useToken, useWindowDimensions } from '../../../hooks'

const Pannel = ({ setC }) => {
	const { token } = useToken()
	const { width } = useWindowDimensions()

	const [symbol, setSymbol] = React.useState('')
	const [type, setType] = React.useState('')
	const { currentFps } = useFps(15)

	return (
		<View style={{ position: 'absolute', top: 0, right: 0, zIndex: 99999 }}>
			<Space>
				<Input
					value={symbol}
					style={{ width: 80 }}
					onChange={e => setSymbol(e.target.value)}
				/>

				<Button
					onClick={e => {
						API.get(
							`/user/my/balance?symbol=${symbol}&type=4`,
							token + '',
							{},
							false
						).then(res => {
							if (res?.result?.result?.success) {
								message.success('OK')
							} else {
								message.error('FAILFAILFAILFAILFAILFAIL')
							}
						})
					}}>
					FPS1:{currentFps}
				</Button>
				<Button
					onClick={e => {
						API.get(
							`/user/my/balance?symbol=${symbol}&type=5`,
							token + '',
							{},
							false
						).then(res => {
							if (res?.result?.result?.success) {
								message.success('성공')
							} else {
								message.error('실패')
							}
						})
					}}>
					FPS2:{currentFps}
				</Button>
				<Button danger size="small" onClick={() => setC(0)}>
					CLOSE
				</Button>
			</Space>
		</View>
	)
}

export default React.memo(Pannel)
