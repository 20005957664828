import React from 'react'
import { fetcher } from 'app/hooks/fetcher'
import { Routes } from 'app/routes'
import { SWRConfig } from 'swr'
import ThemeProvider from 'theme/ThemeProvider'
import FastClick from 'fastclick'
import { ConfigProvider } from 'antd'

const cacheProvider: any = cache => {
	const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'))
	/*
		sessionStorage.getItem('save') == '1'
			? JSON.parse(localStorage.getItem('app-cache') || '[]')
			: []
	)
	*/
	window.addEventListener('beforeunload', () => {
		const appCache = JSON.stringify(Array.from(map.entries()))
		localStorage.setItem('app-cache', appCache)
		//sessionStorage.setItem('save', '1')
	})
	window.addEventListener('pagehide', () => {
		const appCache = JSON.stringify(Array.from(map.entries()))
		localStorage.setItem('app-cache', appCache)
		//sessionStorage.setItem('save', '1')
	})

	return map
}

const App = () => {
	FastClick.attach(document.body)

	return (
		<SWRConfig
			value={{
				fetcher: fetcher,
				provider: cacheProvider,
			}}>
			<ThemeProvider>
				<ConfigProvider theme={{ token: { motion: false } }}>
					<Routes />
				</ConfigProvider>
			</ThemeProvider>
		</SWRConfig>
	)
}

export default App
