import React, { useContext, useState } from 'react'
import { CButton } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import { useTradeModalStore } from '../../../../../hooks/trade/modalStore'
import { Button, Popover, Space, Switch } from 'antd'
import { IconLayoutBoardSplit, IconMaximize } from '@tabler/icons-react'
import { useTradeStore } from '../../../../../hooks/trade/tradeStore'
import { useWindowDimensions } from '../../../../../hooks'
import { ThemeContext } from '../../../../../../theme/ThemeProvider'
import { useTranslation } from 'react-i18next'

const TradeChartNavBar = ({
	tab,
	setTab,
	tabs,
	mobile = false,
	split = false,
}) => {
	const { width } = useWindowDimensions()
	const small = width < 1250
	const { data: store, mutate } = useTradeStore()
	const [open, setOpen] = useState(false)

	const { data: tradeModalStore, change: muateTradeModalStore } =
		useTradeModalStore()

	const { t } = useTranslation()

	const alltabs = [
		'1m',
		'3m',
		'5m',
		'15m',
		'30m',
		'1h',
		'2h',
		'4h',
		'6h',
		'8h',
		'12h',
		'1d',
		'1w',
		'1M',
	]

	const { isDarkMode, theme } = useContext(ThemeContext)
	const COLORS = theme.COLORS

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				borderBottom: `1px solid ${COLORS.stroke}`,
				widows: '100%',
				background: !isDarkMode ? '#0D0E0F' : '#fff',
			}}>
			<View
				style={{
					flexDirection: 'row',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					padding: 3,
					borderRadius: 3,
					width: '100%',
				}}>
				{tabs.map((item: any, index: any) => (
					<CButton
						key={index.toString()}
						title={item}
						onClick={() => {
							setTab(item)
						}}
						textHover={tab === item ? COLORS.fail : COLORS.Prinary}
						style={{
							fontWeight: '500',
							paddingBlock: 3,
							paddingInline: 5,
							fontSize: '1.1rem',
							color: tab === item ? COLORS.raise : COLORS.Prinary,
						}}
					/>
				))}

				{!mobile && (
					<Popover
						onOpenChange={e => {
							setOpen(e)
						}}
						trigger={'click'}
						content={
							<Space.Compact size="small">
								{alltabs.map((item: any, index: any) => (
									<Button
										key={index.toString()}
										type={
											tradeModalStore?.tabs?.includes(
												item
											)
												? 'primary'
												: 'default'
										}
										onClick={() => {
											if (
												tradeModalStore?.tabs?.includes(
													item
												)
											) {
												let temp =
													tradeModalStore?.tabs?.filter(
														(i: any) => i != item
													)
												// alltabs 기준 정렬
												temp.sort(function (a, b) {
													return (
														alltabs.indexOf(a) -
														alltabs.indexOf(b)
													)
												})

												muateTradeModalStore({
													tabs: temp,
												})
											} else {
												let temp = [
													...tradeModalStore?.tabs,
													item,
												]
												// alltabs 기준 정렬
												temp.sort(function (a, b) {
													return (
														alltabs.indexOf(a) -
														alltabs.indexOf(b)
													)
												})
												muateTradeModalStore({
													tabs: temp,
												})
											}
										}}>
										{item}
									</Button>
								))}
							</Space.Compact>
						}
						placement="bottom"
						open={open}>
						<View
							style={{
								flexDirection: 'row',
								marginLeft: 20,
								marginTop: -2,
								cursor: 'pointer',
							}}>
							<Text
								style={{
									color: open ? COLORS.raise : COLORS.Prinary,
									marginRight: 5,
									marginTop: 5,
									whiteSpace: 'nowrap',
									fontSize: '1.1rem',
								}}>
								{t('modify')}
							</Text>
						</View>
					</Popover>
				)}
			</View>
			<View
				style={{ width: mobile ? 100 : '100%', height: 25 }}
				className={'drag-handle'}
			/>
			{!mobile ? (
				<>
					<Button
						size={'small'}
						icon={<IconMaximize size={14} />}
						style={{ fontSize: 12, marginRight: 3 }}
						onClick={() => {
							mutate(store)
							window.open(
								`/trade/chart/${store?.ep_id}/${
									store?.symbol
								}/1m/${!isDarkMode ? 'dark' : 'light'}`,
								'_blank',
								'width=800,height=600,resizable=yes, status=no, toolbar=no, menubar=no, location=no, scrollbars=yes, resizable=yes'
							)
						}}>
						새창
					</Button>
					<Button
						className="widgetClose"
						size={'small'}
						style={{ position: 'absolute', top: -10, right: -10 }}
						onClick={() => {
							muateTradeModalStore({
								chartshow: tradeModalStore?.chartshow
									? false
									: true,
							})
						}}>
						X
					</Button>
					<Space
						direction="horizontal"
						style={{
							position: 'absolute',
							right: 60,
							top: 3,
							alignItems: 'center',
							alignContent: 'center',
							justifyContent: 'flex-end',
							whiteSpace: 'nowrap',
							color: !isDarkMode ? '#ececec' : '#000',
						}}>
						{width < 1300 ? '보유' : '보유종목'}
						<Switch
							checked={tradeModalStore?.showpos}
							checkedChildren="ON"
							unCheckedChildren="OFF"
							onChange={e => {
								setImmediate(() => {
									muateTradeModalStore({
										showpos: e,
									})
								})
							}}
						/>
					</Space>
				</>
			) : null}
		</View>
	)
}

export default React.memo(TradeChartNavBar)
