import BigNumber from 'bignumber.js'
import React, { useContext, useMemo } from 'react'
import { Text } from '../../../../../styles/reactnative'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'
import { useIndexPrice } from '../../../../hooks/trade/indexprice'
import { useCurrency } from '../../../../hooks/trade/useCurrency'

const PnlPrice = ({ item, size = 12, plus = true, }: any) => {
	let pnl = 0

	const { EPs } = useExchangeStore()

	const EP = EPs?.find((ep: any) => ep?.id == item?.exchange_pairs_id)

	let { getPrice, reset } = useIndexPrice(item?.exchange_pairs_id)

	const { COLORS } = useContext(ThemeContext).theme

	const { krwrate, getRate } = useCurrency()
	const currencyrate = getRate(EP?.currency)

	const rate = BigNumber(krwrate?.price || 0)
		.div(currencyrate?.price || 0)
		.toNumber()

	const calc = useMemo(() => {
		return new BigNumber(item?.amount)
			.multipliedBy(EP?.contractamt)
			.multipliedBy(rate)
			.toNumber()
	}, [item?.amount, EP?.contractamt, rate])

	if (item?.exchange_pairs_id) {
		if (item?.status != 9 && item?.status != 8 && item?.status != 10) {
			if (
				getPrice(item?.exchange_pairs_id) === 0 ||
				!getPrice(item?.exchange_pairs_id)
			) {
				reset('Pnlprice' + item?.exchange_pairs_id)
				pnl = 0
			} else {
				if (item?.long_short === 'L') {
					pnl = BigNumber(getPrice(item?.exchange_pairs_id))
						.minus(item?.price || item?.trade_price)
						.multipliedBy(calc)
						.toNumber()
				} else {
					pnl = BigNumber(item?.price || item?.trade_price)
						.minus(getPrice(item?.exchange_pairs_id))
						.multipliedBy(calc)
						.toNumber()
				}
			}
		}

	}

	return (
		<Text
			style={{
				color:
					pnl > 0
						? COLORS.raise
						: pnl < 0
							? COLORS.fail
							: COLORS.textw,
			}}
			size={size}>
			{plus ? (pnl > 0 ? '+' : '') : ''}
			{pnl ? BigNumber(pnl || 0).toFormat(0) : '-'}
		</Text>
	)
}

export default React.memo(PnlPrice)
