import React from 'react'
import DerivativesBody from '../../../../mobile/organisms/Trade/TradeTransaction/DerivativesTransaction/DerivativesBody'
import InfoCurrent from '../../../InfoCurrent'
import TradePositionsTable from '../../TradeDetail/TradePositionsTable'
import { DerivativesTransactionWrapper } from './styles'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { View } from '../../../../../styles/reactnative'
const DerivativesTransaction = () => {
	const {data} = useTradeModalStore()
	return (
		<DerivativesTransactionWrapper>
			{/* <DerivativesHeader /> */}
			<InfoCurrent />
			<TradePositionsTable pos="right" />
			<DerivativesBody />
		</DerivativesTransactionWrapper>
	)
}

export default React.memo(DerivativesTransaction)
