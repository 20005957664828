import { IconSearch } from '@tabler/icons-react'
import { Button, Card, Descriptions, Input, Select, Space, Tooltip } from 'antd'
import { useToken, useWindowDimensions } from 'app/hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { Text, View } from 'styles/reactnative'
import API from 'utils/api'
import { API_COINURL, PARTNERURL } from '../constants/APIKEYS'
import { useCoinGlobal } from '../hooks/stateStore/coinGlobal'
import { useProfile } from '../hooks/user/profilestore'
import TableAPI from './TableAPI'
import UserInfotabs from './UserInfotabs'
import UserTag from './UserTag'
import { useUserGroup } from 'app/hooks/usergroups'

const UserContent = ({
	showOpen,
	selectedData,
	setSelectedData,
	defaultfold = false,
}) => {
	const { width } = useWindowDimensions()
	const [data, setData] = useState<any>({})
	const [level, setLevel] = useState('*')

	const fold = true
	const { data: coinglobal, mutate: mutateCG } = useCoinGlobal()

	const { mutate } = useProfile(selectedData)
	const { data: token } = useToken()
	const {type1} = useUserGroup()
	const [group, setGroup] = useState('*')

	useEffect(() => {
		getUser()
	}, [coinglobal])

	const getUser = useCallback(() => {
		if (!selectedData) return
		API.get(
			`/group/users/${
				isNaN(selectedData) ? 1 : selectedData
			}?user_id=${selectedData}`,
			token + '',
			{},
			false,
			coinglobal == 'C' ? API_COINURL : PARTNERURL
		).then((res: any) => {
			if (res?.result?.success) {
				mutate(selectedData, res?.result?.result)
				setData(res?.result?.result)
			}
		})
	}, [selectedData, token, coinglobal])

	const [search, setSearch] = React.useState(selectedData || '')
	const [search2, setSearch2] = React.useState(null)

	const timerref = React.useRef<any>(null)

	useEffect(() => {
		if (selectedData) {
			getUser()
		}
	}, [token, selectedData, showOpen])

	return (
		<View
			style={{
				flexDirection: width < 600 ? 'column' : 'row',
				justifyContent: 'space-between',
				alignItems: 'flex-start',
				width: '100%',
				height: '100%',
			}}>
			<Card
				size="small"
				title={
					<Descriptions
						size="small"
						style={{ padding: 0, marginTop: 10 }}
						column={2}
						labelStyle={{ marginLeft: 10 }}
						contentStyle={{ fontWeight: 'normal' }}>
						<Descriptions.Item>
							<View
								style={{
									flexDirection: 'row',
									justifyContent: 'space-between',
									width: '100%',
									background: '#404040',
									color: 'white',
									padding: 5,
								}}>
								<Text>{data?.fullname || data?.nickname}</Text>
								<Text style={{ marginRight: 10 }}>
									{data?.loginid}
								</Text>
							</View>
						</Descriptions.Item>
					</Descriptions>
				}
				style={{
					width: '22%',
					height: '100%',
					margin: 0,
					padding: 0,
					minHeight: 600,
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<TableAPI
					defaultMaxData={500}
					excel={false}
					extra={
						<View
							style={{
								flexDirection: 'column',
								justifyContent: 'space-between',
								width: '100%',
								margin: 0,
								padding: 0,
							}}
						>
							<Select
								defaultValue={'*'}
								onChange={e => {
									setGroup(e)
								}}
								value={group}
								style={{
									width: '100%',
									minWidth: 150,
								}}
								options={[
									{ value: '*', label: '그룹전체' },
									...(type1 || []),
								]}
							/>
							<Space.Compact size="small">
								<Button
									danger={level == '*' ? true : false}
									onClick={() => {
										setLevel('*')
									}}>
									전체
								</Button>
								<Button
									danger={level == '1' ? true : false}
									onClick={() => {
										setLevel('1')
									}}>
									활동
								</Button>
								<Button
									danger={level == '2' ? true : false}
									onClick={() => {
										setLevel('6')
									}}>
									모의
								</Button>
								<Input
									placeholder="회원검색"
									defaultValue={search}
									onChange={e => {
										setSearch(e.target.value)
									}}
									onKeyUp={e => {
										if (e.key === 'Enter') {
											setSearch2(search)
										}
									}}
								/>
								<Button
									icon={<IconSearch />}
									size="small"
									onClick={() => {
										setSearch2(search)
									}}
								/>
							</Space.Compact>
						</View>
					}
					stylewidth={'100%'}
					pagination={false}
					height={'500px'}
					width={'100%'}
					className="plist"
					selectedid={selectedData}
					rowAction={data => {
						setSelectedData(data?.id)
						mutate(data?.id, data)
						setData(data)
					}}
					columns={() => [
						{
							title: '그룹',
							dataIndex: 'groupname',
							key: 'groupname',
							align: 'left',
							width: 60,
							sorter: (a, b) =>
								a.groupname < b.groupname
									? -1
									: a.groupname == b.groupname
									? 0
									: 1,
						},
						{
							title: '등급',
							dataIndex: 'level',
							key: 'level',
							align: 'center',
							width: 60,
							sorter: (a, b) =>
								a.level < b.level
									? -1
									: a.level == b.level
									? 0
									: 1,
							render: (text, data) => {
								return (
									<UserTag
										level={text}
										popup={false}
										noname={true}
									/>
								)
							},
						},
						{
							title: '실명',
							dataIndex: 'fullname',
							key: 'fullname',
							align: 'left',
							sorter: (a, b) =>
								a.fullname < b.fullname
									? -1
									: a.fullname == b.fullname
									? 0
									: 1,
							// render: (text, data) => {
							// 	return (
							// 		<Text style={{
							// 		}}
							// 			onClick={() => {
							// 				setSelectedData(data?.id)
							// 			}}>
							// 			{}

							// 		</Text>
							// 	)
							// },
						},
						{
							title: '아이디',
							dataIndex: 'loginid',
							key: 'loginid',
							align: 'left',
							width: 80,
							sorter: (a, b) =>
								a.loginid < b.loginid
									? -1
									: a.loginid == b.loginid
									? 0
									: 1,

							render: (text, data) => {
								return (
									<Tooltip title={text}>
										<Text
											style={{
												cursor: 'pointer',
												width: 70,
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												justifyContent: 'flex-start',
												textOverflow: 'ellipsis',
											}}>
											{text}
										</Text>
									</Tooltip>
								)
							},
						},
					]}
					apikey={`/group/users?group=${group}&level=${level}&${
						search2 ? 'type=user_id&searchWord=' + search2 : 't='
					}`}
				/>
			</Card>

			<UserInfotabs data={data} fold={fold} />
		</View>
	)
}

export default React.memo(UserContent)
