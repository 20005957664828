import React, { lazy, useContext, useEffect, useState } from 'react'
import { Header } from 'app/mobile/components'
import { MContentContainer, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { CToast } from 'app/components/Common'
import { SectionWrap } from '../../organisms/globalOrganismStyles'
import { Text, View } from '../../../styles/reactnative'
import { Announcement } from '../../organisms'
import { useTranslation } from 'react-i18next'
import { MHome } from '../../mobile/organisms'
import axios from 'axios'
import Popup from '../../organisms/Popup'
import { useLogQueue } from '../../hooks/trade/logQueue'
import { useCoinGlobal } from '../../hooks/stateStore/coinGlobal'
import CRenderEmpty from 'app/components/CRenderEmpty'

const HomeMobile = () => {
	const { isDarkMode, theme, isMobile } = useContext(ThemeContext)
	const { t } = useTranslation()

	const [showSelect, setShowSelect] = React.useState(false)

	const { myip, setIp, mutate: mutateLog } = useLogQueue()

	const { mutate: mutateCG } = useCoinGlobal()

	useEffect(() => {
		mutateCG('G')
	}, [])
	useEffect(() => {
		if (!myip) {
			axios.get('https://geolocation-db.com/json/', {
				timeout: 2000,
			}).then(res => {
				setIp(res.data.IPv4)
			}).catch(e => {
				axios.get('https://api.ip.pe.kr/json/').then(res => {
					setIp(res.data.ip)
				})
			})
		} 
	}, [])

	return (
		<MContentContainer isDarkMode={isDarkMode}>
			<Header showSelect={showSelect} setShowSelect={setShowSelect} />
			<MView style={{ marginTop: 50, width: '100%' }}>
				<MHome />

				<SectionWrap style={{ marginTop: 20 }}>
					<View
						style={{
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
							width: '100%',
							maxWidth: 890,
						}}>
						<View
							style={{
								flexDirection: 'row',
								justifyContent: 'center',
								width: '100%',
								marginBottom: 7,
								paddingBottom: 7,
								borderBottom: `1px solid ${theme.COLORS.stroke}`,
							}}>
							<Text
								size={25}
								style={{
									color: theme.COLORS.textw,
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
								}}>
								{t('notice')}
							</Text>
						</View>
						<Announcement title={''} searchshow={false} />
					</View>
				</SectionWrap>
			</MView>
			<Popup />
		</MContentContainer>
	)
}

export default HomeMobile
