import styled from 'styled-components'

// eslint-disable-next-line prettier/prettier
export const PreferenceDrawerSection = styled.div<{ display: string }>`
	position: absolute;
	top: 0px;
	right: 0%;
	width: 330px;
	// later change width size
	height: 100%;
	background-color: ${props => props.theme.COLORS.mdrawerBgColor};
	position: fixed;
	z-index: 2 !important;
	box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
	transform: translateX(0); /* changed it */
	transition: transform 0.3s ease-out;
	${({ display }) =>
		display === 'none' &&
		`
      transform: translateX(100%); /* changed it */
    `}
`

export const PreferenceDrawerBodyWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	flex: 1;
	padding-inline: 10px;
	margin-top: 10px;
	color: ${props => props.theme.COLORS.textw};
`
export const RiskLimitLists = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: ${props => props.theme.COLORS.gray};
	justify-content: space-between;
	width: 100%;
	padding: 15px;
	margin-top: 10px;
	border-radius: 5px;
`
export const RiskLimitListRight = styled.div`
	display: flex;
	flex-direction: row;
`
