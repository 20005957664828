import CButton from 'app/components/Common/CButton'
import CDivider from 'app/components/Common/CDivider'
import CText from 'app/components/Common/CText'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { COLORS, FONTS } from 'app/constants/STYLES'
import {
	FooterContainer,
	FooterWrapper,
	Copyright,
	CopyrightText,
	PrivacyBtn,
} from './styles'
import { useWindowDimensions } from 'app/hooks'

const Footer = () => {
	const { width } = useWindowDimensions()
	const mobile = width < 600
	const { t } = useTranslation()
	const navigate = useHistory()
	const goTo = (url: any) => {
		navigate.push(url)
	}

	return (
		<FooterContainer>
			<FooterWrapper>
				<Copyright>
					<CopyrightText>
						<CText
							text={t('copyRight')}
							style={{
								...FONTS.h5,
								fontSize: 14,
								fontWeight: '500',
							}}
						/>
					</CopyrightText>
				</Copyright>
			</FooterWrapper>
		</FooterContainer>
	)
}

export default React.memo(Footer)
