import React, { useContext } from 'react'
import { Text } from 'styles/reactnative'
import { COLORS } from 'app/constants'
import { ThemeContext } from 'theme/ThemeProvider'
import {
	NotificationItemBody,
	NotificationItemLeft,
	NotificationListItemSection,
} from './styles'
import { useTimezone } from 'app/hooks/timezone'
import { useTranslation } from 'react-i18next'

const NotificationListItems = ({ item }) => {
	const { t } = useTranslation()
	const { textsilver, textw } = useContext(ThemeContext).theme.COLORS

	const getType = type => {
		switch (type) {
			case 1:
				return 'SYSTEM'
			case 2:
				return 'TRADE'
			case 3:
				return 'NEWS'
			default:
				return 'SYSTEM'
		}
	}

	return (
		<NotificationListItemSection>
			<NotificationItemLeft>
				<Text style={{ color: textw }}>
					[{t(getType(item?.type) + '')}]
				</Text>
			</NotificationItemLeft>
			<NotificationItemBody>
				<Text style={{ color: textw }}>{item?.title}</Text>
				<Text style={{ color: textsilver }}>{item?.content}</Text>
				<Text style={{ color: COLORS.lightGray }}>
					{useTimezone(item?.senddate, 'YYYY-MM-DD HH:mm:ss', 9)}
				</Text>
			</NotificationItemBody>
		</NotificationListItemSection>
	)
}

export default NotificationListItems
