import { STORE_ORDER } from 'app/constants/LOCALKEYS'
import useSWR from 'swr'
import { useCallback, useMemo } from 'react'
const KEY = STORE_ORDER

let swrData: any = null

export function useOrderStore(initData?: any, reset?: boolean) {
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (!swrData) return swrData

			swrData = localStorage.getItem(KEY)
				? JSON.parse(localStorage.getItem(KEY) + '')
				: swrData

			if (reset === true) {
				swrData = null
				return null
			}
			if (initData !== undefined) {
				swrData = initData
			}

			return swrData
		},
		{
			dedupingInterval: 1000,
		}
	)

	const amount = useMemo(() => {
		return data?.amount
	}, [data?.amount])

	return {
		data,
		amount,
		mutate: (value: any) => {
			return mutate(data => {
				swrData = { ...value }
				localStorage.setItem(KEY, JSON.stringify(swrData))
				return swrData
			})
		},
		reset: () => {
			swrData = null
			localStorage.removeItem(KEY)
			return mutate(null)
		},

		change: (value: any) => {
			return mutate((data: any) => {
				const temp = { ...data, ...value }
				swrData = temp
				localStorage.setItem(KEY, JSON.stringify(swrData))
				return temp
			})
		},
	}
}
