import styled from 'styled-components'

export const TradeDetailContainer = styled.div`
	height: 100%;
	background-color: ${props => props.theme.COLORS.bgColor};
	position: relative;
	.widgetClose {
		display: none;
	}

	&:hover {
		.widgetClose {
			display: flex;
		}
	}
	overflow: hidden;
`
export const TradeDetailHeaderSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 10px;
	padding-top: 5px;
	padding-bottom: 0;
`

export const TradeDetailNavBarSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 10px;
	padding-bottom: 0;
`
export const TradeDetailListsWrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	border-bottom: 1px solid ${props => props.theme.COLORS.gray};
	border-right: 1px solid ${props => props.theme.COLORS.gray};

	& *::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
`
