import { useMemo, useState } from 'react'
import useSWR from 'swr'
import API from '../../../utils/api'
import { STATUS } from '../../constants/APIKEYS'
import { useToken } from '../auth/token'
import { useProcessStore } from './processStore'
import { useTradeStore } from './tradeStore'

let SWRDATA: any = null
let requestStatus: any = null
let prefresh: any = false
let tore: any = false
let timer: any = null

let timercount = 0

export function useTradePosition() {
	const { token } = useToken()
	const { ep_id } = useTradeStore()
	const [refresh, setRefresh] = useState(false)

	const { data: processing } = useProcessStore()

	const { data, mutate } = useSWR(token ? STATUS : null, async () => {
		const storage = localStorage.getItem('trade')

		if (storage) {
			const temp = JSON.parse(storage)
			if (temp) {
				SWRDATA = temp
				return temp
			}
		}

		if (SWRDATA) {
			return SWRDATA || {}
		}
		const response = await API.get(STATUS, token + '', {}, true)
		SWRDATA = response?.result?.result
		return SWRDATA
	})

	const result: any = useMemo(
		() =>
			data?.position?.filter(
				(item: any) => !processing?.includes(item?.id)
			),
		[data, processing, prefresh]
	)

	const currentposition = useMemo(
		() =>
			result?.length > 0
				? result?.filter(
					(i: any) => i?.exchange_pairs_id == ep_id && !i?.status
				)
				: [],
		[data, ep_id, prefresh]
	)

	const waitlist = useMemo(
		() =>
			result
				? result?.filter(
					(i: any) => i?.exchange_pairs_id == ep_id && i?.status
				)
				: [],
		[data, ep_id, prefresh]
	)

	return {
		data: result || [],
		waitlist: waitlist,
		position: result?.filter((i: any) => !i?.status),
		balance: data?.global,
		currentposition: currentposition,
		spot: data?.spot,
		futures: data?.futures,
		global: data?.global,
		total_trade_price: +data?.today,
		posrefresh: prefresh,
		torefresh: tore,
		reset: () => {
			SWRDATA = {}
			localStorage.removeItem('trade')
			mutate()
		},
		tempmutate: (type, value) => {
			mutate(data => {
				if (!data) data = {}
				if (!SWRDATA) SWRDATA = {}

				const temp = { ...data, ...SWRDATA }
				if (type == 'SP') {
					if (!temp.position) temp.position = []

					if (
						temp.position?.find(
							(i: any) =>
								i.order_price == value.order_price &&
								i.long_short == value.long_short &&
								i.uuid
						)
					) {
						prefresh = !prefresh
						return temp
					}

					if (value?.reduce_position) {
						const check = temp.position.find(
							(i: any) =>
								!i.status &&
								i.exchange_pairs_id == value.exchange_pairs_id
						)
						if (!check) {
							prefresh = !prefresh
							return temp
						}
					}
					temp.position = [...(temp?.position || []), value]
					prefresh = !prefresh
				} else if (type == 'SF') {
					temp.futures = value
				} else if (type == 'SS') {
					temp.spot = value
				} else if (type == 'SG') {
					console.log('global', value)
					temp.global = value
				} else if (type == 'ST') {
					temp.today = value
					tore = !tore
				}
				SWRDATA = temp
				localStorage.setItem('trade', JSON.stringify(temp))

				return temp
			})
		},
		mutate: (type?, value?) => {
			if (value !== undefined) {
				mutate(data => {
					if (!data) data = {}
					if (!SWRDATA) SWRDATA = {}

					const temp = { ...data, ...SWRDATA }
					if (type == 'SP') {
						temp.position = value
						prefresh = !prefresh
					} else if (type == 'SF') {
						temp.futures = value
					} else if (type == 'SS') {
						temp.spot = value
					} else if (type == 'SG') {
						temp.global = value
					} else if (type == 'ST') {
						temp.today = value
						tore = !tore
					}
					SWRDATA = temp
					localStorage.setItem('trade', JSON.stringify(temp))
					return temp
				})
			} else {
				mutate()
			}
		},
		updateData: (type, value) => {
			if (value) {
				if (!SWRDATA) SWRDATA = {}
				if (type == 'SP') {
					SWRDATA.position = value
					prefresh = !prefresh
					console.log('update', prefresh)
				} else if (type == 'SF') {
					SWRDATA.futures = value
				} else if (type == 'SS') {
					SWRDATA.spot = value
				} else if (type == 'SG') {
					SWRDATA.global = value
				} else if (type == 'ST') {
					SWRDATA.today = value
					tore = !tore
				}
			} else {
			}
		},
		tempadd: (order: any) => {
			mutate()
			setRefresh(!refresh)
		},
		setRequestStatus: value => {
			if (!requestStatus) requestStatus = value
			requestStatus()
		},
		requestStatus: (type?, force?) => {
			if (requestStatus) requestStatus(type, force)
		},
	}
}
