import React from 'react'
import { COLORS } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { CSwitch } from 'app/components/Common'

interface IProps {
	title?: any
	content?: any
	checked?: boolean
	setChecked?: any
}
const PreferenceListItems = ({
	title,
	content,
	checked,
	setChecked,
}: IProps) => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'flex-start',
				width: '100%',
				marginTop: 20,
				paddingRight: 5,
			}}>
			<View
				style={{
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					width: '80%',
					paddingRight: 5,
				}}>
				<Text size={14}>{t(title)}</Text>
				<Text
					size={12}
					style={{
						color: COLORS.lightGray,
						marginTop: 7,
						lineHeight: 1.2,
					}}>
					{content && t(content)}
				</Text>
			</View>
			<CSwitch checked={checked} setChecked={setChecked} />
		</View>
	)
}

export default PreferenceListItems
