import { Button, Checkbox, Col, Input, Modal, Row, Space, message } from 'antd'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from '../../../../../../../../theme/ThemeProvider'
import API from '../../../../../../../../utils/api'
import { CANCELORDER } from '../../../../../../../constants/APIKEYS'
import { useToken } from '../../../../../../../hooks'
import { useIndexPrice } from '../../../../../../../hooks/trade/indexprice'
import { useLogQueue } from '../../../../../../../hooks/trade/logQueue'
import { useMitOrder } from '../../../../../../../hooks/trade/mitorder'
import { useTradeModalStore } from '../../../../../../../hooks/trade/modalStore'
import { useOrderStore } from '../../../../../../../hooks/trade/orderStore'
import { useTPLStore } from '../../../../../../../hooks/trade/tplStore'
import { useTradePosition } from '../../../../../../../hooks/trade/tradePosition'
import { useTradeStore } from '../../../../../../../hooks/trade/tradeStore'
import { useUserSocket } from '../../../../../../../hooks/trade/userSocket'
import DerivativesHeader from '../../../../../../../organisms/Trade/TradeTransaction/DerivativesTransaction/DerivativesHeader'
import TradeTabPositions from '../../../../TradeTabFooter/TradeTabPositions'

const DerivativesFormBtns = ({
	isMobile = false,
	ismit = false,
	tab,
	zoom = 1,
}) => {
	const { data: token } = useToken()
	const { data: tradeStore, ep_id } = useTradeStore()
	const { sendOrder } = useUserSocket()

	const [tpslmodal, setTpslmodal] = useState(false)

	const { data: orderStore } = useOrderStore()
	const { data: tplStore, changeTPL } = useTPLStore()
	const { isDarkMode } = useContext(ThemeContext)
	const { raise, fail, stroke, textw, cardOpacity } =
		useContext(ThemeContext).theme.COLORS

	const [profit, setProfit] = useState(false)
	const [loss, setLoss] = useState(false)
	const [profittick, setProfittick] = useState<any>(5)
	const [losstick, setLosstick] = useState(5)
	const { data, mutate: mutatePosition } = useTradePosition()
	const { data: modalStore, change: mutateModalStore } = useTradeModalStore()
	const { change } = useOrderStore()

	const { getPrice } = useIndexPrice(ep_id)

	const { mutate: mutateLog } = useLogQueue()
	const { data: mitdata, mutate: mutateMit } = useMitOrder()

	const profittimer = React.useRef<any>()
	const losstimer = React.useRef<any>()

	const [sdate, setSdate] = useState<any>(null);

	useEffect(() => {

		if(!sdate){
			if(tpslmodal){
				setSdate(new Date().getTime())
			}
		}else{
			if(!tpslmodal){
				mutateLog({
					log: 'FR005',
					data: {
						ep_id: ep_id,
						s: sdate,
						e: new Date().getTime()
					}
				})
				setSdate(null)
			}
		}

	}, [tpslmodal, sdate, ep_id])

	useEffect(() => {
		if (!tradeStore?.ep_id) return

		const data = tplStore
			?.filter((i: any) => i.key == 'TPL' + tradeStore?.ep_id)
			?.map(d => {
				const [profit, loss, p, l] = d?.value?.split(':') || [
					5, 5, 0, 0,
				]
				return {
					profit: p == 1 ? true : false,
					profittick: +profit || 5,
					loss: l == 1 ? true : false,
					losstick: +loss || 5,
				}
			}) || [
				{
					profit: false,
					profittick: 5,
					loss: false,
					losstick: 5,
				},
			]

		setProfit(data?.[0]?.profit)
		setLoss(data?.[0]?.loss)
		setProfittick(data?.[0]?.profittick || 5)
		setLosstick(data?.[0]?.losstick || 5)
	}, [tplStore, tradeStore?.ep_id, tpslmodal])


	const waitlist = data?.filter((i: any) => i?.status)

	const { t } = useTranslation()

	const mitorder = useMemo(() => {
		return (longshort, price) => {
			if (price <= 0) {
				message.error('가격을 입력해주세요.')
				return
			}
			if (!orderStore?.amount) {
				message.error('수량을 입력해주세요.')
				return
			}
			mutateLog({
				log: 'ST011',
				data: {
					ep_id: ep_id,
					longshort: longshort,
					price: price,
					amount: orderStore?.amount,
					type: 'MIT',
					isMobile: isMobile,
				},
			})

			const currentprice = getPrice(ep_id)
			let BS = 'B'

			if (longshort == 'L') {
				if (price > currentprice) {
					BS = 'S'
				} else {
					BS = 'A'
				}
			} else {
				if (price > currentprice) {
					BS = 'A'
				} else {
					BS = 'B'
				}
			}

			if (!modalStore?.skip) {
				mutateModalStore({
					showconfirmorder: true,
					mitlong: longshort,
					mitprice: +price,
					BS: BS,
				})
				return
			} else {
				mutateModalStore({
					mitlong: longshort,
					mitprice: +price,
					BS: BS,
				})
			}

			mutateMit(
				{
					amount: orderStore?.amount,
					longshort: longshort,
					price: +price,
					BS: BS,
				},
				ep_id
			)
			message.success('MIT 주문이 등록되었습니다.')
		}
	}, [
		ep_id,
		mitdata,
		mutateMit,
		orderStore?.amount,
		isMobile,
		modalStore?.skip,
	])

	const marketOrder = long_short => {
		if (tab != 'M' && orderStore?.price <= 0) {
			message.error('가격을 입력해주세요.')
			return
		}

		//MIT주문일때
		if (ismit) {
			mitorder(long_short, orderStore?.price)
			return
		} else {
			mutateModalStore({
				mitlong: null,
			})
		}

		if (!modalStore?.skip) {
			change({
				limit_market: tab || 'M',
				long_short: long_short,
			})
			mutateModalStore({
				showconfirmorder: true,
			})

			return
		}

		mutateLog({
			log: 'FR003',
			data: {
				ep_id: ep_id,
				limit_market: tab,
				long_short: long_short,
				price: orderStore?.price,
				amount: orderStore?.amount,
				type: tab || 'M',
				isMobile: isMobile,
			},
		})

		sendOrder(
			isMobile ? 1 : 0,
			tradeStore?.ep_id,
			long_short,
			tab || 'M',
			orderStore?.price,
			orderStore?.amount,
			'',
			token,
			() => {
				setTimeout(() => {
					mutatePosition()
				}, 1000)
			}
		)
	}

	const liquidAll = async () => {
		if (!modalStore?.skip) {
			if (!window.confirm('전체 청산하시겠습니까?')) return
		}

		const orderParams = {
			ep_id: '*',
			platform: isMobile ? 1 : 0,
		}

		const result = await API.post(
			'/trade/allcloseorder',
			'' + token,
			orderParams
		)

		if (result?.result?.success) {
			mutatePosition()
			message.success('전체청산이 완료되었습니다.')
		} else {
			if (result?.result?.message) {
				message.error(result?.result?.message)
			} else {
				message.error('청산에 실패하였습니다.')
			}
		}
	}

	const cancelAll = long_short => {
		if (!modalStore?.skip) {
			if (!window.confirm('전체취소하시겠습니까?')) return
		}

		if (waitlist?.length > 0) {
			waitlist?.map((item: any) => {
				setImmediate(() => {
					if (long_short == 'ALL') {
						API.post(CANCELORDER, token + '', {
							orderid: item?.id,
						}).then(res => {
							mutatePosition()
						})
					} else {
						if (long_short == item?.long_short) {
							API.post(CANCELORDER, token + '', {
								orderid: item?.id,
							}).then(res => {
								mutatePosition()
							})
						}
					}
				})
			})
		} else {
			message.error('미체결주문 종목이 없습니다.')
		}
	}

	return (
		<View style={{
			width: '100%',
			marginTop: isMobile ? 10 : 0,
		}}>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
				}}>
				<CButton
					title={'매수'}
					onClick={() => marketOrder('L')}
					style={{
						flex: 0.49,
						background: raise,
						color: COLORS.white,
						padding: 8,
						width: '100%',
						borderRadius: 3,
					}}
				/>
				<CButton
					title={'매도'}
					onClick={() => marketOrder('S')}
					style={{
						flex: 0.49,
						background: fail,
						color: COLORS.white,
						padding: 8,
						width: '100%',
						borderRadius: 3,
					}}
				/>
			</View>

			{isMobile && (
				<>
					{/* <CButton
						title={'전체취소'}
						onClick={() => cancelAll('ALL')}
						style={{
							flex: 1,
							marginTop: 30,
							background: point,
							color: COLORS.white,
							padding: 8,
							width: '100%',
							borderRadius: 3,
						}}
					/>
					<CButton
						title={'전체청산'}
						onClick={() => liquidAll()}
						style={{
							flex: 1,
							marginTop: 10,
							background: point,
							color: COLORS.white,
							padding: 8,
							width: '100%',
							borderRadius: 3,
						}}
					/> */}

					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
							flex: 1,
							marginTop: 20,
							paddingBottom: 10,
							borderBottom: '1px solid ' + stroke,
						}}>
						<Text style={{ color: textw }}>현재종목</Text>
						<Button
							onClick={() => setTpslmodal(true)}
							size="small"
							type="default"
							color="primary"
							danger={profit || loss ? true : false}>
							손익절틱 {profit || loss ? '설정됨' : '해제됨'}
						</Button>
					</View>

					<Modal
						open={tpslmodal}
						title="손익절 틱 설정"
						onCancel={() => {
							const data = tplStore
								?.filter((i: any) => i.key == 'TPL' + tradeStore?.ep_id)
								?.map(d => {
									const [profit, loss, p, l] = d?.value?.split(':') || [
										5, 5, 0, 0,
									]
									return {
										profit: p == 1 ? true : false,
										profittick: +profit || 5,
										loss: l == 1 ? true : false,
										losstick: +loss || 5,
									}
								}) || [
									{
										profit: false,
										profittick: 5,
										loss: false,
										losstick: 5,
									},
								]

							setProfit(data?.[0]?.profit)
							setLoss(data?.[0]?.loss)
							setProfittick(data?.[0]?.profittick || 5)
							setLosstick(data?.[0]?.losstick || 5)
							setTpslmodal(false)
						}}
						footer={null}
						centered
						rootClassName={
							'modal' + (isDarkMode ? 'light' : 'dark')
						}>
						<Row
							style={{
								justifyContent: 'space-between',
								alignItems: 'center',
								width: '100%',
								gap: 5,
							}}>
							<Col
								style={{
									gap: 10,
								}}>
								<div
									style={{
										alignItems: 'center',
										color: textw,
										marginBottom: 10,
									}}>
									<Checkbox
										checked={profit}
										onChange={e => {
											setProfit(e.target.checked)
										}}
										style={{
											color: textw,
											whiteSpace: 'nowrap',
										}}>
										익절
									</Checkbox>
									<Input
										type="number"
										size="small"
										style={{
											width: 40,
											padding: 0,
											textAlign: 'center',
										}}
										min={0}
										value={profittick}
										onChange={e => {
											let data: any = e.target.value
											setProfittick(data)
										}}
									/>
									틱
								</div>
								<div
									style={{
										alignItems: 'center',
										color: textw,
									}}>
									<Checkbox
										checked={loss}
										onChange={e => {
											setLoss(e.target.checked)
										}}
										style={{
											color: textw,
											whiteSpace: 'nowrap',
										}}>
										손절
									</Checkbox>
									<Input
										type="number"
										size="small"
										style={{
											width: 40,
											padding: 0,
											textAlign: 'center',
										}}
										value={losstick}
										min={0}
										onChange={e => {
											let data: any = e.target.value
											setLosstick(data)
										}}
									/>
									틱
								</div>
							</Col>
						</Row>
						<View
							style={{
								marginTop: 10,
								flexDirection: 'column',
								justifyContent: 'flex-start',
								alignItems: 'flex-start',
							}}>
							<Text style={{ color: textw }}>
								* 손익절 틱 설정은 현재 [{tradeStore?.pairs}]
								종목에만 적용됩니다.
							</Text>
							<Text style={{ color: textw }}>
								* 손익절 설정을 변경후 적용을 눌러야 적용됩니다.
							</Text>

							<Text style={{ color: raise }}>
								* 현재가와 가까우면 즉시 청산될 가능성이
								있습니다.
							</Text>
						</View>

						<Button
							onClick={() => {

								if(profittick < 1 || losstick < 1) { 
									message.error('1틱 이상으로 설정해주세요.')
									return
								}

							
								setTpslmodal(false)

								changeTPL(
									{
										profittick,
										losstick,
										profit: profit ? 1 : 0,
										loss: loss ? 1 : 0,
									},
									tradeStore?.ep_id
								)

								mutateLog({
									log: 'ST007',
									data: {
										current_ep_id:
											tradeStore?.ep_id,
										profit: profit ? 1 : 0,
										profittick: profittick,
										loss: loss ? 1 : 0,
										losstick: losstick,
										device: 'MTS',
									},
								})
							}}
							type="primary"
							style={{
								width: '100%',
								marginBottom: 10
							}}>
							적용
						</Button>
						<Button
							onClick={() => {

								const data = tplStore
									?.filter((i: any) => i.key == 'TPL' + tradeStore?.ep_id)
									?.map(d => {
										const [profit, loss, p, l] = d?.value?.split(':') || [
											5, 5, 0, 0,
										]
										return {
											profit: p == 1 ? true : false,
											profittick: +profit || 5,
											loss: l == 1 ? true : false,
											losstick: +loss || 5,
										}
									}) || [
										{
											profit: false,
											profittick: 5,
											loss: false,
											losstick: 5,
										},
									]

								setProfit(data?.[0]?.profit)
								setLoss(data?.[0]?.loss)
								setProfittick(data?.[0]?.profittick || 5)
								setLosstick(data?.[0]?.losstick || 5)

								setTpslmodal(false)
							}}
							danger
							type="primary"
							style={{
								width: '100%',
							}}>
							닫기(취소)
						</Button>
					</Modal>

					{isMobile && (
						<TradeTabPositions current={true} />
					)}

					{!isMobile && (
						<Space.Compact
							direction="horizontal"
							style={{
								marginTop: 30,
								width: '100%',
								gap: 3,
								flex: 1,
							}}>
							<label style={{ color: textw }}>
								<Button
									onClick={e => {
										setImmediate(() => {
											mutateModalStore({
												settings: true,
											})
										})
									}}>
									설정
								</Button>
							</label>
						</Space.Compact>
					)}

					<Modal
						open={modalStore?.settings}
						rootClassName={
							'modal' + (isDarkMode ? 'light' : 'dark')
						}
						bodyStyle={{
							backgroundColor: cardOpacity,
							borderRadius: 10,
						}}
						title={t('settings')}
						onCancel={() => mutateModalStore({ settings: false })}
						onOk={() => mutateModalStore({ settings: false })}
						width={670}
						footer={null}
						centered={false}>
						<DerivativesHeader mobile={true} />
					</Modal>
				</>
			)}
		</View>
	)
}

export default DerivativesFormBtns
