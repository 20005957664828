import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { MText, MView } from 'styles/mobilePageStyles'
import { COLORS } from 'app/constants'
import { ThemeContext } from 'theme/ThemeProvider'
import { getSetting, useSetting } from '../../../../../../hooks/user/mysetting'
import { SETTING_TYPE_SYSTEM } from '../../../../../../constants/SETTING'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { IconCheck } from '@tabler/icons-react'
dayjs.extend(utc)
dayjs.extend(timezone)

const TimeZoneContainer = ({ setOpen, item, setItem }) => {
	const { gray } = useContext(ThemeContext).theme.COLORS
	const [timezones, setTimezones] = useState<any>([])

	const { data: setting, updateSetting } = useSetting(SETTING_TYPE_SYSTEM)
	const timezone = getSetting(setting, 'TIMEZONE', 'Asia/Seoul')

	useEffect(() => {
		const zones = dayjs.tz
		setTimezones(zones)
	}, [])

	useEffect(() => {}, [timezone])

	return (
		<MView style={{ width: '100%', height: '100%', padding: 12 }}>
			{timezones?.map((listItem, index) => {
				return (
					<MView
						key={index.toString()}
						onClick={() => {
							updateSetting('TIMEZONE', listItem)
							setItem(listItem)
							setOpen(false)
						}}
						style={{
							flexDirection: 'row',
							width: '100%',
							justifyContent: 'space-between',
							paddingBlock: 10,
							cursor: 'pointer',
							background:
								timezone === listItem ? gray : 'transparent',
							alignItems: 'center',
							paddingInline: 5,
						}}>
						<MText>{listItem}</MText>
						{timezone === listItem ? (
							<IconCheck size={18} color={COLORS.primary} />
						) : null}
					</MView>
				)
			})}
		</MView>
	)
}

export default TimeZoneContainer
