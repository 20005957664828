import { Col } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { Text } from '../../../../styles/reactnative'
import { useWindowDimensions } from '../../../hooks'
import KrwPrice from '../../KrwPrice'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import BigNumber from 'bignumber.js'
import { useLastPrice } from '../../../hooks/trade/lastprice'
import { useInfo24H } from '../../../hooks/trade/info24H'
import DefaultKrw from './DefaultKrw'
import { useIndexPrice } from '../../../hooks/trade/indexprice'

const PricePrevDay = () => {
	const { data: tradeStore, symbol } = useTradeStore()
	const { getPrice } = useIndexPrice(tradeStore?.ep_id)
	const { data: info24 } = useInfo24H(symbol)

	const currentprice = BigNumber(
		getPrice(tradeStore?.ep_id) || info24?.open || 0
	)
	const rate = BigNumber(currentprice)
		.minus(info24?.close || 0)
		.multipliedBy(100)
		.dividedBy(currentprice)

	return (
		<DefaultKrw
			title={'등락률'}
			price={rate.toNumber()}
			dp={2}
			krw={false}
			color={true}
			center={true}
			percent={true}
		/>
	)
}

export default React.memo(PricePrevDay)
