import React, { useEffect, useState } from 'react'
import { View } from 'styles/reactnative'
import AuthHeaderDropdown from './AuthHeaderDropdown'
import { HeaderButtonSection, HeaderButtonText } from './styles'
import { useToken } from '../../../hooks'
import { useMyProfile } from '../../../hooks/user/myprofile'
import { goTo } from '../../../hooks/navigation'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'

interface IProps {
	label?: string
	onPress?: () => void
	notIcon?: boolean
	Icon?: any
	type?: string
}

const AuthHeaderButton = ({ label, onPress, Icon, type }: IProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [hoverState, setHoverState] = useState(false)

	const { data: profile, mutate: mutateProfile } = useMyProfile()
	const { mutate: mutateToken } = useToken()

	const nav = useHistory()

	useEffect(() => {
		if (profile && (profile?.level == -9 || profile?.level == -2)) {
			message.error('접속 권한이 없습니다.')
			mutateProfile(null)

			setTimeout(() => {
				mutateToken(null)
			}, 1000)
		}
	}, [profile?.level])

	return (
		<HeaderButtonSection
			style={Icon ? { width: 40 } : { width: 80 }}
			onClick={
				profile && profile?.level == 5
					? () => {
							goTo(nav, '/partner')
					  }
					: onPress
			}
			onMouseOver={() => setHoverState(true)}
			onMouseOut={() => setHoverState(false)}>
			{(type == 'user' && profile && profile?.level && (profile?.level <= 0 || profile?.level == 6) )  && (
				<div
					style={{
						position: 'absolute',
						marginTop: '26px',
						background: 'black',
						color: 'white',
						fontSize: '11px',
						padding: '3px',
					}}>
					{profile?.level == 6 ? '모의' : '미승인'}
				</div>
			)}
			{(type == 'user' && profile?.level == 5) && (
				<div
					style={{
						position: 'absolute',
						marginTop: '26px',
						background: 'black',
						color: 'white',
						fontSize: '11px',
						padding: '3px',
					}}>
					{profile?.level == 5 && (profile?.groups?.subgroup?'상위파트너':'파트너')}
				</div>
			)}
			{/* Dropdown */}
			<AuthHeaderDropdown type={type} />
			{Icon ? <Icon /> : null}
			{label ? (
				<View style={{ flexDirection: 'row' }}>
					<HeaderButtonText>{label}</HeaderButtonText>
					{/* {hoverState ? (
						<IconCaretUp size={10} stroke={5} />
					) : (
						<IconCaretDown size={10} stroke={5} />
					)} */}
				</View>
			) : null}
		</HeaderButtonSection>
	)
}

export default React.memo(AuthHeaderButton)
