export const ACCESS_TOKEN = 'LOCAL@ACCESS_TOKEN'
export const STORE_HEADER = 'LOCAL@STORE_HEADER'
export const STORE_HEADER_BUTTON = 'LOCAL@STORE_HEADER_BUTTON'
export const STORE_TRADE = 'LOCAL@STORE_TRADE'
export const STORE_ORDER = 'LOCAL@STORE_ORDER'
export const STORE_TPL = 'LOCAL@STORE_TPL'
export const STORE_TRADELAYOUT = 'LOCAL@STORE_TRADELAYOUT'
export const STORE_MODAL_TRADE = 'LOCAL@STORE_TRADEMODAL'
export const STORE_EXCHANGE = 'LOCAL@STORE_EXCHANGE'
export const STORE_PRICE = 'LOCAL@STORE_PRICE'
export const STORE_FAVOURITE = 'LOCAL@STORE_FAVOURITE'
export const STORE_SEARCH_HISTORY = 'LOCAL@STORE_SEARCH_HISTORY'
export const STORE_DEPOSIT = 'LOCAL@DEPOSITSTORE'
export const STORE_SYSTEM = 'LOCAL@LOCALSYSTEMSTORE'
export const PROFILESTORE = 'LOCAL@PROFILESTORE'

export const TRADE_SOCKET = 'LOCAL@TRADE_SOCKET'
export const USER_SOCKET = 'LOCAL@USER_SOCKET'
export const FLASH_QUEUE = 'LOCAL@FLASHQUEUE'

export const THEME_STORE = 'LOCAL@THEMESTORE'

export const NOTI_SETTING = 'LOCAL@NOTI_SETTING'

export const COINGLOBAL = 'LOCAL@COINGLOBAL'
export const STORE_PROCESS = 'LOCAL@A@STORE_PROCESS'
