import React, { useState } from 'react'
import { CSwitch } from 'app/components/Common'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import { toast } from 'react-toastify'
import { SETTING_TYPE_SYSTEM } from '../../../../../constants/SETTING'
import { getSetting, useSetting } from '../../../../../hooks/user/mysetting'

const AccountSettingCheckBox = () => {
	const { t } = useTranslation()
	const { data: setting, updateSetting } = useSetting(SETTING_TYPE_SYSTEM)
	let checked = getSetting(setting, 'EMAILSUB', '0') === '1'

	const setChecked = (c: boolean) => {
		updateSetting('EMAILSUB', c ? '1' : '0')
	}

	return (
		<MView
			style={{
				flexDirection: 'row',
				alignItems: 'center',
				marginTop: 5,
			}}>
			<CSwitch
				checked={checked}
				setChecked={() => {
					setChecked(!checked)
					toast(
						t('latestEvents') +
							t(checked ? ' opened' : ' closed') +
							''
					)
				}}
			/>
			<MText style={{ marginLeft: 10, fontSize: 12 }}>
				{t('latestEvents')}
			</MText>
		</MView>
	)
}

export default AccountSettingCheckBox
