import { useCallback } from 'react'
import useSWR from 'swr'
import API from '../../../utils/api'
import { useToken } from '../auth/token'
import { useTradeModalStore } from './modalStore'

let swrDATA: any = {}
let lastupdate: any = null

export function useMitOrder() {
	const { data: token } = useToken()
	const { mutateMsg } = useTradeModalStore()

	const getMIT = useCallback(async token => {
		return swrDATA || {}
	}, [])

	const getMITServer = async token => {
		const res = await API.get('/trade/mit', token + '', {}, true)
		if (res?.result?.success) {
			const result = res?.result?.result
			swrDATA = {}

			result.forEach((i: any) => {
				if (swrDATA['MIT' + i.ep_id] == undefined) {
					swrDATA['MIT' + i.ep_id] = []
				}

				swrDATA['MIT' + i.ep_id].push({
					amount: i.amount,
					longshort: i.longshort,
					price: i.price,
					BS: i.BS,
				})
			})
		}
	}

	const { data, mutate } = useSWR<any>(
		token ? '/trade/mit' : null,
		async () => {
			if (lastupdate == null) {
				lastupdate = new Date().getTime()
			}

			// 10초 이상 지났을 경우
			if (new Date().getTime() - lastupdate > 10000) {
				await getMIT(token)
				lastupdate = new Date().getTime()
				return swrDATA
			} else {
				return swrDATA
			}
		},
		{
			revalidateOnFocus: true,
			revalidateOnReconnect: true,
			refreshInterval: 10000,
		}
	)

	return {
		data,
		cancel: (epid: number, price: number, longshort: any) => {
			try {
				if (epid) {
					API.delete('/trade/mit', token + '', {
						ep_id: epid,
						price: price,
						longshort: longshort,
					}).then(res => {
						if (res?.result?.success) {
							const result = res?.result?.result
							swrDATA = {}

							result.forEach((i: any) => {
								if (swrDATA['MIT' + i.ep_id] == undefined) {
									swrDATA['MIT' + i.ep_id] = []
								}
								swrDATA['MIT' + i.ep_id].push({
									amount: i.amount,
									longshort: i.longshort,
									price: i.price,
									BS: i.BS,
								})
							})

							return mutate((prev: any) => {
								return swrDATA
							})
						}
					})
				}
			} catch (e) {
				return mutate()
			}
		},
		getMIT: () => {
			getMITServer(token)
		},
		removeandupdate: useCallback(
			async (epid: number, price: number, longshort: any) => {
				await mutate((prev: any) => {
					if (prev['MIT' + epid]) {
						const data = prev['MIT' + epid].filter(
							(i: any) =>
								i.price !== price &&
								(longshort == '*'
									? true
									: i.longshort === longshort)
						)
						swrDATA = { ...prev, ['MIT' + epid]: data }

						return swrDATA
					} else {
						return prev
					}
				})
			},
			[data]
		),
		modify: (
			epid: number,
			price: number,
			longshort: any,
			change_price: number
		) => {
			API.put('/trade/mit', token + '', {
				ep_id: epid,
				price: price,
				longshort: longshort,
				change_price: change_price,
			}).then(res => {
				if (res?.result?.success) {
					mutateMsg('MIT 정정되었습니다.')
					const result = res?.result?.result
					swrDATA = {}

					result.forEach((i: any) => {
						if (swrDATA['MIT' + i.ep_id] == undefined) {
							swrDATA['MIT' + i.ep_id] = []
						}
						swrDATA['MIT' + i.ep_id].push({
							amount: i.amount,
							longshort: i.longshort,
							price: i.price,
							BS: i.BS,
						})
					})

					return mutate((prev: any) => {
						return swrDATA
					})
				}
			})
		},
		mutate: (value?: any, epid?: number) => {
			try {
				if (value !== undefined) {
					if (epid) {
						//console.log(value)
						API.post('/trade/mit', token + '', {
							ep_id: epid,
							mit: value,
						}).then(res => {
							if (res?.result?.success) {
								const result = res?.result?.result
								swrDATA = {}

								result.forEach((i: any) => {
									if (swrDATA['MIT' + i.ep_id] == undefined) {
										swrDATA['MIT' + i.ep_id] = []
									}
									swrDATA['MIT' + i.ep_id].push({
										amount: i.amount,
										longshort: i.longshort,
										price: i.price,
										BS: i.BS,
									})
								})

								return mutate((prev: any) => {
									return swrDATA
								})
							}
						})
					}
				} else {
					return mutate()
				}
			} catch (e) {
				return mutate()
			}
		},
	}
}
