import React from 'react'
import { TradeDetailContainer, TradeDetailListsWrapper } from './styles'
import TradePositionsTable from './TradePositionsTable'
import { useMitOrder } from '../../../hooks/trade/mitorder'

const TradeDetail = ({}) => {
	return (
		<TradeDetailContainer>
			<TradeDetailListsWrapper>
				<TradePositionsTable />
			</TradeDetailListsWrapper>
		</TradeDetailContainer>
	)
}

export default React.memo(TradeDetail)
