import React, { useContext, useState } from 'react'
import { HeaderButtonSection, HeaderButtonText } from './styles'
import HeaderDropdown from './HeaderDropdown'
import { useHeaderBtn } from 'app/hooks/stateStore/headerButton'
import { ThemeContext } from '../../../theme/ThemeProvider'
import { useWindowDimensions } from '../../hooks'

interface IProps {
	label: string
	onPress?: any
	data?: any
	notIcon?: boolean
	icon?: any
	style?: any
	radius?: number
}

const HeaderButton = ({
	label,
	onPress,
	data,
	notIcon,
	icon,
	style = {},
	radius = 0,
}: IProps) => {
	const { data: headerBtn, mutate } = useHeaderBtn()
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [hoverState, setHoverState] = useState(false)

	const { width } = useWindowDimensions()

	if (headerBtn?.hoverState === undefined) {
		mutate({
			hoverState: false,
		})
	}
	const { textw, cardOpacity } = useContext(ThemeContext).theme.COLORS
	return (
		<HeaderButtonSection
			width={width}
			background={cardOpacity}
			hover={radius == 0 ? headerBtn?.hoverState : false}
			onClick={onPress}
			onMouseOut={() => setHoverState(false)}
			onMouseOver={() => {
				mutate({
					hoverState: true,
				})
				setHoverState(true)
			}}>
			{/* Dropdown */}
			<HeaderDropdown data={data} />
			{icon}
			<HeaderButtonText style={{ ...style, whiteSpace: 'nowrap' }}>
				{label}
			</HeaderButtonText>
			{/* {!notIcon ? (
				<View style={{ marginLeft: 3 }}>
					{hoverState ? (
						<IconCaretUp size={12} stroke={5} />
					) : (
						<IconCaretDown size={12} stroke={5} />
					)}
				</View>
			) : null} */}
		</HeaderButtonSection>
	)
}

export default React.memo(HeaderButton)
