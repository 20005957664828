import styled from 'styled-components'
import { COLORS } from '../constants'

export const TagWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	justify-content: center;
	justify-items: center;
	align-items: center;
	align-content: center;

	& > span {
		padding: 1px 2px;
		margin: 2px 2px;
		flex: 1;
		text-align: center;
	}
`
export const MetaView = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	margin-bottom: 5px;
	height: 40px;
	@media only screen and (max-width: 600px) {
		flex-direction: column;
		height: 60px;
	}
`
export const MetaTitle = styled.div`
	width: 150px;
	height: 100%;
	display: flex;
	align-items: flex-start;
	font-size: 14px;
	font-weight: 600;
	@media only screen and (max-width: 600px) {
		width: 100px;
	}
`
export const Meta = styled.div`
	flex: 1;
	display: flex;
	background-color: ${COLORS.lightGray2};
	height: 30px;
	border-radius: 5px;
	align-items: center;
	padding-left: 10px;
	cursor: pointer;
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
