import React, { useContext, useEffect, useState } from 'react'
import { Header, Footer, PrivacyPolicy } from 'app/organisms'
import {
	ContentContainer,
	ContentHalf,
	ContentWrapper,
} from '../../../../styles/globalPage-styles'
import { CFloatBtns, PageMenuTabs, SubFooter } from 'app/components'
import { ThemeContext } from 'theme/ThemeProvider'
import TradeModal from '../../TradeModal'
import { useHistory } from 'react-router-dom'
import TermOfService from '../../../organisms/TermOfService'

const PrivacyPolicyDesktop = () => {
	const history = useHistory()
	const { isDarkMode } = useContext(ThemeContext)
	const privacyPolicy = 'privacyPolicy'
	const TermsOfService = 'TermsOfService'
	const data = [privacyPolicy, TermsOfService]

	//
	const params = new URLSearchParams(window.location.search)
	const urltab = params.get('tab')
	const selectTab = data[parseInt(urltab + '') - 1]
	const [tab, setTab] = useState(selectTab ? selectTab : privacyPolicy)
	useEffect(() => {
		setTab(selectTab ? selectTab : privacyPolicy)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [urltab])

	const [visible, setVisible] = React.useState(false)
	return (

		<ContentContainer isDarkMode={isDarkMode}>
		<Header showSelect={visible} setShowSelect={setVisible} />
			<CFloatBtns />
			<PageMenuTabs
				tab={tab}
				setTab={s => {
					setTab(s)
					history.replace(`/terms-service?tab=${data.indexOf(s) + 1}`)
				}}
				data={data}
			/>
			<ContentWrapper>

				{privacyPolicy === tab ? <PrivacyPolicy /> : <TermOfService />}
			</ContentWrapper>
			<ContentHalf>
				<SubFooter />
				<Footer />
			</ContentHalf>
		</ContentContainer>
	)
}
export default PrivacyPolicyDesktop
