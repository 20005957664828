import { useExchangeStoreAll } from 'app/hooks/trade/exchangeStoreAll'
import { useCurrency } from 'app/hooks/trade/useCurrency'
import BigNumber from 'bignumber.js'
import React, { useContext, useRef } from 'react'
import { MView } from '../../../../../../styles/mobilePageStyles'
import { SpacebetweenRowView } from '../../../../../../styles/reactnative'
import { ThemeContext } from '../../../../../../theme/ThemeProvider'
import { useIndexPrice } from '../../../../../hooks/trade/indexprice'
import OrderBody from './OrderBody'
import { CloseOrderItem } from './OrderFooter'
import OrderHeader from './OrderHeader'
const OrderItems = ({ item, index, current }) => {
	const TCOLORS = useContext(ThemeContext).theme.COLORS

	const { EPs } = useExchangeStoreAll()
	let {
		data: indexprice,
		getPrice,
		reset,
	} = useIndexPrice(item?.exchange_pairs_id)

	const EP = EPs?.find((EPitem: any) => EPitem?.id == item?.exchange_pairs_id)

	const currentprice =
		getPrice(item?.exchange_pairs_id) || indexprice?.[0]?.price || 0

	const { krwrate, getRate } = useCurrency()
	const currencyrate = getRate(EP?.currency)
	const rate = BigNumber(krwrate?.price || 0)
		.div(currencyrate?.price || 0)
		.toNumber()

	let pnl = 0

	let checked = useRef(false)

	if (item?.status != 10 && item?.status != 9 && item?.status != 8) {
		if (!getPrice(item?.exchange_pairs_id)) {
			if (!checked.current) {
				reset('OrderItems' + item?.exchange_pairs_id)
				checked.current = true
			}
		} else {
			if (item?.long_short === 'L') {
				pnl = BigNumber(currentprice)
					.minus(item?.price)
					.multipliedBy(item?.amount)
					.multipliedBy(EP?.contractamt)
					.multipliedBy(rate)
					.toNumber()
			} else {
				pnl = BigNumber(item?.price)
					.minus(currentprice)
					.multipliedBy(item?.amount)
					.multipliedBy(EP?.contractamt)
					.multipliedBy(rate)
					.toNumber()
			}
		}
	}

	return (
		<MView
			style={{
				background: TCOLORS.gray,
				alignSelf: 'center',
				width: current ? '100%' : '100%',
				marginLeft: 5,
				marginRight: 5,
				border:
					current && !item?.status
						? `1px solid ${
								pnl > 0
									? TCOLORS.raise
									: pnl < 0
									? TCOLORS.fail
									: TCOLORS.textsilver
						  }`
						: 'none',
				padding: 10,
				borderRadius: 10,
				marginTop: 6,
			}}>
			<OrderHeader
				item={item}
				coin={EP?.pairs}
				pnl={pnl}
				currency={'원'}
			/>
			<OrderBody item={item} current={current} />
			<SpacebetweenRowView>
				{/*<SetTPOrder item={item} />*/}
				<CloseOrderItem item={item} />
			</SpacebetweenRowView>
		</MView>
	)
}
export default React.memo(OrderItems)
