import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'

export const HeaderButtonWrap = styled.div`
	display: flex;
	align-items: center;
	margin-left: 1rem;
	cursor: pointer;
	&:hover {
		color: ${COLORS.BTN_HOVER};
	}
	color: ${props => props.theme.COLORS.textw};
`

export const HeaderButtonInner = styled.div`
	display: flex;
	margin-left: 0.3rem;
	margin-right: 1rem;
	height: auto;
	font-size: large;
`
