import React from 'react'
import KrwPrice from '../../KrwPrice'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import BigNumber from 'bignumber.js'
import Default from './Default'
import { useTradePosition } from '../../../hooks/trade/tradePosition'
import { useExchangeStore } from '../../../hooks/trade/exchangeStore'
import { useFetcher, useFetcherPublic } from '../../../hooks/fetcher'
import { useIndexPrice } from '../../../hooks/trade/indexprice'
import { useCurrency } from '../../../hooks/trade/useCurrency'

const RealtimePnl = () => {
	let totalpnl = 0
	let pnl = 0

	const { data: tradeStore } = useTradeStore()
	const { data: position, total_trade_price } = useTradePosition()
	const data = position?.filter((item: any) => !item?.status)

	const { EPs } = useExchangeStore()
	const { krwrate, getRate } = useCurrency()
	let { getPrice } = useIndexPrice(tradeStore?.ep_id)

	const realizepnl = total_trade_price || 0

	if (data && data?.length > 0) {
		data?.map((item: any) => {
			const E = EPs?.find((i: any) => item.exchange_pairs_id == i.id)
			const currencyrate = getRate(E?.currency)
			const rate = BigNumber(krwrate?.price || 0)
				.div(currencyrate?.price || 0)
				.toNumber()

			if (
				getPrice(item?.exchange_pairs_id) === 0 ||
				!getPrice(item?.exchange_pairs_id)
			) {
				pnl = 0;
			} else {
				if (item?.long_short === 'L') {
					pnl = BigNumber(getPrice(item?.exchange_pairs_id))
						.minus(item?.price)
						.multipliedBy(item?.amount)
						.multipliedBy(E?.contractamt)
						.multipliedBy(rate)
						.toNumber()
				} else {
					pnl = BigNumber(item?.price)
						.minus(getPrice(item?.exchange_pairs_id))
						.multipliedBy(item?.amount)
						.multipliedBy(E?.contractamt)
						.multipliedBy(rate)
						.toNumber()
				}
			}

			totalpnl = new BigNumber(totalpnl || 0).plus(pnl || 0).toNumber()
		})
	}

	return (
		<Default
			title={'실시간손익'}
			value={
				<KrwPrice
					margin={true}
					price={realizepnl + totalpnl}
					dp={0}
					krw={false}
				/>
			}
		/>
	)
}

export default RealtimePnl
