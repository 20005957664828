import { IconCopy } from '@tabler/icons-react'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useMyProfile } from 'app/hooks/user/myprofile'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import CIUtils from 'utils/ciutils'
import ChangeUsername from './ChangeUsername'
import ProfileImageSection from './ProfileImageSection'

const ProfileEditContainer = () => {
	const { t } = useTranslation()
	const { gray, textw } = useContext(ThemeContext).theme.COLORS
	const { data: myinfo } = useMyProfile()

	return (
		<MView
			style={{
				paddingInline: 16,
				width: '100%',
				height: '100%',
			}}>
			<ProfileImageSection />
			<ChangeUsername />
			<MView
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
					marginTop: 30,
					background: gray,
					padding: 10,
				}}>
				<MText style={{}}>UID</MText>
				<CButton
					title={CIUtils.padId(myinfo?.id)}
					onClick={() => {
						toast(t('copied') + '')
					}}
					icon={
						<IconCopy
							size={16}
							color={COLORS.secondary}
							style={{ marginLeft: 5 }}
						/>
					}
					style={{ alignItems: 'center', color: textw }}
				/>
			</MView>
		</MView>
	)
}

export default ProfileEditContainer
