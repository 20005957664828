import { COLORS } from 'app/constants'
import { useExchangeStoreAll } from 'app/hooks/trade/exchangeStoreAll'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '../../../../styles/reactnative'

const TranslateText = ({ text, data }: any) => {
	const { t } = useTranslation()
	const { EPs } = useExchangeStoreAll()
	if (text == 'HM001') {
		const parsedata = JSON.parse(data?.data)

		if (parsedata?.where == 'TradeFocus') {
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					{'거래창 접속'}
				</Text>
			)
		}
		return (
			<Text style={{ justifyContent: 'flex-start' }}>메인화면 접속</Text>
		)
	} else if (text == 'SO001') {
		try {
			const parsedata = JSON.parse(data?.data)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					{parsedata?.window} 켜짐
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text == 'SO002') {
		try {
			const parsedata = JSON.parse(data?.data)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					{parsedata?.window} 꺼짐
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text.includes('GT001')) {
		const parsedata = data?.data?.split(':') //5010:S:M:0.000001:1:false:0

		const EP = EPs?.find(e => e?.id == parsedata[0])
		return (
			<Text style={{ justifyContent: 'flex-start' }}>{`${EP?.pairs || `만료종목(${parsedata[0]})`
				}, ${parsedata[1] == 'S' ? '매도' : '매수'
				}주문이 접수되었습니다.(시장가 ${parsedata[3]}), 수량: ${parsedata[4]}`}</Text>
		)
	} else if (text.includes('GT003')) {
		return (
			<Text style={{ justifyContent: 'flex-start' }}>
				주문 취소 완료 (주문번호 {data?.data})
			</Text>
		)
	} else if (text.includes('GT010')) {
		const parsedata = data?.data?.split(':') //5010:S:M:0.000001:1:false:0

		const EP = EPs?.find(e => e?.id == parsedata[0])
		return (
			<Text style={{ justifyContent: 'flex-start' }}>{`${EP?.pairs || `만료종목(${parsedata[0]})`
				}, ${parsedata[1] == 'S' ? '매도' : '매수'}주문 정정 시도 ${parsedata[2]
				} > ${parsedata[3]})`}</Text>
		)
	} else if (text.includes('GT011')) {
		const parsedata = data?.data?.split(':') //5010:S:M:0.000001:1:false:0

		const EP = EPs?.find(e => e?.id == parsedata[0])
		return (
			<Text style={{ justifyContent: 'flex-start' }}>{`${EP?.pairs || `만료종목(${parsedata[0]})`
				}, ${parsedata[1] == 'S' ? '매도' : '매수'}주문 취소 시도 ${parsedata[2]
				} `}</Text>
		)
	} else if (text.includes('GT005')) {
		const parsedata = data?.data?.split(':') //5010:S:M:0.000001:1:false:0

		const EP = EPs?.find(e => e?.id == parsedata[0])
		return (
			<Text
				style={{ justifyContent: 'flex-start', color: '#880000' }}>{`${EP?.pairs || `만료종목(${parsedata[0]})`
					}, ${parsedata[1] == 'S' ? '매도' : '매수'}주문이 체결되었습니다.(${parsedata[2] == 'M' ? '시장가' : '지정가'
					} ${parsedata[3]}), 수량: ${parsedata[4]}`}</Text>
		)
	} else if (text.includes('BROWSER')) {
		return <Text style={{ justifyContent: 'flex-start' }}>{'접속'}</Text>		
	}else if (text.includes('LOGINTRY')) {
		return <Text style={{ justifyContent: 'flex-start' }}>{'로그인 시도'}</Text>
	} else if (text.includes('JOIN')) {
		return <Text style={{ justifyContent: 'flex-start' }}>{'서버 연결 체크'}</Text>
	}else if (text.includes('GT006')) {
		const parsedata = JSON.parse(data?.data)
		return (
			<Text
				style={{ justifyContent: 'flex-start', color: '#880000' }}>프론트: {`주문수량변경 : ${parsedata.amount || 0}`}</Text>
		)
	} else if (text.includes('GT999')) {
		const parsedata = data?.data?.split(':') //5010:S:M:0.000001:1:false:0

		const EP = EPs?.find(e => e?.id == parsedata[0])
		return (
			<Text style={{ justifyContent: 'flex-start', color: '#880000' }}>
				[로스컷]{' '}
				{`${EP?.pairs || `만료종목(${parsedata[0]})`}, ${parsedata[1] == 'S' ? '매도' : '매수'
					}주문이 체결되었습니다.(${parsedata[2] == 'M' ? '시장가' : '지정가'
					} ${parsedata[3]})`}
			</Text>
		)
	} else if (text.includes('MIT001')) {
		const parsedata = data?.data?.split(':') //"300000:L:5558.25:2d005e08-2338-4b01-82dd-b53f0560368a:수량 초과"
		const EP = EPs?.find(e => e?.id == +parsedata[0])

		let text = ``
		if (parsedata[5]) {
			text = `${parsedata[4]} > ${parsedata[5]}`
		} else {
			text = `0 > ${parsedata[4]}`
		}

		return (
			<Text
				style={{ justifyContent: 'flex-start', color: COLORS.darkRed }}>
				{EP?.pairs}, MIT등록성공,{' '}
				{parsedata[1] == 'S' ? '매도' : '매수'}, {parsedata[3]}, {text}
				개
			</Text>
		)
	} else if (text.includes('MIT002')) {
		const parsedata = data?.data?.split(':')
		const EP = EPs?.find(e => e?.id == +parsedata[0])
		return (
			<Text
				style={{ justifyContent: 'flex-start', color: COLORS.darkRed }}>
				{EP?.pairs}, MIT정정성공,{' '}
				{parsedata[1] == 'S' ? '매도' : '매수'}, {parsedata[2]} {'>'}{' '}
				{parsedata[3]}, 정정후:{parsedata[4]}개
			</Text>
		)
	} else if (text.includes('MIT003')) {
		const parsedata = data?.data?.split(':')
		const EP = EPs?.find(e => e?.id == +parsedata[0])
		return (
			<Text
				style={{ justifyContent: 'flex-start', color: COLORS.darkRed }}>
				{EP?.pairs}, MIT취소성공,{' '}
				{parsedata[1] == 'S' ? '매도' : '매수'} {parsedata[2]},{' '}
				{parsedata[3]}개
			</Text>
		)
	} else if (text.includes('GT100')) {
		const parsedata = data?.data?.split(':') //"300000:L:5558.25:2d005e08-2338-4b01-82dd-b53f0560368a:수량 초과"
		const order_id = parsedata[3]
		const reason = parsedata[4]

		return (
			<Text style={{ justifyContent: 'flex-start' }}>
				주문취소 ({order_id}) - {reason}
			</Text>
		)
	} else if (text.includes('GT050')) {
		const parsedata = data?.data?.split(':') //${ep_id}:${position.long_short}:${loss}:${lowprice}:${highprice}
		const EP = EPs?.find(e => e?.id == parsedata[0])
		return (
			<Text style={{ justifyContent: 'flex-start' }}>{EP?.pairs}, {parsedata[1] == 'L' ? '매수' : '매도'} 익절틱 발동, 발동기준 {parsedata[2]}</Text>
		)
	} else if (text.includes('GT051')) {
		const parsedata = data?.data?.split(':') //${ep_id}:${position.long_short}:${loss}:${lowprice}:${highprice}
		const EP = EPs?.find(e => e?.id == parsedata[0])
		return (
			<Text style={{ justifyContent: 'flex-start' }}>{EP?.pairs}, {parsedata[1] == 'L' ? '매수' : '매도'} 손절틱 발동, 발동기준 {parsedata[2]}</Text>
		)
	} else if (text.includes('GT009')) {
		return (
			<Text style={{ justifyContent: 'flex-start' }}>
				오버나잇 종목 : {data?.data}
			</Text>
		)
	} else if (text.includes('FR001')) {
		try {
			const parsedata = JSON.parse(data?.data)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 탭변경: {parsedata?.tab == 'M' ? '시장가' : parsedata?.tab == 'L' ? '지정가' : 'MIT'}
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text.includes('FR002')) {
		try {
			const parsedata = JSON.parse(data?.data)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 웹설정변경: 생략: {parsedata?.skip ? 'ON' : 'OFF'}, 원클릭: {parsedata?.oneClick ? 'ON' : 'OFF'}, 고정창: {parsedata?.fixed_orderbook ? 'ON' : 'OFF'}, 소리: {parsedata?.sound ? 'ON' : 'OFF'}, 차트: {parsedata?.chartshow ? 'ON' : 'OFF'}, 상세: {parsedata?.detailshow ? 'ON' : 'OFF'}, 호가: {parsedata?.orderbookshow ? 'ON' : 'OFF'}, 거래: {parsedata?.tradeshow ? 'ON' : 'OFF'}, 저사양: {parsedata?.speed == -1 ? 'ON' : 'OFF'}
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text.includes('FR003')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 주문입력: {EP?.pairs || `만료종목(${parsedata?.ep_id})`}, {parsedata?.type == 'M' ? '시장가' : parsedata?.type == 'L' ? '지정가' : 'MIT'}, {parsedata?.long_short == 'L' ? '매수' : '매도'}, 가격: {parsedata?.price}, {parsedata?.amount}개
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	} else if (text.includes('FR004')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)

			let start = new Date(parsedata?.s || parsedata?.start).toLocaleString('ko-kr')
			let end = new Date(parsedata?.e || parsedata?.end).toLocaleString('ko-kr')
			
			if(start == "Invalid Date") start = '@'
			if(end == "Invalid Date") end = '@'
			

			if(start == "@" || end == "@"){
				return <Text style={{ justifyContent: 'flex-start' }}>
					프론트: 창 {parsedata?.isblur ? '내림' : '올림'} 
				</Text>
			}

			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: {`${EP?.pairs || (parsedata?.ep_id ? `만료종목(${parsedata?.ep_id})` : '알수없음')
						},`} 아웃 ({start} ~ {end})
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	} else if (text.includes('FR005')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)

			let start = new Date(parsedata?.s || parsedata?.start).toLocaleString('ko-kr')
			let end = new Date(parsedata?.e || parsedata?.end).toLocaleString('ko-kr')
			
			if(start == "Invalid Date") start = '@'
			if(end == "Invalid Date") end = '@'

			if(start == "@" || end == "@"){
				return <Text style={{ justifyContent: 'flex-start' }}>
					프론트: 손익절창 {parsedata?.onoff ? '켬' : '끔'} 
				</Text>
			}
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트:  {`${EP?.pairs || (parsedata?.ep_id ? `만료종목(${parsedata?.ep_id})` : '알수없음')
						},`} 손익절창 - ({start} - {end})
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	} else if (text.includes('FR006')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 종목변경 ({EP?.pairs || `만료종목(${parsedata?.ep_id})`})
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	} else if (text.includes('FR008')) {
		try {
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 화면변경 ({data?.data})
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	} else if (text.includes('FR011')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: {EP?.pairs || `만료종목(${parsedata?.ep_id})`}, 주문 팝업 취소
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	} else if (text.includes('FR012')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: {EP?.pairs || `만료종목(${parsedata?.ep_id})`}, MIT팝업 승인, {parsedata?.mit=='L'?'매수':'매도'}, {parsedata?.p}
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	} else if (text.includes('FR013')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: {EP?.pairs || `만료종목(${parsedata?.ep_id})`}, 주문팝업 승인, {parsedata?.m=='M'?'시장가':'지정가'}, {parsedata?.a}개, {parsedata?.p}
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	}  else if (text.includes('FR020')) {
		try {
			const parsedata = JSON.parse(data?.data)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 호가창클릭 {`(${parsedata?.X}, ${parsedata?.Y})`} {parsedata?.T == 0?'봇의심':''}
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	}  else if (text.includes('FR021')) {
		try {
			const parsedata = JSON.parse(data?.data)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 키입력 {`(${parsedata?.K})`}
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	}  else if (text.includes('FR030')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: {EP?.pairs || `만료종목(${parsedata?.ep_id})`}, 오버나잇 {parsedata?.s?'신청':'취소'}
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	}  else if (text.includes('FR031')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: {EP?.pairs || `만료종목(${parsedata?.ep_id})`}, 오버나잇 종목 팝업열림
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	}  else if (text.includes('FR032')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: {EP?.pairs || `만료종목(${parsedata?.ep_id})`}, 오버나잇 {parsedata?.s?'신청':'취소'} 팝업 - 아니요
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	} 
	else if (text.includes('ST007')) {
		try {
			const parsedata = JSON.parse(data?.data)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 손익절 적용 ({parsedata?.profit ? '익절설정' : '익절해제'}, {parsedata?.profittick}틱), ({parsedata?.loss ? '손절설정' : '손절해제'}, {parsedata?.losstick}틱)
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)

		}
	} else if (text.includes('GT000')) {
		const parsedata = data?.data?.split(':') //5010:S:M:0.000001:1:false:0

		const EP = EPs?.find(e => e?.id == parsedata[0])
		return (
			<Text style={{ justifyContent: 'flex-start' }}>{`${EP?.pairs || `만료종목(${parsedata[0]})`
				}, ${parsedata[1] == 'S' ? '매도' : '매수'
				}주문이 접수되었습니다.(지정가 ${parsedata[3]}), 수량: ${parsedata[4]}`}</Text>
		)
	} else if (text.includes('ST010')) {
		const parsedata = JSON.parse(data?.data)

		const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
		return (
			<Text style={{ justifyContent: 'flex-start' }}>
				{EP?.pairs || `만료종목(${parsedata?.ep_id})`}, MIT취소,{' '}
				{parsedata?.price}
				{parsedata?.longshort == 'L' ? '매수' : '매도'},{' '}
				{parsedata?.amount}개
			</Text>
		)
	} else if (text.includes('ST011')) {
		const parsedata = JSON.parse(data?.data)

		const EP = EPs?.find(e => +e?.id == +parsedata?.ep_id)
		return (
			<Text style={{ justifyContent: 'flex-start' }}>
				{EP?.pairs ||
					(parsedata?.ep_id ? `만료종목(${parsedata?.ep_id})` : '')}
				, MIT주문, {parsedata?.longshort == 'L' ? '매수' : '매도'},
				가격:
				{parsedata?.price}, {parsedata?.amount}개
			</Text>
		)
	} else if (text.includes('ST012')) {
		const parsedata = JSON.parse(data?.data)

		const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
		return (
			<Text style={{ justifyContent: 'flex-start' }}>
				{EP?.pairs || `만료종목(${parsedata?.ep_id})`}, MIT정정,{' '}
				{parsedata?.longshort == 'L' ? '매수' : '매도'}, 가격:
				{parsedata?.price}, {parsedata?.amount}개
			</Text>
		)
	} else if (text.includes('ST013')) {
		const parsedata = JSON.parse(data?.data)

		const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
		return (
			<Text style={{ justifyContent: 'flex-start' }}>
				{EP?.pairs || `만료종목(${parsedata?.ep_id})`}, MIT취소,{' '}
				{parsedata?.longshort == 'L' ? '매수' : '매도'}, 가격:
				{parsedata?.price}, {parsedata?.amount}개
			</Text>
		)
	} else if (text.includes('SO003')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					종목리스트에서 종목변경 (
					{EP?.pairs || `만료종목(${parsedata?.ep_id})`}){' '}
				</Text>
			)
		} catch (e) {
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text.includes('ST001')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.current_ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 시장가매도버튼 (
					{EP?.pairs || `만료종목(${parsedata?.current_ep_id})`}){' '}
				</Text>
			)
		} catch (e) {
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text.includes('ST003')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.current_ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					현종목청산 (
					{EP?.pairs || `만료종목(${parsedata?.current_ep_id})`}){' '}
				</Text>
			)
		} catch (e) {
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text.includes('ST002')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.current_ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 전종목청산 (
					{EP?.pairs || `만료종목(${parsedata?.current_ep_id})`}){' '}
				</Text>
			)
		} catch (e) {
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text.includes('ST004')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.current_ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 시장가매수버튼 (
					{EP?.pairs || `만료종목(${parsedata?.current_ep_id})`}){' '}
				</Text>
			)
		} catch (e) {
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text.includes('ST005')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.current_ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 익절 틱 변경 ({parsedata?.onoff ? '설정' : '해제'}),{' '}
					{EP?.pairs || `만료종목(${parsedata?.current_ep_id})`}{' '}
					{parsedata?.tick}틱
				</Text>
			)
		} catch (e) {
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text.includes('ST006')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.current_ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 손절 틱 변경 ({parsedata?.onoff ? '설정' : '해제'}),{' '}
					{EP?.pairs || `만료종목(${parsedata?.current_ep_id})`}{' '}
					{parsedata?.tick}틱
				</Text>
			)
		} catch (e) {
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text.includes('ST008')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
			const EP_C = EPs?.find(e => e?.id == parsedata?.current_ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 포지션 리스트에서 청산,{' '}
					{EP?.pairs || `만료종목(${parsedata?.ep_id})`} ,{' '}
					{parsedata?.id}, {parsedata?.amount}개
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text.includes('ST009')) {
		try {
			const parsedata = JSON.parse(data?.data)
			const EP = EPs?.find(e => e?.id == parsedata?.ep_id)
			const EP_C = EPs?.find(e => e?.id == parsedata?.current_ep_id)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>
					프론트: 포지션 리스트에서 취소요청,{' '}
					{EP?.pairs || `만료종목(${parsedata?.ep_id})`} ,{' '}
					{parsedata?.id}, {parsedata?.amount}개
				</Text>
			)
		} catch (e) {
			console.log(e)
			return (
				<Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
			)
		}
	} else if (text.includes('HM999')) {
		return (
			<Text style={{ justifyContent: 'flex-start' }}>{'세션아웃'}</Text>
		)
	}

	return <Text style={{ justifyContent: 'flex-start' }}>{t(text)}</Text>
}

export default React.memo(TranslateText)
