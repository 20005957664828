import React, { useContext } from 'react'
import { CButton } from 'app/components/Common'

import { ThemeContext } from 'theme/ThemeProvider'
import { IconChevronLeft } from '@tabler/icons-react'
import { OtherHeaderSection } from './styles'
import { MText, MView } from 'styles/mobilePageStyles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import DarkModeToggle from '../../../organisms/Header/DarkModeToggle/mobile'

const OtherHeader = ({ title, RightComp }: any) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const history = useHistory()
	return (
		<OtherHeaderSection isDarkMode={isDarkMode}>
			<CButton
				onClick={() => {
					history.goBack()
				}}
				icon={<IconChevronLeft color={textw} />}
				style={{ height: '100%', width: 50 }}
			/>
			<MView
				style={{
					minWidth: 50,
					height: '100%',
					alignItems: 'center',
					justifyContent: 'center',
					marginLeft: RightComp ? 0 : -40,
				}}>
				<MText style={{ color: textw, fontSize: 16 }}>{t(title)}</MText>
			</MView>
			{RightComp ? <RightComp /> : <DarkModeToggle size={18} />}
		</OtherHeaderSection>
	)
}

export default OtherHeader
