import { Button, Radio, Select, Space, Tag } from 'antd'
import { useToken } from 'app/hooks'
import React from 'react'
import { Text, View } from 'styles/reactnative'
import EntryTime from '../../components/EntryTime'
import KrwPrice from '../../components/KrwPrice'
import TableAPI from '../../components/TableAPI'
import { useCoinGlobal } from '../../hooks/stateStore/coinGlobal'
import { useTradeModalStore } from '../../hooks/trade/modalStore'
import { COLORS } from 'app/constants'
import BankInfo from 'app/components/BankInfo'

const DWListMobile = ({ user_id = null }) => {
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()
	const { level } = useToken()
	const [dtype, setDType] = React.useState<any>(null)
	const { data: coinglobal } = useCoinGlobal()


	return (
		<TableAPI
			coinglobal={coinglobal}
			hideIcon={true}
			closeAction={
				()=>{
					mutateModalStore({
						...modalStore,
						historyModal:false
					})
				}
			}
			title={
				<Space.Compact>
					입출금내역
					{level == 5 && (
						<Radio.Group
							value={modalStore?.depositType || '*'}
							buttonStyle="solid"
							onChange={e => {
								mutateModalStore({
									...modalStore,
									depositType: e.target.value,
								})
							}}>
							<Radio.Button value="*">전체</Radio.Button>
							<Radio.Button value="D">입금</Radio.Button>
							<Radio.Button value="W">출금</Radio.Button>
						</Radio.Group>
					)}

				</Space.Compact>
			}
			extra={<>
				{level == 5 && (
					<Space.Compact size="small">
						<Button
							danger
							onClick={() => {
								window.open('/partner/cash', "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=1450,height=800");
							}}
							type="primary">
							전체회원
						</Button>
						<Button
							onClick={() => {
								setDType(0)
							}}
							type="primary">
							일반입출
						</Button>
						<Button
							onClick={() => {
								setDType(2)
							}}
							type="primary"
							danger>
							별도입출
						</Button>
						<Select
							value={dtype}
							onChange={e => {
								setDType(e)
							}}
							style={{ minWidth: 120 }}
							placeholder={'타입'}>
							<Select.Option value={null}>전체</Select.Option>
							<Select.Option value={0}>일반</Select.Option>
							<Select.Option value={2}>별도</Select.Option>
							<Select.Option value={3}>{'코인<>해선'}</Select.Option>
							<Select.Option value={9}>{'모의'}</Select.Option>
						</Select>
					</Space.Compact>
				)
				}</>
			}
			defaultMaxData={10}

			height={'default'}
			stylewidth={'100%'}
			columns={() => [
				{
					title: '내역',
					dataIndex: 'created_at',
					align: 'center',
					render: (text, record) => {
						return (
							<View style={{ flex: 1, width: '100%', background: '#fafafa', padding: 2, borderRadius: 2 }}>
								<View style={{
									flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start',
									borderTop: '1px solid #dddddd', borderBottom: '1px solid #eeeeee', padding: '2px 0'
								}}>
									<Text>
										신청번호</Text>
									<Text>
										{record?.id}
									</Text>
								</View>
								<View style={{ flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', borderBottom: '1px solid #eeeeee', padding: '5px 0' }}>
									<Text>
										거래상태: </Text>
									<div>
										{record?.process == 1 ? (
											<Tag
												style={{
													backgroundColor: '#f79336',
													color: 'white',
												}}>
												{record?.type == 'W' ? '출금' : '입금'}
												신청
											</Tag>
										) : record?.process == 2 ? (
											<Tag
												style={{
													backgroundColor:
														record?.type == 'W'
															? COLORS.darkRed
															: COLORS.darkGreen,
													color: 'white',
												}}>
												{record?.confirm_amount
													? `${record?.type == 'W'
														? '인출'
														: '지급'
													}완료`
													: `${record?.type == 'W'
														? '출금'
														: '입금'
													}승인`}
											</Tag>
										) : record?.process == 3 ? (
											<Tag
												style={{
													backgroundColor: '#444',
													color: 'white',
												}}>
												{record?.type == 'W'
													? '출금검토'
													: '입금검토'}
											</Tag>
										) : record?.process == 4 ? (
											<Tag style={{ color: '#F05551' }}>
												{record?.type == 'W'
													? '출금거절'
													: '입금거절'}
											</Tag>
										) : (
											<Tag>
												{record?.type == 'W' ? '출금' : '입금'}
												취소
											</Tag>
										)}
									</div>
								</View>

								<View style={{
									flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start',
									borderTop: '1px solid #dddddd', borderBottom: '1px solid #eeeeee', padding: '5px 0'
								}}>
									<Text>
										신청일자</Text>
									<EntryTime
										time={record?.created_at}
										style={{ color: 'black' }}
										offset={9}
									/>
								</View>

								<View style={{ flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', borderBottom: '1px solid #eeeeee', padding: '5px 0' }}>
									<Text>
										신청금액</Text><KrwPrice
										coinglobal={coinglobal}
										price={
											record?.type == 'W'
												? -Math.abs(record?.amount)
												: Math.abs(record?.amount)
										}
									/>
								</View>

								<View style={{
									flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start',
									borderTop: '1px solid #dddddd', borderBottom: '1px solid #eeeeee', padding: '5px 0'
								}}>
									<Text>
										지급일자</Text>
									<EntryTime
										time={record?.confirm_at}
										style={{ color: 'black' }}
										offset={9}
									/>
								</View>

								<View style={{ flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', borderBottom: '1px solid #eeeeee', padding: '5px 0' }}>
									<Text>
										지급금액</Text>
									<KrwPrice
										coinglobal={coinglobal}
										theme={false}
										price={
											record?.type == 'W'
												? -Math.abs(record?.confirm_amount)
												: Math.abs(record?.confirm_amount)
										}
									/>
								</View>
								<View style={{
									flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start',
									borderTop: '1px solid #dddddd',
									background: '#efefef',
									borderBottom: '1px solid #eeeeee', padding: '3px 0'
								}}>
									<Text>
										은행/사유</Text>
									<BankInfo
										text={
											record?.bankaccount == 'undefined/undefined/undefined'
												? '-'
												: record?.bankaccount
										}
										theme={false}
									/>
								</View>

							</View>
						)
					},
				},
			]}
			apikey={
				`/${user_id ? 'group' : 'cash'}/list?${dtype ? `dtype=${dtype}&` : ''
				}type=` +
				(modalStore?.depositType || '*') +
				(user_id ? '&user_id=' + user_id : '') +
				'&t='
			}
		/>
	)
}

export default DWListMobile

/*

				{
					title: '거래번호',
					dataIndex: 'id',
					align: 'center',
					render: (text, record) => {
						return <div>{text}</div>
					},
				},
				{
					title: '상태',
					dataIndex: 'process',
					align: 'center',
					render: (text, data) => (
						
					),
				},
				{
					title: '지급일자',
					dataIndex: 'confirm_at',
					align: 'center',
					render: (text, record) => {
						return (
						)
					},
				},
				{
					title: '지급금액',
					dataIndex: 'confirm_amount',
					align: 'center',
					render: (text, record) => {
						return (
						)
					},
				},
				{
					title: '은행',
					dataIndex: 'bankaccount',
					align: 'center',
					render: (text, record) => {
						return (
							<BankInfo
								text={
									text == 'undefined/undefined/undefined'
										? '-'
										: text
								}
								theme={false}
							/>
						)
					},
				},
*/