import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'styles/reactnative'
import { useTradeModalStore } from '../../../../../../hooks/trade/modalStore'
import { useTradeStore } from '../../../../../../hooks/trade/tradeStore'
import { PreferenceDrawerBodyWrapper } from '../styles'
import PreferenceGeneral from './PreferenceGeneral'
import PreferenceListItems from './PreferenceGeneral/PreferenceListItems'

import PreferenceListBtns from './PreferenceListBtns'
import PreferenceRiskLimits from './PreferenceRiskLimits'

const PreferenceDrawerTrade = () => {
	const { t } = useTranslation()

	const { data: tradeStore, mutate: mutateTradeStore } = useTradeStore()

	const { data: tradeModalStore, mutate: muateTradeModalStore } =
		useTradeModalStore()

	return (
		<PreferenceDrawerBodyWrapper>
			<Text size={14}>
				{t('trades')} {tradeStore?.pairs}
			</Text>
			<PreferenceListBtns title={'positionMode'} content={'oneWayMode'} />
			<PreferenceListBtns
				title={'tpslPreference'}
				content={'tpslOnEntirePosition'}
			/>
			<PreferenceRiskLimits />
			<Text size={14} style={{ marginTop: 20 }}>
				{t('general')} ({t('applyToAll')})
			</Text>
			<PreferenceGeneral />
			<div style={{ marginTop: 10 }}></div>

			<PreferenceListItems
				title={'Chart'}
				content={''}
				checked={tradeModalStore?.chartshow ? true : false}
				setChecked={() => {
					muateTradeModalStore({
						...tradeModalStore,
						chartshow: tradeModalStore?.chartshow ? false : true,
					})
				}}
			/>
			<PreferenceListItems
				title={'Orderbook'}
				content={''}
				checked={tradeModalStore?.orderbookshow ? false : true}
				setChecked={() => {
					muateTradeModalStore({
						...tradeModalStore,
						orderbookshow: tradeModalStore?.orderbookshow
							? false
							: true,
					})
				}}
			/>
			<PreferenceListItems
				title={'Details'}
				content={''}
				checked={tradeModalStore?.detailshow ? false : true}
				setChecked={() => {
					muateTradeModalStore({
						...tradeModalStore,
						detailshow: tradeModalStore?.detailshow ? false : true,
					})
				}}
			/>
		</PreferenceDrawerBodyWrapper>
	)
}

export default PreferenceDrawerTrade
