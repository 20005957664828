import { Row } from 'antd'
import React from 'react'
import AvBalance from '../../../../components/Table/AntCell2/AvBalance'
import AvgPrice from '../../../../components/Table/AntCell2/AvgPrice'
import CurrentPnl from '../../../../components/Table/AntCell2/CurrentPnl'
import HasStock from '../../../../components/Table/AntCell2/HasStock'
import LossCut from '../../../../components/Table/AntCell2/LossCut'
import Overmargin from '../../../../components/Table/AntCell2/Overmargin'
import Overnight from '../../../../components/Table/AntCell2/Overnight'
import RealizePnl from '../../../../components/Table/AntCell2/RealizePnl'
import RealtimePnl from '../../../../components/Table/AntCell2/RealtimePnl'
import TotalPnl from '../../../../components/Table/AntCell2/TotalPnl'
import { TradeListItemSection } from '../../../../components/Trade/TradeListItem/styles'

const InfoDataMobile = () => {
	return (
		<TradeListItemSection
			style={{
				flex: 1,
				flexDirection: 'column',
				alignContent: 'flex-end',
				justifyContent: 'flex-end',
				alignItems: 'flex-end',
				flexGrow: 1,
				padding: 0,
				width: '100%',
			}}>
			<Row
				gutter={12}
				justify="space-evenly"
				align={'middle'}
				style={{
					width: '100%',
					margin: 0,
					alignItems: 'flex-end',
					justifyContent: 'flex-end',
				}}>
				<AvBalance />
				<TotalPnl />
				<CurrentPnl />
				<RealtimePnl />
				<RealizePnl />
			</Row>
			<Row
				gutter={12}
				justify="space-evenly"
				align={'middle'}
				style={{
					width: '100%',
					margin: 0,
					alignItems: 'flex-end',
					justifyContent: 'flex-end',
				}}>
				<HasStock />
				<AvgPrice />
				<LossCut />
				<Overmargin />
				<Overnight />
			</Row>
		</TradeListItemSection>
	)
}

export default InfoDataMobile
