import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Text, View } from '../../../../../../styles/reactnative'
import { debounce } from '@rowsncolumns/grid'
import { COLORS, FONTS } from '../../../../../constants'
import { ThemeContext } from '../../../../../../theme/ThemeProvider'
import BigNumber from 'bignumber.js'

const ThrottlePrice = ({ price, item, currency, throttle = 100 }) => {
	const [throttledPrice, setThrottledPrice] = useState(price)
	const { textw, textsilver } = useContext(ThemeContext).theme.COLORS

	// useCallback을 사용하여 함수 메모이제이션
	const throttledUpdatePrice = useCallback(
		debounce(newPrice => {
			setThrottledPrice(newPrice)
		}, throttle),
		[]
	)

	useEffect(() => {
		// price 값이 변경될 때 쓰로틀링된 함수 실행
		throttledUpdatePrice(price)
	}, [price, throttledUpdatePrice]) // 함수와 price를 의존성 배열에 포함

	return (
		<View style={{ flexDirection: 'row', alignItems: 'center' }}>
			{item?.status >= 8 ? (
				<Text
					style={{
						...FONTS.h6,
						color:
							throttledPrice > 0
								? COLORS.lightRed
								: throttledPrice < 0
								? COLORS.lightGreen
								: textw,
					}}>
					{item?.status == 10
						? 'MIT'
						: item?.status == 8
						? '익절'
						: '손절'}
				</Text>
			) : (
				<Text
					style={{
						...FONTS.h6,
						color:
							throttledPrice > 0
								? COLORS.lightRed
								: throttledPrice < 0
								? COLORS.lightGreen
								: textw,
					}}>
					{throttledPrice > 0 ? '+' : ''}
					{BigNumber(throttledPrice || 0).toFormat(0)} {currency}
				</Text>
			)}
		</View>
	)
}

export default ThrottlePrice
