import { Table } from 'antd'
import { useTradeSocket } from 'app/hooks/trade/tradeSocket'
import { useTradeStore } from 'app/hooks/trade/tradeStore'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from '../../../../../../theme/ThemeProvider'
import { CButton } from '../../../../../components/Common'
import CRenderEmpty from '../../../../../components/CRenderEmpty'
import LastPrice from '../../../../../components/Table/AntCell/LastPrice'
import { COLORS } from '../../../../../constants'
import { useWindowDimensions } from '../../../../../hooks'
import { goTo } from '../../../../../hooks/navigation'
import { useExchangeStore } from '../../../../../hooks/trade/exchangeStore'

const DerivativesBody = ({ height = 350 }) => {
	const { data: tradeStore, mutate: mutateTradeStore } = useTradeStore()
	const { isDarkMode, isMobile } = useContext(ThemeContext)
	const { width } = useWindowDimensions()
	const [rerender, setRerender] = React.useState(false)

	const socket = useTradeSocket()
	const { joinGroup } = socket

	const { EPs } = useExchangeStore()
	const tableRef = React.useRef<any>(null)

	const nav = useHistory()
	const { t } = useTranslation()

	const temp = []

	useEffect(() => {
		setRerender(true)
	}, [width])

	useEffect(() => {
		if (rerender) {
			setTimeout(() => {
				setRerender(false)
			}, 100)
		}
	}, [rerender])

	useEffect(() => {
		if (EPs) {
			EPs.forEach((ep: any) => {
				joinGroup('user', ep.id)
			})
		}
	}, [EPs])

	if (rerender) {
		return (
			<View
				style={{ width: '100vw', minWidth: '', height: height }}></View>
		)
	}

	const mobilecolumn: any = !isMobile
		? [
				{
					title: '코드',
					dataIndex: 'symbol',
					align: 'center',
					render: (text, record) => (
						<View
							style={{
								flexDirection: 'row',
								cursor: 'pointer',
								height: 12,
								fontWeight:
									tradeStore?.ep_id === record?.id
										? 'bold'
										: 'normal',
							}}>
							{text}
						</View>
					),
				},
				{
					title: '월물',
					dataIndex: 'symbol',
					align: 'center',
					render: (text, record) => (
						<View
							style={{
								flexDirection: 'row',
								cursor: 'pointer',
								height: 12,
								whiteSpace: 'nowrap',
								fontWeight:
									tradeStore?.ep_id === record?.id
										? 'bold'
										: 'normal',
							}}>
							{record?.year}-{record?.month}
						</View>
					),
				},
		  ]
		: []

	return (
		<>
			<Table
				style={{ height: '100%', width: '100%' }}
				ref={tableRef}
				size="small"
				dataSource={EPs || temp}
				pagination={false}
				rowKey={record => record.id}
				rootClassName={
					'ovlist ' +
					(width < 1330 ? 'headersmall' : 'headermiddle') +
					(isDarkMode ? ' dark' : ' light')
				}
				onRow={(record, rowIndex) => {
					return {
						onClick: event => {
							mutateTradeStore({
								ep_id: record?.id,
								...record,
							})
							goTo(nav, '/trade/' + record?.id)
						},
					}
				}}
				columns={[
					{
						title: '종목',
						dataIndex: 'groupname',
						align: 'center',
						render: (text, record) => (
							<View
								style={{
									flexDirection: 'row',
									cursor: 'pointer',
									height: 12,
									fontWeight:
										tradeStore?.ep_id === record?.id
											? 'bold'
											: 'normal',
								}}>
								<Text
									style={{
										whiteSpace: 'nowrap',
										fontSize: '1.1em',
									}}>
									{text}
								</Text>
							</View>
						),
					},
					...mobilecolumn,
					{
						title: '현재가',
						dataIndex: 'last',
						align: 'center',
						render: (text, record) => (
							<LastPrice ep_id={record?.id} />
						),
					},
					{
						title: t('trade') + '',
						dataIndex: 'trade',
						align: 'center',
						width: 85,
						render: (text, record) => (
							<CButton
								title={t('trade')}
								style={{
									marginLeft: 10,
									paddingInline: 12,
									height: 30,
									color: COLORS?.gradtext || COLORS.white,
									background: `linear-gradient(45deg, ${COLORS.grad1} 7.72%, ${COLORS.grad2} 100%)`,
								}}
							/>
						),
					},
				]}
			/>
			<CRenderEmpty id={0} />
		</>
	)
}

export default DerivativesBody
