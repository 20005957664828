import BigNumber from 'bignumber.js'
import React from 'react'
import { useInfo24H } from '../../../hooks/trade/info24H'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import DefaultKrw from './DefaultKrw'

const ClosePrice = () => {
	const { ep_id, dp, symbol } = useTradeStore()
	const { data: info24 } = useInfo24H(symbol)
	const openprice = BigNumber(info24?.open || 0)
	const lastclose = info24?.close || 0

	const beforeclose = BigNumber(info24?.open).minus(info24?.change).toNumber()

	return (
		<DefaultKrw
			title={'시가'}
			price={openprice.toNumber() || 0}
			dp={dp}
			krw={false}
			color={true}
			comma={false}
			center={true}
			colordiff={
				beforeclose < openprice.toNumber()
					? 'up'
					: beforeclose > openprice.toNumber()
					? 'down'
					: 'same'
			}
		/>
	)
}

export default React.memo(ClosePrice)
