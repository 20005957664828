import { STORE_MODAL_TRADE } from 'app/constants/LOCALKEYS'
import useSWR from 'swr'
import { useMemo } from 'react'

const KEY = STORE_MODAL_TRADE

const get = localStorage?.getItem(KEY)

let swrData: any = JSON?.parse(
	(get && get != 'null' && get != 'undefined' && get) || '{}'
)

export function useTradeModalStore(initData?: any, reset?: boolean) {
	const { data, mutate } = useSWR<any>(KEY, async () => {
		if (reset === true) {
			swrData = null
			return null
		}
		if (initData !== undefined) {
			swrData = initData
		}

		return swrData
	})

	const skip = useMemo(() => {
		return data?.skip ? true : false
	}, [data?.skip])

	const oneClick = useMemo(() => {
		return data?.oneClick ? true : false
	}, [data?.oneClick])

	return {
		data,
		skip,
		oneClick,
		sound: data?.sound,
		getCurrent: () => {
			return data
		},
		reset: () => {
			swrData = {}
			return mutate()
		},
		mutate: (value: any) => {
			return mutate((prev: any) => {
				localStorage.setItem(KEY, JSON.stringify(value))
				swrData = value
				return value
			})
		},
		change: (value: any) => {
			return mutate((prev: any) => {
				if (!prev) prev = {}
				const temp = { ...prev, ...value }
				swrData = temp
				localStorage.setItem(KEY, JSON.stringify(swrData))
				return swrData
			})
		},
		mutateMsg: (msg: string) => {
			return mutate((prev: any) => {
				const temp = {
					...prev,
					msg: `${new Date().toLocaleString('ko-kr')} | ${msg}`,
				}
				swrData = temp
				localStorage.setItem(KEY, JSON.stringify(swrData))
				return swrData
			})
		},
	}
}
