import React from 'react'
import { DropdownContent } from './styles'
import HeaderAssets from './HeaderAssets'
import HeaderProfile from './HeaderProfile'
import HeaderNotification from './HeaderNotification'
import { useCheckTradePage } from 'utils/ExpensiveFunction'
import HeaderOrders from './HeaderOrders'

interface IProps {
	type?: string
}
const AuthHeaderDropdown = ({ type }: IProps) => {
	return (
		<DropdownContent
			style={{
				top: useCheckTradePage() ? 35 : 50,
				overflowY: type === 'noti' ? 'scroll' : 'hidden',
				width: type === 'noti' ? 350 : 300,
				maxHeight: type === 'noti' ? 500 : 'auto',
				marginRight:
					type === 'noti' ? 300 : type === 'user' ? 260 : 220,
			}}>
			{type === 'asset' ? <HeaderAssets /> : null}
			{type === 'orders' ? <HeaderOrders /> : null}
			{type === 'user' ? <HeaderProfile /> : null}
			{type === 'noti' ? <HeaderNotification /> : null}
		</DropdownContent>
	)
}

export default React.memo(AuthHeaderDropdown)
