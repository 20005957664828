import { STORE_PROCESS } from 'app/constants/LOCALKEYS'
import useSWR from 'swr'

let swrData: any = []
let timer: any = null

export function useProcessStore() {
	const { data, mutate } = useSWR<any>(
		STORE_PROCESS,
		async () => {
			return swrData
		},
		{
			dedupingInterval: 0,
		}
	)
	return {
		data,
		mutate: (value?: any) => {
			swrData = value
			return mutate()
		},
		remove: (value: any) => {
			swrData = swrData.filter((item: any) => item !== value)
			return mutate(swrData)
		},
		clear: () => {
			swrData = []
			return mutate()
		},
		push: (value: any) => {

			return mutate((prev: any) => {
				if (!prev) prev = []
				//prev.push(value)
				swrData = prev
				return prev
			})
		},
	}
}
