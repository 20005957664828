import React, { useEffect, useState } from 'react'

import { IconSearch } from '@tabler/icons-react'
import { Button, Input, Space, Switch } from 'antd'
import { useIndexPrice } from 'app/hooks/trade/indexprice'
import { useCurrency } from 'app/hooks/trade/useCurrency'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import { Text, View } from '../../../styles/reactnative'
import KrwPrice from '../../components/KrwPrice'
import TableAPI from '../../components/TableAPI'
import UserTag from '../../components/UserTag'
import { useCoinGlobal } from '../../hooks/stateStore/coinGlobal'
import { useExchangeStore } from '../../hooks/trade/exchangeStore'
import { useUserList } from '../../hooks/trade/userList'

const PartnerRealtimeCustomer = ({ modalshow = false, setModalshow = e => { } }) => {
	const [modal, setModal] = React.useState<any>(false)
	const [selectedData, setSelectedData] = React.useState<any>(undefined)

	const { userlist, sendwho } = useUserList()
	const [level, setLevel] = React.useState<any>('1')

	const [search, setSearch] = React.useState(selectedData || '')
	const [search2, setSearch2] = React.useState(null)

	const [api, setApi] = React.useState<any>(``)

	const { data: coinglobal } = useCoinGlobal()

	const { krwrate, getRate } = useCurrency()
	const { getPrice } = useIndexPrice(0)

	const [onlyOnline, setOnlyOnline] = useState(false)
	const { EPs } = useExchangeStore()

	const now = dayjs()

	const [startDate, setStartDate] = useState<any>(now.format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState<any>(now.format('YYYY-MM-DD'))

	//00시 ~ 06시까지는 전날로 계산s
	useEffect(() => {
		if (now.hour() < 6) {
			setStartDate(now.subtract(1, 'day').format('YYYY-MM-DD'))
			setEndDate(now.subtract(1, 'day').format('YYYY-MM-DD'))
		}
	}, [])

	const [exceptType1, setExceptType1] = useState(true)
	const [exceptType2, setExceptType2] = useState(true)
	const [exceptType3, setExceptType3] = useState(true)
	const [exceptType4, setExceptType4] = useState(true)
	const [exceptType5, setExceptType5] = useState(true)

	const [exceptType, setExceptType] = useState('')
	const [isAll, setIsAll] = useState(true)

	useEffect(() => {
		const url = `/group/balance?startdate=${startDate}&enddate=${endDate}&realtime=1&isall=${isAll}&start
		&t=${(exceptType && `&excepttype=${exceptType}`) || ''}`

		setApi(url)
		sendwho()
	}, [level, search2, exceptType, startDate, endDate, isAll])

	useEffect(() => {
		sendwho()
	}, [])

	useEffect(() => {
		let exceptType = ''
		if (exceptType1) exceptType += `${exceptType ? ',' : ''}-2`
		if (exceptType2) exceptType += `${exceptType ? ',' : ''}-1`
		if (exceptType3) exceptType += `${exceptType ? ',' : ''}-9`
		if (exceptType4) exceptType += `${exceptType ? ',' : ''}6`
		if (exceptType5) exceptType += `${exceptType ? ',' : ''}7`
		setExceptType(exceptType)
	}, [exceptType1, exceptType2, exceptType3, exceptType4, exceptType5])

	useEffect(() => {
		setModalshow(false)
		if (modalshow) {
			setTimeout(() => {
				setModal(true)
				setSelectedData(null)
			}, 100)
		} else {
		}
	}, [modalshow])

	return (

		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				justifyContent: 'space-between',
			}}>
			<TableAPI
				title={'고객관리'}
				defaultMaxData={500}
				autorefresh={60000}
				stylewidth={'100%'}
				className={'plist nopadding'}
				coinglobal={coinglobal}
				loading={false}
				actionrefresh={() => {
					sendwho()
				}}
				extra={
					<Space
						style={{
							whiteSpace: 'nowrap',
							alignContent: 'center',
							justifyItems: 'center',
							justifyContent: 'center',
						}}>
						<Space.Compact
							size="small"
							style={{
								alignItems: 'center',
								whiteSpace: 'nowrap',
								fontSize: 10,
							}}>
							접속자만
							<Switch
								onChange={e => setOnlyOnline(e)}
								defaultChecked={onlyOnline}
							/>
						</Space.Compact>
						제외
						<Space
							size={'small'}
							style={{
								border: '1px solid #ccc',
								padding: 5,
								borderRadius: 5,
							}}>
							<Space.Compact
								size="small"
								style={{
									alignItems: 'center',
									whiteSpace: 'nowrap',
									fontSize: 10,
								}}>
								거절
								<Switch
									onChange={e => setExceptType1(e)}
									defaultChecked={exceptType1}
								/>
							</Space.Compact>
							<Space.Compact
								size="small"
								style={{
									alignItems: 'center',
									whiteSpace: 'nowrap',
									fontSize: 10,
								}}>
								대기
								<Switch
									onChange={e => setExceptType2(e)}
									defaultChecked={exceptType2}
								/>
							</Space.Compact>
							<Space.Compact
								size="small"
								style={{
									alignItems: 'center',
									whiteSpace: 'nowrap',
									fontSize: 10,
								}}>
								블랙
								<Switch
									onChange={e => setExceptType3(e)}
									defaultChecked={exceptType3}
								/>
							</Space.Compact>
							<Space.Compact
								size="small"
								style={{
									alignItems: 'center',
									whiteSpace: 'nowrap',
									fontSize: 10,
								}}>
								모의
								<Switch
									onChange={e => setExceptType4(e)}
									defaultChecked={exceptType4}
								/>
							</Space.Compact>

							<Space.Compact
								size="small"
								style={{
									alignItems: 'center',
									whiteSpace: 'nowrap',
									fontSize: 10,
								}}>
								페이크
								<Switch
									onChange={e => setExceptType5(e)}
									defaultChecked={exceptType5}
								/>
							</Space.Compact>
						</Space>
						<Input
							placeholder="회원검색"
							defaultValue={search}
							onChange={e => {
								setSearch(e.target.value)
							}}
						/>
						<Button
							icon={<IconSearch />}
							size="small"
							onClick={() => {
								setSearch2(search)
							}}
						/>
					</Space>
				}
				height={650}
				width={'100%'}
				datesearch={false}
				columns={() => [
					{
						title: '접속중',
						dataIndex: 'client',
						key: 'client',
						align: 'center',
						width: 60,
						sorter: (a, b) => a.client - b.client,
						render: (text, data) => {
							const user = userlist.find(
								(user: any) => user == data?.id
							)

							if (user) {
								data.client = 1
							} else {
								data.client = 0
							}

							return (
								<Text
									style={{
										color: user ? 'green' : 'red',
									}}>
									{user ? '접속중' : '미접속'}
								</Text>
							)
						},
					},
					{
						title: '그룹',
						dataIndex: 'referral_code',
						key: 'referral_code',
						width: 70,
					},
					{
						title: '등급',
						dataIndex: 'level',
						key: 'level',
						align: 'center',
						width: 50,
						sorter: (a, b) => a.level - b.level,
						render: (text, data) => {
							return <UserTag level={data?.level} noname={true} popup={false}
							 />
						}
					},
					{
						title: '회원',
						dataIndex: 'id',
						key: 'id',
						align: 'center',
						render: (text, data) => {
							return (
								<UserTag
									userid={data?.id}
									email={data?.email}
									phone={data?.phone}
									nickname={
										data?.fullname || data?.nickname
									}
									setSelectedData={() => {
										setModal(true)
										setSelectedData(null)
										setTimeout(() => {
											setSelectedData(data?.id)
										}, 100)
									}}
								/>
							)
						},
					},
					{
						title: '아이디',
						dataIndex: 'loginid',
						key: 'loginid',
						align: 'center',
						width: 80,
						render: (text, data) => {
							return <Text>{text}</Text>
						},
					},
					{
						title: '손익현황',
						align: 'center',
						children: [
							{
								title: '평가손익',
								dataIndex: 'position',
								key: 'position',
								align: 'center',
								width: 150,
								render: (text, data) => {
									let pnl = 0
									let losscut = 0
									let temptotalpnl = 0

									if (!text?.length) {
										data.realtimebalance = +temptotalpnl || 0
										data.losscut = +losscut

										return (<Text>-</Text>)
									}

									text?.map((item: any) => {
										const EP = EPs.find(e => e.id === item.exchange_pairs_id)
										const currencyrate = getRate(EP?.currency)
										const rate = BigNumber(krwrate?.price || 0)
											.div(currencyrate?.price || 0)
											.toNumber()

										losscut = BigNumber(losscut).plus(BigNumber(item?.amount).multipliedBy(EP?.losscut)).toNumber()

										if (item?.long_short === 'L') {
											pnl = BigNumber(getPrice(item?.exchange_pairs_id))
												.minus(item?.price)
												.multipliedBy(item?.amount)
												.multipliedBy(EP?.contractamt)
												.multipliedBy(rate)
												.toNumber()
										} else {
											pnl = BigNumber(item?.price)
												.minus(getPrice(item?.exchange_pairs_id))
												.multipliedBy(item?.amount)
												.multipliedBy(EP?.contractamt)
												.multipliedBy(rate)
												.toNumber()
										}

										temptotalpnl = BigNumber(temptotalpnl || 0)
											.plus(pnl || 0)
											.toNumber()
									})
									data.realtimebalance = temptotalpnl || 0
									data.losscut = losscut

									return (
										<KrwPrice
											price={temptotalpnl || 0}
											coinglobal={coinglobal}
										/>
									)
								},
							},

							{
								title: '총매매손익',
								dataIndex: 'total_pnl_price',
								key: 'total_pnl_price',
								align: 'center',
								width: 150,
								rowScope: 'row',
								sorter: (a, b) =>
									a.total_pnl_price - b.total_pnl_price,
								render: (text, data) => {
									return (
										<KrwPrice
											price={text}
											coinglobal={coinglobal}
										/>
									)
								},
							},
							{
								title: '수수료',
								dataIndex: 'total_fee_price',
								key: 'total_fee_price',
								sorter: (a, b) =>
									a.total_fee_price - b.total_fee_price,
								align: 'center',
								width: 150,
								render: (text, data) => {
									return (
										<KrwPrice
											price={-Math.abs(text)}
											coinglobal={coinglobal}
										/>
									)
								},
							},
							{
								title: '실현손익',
								dataIndex: 'total_trade_price',
								key: 'total_trade_price',
								align: 'center',
								width: 150,
								sorter: (a, b) =>
									a.total_trade_price -
									b.total_trade_price,
								render: (text, data) => {
									return (
										<KrwPrice
											price={text || 0}
											coinglobal={coinglobal}
										/>
									)
								},
							},
							{
								title: '실시간손익',
								dataIndex: 'realtime',
								key: 'realtime',
								align: 'center',
								width: 150,
								sorter: (a, b) =>
									a.realtime -
									b.realtime,
								render: (text, data) => {
									data.realtime = (+data.total_trade_price||0) + (+data.realtimebalance||0);
									data.allbalance = (+data.realtimebalance||0) + (+data.last_balance||0) || 0;

									return (
										<KrwPrice
											price={+data.realtime}
											coinglobal={coinglobal}
										/>
									)
								},
							},

						],
					},
					{
						title: '잔액정보',
						align: 'center',
						children: [
							{
								title: '평가금액',
								dataIndex: 'allbalance',
								key: 'allbalance',
								align: 'center',
								sorter: (a, b) => a.allbalance - b.allbalance,
								render: (text, data) => {
									return (
										<KrwPrice
											color={false}
											theme={false}
											price={data?.allbalance || data?.last_balance || 0}
											coinglobal={coinglobal}
										/>
									)
								}
							},
							{
								title: '실잔액',
								dataIndex: 'last_balance',
								key: 'last_balance',
								align: 'center',
								sorter: (a, b) =>
									a.last_balance - b.last_balance,
								render: (text, data) => {
									return (
										<KrwPrice
											price={text || 0}
											theme={false}
											color={false}
											coinglobal={coinglobal}
										/>
									)
								},
							}, {
								title: '로스컷',
								dataIndex: 'losscut',
								key: 'losscut',
								align: 'center',
								sorter: (a, b) =>
									a.losscut - b.losscut,
								render: (text, data) => {
									return (
										<KrwPrice
											color={false}
											theme={false}
											price={text || 0}
											coinglobal={coinglobal}
										/>
									)
								},
							},
						],
					},
				]}
				online={true}
				onlyonline={onlyOnline}
				apikey={api}
				footer={1}
			/>
		</View>
	)
}

export default React.memo(PartnerRealtimeCustomer)