import useSWR from 'swr'
import { useToken } from './auth/token'
import API from 'utils/api'

const KEY = '/group/usergroup'

let swrData: any = null

export function useUserGroup() {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			const response = await API.get(
				KEY + '?maxData=1000&page=1',
				token + '',
				{}
			)

			swrData = response?.result?.result?.list

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	const type1 =
		data?.map((item: any) => ({
			label: item.name,
			value: item.id + '',
		})) || []

	const type2 =
		data?.map((item: any) => ({
			label: item.name,
			value: item.name + '',
		})) || []

	return {
		data,
		type1,
		type2,
		mutate: (value: any) => {
			swrData = value
			return mutate()
		},
	}
}
