import API from '../../../../../../utils/api'
import { NAME } from '../../../../../constants/APIKEYS'
import { CHARTAPI } from '../../../../../constants/TRADEAPIKEYS'

/* eslint-disable @typescript-eslint/no-unused-vars */
export const getLanguageFromURL = () => {
	const regex = new RegExp('[\\?&]lang=([^&#]*)')
	const results = regex.exec(window.location.search)
	return results === null
		? null
		: decodeURIComponent(results[1].replace(/\+/g, ' '))
}

//메인차트
export const widgetOptions = (
	tvWidgetRef,
	paris,
	ep_id,
	updatechart,
	mutateUpdateChart,
	tab,
	lang,
	isDarkMode,
	mobile,
	EPs,
	setEP,
	userid
) => {
	const datafeed = new window.Datafeeds.UDFCompatibleDatafeed(CHARTAPI)

	const resolutionIntervalMap = interval => {
		let resolutionsToIntervals = {
			'1m': '1',
			'3m': '3',
			'5m': '5',
			'15m': '15',
			'30m': '30',
			'1h': '60',
			'2h': '120',
			'4h': '240',
			'6h': '360',
			'8h': '480',
			'12h': '720',
			'1d': '1d',
			'1w': '1w',
			'1M': '1M',
		}
		return resolutionsToIntervals[interval]
	}

	lang = lang?.split('-')[0]

	datafeed.resolveSymbol = async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
		extension
	) => {
		const symbolInfo = await API.get(
			'/symbols?symbol=' + symbolName,
			null,
			{},
			false,
			CHARTAPI
		)
		console.log('[MOBILE2]: Symbol resolved', symbolName)
		const EP = EPs?.find(ep => ep?.pairs == symbolName)
		EP && setEP(EP?.id)

		onSymbolResolvedCallback(symbolInfo)
	}

	datafeed.subscribeBars = function (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscribeUID,
		onResetCacheNeededCallback
	) {
		const update = {
			datafeed: datafeed,
			resolution: resolution,
			subscribeUID: subscribeUID,
			updatebars: onRealtimeCallback,
		}
		mutateUpdateChart(update)
	}
	return {
		symbol: EPs?.find(ep => ep?.id == ep_id)?.pairs,
		datafeed: datafeed,
		interval: resolutionIntervalMap(tab) || '1',
		container: 'tv_chart_container',
		library_path: '/charting_library/',
		locale: lang || 'en',
		auto_save_delay: 1,
		load_last_chart: false,
		disabled_features: [
			'timeframes_toolbar',
			'volume_force_overlay',
			'show_logo_on_all_charts',
			'save_chart_properties_to_local_storage',
			'caption_buttons_text_if_possible',
			'header_compare',
			'compare_symbol',
			'control_bar',
			'header_widget_dom_node',
			'header_undo_redo',
			'header_interval_dialog_button',
			'border_around_the_chart',
			'show_interval_dialog_on_key_press',
			mobile && 'header_symbol_search',
			mobile && 'header_compare',
			'study_templates',
			'use_localstorage_for_settings',
			'header_settings',
			'header_saveload featureset',
			// 'header_resolutions',
			//mobile && 'left_toolbar',
		],
		enabled_features: [
			'countdown',
			mobile && 'hide_left_toolbar_by_default',
			'hide_object_tree_and_price_scale_exchange_label',
			'hide_price_scale_if_all_sources_hidden',
		],
		request_only_visible_range_on_reset: true,
		studies_overrides: {
			'volume.volume.color.0': '#007EDA',
			'volume.volume.color.1': '#F05551',
		},
		overrides: {
			'candleStyle.upColor': '#F05551',
			'candleStyle.downColor': '#007EDA',
			'candleStyle.wickUpColor': '#F05551',
			'candleStyle.wickDownColor': '#007EDA',
			'candleStyle.borderUpColor': '#F05551',
			'candleStyle.borderDownColor': '#007EDA',
			'candleStyle.volumeUpColor': '#F05551',
			'candleStyle.volumeDownColor': '#007EDA',
			'plotLineColor.priceLine.long': '#F05551',
			'plotLineWidth.priceLine.long': 2,
			'plotLineColor.priceLine.short': '#007EDA',
			'plotLineWidth.priceLine.short': 2,
			'mainSeriesProperties.showCountdown': true,
			'scalesProperties.showStudyLastValue': false,
		},
		client_id: (NAME + 'ASSET').toLowerCase(),
		timezone: 'Asia/Seoul',
		charts_storage_url: 'https://saveload.tsaaaccccee.uk',
		charts_storage_api_version: '1.1',
		exchanges: [
			NAME
		],
		user_id: userid,
		fullscreen: false,
		autosize: true,
		theme: isDarkMode ? 'light' : 'dark',
		volume_precision: 0,
	}
}
