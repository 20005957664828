import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { IconCopy } from '@tabler/icons-react'
import { CButton, CInput } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { copyTextToClipboard, isIos } from '../../../../../utils/ciutils'

const MReferralForm = ({ title, value, setValue, referral }: any) => {
	const { t } = useTranslation()
	const { descBox, textw } = useContext(ThemeContext).theme.COLORS
	return (
		<MView style={{ paddingTop: 10, fontWeight: '300' }}>
			<MText>{title && t(title)}</MText>
			<CInput
				placeholder={referral ? referral : ''}
				value={value}
				type={'text'}
				icon={
					<CButton
						style={{
							position: 'absolute',
							right: 25,
							marginTop: -10,
						}}
						icon={<IconCopy color={COLORS.secondary} size={18} />}
						onClick={() => {
							if (isIos()) {
								copyTextToClipboard(
									referral ? `${referral}` : value
								)
								toast(
									t('copied') +
										`  ${referral ? `${referral}` : value}`,
									{
										type: 'success',
										autoClose: 500,
									}
								)
							} else {
								navigator.clipboard
									.writeText(referral ? `${referral}` : value)
									.then(() => {
										toast(
											t('copied') +
												`  ${
													referral
														? `${referral}`
														: value
												}`,
											{
												type: 'success',
												autoClose: 500,
											}
										)
									})
									.catch(error => {
										console.error(
											'Failed to copy text: ',
											error
										)
									})
							}
						}}
					/>
				}
				readonly={true}
				onChange={(e: any) => {}}
				vStyle={{
					width: '100%',
					marginTop: 7,
				}}
				style={{
					background: descBox,
					border: 0,
					color: textw,
					padding: 8,
					paddingLeft: 15,
					width: '100%',
					fontSize: 10,
				}}
			/>
		</MView>
	)
}

export default MReferralForm
