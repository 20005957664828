import { useTradePosition } from '../trade/tradePosition'
// import { getPersistData } from '../persist'

// const KEY = MYBALANCE

export function useBalance(symbol?: string, type = 0) {
	const { spot, futures, global, mutate } = useTradePosition()

	return {
		data: type === 0 ? spot : type == 9 ? global : futures,
		mutate: (value?: any) => {
			return mutate()
		},
	}
}
