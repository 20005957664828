import React, { useState, useContext } from 'react'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import PreferenceListItems from './PreferenceListItems'
import { ThemeContext } from 'theme/ThemeProvider'

const PreferenceNotification = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const [display, setDisplay] = useState(false)
	const { t } = useTranslation()

	const icons = display ? (
		<IconChevronUp color={COLORS.lightGray5} size={16} />
	) : (
		<IconChevronDown color={COLORS.lightGray5} size={16} />
	)
	return (
		<View style={{ width: '100%' }}>
			<CButton
				title={t('notification')}
				onClick={() => {
					setDisplay(!display)
				}}
				style={{
					color: textw,
					justifyContent: 'space-between',
					width: '100%',
					marginTop: 20,
				}}
				textStyle={{ marginLeft: -5, color: COLORS.lightGray4 }}
				icon={icons}
			/>
			{display ? (
				<View
					style={{
						width: '100%',
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
					}}>
					<PreferenceListItems
						title={'soundNotification'}
						content={'soundNotificationDesc'}
					/>
				</View>
			) : null}
		</View>
	)
}

export default PreferenceNotification
