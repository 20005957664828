import { useMobileDimensions, useWindowDimensions } from 'app/hooks'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { copyTextToClipboard, isIos } from './ciutils'

export const useCheckTradePage = () => {
	const location = useLocation()
	const urlSegments = location.pathname?.split('/')
	const hasTradeSegment = urlSegments?.some(segment =>
		segment.includes('trade')
	)
	return hasTradeSegment ? true : false
}
export const firstChar = data => {
	return data?.charAt(0).toUpperCase() + data?.slice(1)
}

export const useWSize = (size?: any) => {
	const { width } = useWindowDimensions()
	const dynamicSize = size ? size : 0
	const wSize =
		width < 772
			? width / 1.2
			: width < 1400
			? width < 900
				? width / 1.5 - dynamicSize
				: width / 2 - dynamicSize
			: width / 3 - dynamicSize
	return wSize
}

export const useMobilecheck = () => {
	const { width } = useMobileDimensions()
	return width <= 600
}

export const SliderSize = () => {
	const { width } = useWindowDimensions()
	return width > 1400 ? 4 : width > 1200 ? 3 : width > 600 ? 2 : 1
}
export const WindowSize = (large: any, medium: any, small: any) => {
	const { width } = useWindowDimensions()
	return width > 900 ? large : width > 600 ? medium : small
}
export const checkEmail = email => {
	const filter = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
	if (filter) return true
	else {
		return 'NOTVALIDEMAIL'
	}
}
export const wait = timeout => {
	return new Promise(resolve => setTimeout(resolve, timeout))
}

export const apiError = (t, code) => {
	if (code === 1) {
		return t('LANG_NO_INPUT')
	} else if (code === 2) {
		return t('LANG_NO_USER')
	} else if (code === 3) {
		return t('LANG_FAIL_PASSWORD')
	} else if (code === 4) {
		return t('LANG_EXIST_USER')
	} else if (code === 5) {
		return '아이디를 기입해주세요.'
	} else if (code === 6) {
		return t('LANG_NO_INPUT_PHONE')
	} else if (code === 7) {
		return '승인되지 않은 계정입니다. 관리자에게 문의하세요.'
	} else if (code === 8) {
		return t('LANG_INVALID_REFERRAL')
	} else {
		return t('LANG_ERROR')
	}
}

export const loadData = async (number?: number) => {
	const result = await axios.get('https://api.upbit.com/v1/market/all')
	const newResult = number ? result?.data?.slice(0, number) : result?.data
	return newResult?.filter((d: any) => d.market?.match(/^KRW+/))

	// return newResult;
}
export const copyText = async (text: any) => {
	if (isIos()) {
		copyTextToClipboard(text + '')
	} else {
		navigator.clipboard
			.writeText(`${text}`)
			.then(() => {
				return true
			})
			.catch(error => {
				console.error('Failed to copy text: ', error)
			})
	}
	return true
}
export const truncateText = (text, limit) => {
	if (text.length <= limit) {
		return text
	}
	const truncatedText = text.substr(0, limit).trim()
	const lastSpaceIndex = truncatedText.lastIndexOf(' ')
	if (lastSpaceIndex > -1) {
		return truncatedText.substr(0, lastSpaceIndex) + '...'
	}
	return truncatedText + '...'
}
