import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconSwitchHorizontal } from '@tabler/icons-react'
import { CButton } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import ConvertTypeCoin from './ConvertTypeCoin'
import { COLORS } from 'app/constants'
import DropdownCoin from './DropdownCoin'

const ConvertType = () => {
	const { t } = useTranslation()
	const [coinDropdown, setCoinDropdown] = useState(true)
	const handleSwitchClick = () => {
		setCoinDropdown(prevState => !prevState)
	}

	return (
		<View style={{ width: '100%', flexDirection: 'row' }}>
			<View style={{ width: '20%' }}>
				<Text size={14}>{t('convert')}</Text>
			</View>
			<View
				style={{
					width: '80%',
					flexDirection: 'row',
					justifyContent: 'center',
				}}>
				{coinDropdown ? <DropdownCoin /> : <ConvertTypeCoin />}
				<CButton
					icon={<IconSwitchHorizontal color={COLORS.lightGray} />}
					style={{ width: '10%' }}
					onClick={handleSwitchClick}
				/>
				{coinDropdown ? <ConvertTypeCoin /> : <DropdownCoin />}
			</View>
		</View>
	)
}

export default ConvertType
