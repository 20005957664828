import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import memoize from 'memoize-one'

import { CFlatListSection } from './styles'
import CNoDataFound from '../CNoDataFound'

const createItemData = memoize(items => ({ items }))

const CFlatList = ({
	data,
	renderItem,
	itemSize,
	noDataTitle,
	noData = null,
	autocenter = false,
	centerfixed = false,
	parentsize = 0,
	minusheight = 0,
	refresh = false,
}: any) => {
	const [init, setInit] = React.useState(false)

	const getItemHeight = useCallback(item => {
		if (!item || isNaN(item)) return 50
		return item
	}, [])

	const listref = useRef<any>(null)

	const Row = useCallback(
		({ data, index, style }: any) => {
			return (
				<div style={{ ...style }}>{renderItem(data[index], index)}</div>
			)
		},
		[renderItem]
	)

	useEffect(() => {
		const load = false
		if (load) return
		setInit(true)

		return () => {
			listref.current = null
		}
	}, [])

	useEffect(() => {
		if (listref?.current && autocenter) {
			listref?.current?.scrollTo(
				(data?.length / 2) * getItemHeight(itemSize) -
					parentsize / 2 -
					1
			)
		}
	}, [refresh, data?.length, getItemHeight, itemSize, parentsize, autocenter])

	const offset = useMemo(() => {
		return autocenter
			? (data?.length / 2) * getItemHeight(itemSize) - parentsize / 2
			: 0
	}, [autocenter, data?.length, getItemHeight, itemSize, parentsize])

	return (
		<CFlatListSection>
			{data?.length ? (
				<AutoSizer>
					{({ height, width }) => (
						<List
							itemData={createItemData(data)?.items}
							className="List"
							height={height - minusheight}
							initialScrollOffset={offset}
							onScroll={({
								scrollDirection,
								scrollOffset,
								scrollUpdateWasRequested,
							}) => {
								if (
									listref?.current &&
									centerfixed &&
									autocenter
								) {
									listref?.current?.scrollTo(
										(data?.length / 2) *
											getItemHeight(itemSize) -
											parentsize / 2
									)
								}
							}}
							itemCount={createItemData(data)?.items?.length}
							itemSize={getItemHeight(itemSize)}
							width={width}
							ref={listref}>
							{Row}
						</List>
					)}
				</AutoSizer>
			) : (
				<CNoDataFound
					noDataTitle={noDataTitle}
					noDataContent={undefined}
					noDataSize={undefined}
					noDataHeight={undefined}
					lottieSize={undefined}
				/>
			)}
		</CFlatListSection>
	)
}

export default React.memo(CFlatList)
