import React from 'react'
import { useHistory } from 'react-router-dom'
import { CButton } from 'app/components/Common/'
import { goTo } from 'app/hooks/navigation'
import { COLORS } from 'app/constants/STYLES'
import { View } from 'styles/reactnative'

const LblSignUpOrRegister = ({ btnLabel, url }) => {
	const navigation = useHistory()
	return (
		<View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
			<CButton
				title={btnLabel}
				onClick={() => {
					goTo(navigation, url)
				}}
				style={{
					width: 'auto',
					fontSize: 14,
					paddingBlock: 10,
					backgroundColor: '#252525',
					color: COLORS.primary,
					fontWeight: '500',
				}}
			/>
		</View>
	)
}

export default React.memo(LblSignUpOrRegister)
