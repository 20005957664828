import { createGlobalStyle } from 'styled-components'
import { COLORS } from '../app/constants'

export const MainColor = '#FF445C'
export const MainColorText = '#ffffff'

export const GlobalStyle = createGlobalStyle`
  html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  width: 100vw;
  line-height: 1;
  font-family: 'Roboto';
}
ol,
ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  outline: none;
}
a:hover,
a:active {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: ${COLORS.backgroundColor};
}

::-webkit-scrollbar-thumb {
  background: ${COLORS.primary};
  border-radius: 2px;
  cursor: pointer;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner,
::-webkit-resizer {
  background: ${
    COLORS.gray
  };
}

* { font-family:'Roboto', 'sans-serif'; touch-action: manipulation;}

/* prevent bouncing */
body {
  min-height: 100vh;
  overscroll-behavior: none; /* or overscroll-behavior: none; */
}

body > #root > div {
  min-height: 100vh;
}

html {
  font-size: 62.5%;
}

*:focus {
  outline: none;
}

input[type=checkbox]{
  background:transparent;
}


.warning{
  color:red;
  font-size:13px;
  padding:10px 5px;
}

.modalConfirm {
  font-size: 14px;
}

.modalConfirm h1{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #99f;
}
input:-webkit-autofill:focus {
    -webkit-text-fill-color: #999;
}



[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 90005 !important;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

@media (max-width: 1400px) {


}


@keyframes flashgreen {
  20% {
    background-color: rgba(0,0,255,0.5);
  }
}

@keyframes flashred {
  20% {
    background-color: rgba(255,0,0,0.5);
  }
}

.orderbookrow div{ 
  -webkit-user-select: none;
 -moz-user-select: none;
 -ms-use-select: none;
 user-select: none;
}

.orderbookrow:hover .price{
  border: 1px solid #000 !important;
  -webkit-user-select: none;
 -moz-user-select: none;
 -ms-use-select: none;
 user-select: none;
}
.orderbookrow .order, .orderbookrow .mitorder{
  cursor: pointer;
  -webkit-user-select: none;
 -moz-user-select: none;
 -ms-use-select: none;
 user-select: none;
}

.orderbookrow .order:hover, .orderbookrow .mitorder:hover{
  border: 1px solid #000 !important;
}


.orderbookrow.light:hover .price{
  border: 1px solid white !important;
}

.orderbookrow.light .order:hover, .orderbookrow.light .mitorder:hover{
  border: 1px solid white !important;
}


.inforaw {
  align-items: center;
}

.tradelogowrap{
  display: flex;

  @media (max-width: 1700px) {
  }
}


.ovlist *::-webkit-scrollbar {
  width: 10px;
  height: 6px;
  background: #001B43;
}


.ovlist  {
  margin-top: 10px;
  @media (max-height: 900px) {
    margin-top: 0;
  }
}

.ovlist .ant-table-thead {
  box-shadow: none !important;
  background-color:  ${COLORS.loginbgb}  !important;
}


.ovlist.dark .ant-table-thead {
  box-shadow: none !important;
  background-color:  ${COLORS.loginbgw}  !important;
  color: #fff;
}

.ovlist .ant-table{
  background: transparent !important;
}

.ovlist .ant-table-cell::before {
  width: 0 !important;
}

.ovlist .ant-table-header .ant-table-cell::before {
  width: 0 !important;
}

.ovlist .ant-table-header .ant-table-cell-scrollbar{
  box-shadow: none !important;
}

.ovlist .ant-table .ant-table-header{
  //border-radius: 0 !important;
  background: transparent !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ovlist .ant-table-body{
  overflow-y: auto !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.ovlist .ant-table-thead > tr > th, .ovlist .ant-table-thead > tr > td{
  background: transparent !important;
  
  color: #fff;
  margin-top: 5;
  box-shadow: 0 0 0 0;
  border-radius: 0 !important;
  border-start-start-radius: 0 !important;
  
  @media (max-height: 900px) {
    margin-top: 0;
    height: 25;
    padding: 0;
  }
}

.ovlist .ant-table-container table>thead>tr>th{
  border-end-end-radius: 0 !important;
  border-bottom : 0;
  
  @media (max-height: 900px) {
    height: 25;
  }
}


.ovlist .ant-table-container td{
  background: ${COLORS.loginbgb} !important;
  color: #fff !important;
  border: 0 !important;
}

.ovlist.dark .ant-table-container td{
  background: white !important;
  color: #000 !important;
}


.ovlist.dark .ant-table-container th{
  background:  ${COLORS.loginbgw} !important;
  color: #000 !important;
  border: 0 !important;
}

.ovlist.dark .ant-table-container td.ant-table-cell-scrollbar{
  background: ${COLORS.loginbgb} !important;

}


.ovlist.dark .ant-table-container .ant-table-thead td.ant-table-cell-scrollbar{
  background: transparent !important;
}


.ovlist .ant-table-container th{
  padding: 5px 10px !important;
  border-bottom: 1px solid #444 !important;
}

.ovlist.dark .ant-table-container th{
  border-bottom: 1px solid #eee !important;
}

.activechart{
  border: 1px solid #f00;
}

.ordersmall *{
  font-size: 12px;
}

.ordermiddle *{
  font-size: 14px;
}

.headersmall *{
  font-size: 10px;
}

.headermiddle *{
  font-size: 14px;
}

.widgetCloseWrap{
  
	.widgetClose2 {
		display: none;
	}

	&:hover {
		.widgetClose2 {
			display: flex;
		}
	}
}
* {
  animation-duration: 0.2s !important;
}
.confirmationModal {    
  animation-duration: 0.1s !important;

}

.modaldark {
  & .ant-modal-content {
    background-color: #333 !important;
    color: #fff !important;
  }

  & .ant-modal-header {
    background-color: #333 !important;
  }

  & .ant-modal-header * { 
    color: #fff !important;
  }
}

.modallight{
  & .ant-modal-content {
    background-color: #fff !important;
  }

  & .ant-modal-header {
    background-color: #fff !important;
    color: #000 !important;
  }
}

.ant-table-cell{
  padding: 3px 5px ;
}

.desktopview .ant-message {
  position: absolute;
  bottom: 20px !important;
  top: unset !important;
  width: fit-content;

  left: initial !important;
  right: 20px !important;
}

.ovlist input{
	color: #000000;
}

.ovlist.dark input{
	color: #ffffff !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}

.ant-select .ant-select-selector{
  background: #eeeeee !important;
  border: 0px solid #000 !important;
  color: #000 !important;
  height: auto !important;
}

.ant-select.dark .ant-select-selector{
  color: #fff !important;
  background: #000 !important;
}

.ant-select.dark .ant-select-selection-placeholder{
    color: #aaa !important;
}

.ptable .ant-table-tbody{
  padding: 0 !important;
}
.ptable .ant-table-tbody > tr > td{
  padding: 0 !important;
}


.plist .ant-table-thead .ant-table-cell{
  padding: 2px 1px !important;
  line-height: 1.3;
  align-items: center;
  vertical-align: middle;
}
.plist .ant-table-tbody .ant-table-cell{
  padding: 2px 1px !important;
  line-height: 1.3;
  align-items: center;
  vertical-align: middle;
}

.plist .ant-tag{
  margin-inline-end: 0px;
  margin:0;
}

.plist.nopadding .ant-table-thead .ant-table-cell{
  padding: 0px 0px !important;
}
.plist.nopadding .ant-table-tbody .ant-table-cell{
  padding: 0px 0px !important;
}


.plist .selected .ant-table-cell{
  background: #ffefef !important;
}
.plist.nopadding .ant-tag{
  padding: 0px 0px !important;
  margin-inline-end: 0px;
  line-height: 1.3 !important;
}

.rowsncolumns-grid-scrollbar-x{
  display: none !important;
}

.history-modal .ant-modal-content{
  padding:0 !important;
}

.history-modal .ant-card-head-wrapper{
  positiion: fixed !important;
  z-index: 10000 !important;
  background: white !important;
  left: 0;
  top:0;
  padding: 8px 10px;
  border-bottom: 1px solid #ddd;
}

.tradelist .ant-table-column-title{
  font-size: 11px;
  letter-spacing: -1px
}

.tradelist .ant-table-cell{
  font-size: 12px;
  & *{
    font-size: 12px !important;
  }
}

/* iOS 장치에서 hover 스타일 제거 */
@media (hover: none) {
    button:hover {
        background-color: inherit;
        color: inherit;
    }
}

.ant-modal-root * {
 animation-duration: 0.1s !important;
}

`
