import { Button } from 'antd'
import RatePrevDay from 'app/components/Table/AntCell/RatePrevDay'
import React from 'react'
import TickValue from '../../../../components/Table/AntCell/TickValue'
import {
	TradeListItemSection,
	TradeTextInnerContent,
	TradeTextInnerTitle,
	TradeTextWrapper,
} from './styles'
import { View } from 'styles/reactnative'

interface IProps {
	title?: string
	content?: string
	titleColor?: string
	contentColor?: string
	titleBold?: boolean
	contentBold?: boolean
	icon?: boolean
	onPress?: () => void
}

const TradeListItem = ({
	title,
	content,
	icon,
	titleColor,
	contentColor,
	titleBold,
	contentBold,
	onPress,
}: IProps) => {
	return (
		<TradeListItemSection onClick={onPress ? onPress : () => { }}>
			<Button size='middle'
				style={{
					paddingInline: 3,
				}}
			>변경</Button>
			<View style={{
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
			}}>
				<TradeTextWrapper>
					<TradeTextInnerTitle
						titleColor={titleColor}
						titleBold={titleBold}>
						{title}
					</TradeTextInnerTitle>
					<TradeTextInnerContent
						contentColor={contentColor}
						contentBold={contentBold}
						style={{
							margin: 0, padding: 0
						}}
					>
						<TickValue row={'row'} />
					</TradeTextInnerContent>
				</TradeTextWrapper>
				<TradeTextInnerContent
					contentColor={contentColor}
					contentBold={contentBold}>
					{content}
				</TradeTextInnerContent>
			</View>
		</TradeListItemSection>
	)
}

export default React.memo(TradeListItem)
