// import React, { useContext } from 'react'
// import DOMPurify from 'dompurify'
// import { ThemeContext } from 'theme/ThemeProvider'

// const MarkdownComponent = ({ markdown, style }: any) => {
// 	const { textw } = useContext(ThemeContext).theme.COLORS
// 	const sanitizedHtml = DOMPurify.sanitize(markdown, {
// 		ALLOWED_TAGS: ['strong', 'ul', 'li', 'ol'],
// 	})
// 	return (
// 		<div
// 			style={{
// 				color: textw,
// 				width: '100%',
// 			}}>
// 			<div>
// 				<pre>
// 					<code>
// 						<div
// 							dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
// 						/>
// 					</code>
// 				</pre>
// 			</div>
// 		</div>
// 	)
// }
// export default MarkdownComponent

import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { HTMLCompSection } from './styles'
const HTMLComponent = ({ markdown, style }: any) => {
	const text = ReactHtmlParser(markdown)

	return <HTMLCompSection style={style}>{text}</HTMLCompSection>
}

export default HTMLComponent
