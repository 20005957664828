import React from 'react'
import { IconCheck, IconExclamationMark } from '@tabler/icons-react'
import { CImage } from 'app/components/Common'
import { COLORS, IMAGES } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { AccountInfoNavBarRightSideSection } from './styles'
import { useTranslation } from 'react-i18next'

const AccountInfoNavBarRightSide = () => {
	const { t } = useTranslation()
	return (
		<AccountInfoNavBarRightSideSection>
			<View
				style={{
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
				}}>
				<Text
					size={16}
					style={{
						color: COLORS.gray,
						fontWeight: '500',
						lineHeight: 1.2,
					}}>
					{t('SECURITYWARN')}
				</Text>
				<View style={{ flexDirection: 'row', marginTop: 10 }}>
					<View style={{ flexDirection: 'row' }}>
						<IconCheck
							size={18}
							style={{ color: COLORS.lightGreen }}
						/>
						<Text size={14} style={{ color: COLORS.lightGreen }}>
							{t('Identity Verification')}
						</Text>
					</View>
					<View style={{ flexDirection: 'row' }}>
						<IconExclamationMark
							size={18}
							style={{ color: COLORS.lightRed }}
						/>
						<Text size={14} style={{ color: COLORS.lightRed }}>
							{t('twoFactorAuthentication')}
						</Text>
					</View>
				</View>
			</View>
			<CImage
				src={IMAGES.securityHigh}
				alt="logo"
				width={64}
				height={64}
				resizeMode={'cover'}
			/>
		</AccountInfoNavBarRightSideSection>
	)
}

export default AccountInfoNavBarRightSide
