import { Button, DatePicker, Popconfirm, Space } from 'antd'
import dayjs from 'dayjs'
import React from 'react'

const DateFilter = ({
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	mobile = false,
}) => {
	if (mobile) {
		return (
			<Space direction='vertical'>
				<Space.Compact size='small'>
					<DatePicker
						placeholder="시작일"
						allowClear={true}
						onChange={e =>
							setStartDate(
								e
									? dayjs(e, 'YYYY-MM-DD').format(
											'YYYY-MM-DD'
									  )
									: null
							)
						}
						value={
							startDate
								? dayjs(startDate, 'YYYY-MM-DD')
								: undefined
						}
					/>
					<DatePicker
						placeholder="종료일"
						allowClear={true}
						onChange={e =>
							setEndDate(
								e
									? dayjs(e, 'YYYY-MM-DD').format(
											'YYYY-MM-DD'
									  )
									: null
							)
						}
						value={
							endDate ? dayjs(endDate, 'YYYY-MM-DD') : undefined
						}
					/>
				</Space.Compact>
				<Space.Compact size='small'>
					<Button
						type={!startDate || !endDate ? 'primary' : 'default'}
						onClick={() => {
							const today = dayjs().format('YYYY-MM-DD')
							const prev = dayjs(today)
								.subtract(1, 'day')
								.format('YYYY-MM-DD')

							//새벽 12시~오전 7시 사이에 조회할 경우 전날 데이터 조회
							if (dayjs().hour() < 7) {
								setStartDate(prev)
								setEndDate(prev)
							} else {
								setStartDate(today)
								setEndDate(today)
							}
						}}>
						당일
					</Button>
					<Button
						type={!startDate || !endDate ? 'primary' : 'default'}
						onClick={() => {
							const today = dayjs().format('YYYY-MM-DD')
							const prev = dayjs(today)
								.subtract(1, 'day')
								.format('YYYY-MM-DD')

							//새벽 12시~오전 7시 사이에 조회할 경우 전날 데이터 조회
							if (dayjs().hour() < 7) {
								setStartDate(
									dayjs(prev)
										.subtract(1, 'day')
										.format('YYYY-MM-DD')
								)
								setEndDate(
									dayjs(prev)
										.subtract(1, 'day')
										.format('YYYY-MM-DD')
								)
							} else {
								setStartDate(
									dayjs(today)
										.subtract(1, 'day')
										.format('YYYY-MM-DD')
								)
								setEndDate(
									dayjs(today)
										.subtract(1, 'day')
										.format('YYYY-MM-DD')
								)
							}
						}}>
						어제만
					</Button>
					<Button
						type={!startDate || !endDate ? 'primary' : 'default'}
						onClick={() => {
							const today = dayjs().format('YYYY-MM-DD')
							setStartDate(
								dayjs(today)
									.subtract(7, 'day')
									.format('YYYY-MM-DD')
							)
							setEndDate(today)
						}}>
						7일
					</Button>
					<Button
						type={!startDate || !endDate ? 'primary' : 'default'}
						onClick={() => {
							const today = dayjs().format('YYYY-MM-DD')
							setStartDate(
								dayjs(today)
									.subtract(31, 'day')
									.format('YYYY-MM-DD')
							)
							setEndDate(today)
						}}>
						30일
					</Button>
					<Popconfirm
						title="전체 조회시 시간이 소요될 수 있습니다. 조회 후 잠시 기다려주세요."
						okText="조회"
						cancelText="취소"
						onConfirm={() => {
							setStartDate('')
							setEndDate('')
						}}>
						<Button
							type={
								!startDate || !endDate ? 'primary' : 'default'
							}
							danger={!startDate || !endDate}
							onClick={() => {}}>
							전체
						</Button>
					</Popconfirm>
				</Space.Compact>
			</Space>
		)
	}

	return (
		<Space.Compact>
			<DatePicker
				placeholder="시작일"
				allowClear={true}
				onChange={e =>
					setStartDate(
						e ? dayjs(e, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
					)
				}
				value={startDate ? dayjs(startDate, 'YYYY-MM-DD') : undefined}
			/>
			<DatePicker
				placeholder="종료일"
				allowClear={true}
				onChange={e =>
					setEndDate(
						e ? dayjs(e, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
					)
				}
				value={endDate ? dayjs(endDate, 'YYYY-MM-DD') : undefined}
			/>
			<Space.Compact>
				<Button
					type={!startDate || !endDate ? 'primary' : 'default'}
					onClick={() => {
						const today = dayjs().format('YYYY-MM-DD')
						const prev = dayjs(today)
							.subtract(1, 'day')
							.format('YYYY-MM-DD')

						//새벽 12시~오전 7시 사이에 조회할 경우 전날 데이터 조회
						if (dayjs().hour() < 7) {
							setStartDate(prev)
							setEndDate(prev)
						} else {
							setStartDate(today)
							setEndDate(today)
						}
					}}>
					당일
				</Button>
				<Button
					type={!startDate || !endDate ? 'primary' : 'default'}
					onClick={() => {
						const today = dayjs().format('YYYY-MM-DD')
						const prev = dayjs(today)
							.subtract(1, 'day')
							.format('YYYY-MM-DD')

						//새벽 12시~오전 7시 사이에 조회할 경우 전날 데이터 조회
						if (dayjs().hour() < 7) {
							setStartDate(
								dayjs(prev)
									.subtract(1, 'day')
									.format('YYYY-MM-DD')
							)
							setEndDate(
								dayjs(prev)
									.subtract(1, 'day')
									.format('YYYY-MM-DD')
							)
						} else {
							setStartDate(
								dayjs(today)
									.subtract(1, 'day')
									.format('YYYY-MM-DD')
							)
							setEndDate(
								dayjs(today)
									.subtract(1, 'day')
									.format('YYYY-MM-DD')
							)
						}
					}}>
					어제만
				</Button>
				<Button
					type={!startDate || !endDate ? 'primary' : 'default'}
					onClick={() => {
						const today = dayjs().format('YYYY-MM-DD')
						setStartDate(
							dayjs(today).subtract(7, 'day').format('YYYY-MM-DD')
						)
						setEndDate(today)
					}}>
					7일
				</Button>
				<Button
					type={!startDate || !endDate ? 'primary' : 'default'}
					onClick={() => {
						const today = dayjs().format('YYYY-MM-DD')
						setStartDate(
							dayjs(today)
								.subtract(31, 'day')
								.format('YYYY-MM-DD')
						)
						setEndDate(today)
					}}>
					30일
				</Button>
				<Popconfirm
					title="전체 조회시 시간이 소요될 수 있습니다. 조회 후 잠시 기다려주세요."
					okText="조회"
					cancelText="취소"
					onConfirm={() => {
						setStartDate('')
						setEndDate('')
					}}>
					<Button
						type={!startDate || !endDate ? 'primary' : 'default'}
						danger={!startDate || !endDate}
						onClick={() => {}}>
						전체
					</Button>
				</Popconfirm>
			</Space.Compact>
		</Space.Compact>
	)
}

export default React.memo(DateFilter)
