import { Button } from 'antd'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import React, { useEffect, useState } from 'react'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import ChartContainer from '../../organisms/Trade/TradeChart/TradeChartSection/ChartContainer'
import TradeChartNavBar from '../../organisms/Trade/TradeChart/TradeChartSection/TradeChartNavBar'

const TradeChart = ({ isblur }) => {
	const { theme } = React.useContext(ThemeContext)
	const [tab, setTab] = useState('1m')
	const [isChart, setIsChart] = useState(true)

	const [firstLoad, setFirstLoad] = useState(false)
	const { change } = useTradeModalStore()
	const tabs = [
		'1m',
		'3m',
		'5m',
		'15m',
		'30m',
		'1h',
		'2h',
		'4h',
		'8h',
		'12h',
		'1d',
		'1w',
		'1M',
	]

	useEffect(() => {
		setIsChart(true)
	}, [])

	return (
		<>
			{!firstLoad ? (
				<View
					style={{
						width: '100%',
						height: 30,
						flexDirection: 'column',
					}}>
					<Button
						style={{
							width: '100%',
							height: 30,
							borderRadius: 0,
						}}
						type="primary"
						onClick={() => {
							setIsChart(false)
							setFirstLoad(true)
						}}>
						차트열기
					</Button>
				</View>
			) : (
				<>
					<View
						style={{
							width: '100%',
							height: 30,
							flexDirection: 'column',
						}}>
						<Button
							style={
								!isChart
									? {
											width: '100%',
											height: 30,
											borderRadius: 0,
											backgroundColor: '#606060',
									  }
									: {
											width: '100%',
											height: 30,
											borderRadius: 0,
									  }
							}
							type="primary"
							onClick={() => {
								setIsChart(!isChart)
								setFirstLoad(true)
							}}>
							{!isChart ? '차트닫기' : '차트열기'}
						</Button>
					</View>
				</>
			)}

			{firstLoad && (
				<div
					style={{
						display: !isChart ? 'flex' : 'none',
						width: '100%',
						height: 260,
						flexDirection: 'column',
					}}>
					<TradeChartNavBar
						tab={tab}
						setTab={setTab}
						tabs={tabs}
						mobile={true}
					/>
					<ChartContainer
						isDragging={false}
						tab={tab}
						mobile={true}
						isblur={isblur}
					/>
				</div>
			)}
		</>
	)
}

export default React.memo(TradeChart)
