import BigNumber from 'bignumber.js'
import React from 'react'
import { useBalance } from '../../../../hooks/user/mybalance'
import { AssetDerivativesTableSection } from '../styles'
import AssetDerivativesTableHeader from './AssetDerivativesTableHeader'
import AssetDerivativesTableList from './AssetDerivativesTableList'

const AssetDerivativesTable = () => {
	BigNumber.clone() // new line
	const header = ['종목', '사용가능', '증거금/출금']

	const { data: spot } = useBalance('KRW', 9)

	const data = [
		{
			coin: '원화',
			balance: spot?.balance,
			availableBalance: BigNumber(spot?.balance || 0)
				.minus(spot?.lockbalance || 0)
				.toNumber(),
			lockBalance: BigNumber(spot?.lockbalance || 0).toFormat(0),
		},
	]
	return (
		<AssetDerivativesTableSection>
			<AssetDerivativesTableHeader header={header} />
			<AssetDerivativesTableList data={data} />
		</AssetDerivativesTableSection>
	)
}

export default AssetDerivativesTable
