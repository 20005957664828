import { IconChevronRight } from '@tabler/icons-react'
import { CButton, CText } from 'app/components/Common/'
import { FONTS } from 'app/constants/'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useHeader, useHeaderBtn } from 'app/hooks'
import { toast } from 'react-toastify'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useCheckTradePage } from 'utils/ExpensiveFunction'
import {
	DropDownContainer,
	DropdownContent,
	DropDownLeft,
	DropdownList,
	DropDownSubContainer,
} from './styles'
import SubDropdown from './SubDropdown'

interface IProps {
	data?: any
}
const HeaderDropdown = ({ data }: IProps) => {
	const { textw, borderColor } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const history = useHistory()
	const goTo = (url: any) => {
		history.push(url)
	}
	const { data: headerBtn, mutate: headerBtnMutate } = useHeaderBtn()
	const { data: header, mutate: headerMutate } = useHeader()
	if (header?.display === undefined) {
		headerMutate({ ...header, display: 'block' })
	}

	const dropDownSizeFilter = data?.some(obj => obj?.subdropdown)

	return (
		<DropdownContent
			sizeCheck={dropDownSizeFilter}
			style={{
				top: useCheckTradePage() ? 35 : 55,
			}}>
			{data?.length ? (
				<DropdownList>
					{data?.map((item: any, index: any) => (
						<DropDownContainer
							key={index.toString()}
							onMouseEnter={() => {
								headerMutate({ ...header, display: 'block' })
							}}
							display={header?.display}>
							<DropDownSubContainer
								onClick={() => {
									if (item?.onClick) item?.onClick()
									if (item?.goTo) goTo(item?.goTo)
									if (item?.message)
										toast(`${t(item?.message)}`)
								}}>
								<View
									style={{
										alignItems: 'flex-start',
										padding: 20,
									}}
									onClick={() => {
										headerBtnMutate({
											...headerBtn,
											hoverState: false,
										})
									}}>
									<DropDownLeft>
										<CButton
											title={item?.title}
											style={{
												padding: 0,
												paddingBottom: 3,
												color: textw,
											}}
										/>
										{item?.subdropdown?.length ? (
											<IconChevronRight
												size={14}
												color={textw}
											/>
										) : null}
									</DropDownLeft>
									<CText
										text={t(item?.content)}
										style={{
											...FONTS.h8,
											color: borderColor,
											lineHeight: 1.3,
										}}
										vStyle={{
											alignItems: 'flex-start',
										}}
									/>
								</View>

								{/* NESTED DROPDOWN */}
								{item?.subdropdown ? (
									<SubDropdown
										subDropdown={
											item?.subdropdown &&
											item?.subdropdown
										}
									/>
								) : null}
								{/* NESTED DROPDOWN */}
							</DropDownSubContainer>
						</DropDownContainer>
					))}
				</DropdownList>
			) : null}
		</DropdownContent>
	)
}

export default HeaderDropdown
