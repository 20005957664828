import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'styles/reactnative'
import { COLORS } from 'app/constants/STYLES'

import {
	SectionContainer,
	SectionWrap,
	LoginWrapper,
	LoginSubContainer,
	BtnContainer,
	LoginWidth,
	Divider,
} from './styles'
import { BtnApple, BtnGoogle, LoginHeader } from './AuthContainers'
import LoginForm from './Forms/LoginForm'
import VerifySection from './VerifySection'
import { ThemeContext } from 'theme/ThemeProvider'

const LoginSection = () => {
	const [loginSuccess, setLoginSuccess] = useState('')
	const { t } = useTranslation()
	const { isDarkMode } = React.useContext(ThemeContext)
	const background = isDarkMode ? COLORS.loginbgw : COLORS.loginbgb
	return (
		<SectionContainer>
			{loginSuccess?.length > 5 ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}>
					<VerifySection
						authSuccess={loginSuccess}
						setAuthSuccess={setLoginSuccess}
					/>
				</div>
			) : (
				<SectionWrap>
					<LoginWrapper style={{ background }}>
						<LoginHeader label={t('login')} />
						<LoginSubContainer>
							<LoginForm setLoginSuccess={setLoginSuccess} />
						</LoginSubContainer>
					</LoginWrapper>
				</SectionWrap>
			)}
		</SectionContainer>
	)
}

export default React.memo(LoginSection)
