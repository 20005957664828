import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MText, MView } from 'styles/mobilePageStyles'
import CurrencyBtn from './CurrencyBtn'
import { currencyData } from './currencyData'
import { useFetcherPublic } from '../../../../../../hooks/fetcher'
import { getSetting, useSetting } from '../../../../../../hooks/user/mysetting'
import { SETTING_TYPE_SYSTEM } from '../../../../../../constants/SETTING'

const CurrencyContainer = ({ setOpen, item, setItem }) => {
	const { t } = useTranslation()

	const { data: setting, updateSetting } = useSetting(SETTING_TYPE_SYSTEM)
	const currency = getSetting(setting, 'currency', 'USD')

	const { data: result } = useFetcherPublic('/system/currency')
	const currencyOptions = result?.result?.result?.map((item: any) => ({
		value: item.code,
		label: item.name,
	}))

	useEffect(() => {}, [currency])

	return (
		<MView style={{ width: '100%', height: '100%', padding: 16 }}>
			<MText style={{}}>{t('currentOption')}</MText>
			<CurrencyBtn
				title={currency}
				setItem={setItem}
				item={{
					value: currency,
					label: currencyOptions?.find(
						(item: any) => item.value === currency
					)?.label,
				}}
				setOpen={setOpen}
			/>
			<MView style={{ paddingBlock: 15, flex: 1, width: '100%' }}>
				<MText style={{}}>{t('more')}</MText>
				<MView
					style={{
						flexDirection: 'row',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
						marginTop: 10,
						width: '100%',
					}}>
					<MView
						style={{
							flexDirection: 'row',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
							width: '100%',
						}}>
						{currencyOptions?.map((listItem: any, index) => (
							<CurrencyBtn
								key={index.toString()}
								title={currency}
								item={listItem}
								setItem={value => {
									setItem(value)
									updateSetting('CURRENCY', value)
								}}
								setOpen={setOpen}
							/>
						))}
					</MView>
				</MView>
			</MView>
		</MView>
	)
}

export default CurrencyContainer
