import styled from 'styled-components'

export const MContentContainer = styled.div<{ isDarkMode?: any }>`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	padding: 0;
	flex: 1;
	background-color: ${props => props.theme.COLORS.background};
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	/* ::-webkit-scrollbar {
		display: none !important;
	}

	::-moz-scrollbar {
		display: none !important;
	} */
`

export const MView = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
`

export const MNOANIView = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;

	& > * {
		animation-duration: 0s !important;
	}
`

export const MText = styled.div<{
	size?: number
	hoverColor?: string
}>`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: row;
	font-family: 'Roboto';
	color: ${props => props.theme.COLORS.textw};
	font-size: 12px;
	&:hover {
		${({ hoverColor }) => hoverColor && `color: ${hoverColor} !important;`}
	}
	@media screen and (max-width: 800px) and (min-width: 600px) {
		font-size: 14px;
	}
`
