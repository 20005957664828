import { Col } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import DefaultKrw from './DefaultKrw'
const EndDate = () => {
	const { data: tradeStore } = useTradeStore()
	//하루 빼고 yyyy-mm-dd

	let date = new Date(tradeStore?.enddate)
	date.setDate(date.getDate() )
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()
	const endDate = `${year}-${month < 10 ? `0${month}` : month}-${
		day < 10 ? `0${day}` : day
	}`

	return (
		<DefaultKrw
			title={'만료일'}
			price={endDate || ''}
			dp={tradeStore?.dp}
			krw={false}
			text={true}
		/>
	)
}

export default React.memo(EndDate)
