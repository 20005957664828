import React from 'react'
import KrwPrice from '../../KrwPrice'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import BigNumber from 'bignumber.js'
import Default from './Default'
import { useTradePosition } from '../../../hooks/trade/tradePosition'
import { useExchangeStore } from '../../../hooks/trade/exchangeStore'

const LossCut = () => {
	let currentAmount = 0
	const { data } = useTradePosition()
	const { EPs } = useExchangeStore()

	data?.map((item: any) => {
		if(item?.status) return
		
		const EP = EPs?.find((i: any) => i?.id == item?.exchange_pairs_id)
		currentAmount = new BigNumber(currentAmount)
			.plus(BigNumber(item?.amount || 0).multipliedBy(EP?.losscut || 1))
			.toNumber()
	})

	return (
		<Default
			title={'로스컷'}
			value={
				<KrwPrice
					margin={true}
					price={currentAmount}
					dp={0}
					krw={false}
					color={false}
				/>
			}
		/>
	)
}

export default React.memo(LossCut)
