import { usePartnerSocket } from 'app/hooks/trade/partnerSocket'
import React, { useEffect, useState } from 'react'

const CPartnerRenderEmpty = ({ id, leaveid = 0 }: any) => {
	const socket = usePartnerSocket()
	const { joinGroup, leaveGroup } = socket

	const [timer, setTimer] = useState<any>(null)

	useEffect(() => {
		return () => {
			if (leaveid) {
				leaveGroup('user', leaveid)
			}
		}
	}, [leaveid])

	useEffect(() => {
		if (id) {
			const temp = setInterval(() => {
				joinGroup('user', id)
			}, 5000)
			setTimer(temp)
		} else {
			joinGroup('all')
		}

		return () => {
			if (timer) clearInterval(timer)
			setTimer(null)
			if (id) {
				leaveGroup('user', id)
			} else {
				leaveGroup('all')
			}
		}
	}, [])

	return null
}
export default React.memo(CPartnerRenderEmpty)
