import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { DepositRightSectionWrapper } from './styles'

const DepositRightSection = () => {
	const { textsilver, descBox } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<DepositRightSectionWrapper>
			<View
				style={{
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					background: `${descBox}`,
					marginRight: 30,
					padding: 10,
					height: '95%',
					overflow: 'hidden',
				}}>
				<Text size={16}>{t('important')}:</Text>
				<View style={{ paddingBlock: 8, paddingInline: 5 }}>
					<Text
						size={12}
						style={{ color: textsilver, lineHeight: 1.2 }}>
						{t('depositFirstDesc')}
					</Text>
					<Text
						size={12}
						style={{
							color: textsilver,
							marginTop: 15,
							lineHeight: 1.2,
						}}>
						{t('depositSecondDesc')}
					</Text>
					<Text
						size={12}
						style={{
							color: textsilver,
							marginTop: 15,
							lineHeight: 1.2,
						}}>
						{t('depositThirdDesc')}
					</Text>
					<Text
						size={12}
						style={{
							color: textsilver,
							marginTop: 15,
							lineHeight: 1.2,
						}}>
						{t('depositFourthDesc')}
					</Text>
					<Text
						size={12}
						style={{
							color: textsilver,
							marginTop: 15,
							lineHeight: 1.2,
						}}>
						{t('depositFifthDesc')}
					</Text>
				</View>
			</View>
		</DepositRightSectionWrapper>
	)
}

export default DepositRightSection
