import React, { useEffect, useState } from 'react'
import { Badge } from 'antd'
import { Text } from '../../../styles/reactnative'
import { typeFromLevel } from '../../../utils/ciutils'

const LevelIcon = ({ level }) => {


	const leveltext = typeFromLevel(level)
	return (
			<Text style={{ fontSize: 11, whiteSpace: 'nowrap' }}>
				{leveltext?.replace(/회원/gi, '')}
			</Text>
	)
}
export default React.memo(LevelIcon)
