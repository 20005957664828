import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import OtpInput from 'react-otp-input'

import { COLORS } from 'app/constants'
import { useWindowDimensions } from 'app/hooks'
import { View } from 'styles/reactnative'

import API from '../../../../utils/api'
import { goTo } from '../../../hooks/navigation'
import { useToken } from '../../../hooks/'
import { ThemeContext } from '../../../../theme/ThemeProvider'

const VerifyCode = ({ authSuccess, setAuthSuccess, setVerifyError }) => {
	const navigate = useHistory()
	const { width } = useWindowDimensions()
	const { mutate } = useToken('', true)
	const [code, setCode] = useState('')

	const { isDarkMode } = React.useContext(ThemeContext)
	const background = isDarkMode ? COLORS.white : 'rgba(21, 21, 21, 0.5)'

	const handleVerification = async () => {
		if (authSuccess) {
			if (code.length === 6) {
				const res = await API.postPublic('/auth/verifycode', {
					verifyid: authSuccess,
					code: code,
				})
				if (res.result !== false) {
					mutate(res.result)
					goTo(navigate, '/')
					setAuthSuccess('')
					setVerifyError(false)
				}
				if (res.result === false) {
					setVerifyError(true)
				}
			}
		}
	}
	const handleChange = (otp: string) => setCode(otp)

	useEffect(() => {
		handleVerification()
		if (code.length === 0) {
			setVerifyError(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code, setVerifyError])

	const inputSize = width > 380 ? 50 : width > 360 ? 40 : 30
	return (
		<View
			style={{ alignItems: 'flex-start', width: '100%', marginTop: 10 }}>
			<OtpInput
				isInputNum={true}
				value={code}
				onChange={handleChange}
				numInputs={6}
				containerStyle={{
					height: 50,
					width: '100%',
					justifyContent: 'space-between',
				}}
				inputStyle={{
					background: background,
					color: COLORS.primary,
					width: inputSize,
					height: inputSize,
					borderRadius: 5,
					border: `1px solid ${COLORS.lightGray}`,
					fontSize: 25,
				}}
				hasErrored={false}
				isDisabled={false}
				focusStyle={{}}
				disabledStyle={{
					background: '#F05551',
				}}
				errorStyle={{
					color: '#F05551',
				}}
			/>
		</View>
	)
}

export default VerifyCode
