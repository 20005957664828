
import { Button, Input, Modal, Space } from 'antd'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { useOrderStore } from 'app/hooks/trade/orderStore'
import React from 'react'


const TradeAmount = () => {
    const { data: modalStore, change: mutateModalStore } = useTradeModalStore()
    const { data: orderStore, change } = useOrderStore()
    return (
        <Modal
            open={modalStore?.setOrder}
            title={'주문수량버튼설정'}
            cancelText={'닫기'}
            onCancel={() => {
                mutateModalStore({
                    setOrder: false,
                })
            }}
            footer={null}>
            <Space.Compact style={{ alignItems: 'center' }}>
                <Input
                    size="small"
                    value={orderStore?.order_short1}
                    onChange={e => {
                        change({
                            order_short1: e.target.value,
                        })
                    }}
                    type={'number'}
                />
                <Input
                    size="small"
                    value={orderStore?.order_short2}
                    onChange={e => {
                        change({
                            order_short2: e.target.value,
                        })
                    }}
                    type={'number'}
                />
                <Input
                    size="small"
                    value={orderStore?.order_short3}
                    onChange={e => {
                        change({
                            order_short3: e.target.value,
                        })
                    }}
                    type={'number'}
                />
                <Input
                    size="small"
                    value={orderStore?.order_short4}
                    onChange={e => {
                        change({
                            order_short4: e.target.value,
                        })
                    }}
                    type={'number'}
                />
                <Input
                    size="small"
                    value={orderStore?.order_short5}
                    onChange={e => {
                        change({
                            order_short5: e.target.value,
                        })
                    }}
                    type={'number'}
                />
                <Input
                    size="small"
                    value={orderStore?.order_short6}
                    onChange={e => {
                        change({
                            order_short6: e.target.value,
                        })
                    }}
                    type={'number'}
                />
            </Space.Compact>
            <div style={{ textAlign: 'center', marginTop: 10 }}>
                <Button
                    size="small"
                    onClick={() => {
                        mutateModalStore({
                            setOrder: false,
                        })
                    }}>
                    닫기
                </Button>
            </div>
        </Modal>

    )
}

export default React.memo(TradeAmount)
