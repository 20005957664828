import { Tag } from 'antd'
import { COLORS } from 'app/constants'
import React from 'react'
import { Text } from 'styles/reactnative'
import EntryTime from '../../../../../components/EntryTime'
import KrwPrice from '../../../../../components/KrwPrice'
import UserTag from '../../../../../components/UserTag'
import { TagWrap } from '../../../../../components/styles'

export const AdminWithdrawalTableColumn: any = (mutate, openModal) => {
	return [
		{
			title: '번호',
			dataIndex: 'id',
			align: 'center',
			width: 70,
			render: (text, data) => <Text>{text}</Text>,
		},
		{
			title: '생성일',
			dataIndex: 'created_at',
			align: 'center',
			width: 130,
			render: (text, data) => <EntryTime time={text} offset={9}/>,
		},
		{
			title: '인출일',
			dataIndex: 'confirm_at',
			align: 'center',
			width: 130,
			render: (text, data) => {
				{
					return text ? <EntryTime time={text} offset={9}/> : <Text>-</Text>
				}
			},
		},
		{
			title: '그룹',
			dataIndex: 'referral_code',
			align: 'center',
			render: (text, data) => <Text>{text}</Text>,
		},
		{
			title: '실명',
			dataIndex: 'user_id',
			align: 'center',
			width: 150,
			render: (text, data) => (
				<UserTag
					userid={data?.user_id}
					email={data?.email}
					phone={data?.phone}
					nickname={data?.fullname || data?.nickname}
					onClick={() => {
						openModal(data?.user_id)
					}}
				/>
			),
		},
		{
			title: '아이디',
			dataIndex: 'loginid',
			align: 'center',
		},
		{
			title: '진행',
			dataIndex: 'process',
			align: 'center',
			width: 50,
			render: (text, data) => (
				<TagWrap>
					{text == 1 ? (
						<Tag
							style={{
								backgroundColor: '#f79336',
								color: 'white',
							}}>
							대기
						</Tag>
					) : text == 2 ? (
						<Tag
							style={{
								backgroundColor: COLORS.lightGreen,
								color: 'white',
							}}>
							{data?.confirm_amount ? '출금완료' : '출금승인'}
						</Tag>
					) : text == 3 ? (
						<Tag
							style={{ backgroundColor: '#444', color: 'white' }}>
							검토중
						</Tag>
					) : text == 4 ? (
						<Tag style={{ color: 'red' }}>거절됨</Tag>
					) : (
						<Tag>취소</Tag>
					)}
				</TagWrap>
			),
		},
		{
			title: '출금신청액',
			align: 'center',
			dataIndex: 'amount',
			render: (text, data) => <KrwPrice price={text} dp={0} />,
		},
		{
			title: '출금승인액',
			align: 'center',
			dataIndex: 'confirm_amount',
			render: (text, data) => <KrwPrice price={text || 0} dp={0} />,
		},
		{
			title: '출금계좌',
			align: 'center',
			dataIndex: 'bankaccount',
			render: (text, data) => {
				return <Text>{text}</Text>
			},
		},
		{
			title: '관리메모',
			align: 'center',
			dataIndex: 'memo',
			render: (text, data) => {
				return <Text>{text}</Text>
			},
		},
	]
}
