import useSWR from 'swr'
import API from '../../../utils/api'
import { EXCHANGESETTINGS } from '../../constants/APIKEYS'
import { useToken } from '../auth/token'

let KEY: string | null = EXCHANGESETTINGS

let swrData: any = []
let swrData2: any = []

export function useExchangeSetting(ep_id: number) {
	KEY = `${EXCHANGESETTINGS}/${ep_id}`
	const {token} = useToken()

	if (!ep_id) KEY = null

	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (swrData[ep_id]) return swrData[ep_id]

			const response = await API.get(KEY + '', token+'', {}, false)
			swrData[ep_id] = response.result
			return swrData[ep_id]
		},
		{ revalidateOnFocus: false }
	)

	swrData2[ep_id] = data

	return {
		data,
		getData(ep_id: number) {
			if (swrData2[ep_id]) return swrData2[ep_id]
			else return null
		},
		mutate: (value: any) => {
			if (value !== undefined) swrData[ep_id] = value
			return mutate()
		},
	}
}
