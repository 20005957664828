import { Button, Space } from 'antd'
import React, { useContext } from 'react'
import { Text } from '../../../../../../../styles/reactnative'
import { useAvaiblecheck } from '../../../../../../hooks/trade/avaibleCheck'
import { ThemeContext } from '../../../../../../../theme/ThemeProvider'
import { useTradeModalStore } from '../../../../../../hooks/trade/modalStore'

const SpaceAva = ({ isMobile }: any) => {
	const { availableLong, availableShort } = useAvaiblecheck()
	const { change: mutateModalStore } = useTradeModalStore()

	const { textw, stroke, onTertiary, fail, raise } =
		useContext(ThemeContext).theme.COLORS

	if (!isMobile) return null

	return (
		<Space
			style={{
				marginTop: 15,
				padding: '10px 0',
				borderTop: `1px solid ${stroke}`,
				borderBottom: `1px solid ${stroke}`,
				width: '100%',
			}}>
			<Text style={{ color: textw }}>가능</Text>
			<Text
				style={{
					background: onTertiary,
					color: fail,
					padding: '3px 5px',
				}}>
				매도 {availableShort > 0 ? availableShort : 0}
			</Text>
			<Text
				style={{
					background: onTertiary,
					color: raise,
					padding: '3px 5px',
				}}>
				매수 {availableLong > 0 ? availableLong : 0}
			</Text>

			{isMobile && (
				<Button
					size="small"
					onClick={e => {
						setImmediate(() => {
							mutateModalStore({
								settings: true,
							})
						})
					}}>
					설정
				</Button>
			)}
		</Space>
	)
}

export default React.memo(SpaceAva)
