import { Space, Switch } from 'antd'
import { useLogQueue } from 'app/hooks/trade/logQueue'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import React from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
import { useWindowDimensions } from '../../../../../hooks'
import { DerivativesHeaderWrapper } from './styles'

const DerivativesHeader = ({ mobile = false }) => {
	const { textw } = React.useContext(ThemeContext).theme.COLORS
	const { width } = useWindowDimensions()
	const { data: modalStore, change: mutateModalStore } = useTradeModalStore()

	const { mutate: mutateLog } = useLogQueue()

	const saveLog = (type, before) => {

		mutateLog({
			log: 'FR002',
			data: {
				before: type + '|' + before,
				skip: modalStore?.skip,
				oneClick: modalStore?.oneClick,
				fixed_orderbook: modalStore?.fixed_orderbook,
				sound: modalStore?.sound,
				chartshow: modalStore?.chartshow,
				detailshow: modalStore?.detailshow,
				orderbookshow: modalStore?.orderbookshow,
				tradeshow: modalStore?.tradeshow,
				speed: modalStore?.speed,
			},
		})

	}



	return (
		<DerivativesHeaderWrapper>
			<Space
				direction="horizontal"
				style={{
					padding: width < 1300 ? 0 : 5,
					width: '100%',
					justifyContent: 'space-between',
				}}>
				<Space.Compact direction="vertical" style={{ width: '100%' }}>
					<label
						style={{
							color: textw,
							fontSize: 11,
							whiteSpace: 'nowrap',
						}}>
						{width < 1300 ? '확인생략' : '주문확인생략'}
					</label>
					<Switch
						size={width < 1300 ? 'small' : 'default'}
						checkedChildren="ON"
						unCheckedChildren="OFF"
						checked={modalStore?.skip ? true : false}
						onChange={checked => {
							const before = modalStore?.skip
							setImmediate(() => {
								mutateModalStore({
									skip: checked,
								})

								setTimeout(() => {
									saveLog("S", before)
								}, 100)
							})
						}}
					/>
				</Space.Compact>
				<Space.Compact direction="vertical" style={{ width: '100%' }}>
					<label
						style={{
							color: textw,
							fontSize: 11,
							whiteSpace: 'nowrap',
						}}>
						{width < 1300 ? '원클릭' : '원클릭주문'}
					</label>
					<Switch
						size={width < 1300 ? 'small' : 'default'}
						checkedChildren="ON"
						unCheckedChildren="OFF"
						checked={modalStore?.oneClick ? true : false}
						onChange={checked => {
							const before = modalStore?.oneClick
							setImmediate(() => {
								mutateModalStore({
									oneClick: checked,
								})
								setTimeout(() => {
									saveLog("O", before)
								}, 100)
							})
						}}
					/>
				</Space.Compact>
				<Space.Compact direction="vertical" style={{ width: '100%' }}>
					<label
						style={{
							color: textw,
							fontSize: 11,
							whiteSpace: 'nowrap',
						}}>
						{width < 1300 ? '호가고정' : '호가창고정'}
					</label>
					<Switch
						size={width < 1300 ? 'small' : 'default'}
						checkedChildren="ON"
						unCheckedChildren="OFF"
						checked={modalStore?.fixed_orderbook ? true : false}
						onChange={checked => {
							const before = modalStore?.fixed_orderbook
							setImmediate(() => {
								mutateModalStore({
									fixed_orderbook: checked,
								})
								setTimeout(() => {
									saveLog("F", before)
								}, 100)
							})
						}}
					/>
				</Space.Compact>
				<Space.Compact direction="vertical" style={{ width: '100%' }}>
					<label
						style={{
							color: textw,
							fontSize: 11,
							whiteSpace: 'nowrap',
						}}>
						{width < 1300 ? '음성' : '음성'}
					</label>
					<Switch
						size={width < 1300 ? 'small' : 'default'}
						checkedChildren="ON"
						unCheckedChildren="OFF"
						checked={modalStore?.sound ? true : false}
						onChange={checked => {
							const before = modalStore?.sound
							setImmediate(() => {
								mutateModalStore({
									sound: checked,
								})
								setTimeout(() => {
									saveLog("S", before)
								}, 100)
							})
						}}
					/>
				</Space.Compact>
			</Space>

			{!mobile && (
				<Space
					direction="horizontal"
					style={{
						padding: width < 1300 ? 0 : 5,
						paddingTop: 5,
						paddingBottom: 5,
						width: '100%',
						justifyContent: 'space-between',
					}}>
					<Space.Compact
						direction="vertical"
						style={{ width: '100%' }}>
						<label style={{ color: textw, fontSize: 11 }}>
							차트창
						</label>
						<Switch
							checkedChildren="ON"
							unCheckedChildren="OFF"
							size={width < 1300 ? 'small' : 'default'}
							checked={modalStore?.chartshow ? false : true}
							onChange={checked => {
								const before = modalStore?.chartshow
								setImmediate(() => {
									mutateModalStore({
										chartshow: !checked,
									})
								})
								setTimeout(() => {
									saveLog("C", before)
								}, 100)
							}}
						/>
					</Space.Compact>
					<Space.Compact
						direction="vertical"
						style={{ width: '100%' }}>
						<label style={{ color: textw, fontSize: 11 }}>
							내역창
						</label>
						<Switch
							checkedChildren="ON"
							unCheckedChildren="OFF"
							size={width < 1300 ? 'small' : 'default'}
							checked={modalStore?.detailshow ? false : true}
							onChange={checked => {
								const before = modalStore?.detailshow
								setImmediate(() => {
									mutateModalStore({
										detailshow: !checked,
									})
								})
								setTimeout(() => {
									saveLog("D", before)
								}, 100)
							}}
						/>
					</Space.Compact>
					<Space.Compact
						direction="vertical"
						style={{ width: '100%' }}>
						<label style={{ color: textw, fontSize: 11 }}>
							호가창
						</label>
						<Switch
							checkedChildren="ON"
							unCheckedChildren="OFF"
							size={width < 1300 ? 'small' : 'default'}
							checked={modalStore?.orderbookshow ? false : true}
							onChange={checked => {
								const before = modalStore?.orderbookshow
								setImmediate(() => {
									mutateModalStore({
										orderbookshow: !checked,
									})
								})
								setTimeout(() => {
									saveLog("OB", before)
								}, 100)
							}}
						/>
					</Space.Compact>
					<Space.Compact
						direction="vertical"
						style={{ width: '100%' }}>
						<label style={{ color: textw, fontSize: 11 }}>
							거래창
						</label>
						<Switch
							checkedChildren="ON"
							unCheckedChildren="OFF"
							size={width < 1300 ? 'small' : 'default'}
							checked={modalStore?.tradeshow ? false : true}
							onChange={checked => {
								const before = modalStore?.tradeshow
								setImmediate(() => {
									mutateModalStore({
										tradeshow: !checked,
									})
								})
								setTimeout(() => {
									saveLog("T", before)
								}, 100)
							}}
						/>
					</Space.Compact>

				</Space>
			)}
			<Space style={{ width: '100%', justifyContent: 'flex-end' }}>
				<Space.Compact
					direction="vertical"
					style={{ width: '100%' }}>
					<label style={{ color: textw, fontSize: 11 }}>
						버튼효과
					</label>
					<Switch
						checkedChildren="ON"
						unCheckedChildren="OFF"
						size={width < 1300 ? 'small' : 'default'}
						checked={modalStore?.btnEffect ? true : false}
						onChange={checked => {
							const before = modalStore?.btnEffect
							setImmediate(() => {
								mutateModalStore({
									btnEffect: checked ? true : false,
								})
							})
							setTimeout(() => {
								saveLog("SE", before)
							}, 100)
						}}
					/>
				</Space.Compact>
			</Space>
			{/* <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
				<Space.Compact
					direction="vertical"
					style={{ width: '100%' }}>
					<label style={{ color: textw, fontSize: 11 }}>
						저사양모드
					</label>
					<Switch
						checkedChildren="ON"
						unCheckedChildren="OFF"
						size={width < 1300 ? 'small' : 'default'}
						checked={modalStore?.speed == -1 ? true : false}
						onChange={checked => {
							const before = modalStore?.speed
							setImmediate(() => {
								mutateModalStore({
									speed: checked ? -1 : 0,
								})
							})
							setTimeout(() => {
								saveLog("SP", before)
							}, 100)
						}}
					/>
				</Space.Compact>
			</Space> */}
		</DerivativesHeaderWrapper>
	)
}

export default DerivativesHeader
