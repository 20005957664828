import useSWR from 'swr'
import API from '../../../utils/api'
import { CHARTAPI, INFO24H } from '../../constants/TRADEAPIKEYS'
import { useToken } from '../auth/token'

let swrData: any = [0]
let refresh = false

export function useInfo24H(symbol: any) {
	const { data: token } = useToken()

	let KEY: any = INFO24H + '?symbol=' + 0

	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (swrData.length > 1) {
				return swrData
			}

			const response = await API.get(KEY, token + '', {}, false, CHARTAPI+'/v1')
			swrData = [new Date().getTime(), ...response?.result]

			return swrData
		},
		{
			revalidateOnFocus: true,
			refreshInterval: 60000,
			revalidateOnReconnect: true,
		}
	)

	const epdata = data?.find((item: any) => item?.symbol == symbol)
	return {
		data: epdata,
		refresh,
		getinfo24: symbol2 => {
			const temp = data?.find((item: any) => item?.symbol == symbol2)
			return temp
		},
		mutate: (value?: any, epid?: number) => {
			mutate()
		},
		resetinfo24: () => {
			mutate()
		},
		updatehighlow: (low, high, symbol2) => {
			let ischanged = false
			const newdata = swrData?.map((item: any) => {
				if (item.symbol == symbol2) {
					if (item.high < high) {
						item.high = high
						ischanged = true
					}
					if (item.high < low) {
						item.high = low
						ischanged = true
					}

					if (item.low > low) {
						item.low = low
						ischanged = true
					}
					if (item.low > high) {
						item.low = high
						ischanged = true
					}
				}
				return item
			})

			newdata[0] = new Date().getTime()

			if (ischanged) {
				refresh = !refresh
				swrData = newdata
			}
			mutate()
		},
	}
}
