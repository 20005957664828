import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
	SpacebetweenRowView,
	Text,
	View,
} from '../../../../../../styles/reactnative'
import { ThemeContext } from '../../../../../../theme/ThemeProvider'
import CoinRate from '../../../../../components/CoinRate/mobile'
import { COLORS, FONTS } from '../../../../../constants'
import { goTo } from '../../../../../hooks/navigation'
import { useTradeModalStore } from '../../../../../hooks/trade/modalStore'
import ThrottlePrice from './ThrottlePrice'
import { IconAlertTriangle } from '@tabler/icons-react'
import { useLogQueue } from 'app/hooks/trade/logQueue'

const OrderHeader = ({ item, coin, pnl, currency }) => {
	const { textw, textsilver } = useContext(ThemeContext).theme.COLORS
	const { data: modalStore } = useTradeModalStore()

	const { mutate: mutateLog } = useLogQueue()
	const navigation = useHistory()
	return (
		<SpacebetweenRowView>
			<View style={{ alignItems: 'flex-start' }}>
				<View
					style={{ flexDirection: 'row' }}
					onClick={() => {
						goTo(navigation, `/trade/${item?.exchange_pairs_id}`)
							
						mutateLog({
							log: 'FR006',
							data: {
								ep_id: item?.exchange_pairs_id
							},
						})

					}}>
					<Text style={{ ...FONTS.h6, color: textw }}>{coin}</Text>
					<CoinRate
						color={
							item?.long_short === 'L'
								? COLORS.lightRed
								: COLORS.lightGreen
						}
						content={item?.long_short === 'L' ? '매수' : '매도'}
					/>
				</View>
			</View>
			<View style={{ alignItems: 'flex-end' }}>
				<Text
					style={{
						...FONTS.h8,
						color: textsilver,
						paddingBottom: 3,
					}}>
					{modalStore?.noprice && (
						<IconAlertTriangle size={14} color={COLORS.lightRed} />
					)}
					평가손익
				</Text>
				<ThrottlePrice
					price={pnl}
					item={item}
					currency={currency}
					throttle={30}
				/>
			</View>
		</SpacebetweenRowView>
	)
}
export default React.memo(OrderHeader)
