import { Button } from 'antd'
import React, { useEffect } from 'react'
import KrwPrice from '../../KrwPrice'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import BigNumber from 'bignumber.js'
import Default from './Default'
import { useBalance } from '../../../hooks/user/mybalance'
import { useTradePosition } from '../../../hooks/trade/tradePosition'
import { useExchangeStore } from '../../../hooks/trade/exchangeStore'
import { useFetcherPublic } from '../../../hooks/fetcher'
import { useIndexPrice } from '../../../hooks/trade/indexprice'
import { useTranslation } from 'react-i18next'
import { useCurrency } from '../../../hooks/trade/useCurrency'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'

const AvBalance = () => {
	const { t } = useTranslation()
	const { data: balanceData, mutate } = useBalance('KRW', 9)

	const [totalpnl, setTotalpnl] = React.useState(0)
	const [available, setAvailable] = React.useState<any>(0)

	const { data: tradeStore } = useTradeStore()

	const { change } = useTradeModalStore()

	const { data: position, reset:resetPos } = useTradePosition()
	const data = position?.filter((item: any) => !item?.status)
	const { EPs } = useExchangeStore()
	const { krwrate, getRate } = useCurrency()
	let { getPrice } = useIndexPrice(tradeStore?.ep_id)

	useEffect(() => {
		resetPos()
	}, [])

	useEffect(() => {
		mutate()
	}, [])

	useEffect(() => {
		let pnl = 0
		let temptotalpnl = 0
		if (data && data?.length > 0) {
			data?.map((item: any) => {
				const E = EPs?.find((i: any) => item.exchange_pairs_id == i.id)
				const currencyrate = getRate(E?.currency)
				const rate = BigNumber(krwrate?.price || 0)
					.div(currencyrate?.price || 0)
					.toNumber()

				if (
					getPrice(item?.exchange_pairs_id) === 0 ||
					!getPrice(item?.exchange_pairs_id)
				) {
					change({
						noprice: true,
					})
				} else {

					change({
						noprice: false,
					})
					if (item?.long_short === 'L') {
						pnl = BigNumber(getPrice(item?.exchange_pairs_id))
							.minus(item?.price)
							.multipliedBy(item?.amount)
							.multipliedBy(E?.contractamt)
							.multipliedBy(rate)
							.toNumber()
					} else {
						pnl = BigNumber(item?.price)
							.minus(getPrice(item?.exchange_pairs_id))
							.multipliedBy(item?.amount)
							.multipliedBy(E?.contractamt)
							.multipliedBy(rate)
							.toNumber()
					}

					temptotalpnl = BigNumber(temptotalpnl || 0)
						.plus(pnl || 0)
						.toNumber()
				}
			})

			setTotalpnl(temptotalpnl)
		} else {
			setTotalpnl(0)
		}
	}, [data, getPrice, krwrate, EPs])

	useEffect(() => {
		setAvailable(
			BigNumber(balanceData?.balance || 0)
				.plus(totalpnl || 0)
				.toNumber()
		)
	}, [balanceData?.balance, totalpnl])


	return (
		<Default
			title={t('avbalance')}
			value={
				isNaN(available) ? (
					<Button
						size="small"
						type="primary"
						style={{
							padding: 0,
							width: '100%',
							margin: 0,
							height: 17,
							fontSize: 10,
						}}>
						입금신청
					</Button>
				) : (
					<KrwPrice
						margin={true}
						price={available || 0}
						dp={0}
						krw={false}
						color={false}
					/>
				)
			}
		/>
	)
}

export default AvBalance
