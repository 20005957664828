import useSWR from 'swr'
import { USER_SOCKET } from '../../constants/LOCALKEYS'

import { message } from 'antd'
import throttle from 'lodash/throttle'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useFps } from 'react-fps'
import { useToken } from '../auth/token'
import { useOvernight } from './overnight'
import { useTradePosition } from './tradePosition'

import useSound from 'use-sound'

import buy from '../../../assets/buy.mp3'
import done from '../../../assets/done.mp3'
import sell from '../../../assets/sell.mp3'
import { USER_SOCKET_URL } from '../../constants/APIKEYS'
import { useLogQueue } from './logQueue'
import { useMitOrder } from './mitorder'
import { useTradeModalStore } from './modalStore'
import { useUserList } from './userList'
import useWindowFocus from './windowFocus'
import { ThemeContext } from 'theme/ThemeProvider'

// WebSocket 인스턴스 생성
let tradeWS: WebSocket | null = null
let send = false

let lastcallwho = 0

let lastcallgroup: any = null
let lastrefresh: any = null

const joinGroup = (token?: string) => {
	if (token && tradeWS != null) {
		if (tradeWS && tradeWS.readyState === WebSocket.OPEN) {
			const now = new Date().getTime()
			if (lastcallgroup == null || now - lastcallgroup > 5000) {
				lastcallgroup = now
				tradeWS.send(
					`{"type":"join","group": "user", "token": "${token}"}`
				)
			}
		}
	}
}

export function useUserSocket() {
	const [buySound] = useSound(buy, { volume: 1 })
	const [sellSound] = useSound(sell, { volume: 1 })
	const [doneSound] = useSound(done, { volume: 1 })

	const { data: token, userid } = useToken()

	const { myip, data: logqueue, popLog, mutate: mutateLog } = useLogQueue()
	const { who, sentwho, mutateUserlist } = useUserList()

	const { isMobile } = useContext(ThemeContext)

	if (who) {
		if (new Date().getTime() - lastcallwho > 5000) {
			lastcallwho = new Date().getTime()
			if (tradeWS != null) {
				if (tradeWS?.readyState === WebSocket.OPEN) {
					tradeWS?.send(`{"type":"WHO"}`)
					sentwho()
				}
			}
		}
	}


	const { currentFps } = useFps(15)
	const [fps, setFps] = useState(0)

	const { data: modalStore, sound, change } = useTradeModalStore()

	const { removeandupdate } = useMitOrder()

	const {
		data: position,
		mutate: mutatePosition,
		updateData,
	} = useTradePosition()

	useEffect(() => {
		if (currentFps >= 50) {
			setFps(120)
		} else if (currentFps > 40) {
			setFps(60)
		} else if (currentFps > 20) {
			setFps(20)
		} else {
			setFps(0)
		}
	}, [currentFps])

	const { mutate: mutateOvernight } = useOvernight()

	const handlePosition = pos => {
		mutatePosition('SP', pos)
	}

	const handleUsers = (getDATA: any) => {
		if (getDATA?.substring(0, 2) == 'CO') {
			const pos = JSON.parse(getDATA?.substring(2))
			mutateUserlist(pos)
		}
	}

	const handleMessage = useCallback(
		throttle(async getDATA => {
			const trade = JSON.parse(getDATA)
			try {
				if (trade.type == 'LOSSCUT') {
					if (trade?.userid == userid) {
						message.warning(
							'로스컷 발생하였습니다. [발동자산' +
								parseInt(trade?.calcprice + '') +
								'] [로스컷기준:' +
								parseInt(trade?.losscut + '') +
								']'
						)
						setTimeout(() => {
							mutatePosition()
						}, 400)
					}
				} else if (trade.type == 'MSG') {
					if (trade?.msg) {
						if (trade?.wie == '1') {
							message.success(trade?.msg)
						} else if (trade?.wie == '2') {
							message.warning(trade?.msg)
						} else {
							message.error(trade?.msg)
						}
					}
				} else if (trade.type === 'ORDER') {
					mutateOvernight()
				}
			} catch (e) {
				console.error('ERROR', e)
				console.log(trade)
				console.log('-------------------')
			}
		}, 120 - fps),
		[userid, position, fps]
	)

	const handleStatusMessage = async getDATA => {
		const trade = JSON.parse(getDATA)

		try {
			if (trade.type == 'STATUS') {
				mutatePosition(trade)
			} else if (trade?.type == 'MIT') {
				removeandupdate(trade?.ep_id, trade?.price, '*')
			}
		} catch (e) {
			console.error('ERROR', e)
			console.log(trade)
			console.log('-------------------')
		}
	}

	const handleOrderMessage = useCallback(
		throttle(async getDATA => {
			if (!sound) {
				return
			}
			const trade = JSON.parse(getDATA)

			try {
				if (trade.type == 'ORDER') {
					if (trade?.userid == userid) {
						//console.log('ORDER', trade)
						if (
							trade?.ordertype == 'N' ||
							trade?.ordertype == 'U'
						) {
							if (trade?.long_short == 'L') {
								buySound()
							} else {
								sellSound()
							}
						} else {
							doneSound()
						}
					}

					mutateOvernight()
				}
			} catch (e) {
				console.error('ERROR', e)
				console.log(trade)
				console.log('-------------------')
			}
		}, 500),
		[fps, sound]
	)

	const handleRefreshMessage = useCallback(
		throttle(async getDATA => {
			const trade = JSON.parse(getDATA)

			try {
				if (trade.type == 'REFRESH') {
					if (trade?.userid == userid) {
						setTimeout(() => {
							mutatePosition()
						}, 100)

						setTimeout(() => {
							mutatePosition()
							mutateOvernight()
						}, 1000)
					}
				}
			} catch (e) {
				console.error('ERROR', e)
				console.log(trade)
				console.log('-------------------')
			}
		}, 10),
		[fps]
	)

	const { data, mutate } = useSWR<any>(
		USER_SOCKET,
		async () => {
			if (tradeWS && token && tradeWS?.readyState === WebSocket.OPEN) {
				joinGroup(token + '')
			}

			const dataProcess = (getDATA: any) => {
				if (getDATA == null) return
				//트레이드 리스트는 쓰로틀링 제외
				if (!modalStore?.isOnline) change({ isOnline: true })

				if (getDATA[0] == 'T') {
				} else if (getDATA[0] == 'X') {
				} else if (getDATA[0] == 'O') {
				} else if (getDATA[0] == 'S') {
					if (getDATA?.substring(0, 2) == 'SP') {
						//개별 포지션
						const pos = JSON.parse(getDATA?.substring(2))
						handlePosition(pos)
					} else if (getDATA?.substring(0, 2) == 'SO') {
						const pos = getDATA?.substring(2)
						const temp = pos.split('@')
						let orders: any = []
						if (temp.length == 0) {
							handlePosition([])
							return
						}
						const convertype = (text: any) => {
							if (!isNaN(text)) {
								return +text
							} else if (text == 'true' || text == 'false') {
								return text == 'true'
							} else if (text == 'null') {
								return null
							} else if (text == 'undefined') {
								return undefined
							} else {
								return text
							}
						}

						for (const p of temp) {
							const type = p.substring(0, 1)
							const pos = p.substring(1).split('|')
							if (type == 'P') {
								const ord = {
									user_id: convertype(pos[0]),
									id: convertype(pos[1]),
									open_close: convertype(pos[2]),
									price: convertype(pos[3]),
									exchange_pairs_id: convertype(pos[4]),
									long_short: convertype(pos[5]),
									amount: convertype(pos[6]),
									created_at: convertype(pos[7]),
								}
								orders.push(ord)
							} else if (type == 'O') {
								const ord = {
									id: convertype(pos[0]),
									status: convertype(pos[1]),
									limit_market: convertype(pos[2]),
									amount: convertype(pos[3]),
									f: convertype(pos[4]),
									order_price: convertype(pos[5]),
									trade_price: convertype(pos[6]),
									user_id: convertype(pos[7]),
									uuid: convertype(pos[8]),
									reduce_position: convertype(pos[9]),
									remain_amount: convertype(pos[10]),
									long_short: convertype(pos[11]),
									created_at: convertype(pos[12]),
									traded_at: convertype(pos[13]),
									exchange_pairs_id: convertype(pos[14]),
								}
								orders.push(ord)
							}
						}

						handlePosition(orders)
					} else if (getDATA?.substring(0, 2) == 'SS') {
						//스팟
						const pos = getDATA?.substring(2)?.split('|')
						updateData('SS', {
							balance: pos[0],
							lockbalance: pos[1],
						})
					} else if (getDATA?.substring(0, 2) == 'SG') {
						//글로벌
						const pos = getDATA?.substring(2)?.split('|')
						// handleGlobal(pos)
						mutatePosition('SG', {
							balance: pos[0],
							lockbalance: pos[1],
						})
					} else if (getDATA?.substring(0, 2) == 'SF') {
						//퓨처
						const pos = getDATA?.substring(2)?.split('|')
						updateData('SF', {
							balance: pos[0],
							lockbalance: pos[1],
						})
					} else if (getDATA?.substring(0, 2) == 'ST') {
						//개별 포지션
						const pos = +getDATA?.substring(2)
						mutatePosition('ST', pos)
					}
				} else if (getDATA?.substring(0, 2) == 'CO') {
					handleUsers(getDATA)
				} else {
					if (getDATA) {
						handleRefreshMessage(getDATA)
						handleStatusMessage(getDATA)
						handleOrderMessage(getDATA)
						handleMessage(getDATA)
					}
				}
			}

			const onmessage = async (data: any) => {
				let getDATA: any = null
				try {
					getDATA = await data?.data.text()
				} catch (e) {
					try {
						const reader = new FileReader()
						reader.onload = function () {
							getDATA = reader.result
							dataProcess(getDATA)
						}
						reader.readAsText(data?.data)

						return
					} catch (e) {
						console.log('onmessage error2', e, data?.data)
						return
					}
				}

				dataProcess(getDATA)
			}

			if (
				tradeWS == null ||
				tradeWS?.readyState === WebSocket.CLOSED ||
				tradeWS?.readyState === WebSocket.CLOSING
			) {
				try {
					console.log('START SOCKET', USER_SOCKET_URL)
					tradeWS = new WebSocket(USER_SOCKET_URL)
					tradeWS.binaryType = 'blob'

					tradeWS.onclose = () => {
						console.log('@@@@@@@@@@@tradeWS close@@@@@@@@@@@@@')
						tradeWS = null

						setTimeout(() => {
							mutate()
							joinGroup(token + '')
						}, 1000)
					}

					tradeWS.onerror = () => {
						console.log('tradeWS error')

						setTimeout(() => {
							mutate()
							joinGroup(token + '')
						}, 1000)
					}
					tradeWS.onopen = () => {
						console.log('tradeWS open')
						joinGroup(token + '')
					}
					// console.log('tradeWS', tradeWS)
				} catch (e) {
					console.log('tradeWS error', e)
				}
			}

			if (tradeWS != null) {
				tradeWS.onmessage = onmessage
			}

			return tradeWS
		},
		{
			revalidateIfStale: true,
			revalidateOnFocus: true,
			revalidateOnReconnect: true,
			dedupingInterval: 0,
			refreshInterval: 1000 * 30,
		}
	)

	if (logqueue?.length > 0 && token) {
		if (data != null) {
			if (data?.readyState === WebSocket.OPEN) {
				const execLog = popLog()
				if (execLog) {
					data.send(
						`{"type":"LOG", "token":"${token}", "data": ${JSON.stringify(
							execLog
						)}, "ip": "${myip}" }`
					)
				}
			}
		}
	}

	useEffect(() => {
		if (token) {
			joinGroup(token + '')
		}

		const setOnline = () => {
			change({ isOnline: true })
			joinGroup(token + '')
		}

		const setOffline = () => {
			change({ isOnline: false })
		}

		window.addEventListener('online', setOnline)
		window.addEventListener('offline', setOffline)

		return () => {
			window.removeEventListener('online', setOnline)
			window.removeEventListener('offline', setOffline)
		}
	}, [token])

	useEffect(() => {
		if (tradeWS?.readyState === WebSocket.OPEN) {
			change({ isOnline: true })
		} else {
		}
	}, [tradeWS, tradeWS?.readyState])

	

	return {
		isConnectd: data?.readyState === WebSocket.OPEN,
		data,
		updateLastTrade: () => {},
		mutate: () => {
			return mutate()
		},
		saveLog: (data: any) => {
			mutateLog(data)
		},
		reset() {
			console.log('@RESET')
		},
		joinGroup: () => {
			joinGroup(token + '')
			console.log('joinGroup', data?.readyState)
			return mutate()
		},
		leaveGroup: () => {
			if (tradeWS != null) {
				if (tradeWS && tradeWS.readyState === WebSocket.OPEN) {
					tradeWS.send(
						`{"type":"leave","group": "user", "token": "${token}"}`
					)
				}
			}
			return mutate()
		},
		send: (data: any) => {
			if (tradeWS != null) {
				tradeWS &&
					tradeWS.readyState === WebSocket.OPEN &&
					tradeWS.send(data)
			}
		},
		sendOrder: async (
			platform,
			ep_id,
			long_short,
			limit_market,
			price,
			amount,
			mit,
			token,
			mutateAfter = (result?) => {},
			closeModal = () => {},
			reduce_position = 0,
			mutateMessage = msg => {
				message.success(msg)
			}
		) => {
			if (amount % 1 !== 0) {
				message.error('수량은 소수점을 입력할 수 없습니다.')
				return
			}

			if (!amount) {
				message.error('수량을 입력해주세요')
				return
			}

			if (limit_market == 'L' && price <= 0) {
				message.error('가격을 입력해주세요')
				return
			}
			const orderParams = {
				type: 'SORDER',
				//token: token,
				data: `${ep_id}|M|${long_short}|C|1|${reduce_position}|${limit_market}|${price}|${amount}|${
					mit == 'MIT' ? 1 : 0
				}|0|0||${platform}`,
			}

			if (data && data?.readyState === WebSocket.OPEN) {
				data?.send(JSON.stringify(orderParams))
				mutateAfter()
			} else {
				message.error(
					`네트워크가 불안정합니다. 잠시 후 재시도해주세요.`
				)
				//sendOrder(platform, ep_id, long_short, limit_market, price, amount, mit, token, mutateAfter, closeModal, reduce_position, mutateMessage)
			}
		},
		requestStatus: (subtype?, force?) => {
			if (!send) {
				send = true
				if (tradeWS != null) {
					if (tradeWS && tradeWS.readyState === WebSocket.OPEN) {
						if (subtype) {
							tradeWS.send(
								`{"type":"STATUS", "userid":"${userid}", "subtype":"${subtype}"}`
							)
						} else {
							const now = new Date().getTime()

							if (
								lastrefresh == null ||
								now - lastrefresh > 5000 ||
								force
							) {
								lastrefresh = now
								tradeWS.send(
									`{"type":"REFRESH", "userid":"${userid}"} `
								)
							}
						}
					}
				}
				setTimeout(() => {
					send = false
				}, 100)
			}
		},
	}
}
