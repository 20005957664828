import React, { useEffect, useState } from 'react'
import { CButton, CInput } from 'app/components/Common'
import { useTranslation } from 'react-i18next'
import { Text } from 'styles/reactnative'
import { OrderValueInputSection } from '../styles'
import { useTradeStore } from '../../../../../../../hooks/trade/tradeStore'
import { useLastPrice } from '../../../../../../../hooks/trade/lastprice'
import { ThemeContext } from 'theme/ThemeProvider'
import { COLORS, FONTS } from '../../../../../../../constants'
import BigNumber from 'bignumber.js'
import { useOrderStore } from '../../../../../../../hooks/trade/orderStore'
import { trade } from '../../../../../../../constants/UIData/HEADER'
import { Button, Space } from 'antd'
import { IconMinus, IconPlus } from '@tabler/icons-react'

const OrderPriceInput = () => {
	const { textw, onSecondary, descBox, point } =
		React.useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()

	const { data: tradeStore } = useTradeStore()
	const { data: orderStore, change: mutateOrderStore } = useOrderStore()
	const { data: lastPrice } = useLastPrice(tradeStore?.ep_id)
	const [orderPrice, setOrderPrice] = useState(orderStore?.price || '')

	useEffect(() => {
		if (isNaN(orderPrice)) {
			return
		}

		mutateOrderStore({ price: orderPrice })
	}, [orderPrice])

	useEffect(() => {
		setOrderPrice(lastPrice?.[0]?.price)
	}, [tradeStore?.ep_id])

	useEffect(() => {
		setOrderPrice(orderStore?.price || '')
	}, [orderStore?.price])

	return (
		<OrderValueInputSection>
			<Text
				style={{
					color: textw,
					...FONTS.h7,
				}}>
				주문가격
			</Text>
			<Space style={{}}>
				<Button
					size="small"
					icon={<IconMinus />}
					onClick={() => {
						if (!orderPrice || isNaN(orderPrice)) {
							return
						}

						const dpLimit = tradeStore?.dp || 3
						const unit = tradeStore?.unit || 1

						let value = BigNumber(orderPrice)
							.minus(unit)
							.toFixed(dpLimit)

						if (+value < 0) {
							value = '0'
						}

						setOrderPrice(value)
					}}
					style={{
						color: point,
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: 34,
					}}
				/>
				<CInput
					placeholder={''}
					value={orderPrice}
					type={'text'}
					// 소수점 {tradeStore.dp }자리수 까지 제한 마이너스는 금지
					onChange={(e: any) => {
						const dpLimit = tradeStore?.dp || 3 // tradeStore.dp 값이 존재하면 해당 값을, 없으면 기본값으로 3을 사용합니다.

						let value = e.target.value?.replace(/[^0-9.]/g, '') // 숫자와 소수점 이외의 문자 제거

						// 소수점 이하 자릿수 제한
						const decimalIndex = value.indexOf('.')
						if (decimalIndex !== -1) {
							const integerPart = value.slice(0, decimalIndex)
							let decimalPart = value.slice(decimalIndex + 1)
							decimalPart = decimalPart.slice(0, dpLimit) // dpLimit 변수에 따라 소수점 이하 자릿수 제한
							value = `${integerPart}.${decimalPart}`
						}

						setOrderPrice(value)
					}}
					vStyle={{ padding: 0, flexDirection: 'row', width: '100%' }}
					style={{
						background: onSecondary,
						color: '#2B2E33',
						border: 'none',
						flex: 1,
						padding: 0,
						fontSize: 15,
						width: '100%',
						minHeight: 34,
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center',
						marginTop: 10,
					}}
				/>
				<Button
					size="small"
					icon={<IconPlus />}
					onClick={() => {
						if (!orderPrice || isNaN(orderPrice)) {
							return
						}

						const dpLimit = tradeStore?.dp || 3
						const unit = tradeStore?.unit || 1

						let value = BigNumber(orderPrice)
							.plus(unit)
							.toFixed(dpLimit)

						if (+value < 0) {
							value = '0'
						}

						setOrderPrice(value)
					}}
					style={{
						minHeight: 34,
						justifyContent: 'center',
						alignItems: 'center',
						color: point,
					}}
				/>
				<Button
					size="small"
					onClick={() => {
						setOrderPrice(
							BigNumber(lastPrice?.[0]?.price).toFixed(
								tradeStore?.dp || 3
							)
						)
					}}
					style={{
						color: point,
						whiteSpace: 'nowrap',
						fontSize: 12,
						letterSpacing: -0.5,
						minHeight: 34,
					}}>
					현재가
				</Button>
			</Space>
		</OrderValueInputSection>
	)
}

export default OrderPriceInput
