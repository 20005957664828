import { useMemo } from 'react'
import useSWR from 'swr'
const KEY = '@CONFIRM_STORE'

let swrData: any = null

export function useConfirmStore() {
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (!swrData) return swrData

			return swrData
		},
		{
			dedupingInterval: 1000,
		}
	)


	return {
		data,
		mutate: (value: any) => {
			return mutate(data => {

				swrData = { ...value }
				return swrData
			})
		},
		reset: () => {
			swrData = null
			return mutate({})
		},

		change: (value: any) => {
			return mutate((data: any) => {
				const temp = { ...data, ...value }
				swrData = temp
				return temp
			})
		},
	}
}
