import styled from 'styled-components'
import bgDark from 'assets/images/bg_dark.png'
import bgLight from 'assets/images/bg_light.png'

export const MobileTradeModalView = styled.div<{ isDarkMode?: any }>`
	display: flex;
	flex-direction: column;
`
export const ContentContainer = styled.div<{ isDarkMode?: any }>`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: top;
	position: relative;
	padding: 0;
	flex-grow: 1;
	background-color: ${props => (props.isDarkMode ? '#FDFDFD' : '#0D0E0F')};
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
`
export const ContentWrapper = styled.div`
	width: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	padding: 0;
`
export const ContentSplit = styled.div`
	width: 100%;
	height: 100%;
	font-size: 1.4rem;
	margin-top: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
`

export const ContentLeft = styled.div`
	width: 80%;
	height: 60%;
	display: flex;
	flex-direction: row;
	@media (max-width: 1200px) {
		width: 100%;
	}
	@media (max-width: 900px) {
		flex-direction: column;
		height: 70%;
	}
`
export const ContentCenter = styled.div`
	width: 80%;
	height: 40%;
	@media (max-width: 1200px) {
		width: 100%;
		height: 40%;
	}
	@media (max-width: 900px) {
		height: 30%;
	}
`
export const ContentRight = styled.div`
	width: 20%;
	height: 100%;
	@media (max-width: 1200px) {
		display: none;
	}
	@media (max-width: 900px) {
		height: 30%;
	}
`

export const TradeRealTimeWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@media (max-width: 900px) {
		height: 100%;
		margin-bottom: 10px;
	}
`
