import React, { useContext } from 'react'
// import { Text } from 'styles/reactnative'
import { StyledButton } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'

interface IProps {
	title?: any
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
	style?: any
	border?: { width: string; color?: string; style?: string }
	backgroundHover?: any
	textHover?: any
	icon?: any
	iconLeft?: boolean
	size?: number
	textStyle?: React.CSSProperties
	lefticon?: any
	id?: string
	hightlight?: string
}
const CButton = ({
	title,
	icon,
	onClick,
	style,
	border,
	backgroundHover,
	textHover,
	iconLeft,
	size,
	textStyle,
	lefticon,
	id,
	hightlight
}: IProps) => {

	const { theme } = useContext(ThemeContext)
	const [touched, setTouched] = React.useState(false)

	const { data: modalStore } = useTradeModalStore()


	const touchedstyle = !modalStore?.btnEffect ? {} :  touched ? { opacity: 0.8 } : {}

	return (
		<StyledButton
			size={size}
			id={id}
			className={'customBtn'}
			type="button"
			backgroundHover={backgroundHover}
			textHover={textHover}
			style={{
				borderStyle:
					border?.style !== undefined ? border.style : 'solid',
				borderWidth: border?.width !== undefined ? border.width : 0,
				borderColor:
					border?.color !== undefined ? border.color : 'transparent',
				display: 'flex',
				flexDirection: icon ? 'row' : 'column',
				alignItems: 'center',
				alignSelf: 'center',
				justifyContent: 'center',
				...style,
				...touchedstyle,

			}}
			onTouchStart={() => { setTouched(true) }}
			onTouchEnd={() => { setTimeout(() => { setTouched(false) }, 100) }}

			onClick={onClick}>
			{iconLeft && icon ? icon : null}
			{/* <Text style={{ ...textStyle }}></Text> */}
			{lefticon ? lefticon : null}
			{title}
			{!iconLeft && icon ? icon : null}
		</StyledButton>
	)
}

export default React.memo(CButton)
