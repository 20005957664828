import useSWR from 'swr'
import API from '../../../utils/api'
import { TRADELIST } from '../../constants/APIKEYS'
import { useToken } from '../auth/token'

let swrData: any = []
let swrData2: any = []
let mutateList: any = []

export function useTradeList(ep_id: number) {
	const { data: token } = useToken()
	let KEY: any = TRADELIST + '?ep_id=' + ep_id

	if (!ep_id || !token) KEY = null

	const { data, mutate } = useSWR<any>(KEY, async () => {
		if (swrData[ep_id] != undefined) return swrData[ep_id] || []

		const result = await API.get(KEY, token + '', {}, false)
		swrData[ep_id] = result.result || []

		return swrData[ep_id]
	})

	swrData2[ep_id] = data
	if (mutateList[ep_id] == null) mutateList[ep_id] = mutate

	return {
		data,
		getData: (epid?: any) => {
			if (swrData2[epid ? epid : ep_id])
				return swrData2[epid ? epid : ep_id]
			else return null
		},
		mutate: (value?: any, epid?: any) => {
			if (value !== undefined) swrData[epid ? epid : ep_id] = value
			if (mutateList[epid ? epid : ep_id] == null) {
				return mutate()
			} else {
				return mutateList[epid ? epid : ep_id]()
			}
		},
		reset: (epid?: any) => {
			if (mutateList[epid ? epid : ep_id] != null) {
				mutateList[epid ? epid : ep_id]([])
			}else{
				mutate([])
			}
		},
		pushmutate: (value?: any, epid?: any) => {
			try {
				if (mutateList[epid ? epid : ep_id] == null) {
					return mutate((prev: any) => {
						if(!prev) prev = []

						const temp = [...value, ...prev].slice(0, 100)

						if (value !== undefined)
							swrData[epid ? epid : ep_id] = temp
						return temp
					})
				} else {
					return mutateList[epid ? epid : ep_id]((prev: any) => {
						if(!prev) prev = []
						const temp = [...value, ...prev].slice(0, 100)

						if (value !== undefined)
							swrData[epid ? epid : ep_id] = temp
						return temp
					})
				}
			} catch (e) {
				console.log('pushmutate', e)
			}
		},
	}
}
