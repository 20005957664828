import { COLORS } from 'app/constants'
import React from 'react'
import Switch from 'react-switch'

const CSwitch = ({ checked, setChecked, action, style = {} }: any) => {
	return (
		<Switch
			checked={checked}
			onChange={() => {
				if (setChecked) {
					setChecked(!checked)
				}
				if (action) {
					action(checked)
				}
			}}
			onColor={COLORS.primary}
			onHandleColor={COLORS.lightGray1}
			offHandleColor={COLORS.lightGray1}
			handleDiameter={15}
			uncheckedIcon={false}
			checkedIcon={false}
			height={10}
			width={25}
			className="react-switch"
			id="material-switch"
		/>
	)
}

export default React.memo(CSwitch)
