import { IconCircleArrowUp } from '@tabler/icons-react'
import { Button, message, Modal, Spin } from 'antd'
import { useLogQueue } from 'app/hooks/trade/logQueue'
import useWindowFocus from 'app/hooks/trade/windowFocus'
import axios from 'axios'
import React, { useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { MContentContainer, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { Text, View } from '../../../styles/reactnative'
import { DEFAULT_EP_ID } from '../../constants/DEFAULT'
import { useWindowDimensions } from '../../hooks'
import { useExchangeSetting } from '../../hooks/trade/exchangeSetting'
import { useIndexPrice } from '../../hooks/trade/indexprice'
import { useLastPrice } from '../../hooks/trade/lastprice'
import { useTradeModalStore } from '../../hooks/trade/modalStore'
import { useTradeList } from '../../hooks/trade/tradeList'
import { useTradeStore } from '../../hooks/trade/tradeStore'
import VersionChecker from '../../organisms/Header/VersionChecker'
import MTradeModal from '../MTradeModal'
import TradeM from './TradeM'
import TradeMobileTop from './TradeMobileTop'
import { useTradeSocket } from 'app/hooks/trade/tradeSocket'

// Soe Thet
const TradeMobile = () => {
	const { t } = useTranslation()
	const { width } = useWindowDimensions()

	const [todaynoshow, setTodaynoshow] = React.useState(false)

	const [isblur, setIsblur] = React.useState(false)
	const [blurStart, setBlurStart] = React.useState<any>(null)

	const [noprice, setNoprice] = React.useState(true)
	const { isDarkMode, theme, isMobile } = React.useContext(ThemeContext)

	const realDevice = /iphone|ipad|ipod|android|mobile/i.test(
		navigator.userAgent?.toLowerCase()
	)
	const location = useLocation()

	let ep_id = Number(location.pathname?.split('/')[2])
	const { data: exchangeSetting, mutate } = useExchangeSetting(ep_id)
	const { data: tradeStore, mutate: mutateTradeStore } = useTradeStore({
		ep_id: ep_id,
		...exchangeSetting,
	})

	useEffect(() => {
		//오늘은 다시 보시않기 24시간체크
		if (localStorage.getItem('todaynoshow')) {
			let todaynoshow = localStorage.getItem('todaynoshow')
			let now = new Date().getTime()
			if (now - Number(todaynoshow) > 86400000) {
				localStorage.removeItem('todaynoshow')
			} else {
				setTodaynoshow(true)
			}
		}
	}, [])

	useEffect(() => {
		message.config({
			duration: 1,
			maxCount: 3,
		})
	}, [])



	const { reset } = useTradeSocket()
	const { myip, setIp, mutate: mutateLog } = useLogQueue()

	if (tradeStore?.coin_type === undefined) {
		mutateTradeStore({ ...tradeStore, coin_type: tradeStore?.symbol })
	}

	const { mutate: mutateIndexPrice } = useIndexPrice(
		tradeStore?.ep_id || DEFAULT_EP_ID
	)
	const { mutate: mutateLastPrice } = useLastPrice(
		tradeStore?.ep_id || DEFAULT_EP_ID
	)
	const { mutate: mutateTradeList } = useTradeList(
		tradeStore?.ep_id || DEFAULT_EP_ID
	)

	const { data: modalStore } = useTradeModalStore()

	useEffect(() => {
		if (!exchangeSetting?.pairs) {
			return
		}
		mutate(undefined)
		mutateTradeStore({
			ep_id: ep_id,
			...exchangeSetting,
		})
		mutateIndexPrice(null)
		mutateLastPrice(null)
		mutateTradeList([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ep_id, exchangeSetting])

	let isTradeChart = tradeStore?.isTradeChart
	if (isTradeChart === undefined) isTradeChart = true

	useEffect(() => {
		reset()
		if (!myip) {
			axios
				.get('https://api.ip.pe.kr/json/', {
					timeout: 3000,
				})
				.then(res => {
					setIp(res.data.ip)
					setTimeout(() => {
						mutateLog({
							log: 'HM001',
							data: {
								where: 'TradeFocus',
								device: 'MTS',
								ep_id: ep_id,
								isMobile: isMobile,
							},
						})
					}, 500)
				})
				.catch(e => {
					axios
						.get('https://geolocation-db.com/json/', {
							timeout: 2000,
						})
						.then(res => {
							setIp(res.data.IPv4)

							setTimeout(() => {
								mutateLog({
									log: 'HM001',
									data: {
										where: 'TradeFocus',
										device: 'MTS',
										ep_id: ep_id,
										isMobile: isMobile,
									},
								})
							}, 500)
						})
						.catch(e => {})
				})
		} else {
			mutateLog({
				log: 'HM001',
				data: {
					where: 'TradeFocus',
					device: 'MTS',
					ep_id: ep_id,
					isMobile: isMobile,
				},
			})
		}
	}, [])

	useEffect(() => {
		if (!isblur) {
			if (!blurStart) {
				return
			}

			const diff = new Date().getTime() - blurStart

			if (Math.abs(diff) >= 2000) {
				mutateLog({
					log: 'FR004',
					data: {
						isblur: isblur,
						ep_id: ep_id,
						s: blurStart,
						e: new Date().getTime(),
					},
				})
				setBlurStart(null)
			}
		}
	}, [isblur, blurStart])

	/** 화면으로 복귀했을때 **/
	const onWindowFocus = useCallback(() => {
		mutateIndexPrice()

		setTimeout(() => {
			setIsblur(false)
		}, 500)
	}, [mutateIndexPrice])

	const onWindowBlur = useCallback(() => {
		setIsblur(true)
		setBlurStart(new Date().getTime())
	}, [])

	useWindowFocus(onWindowFocus, onWindowBlur)

	return (
		<MContentContainer
			isDarkMode={isDarkMode}
			onKeyDown={e => {
				if (e.code == 'Space' || e.key == 'Enter' || e.key == 'Tab') {
					mutateLog({
						log: 'FR021',
						data: {
							K: e.code,
						},
					})
				}
			}}
			onClick={e => {
				const X = e.clientX
				const Y = e.clientY
				const isTrusted = e.isTrusted

				console.log(e.target)

				if (X == 0 && Y == 0) {
					return
				}

				// mutateLog({
				// 	log: 'FR020',
				// 	data: {
				// 		X, Y, T: isTrusted ? 1 : 0
				// 	},
				// })
			}}>
			<Helmet>
				<title>
					{exchangeSetting?.pairs + ' - ' + t('titleTrade')}
				</title>
				<meta
					name="viewport"
					content={
						!isMobile && realDevice
							? 'width=1600, initial-scale=1, maximum-scale=1, user-scalable=no'
							: width < 350
							? 'width=350, initial-scale=1, maximum-scale=1, user-scalable=no'
							: 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, minimal-ui'
					}
				/>
				<meta name="HandheldFriendly" content="true" />
				<meta name="google" content="notranslate" />
			</Helmet>
			<VersionChecker />

			{!todaynoshow && isblur && (
				<Modal
					open={isblur}
					closeIcon={null}
					mask={true}
					okText={'새로고침'}
					bodyStyle={{
						justifyContent: 'flex-start',
						alignItems: 'flex-start',
					}}
					onCancel={() => {
						setIsblur(false)
					}}
					onOk={() => {
						window.location.reload()
					}}
					cancelText="닫기"
					footer={
						<View
							style={{
								justifyContent: 'center',
								alignItems: 'center',
								width: '100%',
								flexDirection: 'row',
							}}>
							<Button
								onClick={() => {
									setIsblur(false)
								}}>
								닫기
							</Button>
							<Button
								type="primary"
								onClick={() => {
									window.location.reload()
								}}>
								새로고침
							</Button>
							<Button
								onClick={() => {
									localStorage.setItem(
										'todaynoshow',
										new Date().getTime() + ''
									)
									setTodaynoshow(true)
								}}>
								오늘은 다시보지 않기
							</Button>
						</View>
					}
					closable={false}>
					<Text
						style={{
							textAlign: 'left',
							width: '100%',
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
						}}>
						모바일웹 재실행중입니다.
					</Text>
					<Text
						style={{
							textAlign: 'left',
							width: '100%',
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
						}}>
						시세데이터 갱신 중이오니 잠시만 기다려주세요.
					</Text>
					<Text
						style={{
							color: 'red',
							fontWeight: 'bold',
							width: '100%',
							marginTop: 20,
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
						}}>
						평가손익이 정확하지 않을수 있으니
					</Text>
					<Text
						style={{
							color: 'red',
							fontWeight: 'bold',
							width: '100%',
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
						}}>
						주의하시기 바랍니다.
					</Text>
				</Modal>
			)}

			{!todaynoshow && modalStore?.noprice && (
				<Modal
					open={noprice}
					closeIcon={null}
					mask={false}
					okText={'새로고침'}
					bodyStyle={{
						justifyContent: 'flex-start',
						alignItems: 'flex-start',
					}}
					onCancel={() => {
						setNoprice(false)
					}}
					onOk={() => {
						window.location.reload()
					}}
					cancelText="닫기"
					footer={
						<View
							style={{
								justifyContent: 'center',
								alignItems: 'center',
								width: '100%',
								flexDirection: 'row',
							}}>
							<Button
								onClick={() => {
									setNoprice(false)
								}}>
								닫기
							</Button>
							<Button
								type="primary"
								onClick={() => {
									window.location.reload()
								}}>
								새로고침
							</Button>
							<Button
								onClick={() => {
									localStorage.setItem(
										'todaynoshow',
										new Date().getTime() + ''
									)
									setTodaynoshow(true)
								}}>
								오늘은 다시보지 않기
							</Button>
						</View>
					}
					closable={false}>
					<Text
						style={{
							textAlign: 'left',
							width: '100%',
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
						}}>
						현재 네트워크가 원할하지 않아,
					</Text>
					<Text
						style={{
							textAlign: 'left',
							width: '100%',
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
						}}>
						시세데이터 수신이 원할하지 않습니다.
					</Text>
					<Text
						style={{
							color: 'red',
							fontWeight: 'bold',
							width: '100%',
							marginTop: 20,
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
						}}>
						평가손익이 정확하지 않을수 있으니
					</Text>
					<Text
						style={{
							color: 'red',
							fontWeight: 'bold',
							width: '100%',
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
						}}>
						주의하시기 바랍니다.
					</Text>
				</Modal>
			)}

			{!todaynoshow && !modalStore?.isOnline && (
				<Modal
					open={true}
					footer={null}
					closeIcon={null}
					closable={false}>
					<Text style={{ textAlign: 'center' }}>
						<Spin size="default" />
						서버에 연결중입니다. 잠시만 기다려주세요.
					</Text>
					<Button
						style={{}}
						onClick={() => {
							window.location.reload()
						}}>
						새로고침
					</Button>
					<Button
						onClick={() => {
							localStorage.setItem(
								'todaynoshow',
								new Date().getTime() + ''
							)
							setTodaynoshow(true)
						}}>
						오늘은 다시보지 않기
					</Button>
				</Modal>
			)}

			<View
				style={{
					position: 'fixed',
					zIndex: 1001,
					bottom: 60,
					width: 50,
					height: 50,
					right: 5,
				}}>
				<Button
					size="small"
					type={undefined}
					style={{
						background: 'rgba(0,0,0,0.5)',
						padding: 0,
						borderRadius: 50,
						justifyContent: 'center',
						alignItems: 'center',
						width: 25,
						height: 25,
					}}
					icon={<IconCircleArrowUp color={'#cdcdcd'} />}
					onClick={() => {
						window.scrollTo(0, 0)
					}}></Button>
			</View>
			<MView style={{ marginTop: 0, width: '100%', height: '100%' }}>
				<TradeMobileTop />
				<TradeM isblur={isblur} />
			</MView>
			<MTradeModal />
		</MContentContainer>
	)
}

export default React.memo(TradeMobile)
