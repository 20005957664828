import { IconChevronRight } from '@tabler/icons-react'
import { goTo } from 'app/hooks/navigation'
import { CBottomSheet } from 'app/mobile/components'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'

const SecurityLists = ({
	title,
	open,
	setOpen,
	item,
	setItem,
	BottomComp,
	navigate,
	content,
	maxHeight = 500,
}: any) => {
	const navigation = useHistory()
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<MView
			onClick={() => {
				setOpen && setOpen(!open)
				navigate && goTo(navigation, navigate)
			}}
			style={{
				width: '100%',
				flexDirection: 'row',
				paddingBlock: 10,
				cursor: 'pointer',
			}}>
			<MView
				style={{
					flex: 1,
					flexDirection: 'row',
					alignItems: 'center',
				}}>
				<MText style={{ fontSize: 12, color: textw, fontWeight: 300 }}>
					{t(title)}
				</MText>
			</MView>
			<MView style={{ flexDirection: 'row', alignItems: 'center' }}>
				{content ? <MText>{content}</MText> : null}
				<IconChevronRight size={16} color={textw} />
			</MView>
			<CBottomSheet
				open={open}
				setOpen={setOpen}
				BottomContainer={BottomComp}
				item={item}
				setItem={setItem}
				maxHeight={maxHeight}
			/>
		</MView>
	)
}

export default SecurityLists
