/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Spin } from 'antd'
import React, { useContext, useRef } from 'react'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { ChartContainerWrapper } from './styles'
import Chart from './Chart'

declare global {
	interface Window {
		Datafeeds: any
	}
}
let orderlines: any = []

const ChartContainer = ({
	tab,
	isDragging,
	mobile = false,
	split = false,
	isblur = false,
}) => {
	const ref = useRef<any>(null)
	const tvWidgetRef = useRef<any>(null)

	const [load, setLoading] = React.useState(false)

	const { bgColor, raise, fail } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)

	return (
		<ChartContainerWrapper>
			{load && (
				<View style={{ position: 'absolute', top: '50%', left: '50%' }}>
					<Spin />
				</View>
			)}
			{!isblur && (
				<Chart
					tab={tab}
					mobile={mobile}
					setLoading={setLoading}
					tref={ref}
					tvWidgetRef={tvWidgetRef}
				/>
			)}
			{!mobile && (
				<Space
					direction="horizontal"
					style={{
						position: 'absolute',
						alignItems: 'center',
						alignContent: 'center',
						justifyContent: 'flex-end',
						whiteSpace: 'nowrap',
						zIndex: 1,
						color: !isDarkMode ? '#ececec' : '#000',
						...(split
							? {
									right: 10,
									top: 8,
							  }
							: {
									left: 240,
									top: 37,
							  }),
					}}></Space>
			)}

			<View
				ref={ref}
				id={'tv_chart_container'}
				className={'TVChartContainer'}
				style={{
					width: '100%',
					height: '100%',
					background: bgColor,
				}}></View>
			{isDragging && (
				<View
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(255,255,255,0.1)',
						zIndex: 999,
					}}></View>
			)}
		</ChartContainerWrapper>
	)
}

export default ChartContainer
