import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CInput } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import { TransferAmountWrapper } from '../styles'
import TransferAll from './TransferAll'
import TransferableAmount from './TransferableAmount'
import { ThemeContext } from 'theme/ThemeProvider'
import { Input, InputNumber } from 'antd'

const TransferAmount = ({ balance, amount, setAmount, coinType }) => {
	const { t } = useTranslation()
	const { isDarkMode } = useContext(ThemeContext)
	const { textw, gray } = useContext(ThemeContext).theme.COLORS
	return (
		<TransferAmountWrapper>
			<View
				style={{
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					alignSelf: 'flex-start',
				}}>
				<Text>{t('amount')}</Text>
			</View>
			<InputNumber
				value={amount}
				formatter={value => {
					const parts = `${value}`.split('.');
					const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
					const decimalPart = parts[1] ? '.' + parts[1].replace(/,/g, '') : '';
					return integerPart + decimalPart;
				}}
				parser={value =>
					value!
						.replace(/\$\s?|(,*)/g, '')
						?.replace(/[^\d.]/g, '')
				}
				rootClassName={'ovlist ' + (!isDarkMode ? 'dark' : 'light')}
				step={10000}
				onChange={e => setAmount(e)}
				style={{
					flexDirection: 'row',
					background: gray,
					marginTop: 10,
					width: '100%',
					justifyContent: 'space-between',
					padding: 8,
					borderRadius: 5,
					paddingRight: 120,
					marginBottom: 10,
					cursor: 'pointer',
					border: 'none',
					color: textw,
				}}
			/>

			<TransferAll
				balance={balance}
				setAmount={setAmount}
				coinType={coinType}
			/>
			<TransferableAmount balance={balance} coinType={coinType} />
		</TransferAmountWrapper>
	)
}

export default TransferAmount
