import React from 'react'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View } from 'styles/reactnative'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'

const AssetsBody = () => {
	const { t } = useTranslation()
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()
	return (
		<View
			style={{
				alignItems: 'flex-start',
				marginTop: 20,
				justifyContent: 'space-between',
				width: '100%',
			}}>
			<View
				style={{
					flexDirection: 'row',
					marginTop: 10,
					justifyContent: 'space-between',
					width: '100%',
					paddingInline: 20,
				}}>
				<CButton
					title={t('deposit')}
					onClick={() => {
						mutateModalStore({
							...modalStore,
							gDeposit: true,
							gDepositType: 'D',
						})
					}}
					// icon={<IconArrowBarToDown size={18} color={textsilver} />}
					textStyle={{ color: COLORS.black }}
					style={{
						borderWidth: 1,
						borderColor: COLORS.secondary,
						color: COLORS.secondary,
						paddingBlock: 10,
						paddingInline: 25,
					}}
					iconLeft={true}
					backgroundHover={COLORS.secondary}
					textHover={COLORS.white}
				/>
				<CButton
					title={t('withdraw')}
					textStyle={{ color: COLORS.black }}
					onClick={() => {
						mutateModalStore({
							...modalStore,
							gDeposit: true,
							gDepositType: 'W',
						})
					}}
					// icon={<IconArrowBarToDown size={18} />}
					style={{
						borderWidth: 1,
						background: COLORS.secondary,
						color: COLORS.white,
						paddingBlock: 10,
						paddingInline: 20,
					}}
					iconLeft={true}
					textHover={COLORS.white}
				/>
			</View>
		</View>
	)
}

export default React.memo(AssetsBody)
