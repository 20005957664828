import React, { useEffect, useState } from 'react'

import { Space, Switch } from 'antd'
import { Text } from '../../../styles/reactnative'
import KrwPrice from '../../components/KrwPrice'
import TableAPI from '../../components/TableAPI'
import UserTag from '../../components/UserTag'
import { useCoinGlobal } from '../../hooks/stateStore/coinGlobal'
import { useExchangeStore } from '../../hooks/trade/exchangeStore'
import Contracts2 from './Contracts2'
import MarkPrice from './MarkPrice'
import Unrealizedplpercent from './Unrealizedplpercent'

const PartnerRealtimePosition = ({
	modalshow = false,
	setModalshow = e => {},
}) => {
	const [modal, setModal] = React.useState<any>(false)
	const [selectedData, setSelectedData] = React.useState<any>(undefined)

	const { data: coinglobal } = useCoinGlobal()

	const { EPs } = useExchangeStore()

	const [exceptType1, setExceptType1] = useState(true)
	const [exceptType2, setExceptType2] = useState(true)
	const [exceptType3, setExceptType3] = useState(true)
	const [exceptType4, setExceptType4] = useState(true)
	const [exceptType5, setExceptType5] = useState(true)

	const [exceptType, setExceptType] = useState('')

	useEffect(() => {
		let exceptType = ''
		if (exceptType1) exceptType += `${exceptType ? ',' : ''}-2`
		if (exceptType2) exceptType += `${exceptType ? ',' : ''}-1`
		if (exceptType3) exceptType += `${exceptType ? ',' : ''}-9`
		if (exceptType4) exceptType += `${exceptType ? ',' : ''}6`
		if (exceptType5) exceptType += `${exceptType ? ',' : ''}7`
		setExceptType(exceptType)
	}, [exceptType1, exceptType2, exceptType3, exceptType4, exceptType5])

	useEffect(() => {
		setModalshow(false)
		if (modalshow) {
			setTimeout(() => {
				setModal(true)
				setSelectedData(null)
			}, 100)
		} else {
		}
	}, [modalshow])

	return (
		<TableAPI
			title={'활성종목'}
			className={'plist nopadding'}
			coinglobal={coinglobal}
			autorefresh={7000}
			loading={false}
			extra={
				<Space
					style={{
						whiteSpace: 'nowrap',
						alignContent: 'center',
						justifyItems: 'center',
						justifyContent: 'center',
					}}>
					제외
					<Space
						size={'small'}
						style={{
							border: '1px solid #ccc',
							padding: 5,
							borderRadius: 5,
						}}>
						<Space.Compact
							size="small"
							style={{
								alignItems: 'center',
								whiteSpace: 'nowrap',
								fontSize: 10,
							}}>
							거절
							<Switch
								onChange={e => setExceptType1(e)}
								defaultChecked={exceptType1}
							/>
						</Space.Compact>
						<Space.Compact
							size="small"
							style={{
								alignItems: 'center',
								whiteSpace: 'nowrap',
								fontSize: 10,
							}}>
							대기
							<Switch
								onChange={e => setExceptType2(e)}
								defaultChecked={exceptType2}
							/>
						</Space.Compact>
						<Space.Compact
							size="small"
							style={{
								alignItems: 'center',
								whiteSpace: 'nowrap',
								fontSize: 10,
							}}>
							블랙
							<Switch
								onChange={e => setExceptType3(e)}
								defaultChecked={exceptType3}
							/>
						</Space.Compact>
						<Space.Compact
							size="small"
							style={{
								alignItems: 'center',
								whiteSpace: 'nowrap',
								fontSize: 10,
							}}>
							모의
							<Switch
								onChange={e => setExceptType4(e)}
								defaultChecked={exceptType4}
							/>
						</Space.Compact>

						<Space.Compact
							size="small"
							style={{
								alignItems: 'center',
								whiteSpace: 'nowrap',
								fontSize: 10,
							}}>
							페이크
							<Switch
								onChange={e => setExceptType5(e)}
								defaultChecked={exceptType5}
							/>
						</Space.Compact>
					</Space>
				</Space>
			}
			columns={() => [
				{
					title: '번호',
					dataIndex: 'id',
					width: 80,
					align: 'center',
					render: (text, data) => <Text>{text}</Text>,
				},
				{
					title: '그룹',
					dataIndex: 'referral_code',
					align: 'center',
				},
				{
					title: '등급',
					dataIndex: 'level',
					align: 'center',
					render: (text, data) => {
						return (
							<UserTag level={text} popup={false} noname={true} />
						)
					},
				},
				{
					title: '회원',
					align: 'center',
					dataIndex: 'user_id',
					sorter: (a: any, b: any) => b.user_id - b.user_id,
					render: (text, data) => (
						<UserTag
							userid={data?.user_id}
							email={data?.email}
							phone={data?.phone}
							nickname={data?.fullname || data?.nickname}
							setSelectedData={() => {
								setModal(true)
								setSelectedData(null)
								setTimeout(() => {
									setSelectedData(data?.user_id)
								}, 100)
							}}
						/>
					),
				},
				{
					title: '종목',
					align: 'center',
					dataIndex: 'contracts',
					render: (text, data) => (
						<Contracts2 data={data} small={true} />
					),
				},
				{
					title: '수량',
					dataIndex: 'amount',
					align: 'center',
					sorter: (a, b) => a.amount - b.amount,
					render: (text, data) => <Text>{text}</Text>,
				},
				{
					title: '평단가',
					align: 'center',
					dataIndex: 'price',
					sorter: (a, b) => a.price - b.price,
					render: (text, data) => {
						const EP = EPs.find(
							item => item.id === data?.exchange_pairs_id
						)
						return (
							<KrwPrice
								price={text}
								ep_id={data?.exchange_pairs_id}
								dp={EP?.dp}
								color={false}
								theme={false}
								krw={false}
								center={true}
							/>
						)
					},
				},
				{
					title: '현재가',
					align: 'center',
					dataIndex: 'price',
					render: (text, data) => (
						<MarkPrice data={data} partner={true} />
					),
				},
				{
					title: '증거금',
					align: 'center',
					dataIndex: 'margin',
					sorter: (a, b) => a.margin - b.margin,
					render: (text, data) => (
						<KrwPrice price={text} theme={false} color={false} />
					),
				},
				{
					title: '평가손익',
					align: 'center',
					dataIndex: 'unrealizedplpercent',
					render: (text, data) => (
						<Unrealizedplpercent data={data} color={true} />
					),
				},
				{
					title: '담보금',
					align: 'center',
					dataIndex: 'balance',
					sorter: (a, b) => a.balance - b.balance,
					render: (text, data) => (
						<KrwPrice price={text} theme={false} color={false} />
					),
				},
				{
					title: '오버나잇',
					align: 'center',
					dataIndex: 'realize_dpl',
					render: (text, data) => {
						return <Text>-</Text>
					},
				},
			]}
			usermodal={true}
			height={'default'}
			apikey={`/group/history/positions?ep_id=*
				${(exceptType && `&excepttype=${exceptType}`) || ''}
				&t=`}
		/>
	)
}

export default React.memo(PartnerRealtimePosition)
