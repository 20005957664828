import { IconAlertCircle } from '@tabler/icons-react'
import { Select } from 'antd'
import { useWindowDimensions } from 'app/hooks'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from '../../../theme/ThemeProvider'
import CRenderEmptyOrderBook from '../../components/CRenderEmpty/orderbook'
import { CButton } from '../../components/Common'
import { useExchangeStore } from '../../hooks/trade/exchangeStore'
import { useTradeModalStore } from '../../hooks/trade/modalStore'
import { useTradeStore } from '../../hooks/trade/tradeStore'
import DarkModeToggle from '../../organisms/Header/DarkModeToggle'
import TradePositionsTable from '../../organisms/Trade/TradeDetail/TradePositionsTable'
import OrderSetting from '../../organisms/Trade/TradeNavBar/OrderSetting'
import OrderBook from '../../organisms/Trade/TradeRealTime/OrderBook'
import ConfirmOrder from '../TradePage/ConfirmOrder'
import CRenderEmpty from 'app/components/CRenderEmpty'

export const OrderBookView = () => {
	const location = useLocation()

	const urlSegments = location.pathname?.split('/')

	const { width, height } = useWindowDimensions()
	const [width2, setWidth2] = React.useState(0)
	const [height2, setHeight2] = React.useState(0)
	const [selected, setSelected] = React.useState<any>('')

	const [changeText, setChangeText] = React.useState(false)

	const { stroke, textw } = React.useContext(ThemeContext).theme.COLORS

	const { data: tradeStore, mutate: mutateTradeStore } = useTradeStore()
	const { data: tradeModalStore, change: mutateTradeModalStore } =
		useTradeModalStore()
	const { EPs } = useExchangeStore()

	const { theme, toggleTheme } = React.useContext(ThemeContext)
	React.useEffect(() => {
		if (urlSegments[3] == undefined) return

		if (urlSegments[4] == 'dark') {
			toggleTheme(true)
		} else {
			toggleTheme(false)
		}
		if (+urlSegments[3] >= 5000) {
			mutateTradeStore({
				ep_id: +urlSegments[3],
				...EPs?.find((item: any) => item?.id == +urlSegments[3]),
			})
			setSelected(urlSegments[3] + '')
		}
	}, [urlSegments[3], urlSegments[4]])

	React.useEffect(() => {
		setChangeText(true)
		setTimeout(() => {
			setChangeText(false)
		}, 500)
	}, [tradeModalStore?.msg])

	const parentRef = React.useRef<any>(null)
	if (parentRef.current) {
		if (parentRef?.current?.clientHeight != height2) {
			setHeight2(parentRef?.current?.clientHeight)
		}
		if (parentRef?.current?.clientWidth != width2) {
			setWidth2(parentRef?.current?.clientWidth)
		}
	}

	return (
		<View
			style={{
				width: width,
				height: height,
				background: theme.COLORS.bgColor,
			}}
			ref={parentRef}>
			<Helmet>
				<title>{tradeStore?.name}</title>
			</Helmet>
			<View
				style={{
					width: '100%',
					flexDirection: 'row',
					borderBottom: `1px solid ${theme.COLORS.stroke}`,
					paddingBottom: 5,
				}}>
				<Select
					style={{ width: '100%', flex: 1 }}
					value={selected}
					options={EPs?.map((item: any) => ({
						value: item?.id + '',
						label: '[' + item?.pairs + ']  ' + item?.name,
					}))}
					onChange={e => {
						setSelected(e + '')
						mutateTradeStore({
							ep_id: +e,
							...EPs?.find((item: any) => +item?.id === +e),
						})
					}}></Select>

				<Wrapper>
					<CButton
						border={{
							width: '1px',
							color: stroke,
							style: 'solid',
						}}
						style={{ color: textw }}
						onClick={() => {
							mutateTradeModalStore({
								skip: tradeModalStore?.skip ? false : true,
							})
						}}
						title={tradeModalStore?.skip ? '확인생략' : '확인창'}
					/>
				</Wrapper>
				<Wrapper>
					<CButton
						border={{
							width: '1px',
							color: stroke,
							style: 'solid',
						}}
						style={{ color: textw }}
						onClick={() => {
							mutateTradeModalStore({
								oneClick: !tradeModalStore?.oneClick,
							})
						}}
						title={tradeModalStore?.oneClick ? '1클릭' : '2클릭'}
					/>
				</Wrapper>

				<DarkModeToggle />
			</View>
			<View
				style={{
					border: '1px solid ' + theme.COLORS.stroke,
					background: theme.COLORS.stroke,
					width: '100%',
					padding: 8,
					justifyContent: 'center',
					alignContent: 'flex-start',
					alignItems: 'flex-start',
				}}>
				<Text
					style={{
						color: changeText
							? theme.COLORS.raise
							: theme.COLORS.textw,
						fontSize: '11px',
					}}>
					<IconAlertCircle
						size={14}
						style={{
							color: theme.COLORS.textw,
							marginRight: 5,
						}}
					/>
					{tradeModalStore?.msg || '-'}
				</Text>
			</View>
			<OrderSetting type={1} />
			<ConfirmOrder />
			<OrderBook
				parentHeight={height2}
				parentWidth={width2}
				newwindow={true}
			/>
			<View style={{ height: '30%', width: '100%' }}>
				<TradePositionsTable popup={true} pos={'new'} />
			</View>
			<CRenderEmpty id={tradeStore?.ep_id} leaveid={tradeStore?.ep_id} />
		</View>
	)
}

const Wrapper = styled.div<{ rotated?: boolean }>`
	transition: transform 0.2s ease-in-out;
	display: flex;
	cursor: pointer;
	align-items: center;
	&:active {
		transform: scale(0.95);
	}
	&:focus {
		outline: none;
		transform: scale(1.05);
	}
`
