import React, { useContext, useState } from 'react'
import { Checkbox, Image, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { usePopup } from '../../hooks/popup'
import { MarkdownComponent } from '../../components'
import { View } from '../../../styles/reactnative'

const PopupModal = ({ item, vis }) => {
	const [visible, setVisible] = useState(true)
	const { t } = useTranslation()
	const images = JSON.parse(item?.images || '[]')
	return (
		<Modal
			key={'POPUP' + item.id}
			title={item?.title}
			open={visible}
			mask={false}
			centered={item?.width == 0}
			okButtonProps={{ style: { display: 'none' } }}
			cancelText={t('CLOSE')}
			width={item?.width + 'px'}
			onCancel={() => {
				setVisible(false)
			}}>
			<MarkdownComponent markdown={item?.content} />

			<View style={{ alignItems:'center' }} >
			{images?.map((image: any) => {
				return (
					<Image
						key={'POPUP' + item.id + image}
						src={image}
					/>
				)
			})}
			</View>

			<Checkbox
				defaultChecked={vis === '1'}
				onChange={e => {
					localStorage.setItem(
						'POPUP' + item.id,
						e.target.checked ? '1' : '0'
					)
				}}>
				다시 보지 않기
			</Checkbox>
		</Modal>
	)
}

export default PopupModal
