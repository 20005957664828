import React, { useContext, useEffect, useState } from 'react'
import { useMobileDimensions, useToken } from '../hooks'
import { ThemeContext } from '../../theme/ThemeProvider'
import {
	ContentContainer,
	ContentWrapper,
} from '../../styles/globalPage-styles'
import { Header } from '../organisms'
import { COIN_URL } from '../constants/APIKEYS'
import { Modal, Space, Spin, message } from 'antd'
import { IconCircleXFilled } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useTradeModalStore } from '../hooks/trade/modalStore'
import { useLogQueue } from '../hooks/trade/logQueue'
import { View } from '../../styles/reactnative'
import { THEME_STORE } from '../constants/LOCALKEYS'
import { useOrderStore } from '../hooks/trade/orderStore'
import { useTradePosition } from '../hooks/trade/tradePosition'
import { useIndexPrice } from 'app/hooks/trade/indexprice'
import { useUserSocket } from 'app/hooks/trade/userSocket'

const Logout = ({ }: any) => {
	const { t } = useTranslation()
	const { data: token, mutate } = useToken()
	const [islogout, setislogout] = useState(false)
	const { mutate: mutateModalStore } = useTradeModalStore()
	const { reset } = useOrderStore()
	const { reset: resPos } = useTradePosition()
	const { leaveGroup } = useUserSocket()

	const { isMobile } = useMobileDimensions()

	const { mutate: mutateLog } = useLogQueue()

	const msg = window.location.href.split('?msg=')[1]

	useEffect(() => {
		leaveGroup();
		reset()
		resPos()

		mutateLog({
			log: 'HM999',
			data: {
				isMobile: isMobile,
			},
		})

		mutate(null).then(() => {
			leaveGroup();
			reset()
			resPos()
			mutateModalStore(null)
		})

		setTimeout(() => {
			leaveGroup();
			reset()
			resPos()
			mutateModalStore(null)
		}, 500)

		if (islogout) {
			if (token) {
				mutate(null)
			}
			setTimeout(() => {
				window.location.href = '/'
			}, 1500)

			setislogout(false)
		} else {
			setTimeout(() => {
				window.location.href = '/'
			}, 1500)
		}
	}, [])

	const { isDarkMode, theme } = useContext(ThemeContext)
	return (
		<ContentContainer isDarkMode={isDarkMode}>
			<Header />
			<ContentWrapper style={{ flex: 1 }}>
				<div
					style={{
						flex: 1,
						justifyContent: 'center',
						width: '100%',
						height: '100%',
						color: theme.COLORS.textw,
						alignItems: 'center',
						marginTop: 30,
						justifyItems: 'center',
						textAlign: 'center',
						minHeight: 500,
					}}>
					<View>Page Redirecting..</View>
					<Spin
						size="large"
						style={{
							padding: '50px 0',
							minHeight: 500,
						}}></Spin>
					<Modal
						open={true}
						footer={null}
						closeIcon={null}
						closable={false}
						centered>
						<Space direction="vertical">
							<Space style={{ fontWeight: 'bold' }}>
								<IconCircleXFilled color="red" />
								{t('SESSIONEXPIRE')}
							</Space>

							<Space>{t('SESSIONEXPIREDESC')}</Space>

							<Space>{decodeURI(msg || '') || ''}</Space>
						</Space>
					</Modal>
				</div>
			</ContentWrapper>
		</ContentContainer>
	)
}
export default Logout
