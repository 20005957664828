import useSWR from 'swr'
import { getPersistData } from '../persist'

let swrData: any = getPersistData('LOCAL@USERLIST') || []

let w = false

export function useUserList() {
	const { data, mutate } = useSWR<any>('LOCAL@USERLIST', async () => {
		return swrData || []
	})

	const { data: who, mutate: mutateWho } = useSWR<any>(
		'LOCAL@USERLIST2',
		async () => {
			return w || false
		}
	)

	return {
		userlist: data,
		mutateUserlist: data => {
			try {
				swrData = data
				if (data !== undefined) {
					return mutate([...data])
				} else {
					return mutate()
				}
			} catch (e) {
				return mutate()
			}
		},
		who,
		sentwho: async () => {
			w = false
			return await mutateWho()
		},
		sendwho: async () => {
			w = true
			return await mutateWho()
		},
	}
}
