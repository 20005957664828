import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const DerivativesTransactionWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: ${props => props.theme.COLORS.bgColor};
	color: ${COLORS.greyWhite};
`
