import { CHARTAPI } from './TRADEAPIKEYS'

export const VERSION = process.env.REACT_APP_VERSION || '5.0.5' // 배포시 수정

export const COINHIDE = process.env.REACT_APP_COINHIDE || false // 코인기능사용여부
export const ENGONLY = process.env.REACT_APP_ENGLISH || false // 코인기능사용여부

export const NAME = process.env.REACT_APP_NAME

export const API_URL = process.env.REACT_APP_API_URL // API URL
export const API_COINURL = process.env.REACT_APP_COINAPI_URL // API URL
export const TRADE_SOCKET_URL = process.env.REACT_APP_SOCKET_URL // API URL

export const CHART_SOCKET_URL = process.env.REACT_APP_CHARTSOCKET_URL || '' // API URL
export const USER_SOCKET_URL = process.env.REACT_APP_USERSOCKET_URL || ''// API URL

export const PARTNERURL = process.env.REACT_APP_IMAGEAPI_URL
/////////////////////////////////////////////////////////////////////

export const USERINFO = '/user/my/info' // 사용자 정보
export const MYSETTINGS = '/user/my/settings' // 내 설정
export const MYBALANCE = '/user/my/balance' // 내 자산
export const MYLOCKBALANCE = '/user/my/lockbalance' // 내 자산
export const POPUP = '/popup/active' // popup
export const MYTRADESETTINGS = '/user/my/tradesetting' // 내 트레이딩설정
export const CHART_HISTORY = CHARTAPI + '/history'

export const TRANSFER = '/user/my/transfer'

export const EXCHANGESETTINGS = '/exchanges' // 거래쌍 설정
export const ORDERBOOK = '/trade/orderbook' // 오더북
export const CANCELORDER = '/trade/cancel' // 주문취소

export const TRADELIST = '/trade/tradelist' // 주문취소

//COIN
export const COIN_URL: any = process.env.REACT_APP_COIN_URL

export const STATUS = '/trade/status'
