import React, { useEffect, useMemo, useRef, useState } from 'react'
import OrderBookTableMobile1 from './OrderBookTableMobile1'
// import useSWR from 'swr'
// import { FLASH_QUEUE } from '../../../../constants/LOCALKEYS'

const OrderBookMobile = ({
	parentRef,
	parentHeight,
	parentWidth,
	newwindow = false,
}: any) => {
	return (
		<div
			style={{
				height: '510px',
				display: 'flex',
				alignItems: 'flex-start',
				width: '100%',
				justifyContent: 'center',
				overflowY: 'auto',
				flexDirection: 'column',
			}}>
			<OrderBookTableMobile1
				parentHeight={parentHeight + 100}
				parentWidth={parentWidth}
				newwindow={false}
			/>
		</div>
	)
}
export default OrderBookMobile
