import React from 'react'
import { useTranslation } from 'react-i18next'
import { MText } from 'styles/mobilePageStyles'
import { MButtonSection } from './styles'

const MButton = ({
	icon,
	title,
	onClick,
	style,
	textStyle,
	borderColor,
	refs,
}: any) => {
	const { t } = useTranslation()
	return (
		<MButtonSection
			ref={refs}
			className={'customBtn'}
			type="button"
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				alignSelf: 'center',
				justifyContent: 'center',
				borderWidth: 0,
				borderBottomWidth: borderColor ? 1 : 0,
				borderBottomColor: borderColor ? borderColor : 'transparent',
				paddingBottom: borderColor ? 3 : 0,
				...style,
			}}
			onClick={onClick}>
			{icon}
			<MText style={{ fontSize: 13, ...textStyle }}>{title}</MText>
		</MButtonSection>
	)
}

export default MButton
