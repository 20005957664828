import React from 'react'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import BigNumber from 'bignumber.js'
import { useFetcherPublic } from '../../../hooks/fetcher'
import DefaultKrw from './DefaultKrw'

const CurrencyRate = () => {
	BigNumber.clone() // new line

	const { data: tradeStore } = useTradeStore()
	const currencyList = useFetcherPublic('/system/currency')

	const equivusd = currencyList?.data?.result?.result?.find(
		(item: any) => item?.code === 'USD'
	)

	const equiv = currencyList?.data?.result?.result?.find(
		(item: any) => item?.code === tradeStore?.currency
	)

	const equivkrw = currencyList?.data?.result?.result?.find(
		(item: any) => item?.code === 'KRW'
	)

	const currencyrate = BigNumber(equivusd?.price || 0)
		.multipliedBy(equivkrw?.price || 0)
		.dividedBy(equiv?.price || 0)
		.toFixed(2)
	return (
		<DefaultKrw
			title={'환율'}
			price={currencyrate}
			dp={2}
			krw={false}
			color={false}
		/>
	)
}

export default React.memo(CurrencyRate)
