import React from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'app/constants/STYLES'
import { CImage, CButton } from 'app/components/Common'
import google from 'assets/icons/google.png'
import { useGoogleLogin } from '@react-oauth/google'

const BtnGoogle = () => {
	const { t } = useTranslation()
	const login = useGoogleLogin({
		onSuccess: tokenResponse => console.log(tokenResponse),
		onError: e => console.log(e, 'Login Failed'),
	})

	return (
		<CButton
			title={t('google')}
			icon={
				<CImage
					src={google}
					alt="logo"
					width={18}
					height={18}
					resizeMode={'cover'}
					style={{ borderRadius: 50, marginRight: 10 }}
				/>
			}
			onClick={() => login()}
			style={{
				justifyContent: 'center',
				width: '40%',
				marginRight: 10,
				padding: 10,
				flexDirection: 'row-reverse',
				color: COLORS.white,
				background: COLORS.grey,
			}}
		/>
	)
}

export default React.memo(BtnGoogle)
