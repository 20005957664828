import React, { useEffect, useRef, useState } from 'react'
import { View } from 'styles/reactnative'
import { TradeRealTimeContainer } from './styles'

import OrderBookMobile3 from './OrderBook/mobile3'

const TradeRealTimeMobile = ({ style = {}, isblur=false }) => {
	const [height, setHeight] = useState(0)
	const [width, setWidth] = useState(0)

	const parentRef = useRef<any>(null)

	useEffect(() => {
		if (parentRef.current) {
			if (parentRef?.current?.clientHeight != height) {
				setHeight(parentRef.current?.clientHeight)
			}
			if (parentRef?.current?.clientWidth != width) {
				setWidth(parentRef.current?.clientWidth)
			}
		}
	}, [parentRef.current, parentRef.current?.clientWidth])

	return (
		<TradeRealTimeContainer ref={parentRef} style={{ ...style }}>
			<View
				style={{
					width: '100%',
					height: '100%',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
				}}>
				<OrderBookMobile3
					parentRef={parentRef}
					parentHeight={height}
					parentWidth={width || 100}
					isblur={isblur}
				/>
			</View>
		</TradeRealTimeContainer>
	)
}

export default TradeRealTimeMobile
