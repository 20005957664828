import React, { useState, useContext } from 'react'
import { CDrawerHeader, CTabs } from 'app/components/Common'
import { PreferenceDrawerSection } from './styles'
import { COLORS, FONTS } from 'app/constants'
import PreferenceDrawerTrade from './PreferenceDrawerTrade'
import PreferenceDrawerTheme from './PreferenceDrawerTheme'
import { ThemeContext } from 'theme/ThemeProvider'

const PreferenceDrawer = ({ displayPreference, setDisplayPreference }) => {
	const { gray } = useContext(ThemeContext).theme.COLORS
	const TRADE = 'trade'
	const THEME = 'theme'
	const [tab, setTab] = useState(TRADE)
	const data = [TRADE, THEME]
	return (
		<PreferenceDrawerSection display={displayPreference}>
			<CDrawerHeader
				onClick={setDisplayPreference}
				title={'preferences'}
			/>
			<CTabs
				tabs={tab}
				setTabs={setTab}
				data={data}
				vStyle={{
					borderBottom: `1px solid ${gray}`,
				}}
				activeColor={COLORS.secondary}
				borderActiveColor={COLORS.secondary}
				style={{
					...FONTS.h6,
					fontWeight: '600',
					marginRight: 20,
				}}
			/>
			{tab === TRADE ? (
				<PreferenceDrawerTrade />
			) : (
				<PreferenceDrawerTheme />
			)}
		</PreferenceDrawerSection>
	)
}

export default React.memo(PreferenceDrawer)
