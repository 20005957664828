import React, { useContext } from 'react'
import { IconExclamationMark } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import { useWindowDimensions } from 'app/hooks'
import { CButton, CImage } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { AccountSecurityListItemSection, InfoLeftLists } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'

interface IProps {
	item?: any
	index?: number
}
const AccountSecurityListItems = ({ item, index }: IProps) => {
	const { borderColor, textw } = useContext(ThemeContext).theme.COLORS
	const { width } = useWindowDimensions()
	const { t } = useTranslation()
	return (
		<AccountSecurityListItemSection
			style={{
				borderTop: index === 0 ? 'none' : `1px solid ${borderColor}`,
			}}>
			<InfoLeftLists>
				<View
					style={{
						flexDirection: 'row',
						flexShrink: 1,
						flex: 1,
						minWidth: 230,
						alignItems: width < 700 ? 'flex-start' : 'center',
						justifyContent: 'flex-start',
					}}>
					<CImage
						src={item?.image}
						alt="logo"
						width={35}
						height={35}
						resizeMode={'cover'}
						style={{ borderRadius: 50, marginRight: 10 }}
					/>

					<Text size={16} style={{ fontWeight: '500', color:textw }}>
						{item?.title}
					</Text>
				</View>
				<View
					style={{
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
						width: '100%',
					}}>
					<View
						style={{
							flexDirection: 'row',
							marginBottom: 7,
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}>
						<IconExclamationMark
							size={14}
							style={{
								background: item?.contentIconColor
									? item?.contentIconColor
									: COLORS.lightGray,
								borderRadius: 50,
								color: COLORS.white,
								marginRight: 10,
							}}
						/>
						<Text
							size={14}
							style={{
								color: item?.contentColor
									? item?.contentColor
									: textw,
							}}>
							{item?.content}
						</Text>
					</View>

					{item?.desc ? (
						<Text
							size={14}
							style={{
								color: item?.descColor
									? item?.descColor
									: textw,
								lineHeight: 1.2,
							}}>
							{item?.desc}
						</Text>
					) : null}
				</View>
			</InfoLeftLists>
			<View
				style={{
					alignSelf: width < 970 ? 'flex-start' : 'flex-end',
					justifyContent: width < 970 ? 'flex-start' : 'flex-end',
					alignItems: width < 970 ? 'flex-start' : 'flex-end',
					marginLeft: 45,
					marginTop: -15,
				}}>
				{item?.button && (
					<CButton
						title={t(item.button?.title)}
						onClick={() => {
							if(item?.button?.modelOpen)
								item?.button?.modelOpen()
						}}
						style={{
							whiteSpace: 'nowrap',
							background:
								item?.button.title.slice(0, 1) === 'c'
									? borderColor
									: COLORS.primary,
							paddingBlock: width < 980 ? 4 : 7,
							paddingInline: width < 980 ? 7 : 15,
							alignSelf: 'flex-end',
							fontSize: width < 980 ? 10 : 14,
							color: COLORS.white,
							marginTop: width > 600 ? 10 : 0,
						}}
					/>
				)}
			</View>
		</AccountSecurityListItemSection>
	)
}

export default AccountSecurityListItems
