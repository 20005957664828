import { Button, Col, Space } from 'antd'
import React from 'react'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { useWindowDimensions } from '../../../../hooks'
import { useAvaiblecheck } from '../../../../hooks/trade/avaibleCheck'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { useLogQueue } from 'app/hooks/trade/logQueue'

const ColAva = () => {
	const { theme } = React.useContext(ThemeContext)
	const { availableLong, availableShort } = useAvaiblecheck()
	const { width } = useWindowDimensions()
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()

	const { mutate: mutateLog } = useLogQueue()

	return (
		<Col>
			<Space style={{ alignItems: 'center' }}>
				<div style={{ color: theme.COLORS.textw }}>가능수량</div>
				<div
					style={{
						background: theme.COLORS.onTertiary,
						color: theme.COLORS.fail,
						padding: '3px 5px',
					}}>
					매도 {availableShort > 0 ? availableShort : 0}
				</div>
				<div
					style={{
						background: theme.COLORS.onTertiary,
						color: theme.COLORS.raise,
						padding: '3px 5px',
					}}>
					매수 {availableLong > 0 ? availableLong : 0}
				</div>
				<Button
					type="primary"
					size="small"
					style={
						width < 1330
							? {
								paddingInline: 3,
								backgroundColor: '#00499F',
							}
							: {
								paddingBlock: 0,
								backgroundColor: '#00499F',
							}
					}
					onClick={() => {


						mutateLog({
							log: 'FR031',
							data: {
							},
						})
						mutateModalStore({
							...modalStore,
							overnightsetting: true,
						})
					}}>
					오버설정
				</Button>
			</Space>
		</Col>
	)
}

export default React.memo(ColAva)
