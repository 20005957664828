import { Spin } from 'antd'
import { COLORS } from 'app/constants'
import BigNumber from 'bignumber.js'
import React, { useEffect } from 'react'
import { Text, View } from 'styles/reactnative'
import { useFetcherPublic } from '../../hooks/fetcher'
import { useExchangeStore } from '../../hooks/trade/exchangeStore'
import { useIndexPrice } from '../../hooks/trade/indexprice'
import { useCurrency } from 'app/hooks/trade/useCurrency'

const Unrealizedplpercent = ({ data, color = false }) => {
	let { getPrice, reset } = useIndexPrice(data?.exchange_pairs_id)
	let { EPs } = useExchangeStore()

	const EP = EPs?.find(item => item.id === data?.exchange_pairs_id)

	let pnl = 0

	const currentprice = getPrice(data?.exchange_pairs_id)

	useEffect(() => {
		if (
			getPrice(data?.exchange_pairs_id) === 0 ||
			!getPrice(data?.exchange_pairs_id)
		) {
			reset('Unrealizedplpercent' + data?.exchange_pairs_id)
		}
	}, [data?.exchange_pairs_id])
 
	const { krwrate, getRate, mutate } = useCurrency()
	const currencyrate = getRate(EP?.currency)

	useEffect(() => {
		if(!krwrate) {
			mutate()
		}
	}, [krwrate, currencyrate])

	const rate = BigNumber(krwrate?.price || 0)
		.div(currencyrate?.price || 0)
		.toNumber() || 1343


	if (data?.long_short === 'L') {
		pnl = BigNumber(currentprice)
			.minus(data?.price)
			.multipliedBy(data?.amount)
			.multipliedBy(EP?.contractamt)
			.multipliedBy(rate)
			.toNumber()
	} else {
		pnl = BigNumber(data?.price)
			.minus(currentprice)
			.multipliedBy(data?.amount)
			.multipliedBy(EP?.contractamt)
			.multipliedBy(rate)
			.toNumber()
	}

	if (currentprice === 0) {
		return <Spin />
	}

	return (
		<View
			style={{
				whiteSpace: 'nowrap',
				lineHeight: 1,
				flexDirection: 'column',
				alignContent: 'center',
				flex: 1,
				alignItems: 'flex-end',
				backgroundColor: color ? pnl > 0 ? '#fff1f1' : pnl < 0 ? '#f1f1ff' : 'transparent' : 'transparent',
			}}>
			<Text
				style={{
					fontSize: 13,
					whiteSpace: 'nowrap',
					color:
						pnl > 0
							? COLORS.lightRed
							: pnl < 0
								? COLORS.lightGreen
								: '#000',
				}}>
				{pnl > 0 ? '+' : ''}
				{BigNumber(pnl).toFormat(0)}원
			</Text>
			<Text
				style={{
					fontSize: 11,
					whiteSpace: 'nowrap',
					color:
						pnl > 0
							? COLORS.lightRed
							: pnl < 0
								? COLORS.lightGreen
								: '#000',
				}}></Text>
		</View>
	)
}

export default Unrealizedplpercent
