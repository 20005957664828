import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'

import CRenderEmpty from '../../../components/CRenderEmpty'
import DerivativesBody from '../../../organisms/Trade/TradeTransaction/DerivativesTransaction/DerivativesBody'
import { HomeSection } from './styles'
const MHome = () => {
	const { isDarkMode } = useContext(ThemeContext)

	return (
		<HomeSection isDarkMode={isDarkMode}>
			<DerivativesBody />
			 <CRenderEmpty id={0} />
		</HomeSection>
	)
}

export default MHome
