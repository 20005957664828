import { COLORS, FONTS } from 'app/constants'
import { useWindowDimensions } from 'app/hooks'
import React from 'react'
import { CTabs } from '../Common'
import { PageMenuSection, PageMenuTabSection } from './styles'
interface IProps {
	tab?: string
	setTab?: any
	data: any
	bgColor?: string
	style?: any
	activeColor?: any
}
const PageMenuTabs = ({
	tab,
	setTab,
	data,
	bgColor,
	style,
	activeColor,
}: IProps) => {
	const { width } = useWindowDimensions()
	return (
		<PageMenuTabSection
			style={{
				background: bgColor ? bgColor : COLORS.white,
				...style,
			}}>
			<PageMenuSection>
				<CTabs
					tabs={tab}
					setTabs={setTab}
					data={data}
					vStyle={{
						padding: 0,
						margin: 0,
						justifyContent: 'flex-start',
						alignSelf: 'center',
					}}
					activeColor={activeColor ? COLORS.white : COLORS.primary}
					borderActiveColor={'transparent'}
					style={{
						fontSize: width > 1200 ? 14 : 12,
						alignSelf: 'center',
						fontWeight: '400',
						paddingRight: width > 900 ? 40 : 20,
					}}
				/>
			</PageMenuSection>
		</PageMenuTabSection>
	)
}

export default PageMenuTabs
