import React, { useState } from 'react'
import { MView } from 'styles/mobilePageStyles'
import AccountSettingLists from './AccountSettingLists'
import SecurityListTitle from '../MAccountSecurity/SecurityListTitle'
import AccountSettingCheckBox from './AccountSettingCheckBox'
import ChangeLanguageContainer from './ChangeLanguageContainer'
import TimeZoneContainer from './TimeZoneContainer'
import CurrencyContainer from './CurrencyContainer'
import NotiSettingContainer from './NotiSettingContainer'
import { useDefaultCodes } from 'locales/defaultCodes'
import { SETTING_TYPE_SYSTEM } from '../../../../../constants/SETTING'
import { getSetting, useSetting } from '../../../../../hooks/user/mysetting'

const MAccountSettings = () => {
	const { name: languageName } = useDefaultCodes()
	const [changeLanguage, setChangeLanguage] = useState(false)
	const [timezone, setTimeZone] = useState(false)
	const [currency, setCurrency] = useState(false)
	const [notiSetting, setNotiSetting] = useState(false)

	const { data: setting } = useSetting(SETTING_TYPE_SYSTEM)
	const tz = getSetting(setting, 'TIMEZONE', 'Asia/Seoul')
	const cr = getSetting(setting, 'CURRENCY', 'USD')

	const [timezoneItem, setTimezoneItem] = useState(tz)
	const [currencyItem, setCurrencyItem] = useState(cr)
	const [languageItem, setLanguageItem] = useState(languageName)

	return (
		<MView style={{ width: '100%' }}>
			<MView style={{ width: '100%', marginTop: 10 }}>
				<SecurityListTitle title={'language'} />
				<AccountSettingLists
					title={'chooseYourLanguage'}
					content={languageItem}
					open={changeLanguage}
					setOpen={setChangeLanguage}
					BottomComp={ChangeLanguageContainer}
					item={languageItem}
					setItem={setLanguageItem}
				/>
			</MView>
			<MView style={{ width: '100%', marginTop: 10 }}>
				<SecurityListTitle title={'timezone'} />
				<AccountSettingLists
					title={'selectYourTimezone'}
					content={timezoneItem}
					open={timezone}
					setOpen={setTimeZone}
					BottomComp={TimeZoneContainer}
					item={timezoneItem}
					setItem={setTimezoneItem}
				/>
			</MView>
			<MView style={{ width: '100%', marginTop: 10 }}>
				<SecurityListTitle title={'currency'} />
				<AccountSettingLists
					title={'equivalentCurrency'}
					content={currencyItem}
					open={currency}
					setOpen={setCurrency}
					BottomComp={CurrencyContainer}
					item={currencyItem}
					setItem={setCurrencyItem}
					maxHeight={300}
				/>
			</MView>
			<MView style={{ width: '100%', marginTop: 10 }}>
				<SecurityListTitle title={'notification'} />
				<AccountSettingLists
					title={'notificationSetting'}
					open={notiSetting}
					setOpen={setNotiSetting}
					BottomComp={NotiSettingContainer}
					navigate={'/notiSetting'}
				/>
			</MView>
			<MView style={{ width: '100%', marginTop: 10 }}>
				<SecurityListTitle title={'emailSubscription'} />
				<AccountSettingCheckBox />
			</MView>
		</MView>
	)
}

export default MAccountSettings
