import React, { useEffect } from 'react'
import { MNOANIView, MView } from 'styles/mobilePageStyles'

import { ContentSplit } from '../../../styles/globalPage-styles'
import { ThemeContext } from '../../../theme/ThemeProvider'
import DerivativesBody from '../../mobile/organisms/Trade/TradeTransaction/DerivativesTransaction/DerivativesBody/mobile'

import { useMitOrder } from '../../hooks/trade/mitorder'
import { useTradeStore } from '../../hooks/trade/tradeStore'
import TradeTabFooter from '../../mobile/organisms/Trade/TradeTabFooter'
import TradeRealTimeMobile from '../../organisms/Trade/TradeRealTime/mobile'
import ConfirmOrder from './ConfirmOrder'
import TradeAmount from './Modal/TradeAmount'
import TradeChart from './TradeChart'

const TradeM = ({ isblur }) => {
	const { theme } = React.useContext(ThemeContext)
	const { tradeBG } = theme.COLORS
	const { data: tradeStore, changeTradeStore: mutateTradeStore } =
		useTradeStore()

	const { getMIT } = useMitOrder()
	useEffect(() => {
		getMIT()
	}, [])

	useEffect(() => {
		if (!tradeStore?.chart_id) {
			mutateTradeStore({ chart_id: tradeStore?.ep_id })
		}
	}, [])

	return (
		<MView
			style={{
				marginTop: 0,
				width: '100%',
				height: '100%',
				paddingBottom: 20,
				minHeight: 1000,
				backgroundColor: tradeBG,
			}}>
			<TradeChart isblur={isblur} />

			<ContentSplit
				style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
				<MView style={{ width: '40%', height: 600 }}>
					<TradeRealTimeMobile
						style={{ height: 600 }}
						isblur={isblur}
					/>
				</MView>
				<MView style={{ flex: 1 }}>
					<DerivativesBody isMobile={true} />
				</MView>
			</ContentSplit>

			<ConfirmOrder />
			<TradeAmount />
			{!isblur && <TradeTabFooter />}
		</MView>
	)
}

export default React.memo(TradeM)
