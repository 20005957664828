import React, { useState } from 'react'
import KrwPrice from '../../KrwPrice'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import BigNumber from 'bignumber.js'
import Default from './Default'
import { useTradePosition } from '../../../hooks/trade/tradePosition'
import { useExchangeStore } from '../../../hooks/trade/exchangeStore'
import { useFetcherPublic } from '../../../hooks/fetcher'
import { useIndexPrice } from '../../../hooks/trade/indexprice'
import { Text } from '../../../../styles/reactnative'
import { useWindowDimensions } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import Pannel from './Pannel'

const AvgPrice = () => {
	let avgPrice = ''

	const [c, setC] = useState(0)

	const ref = React.useRef<any>(null)

	const { data: position } = useTradePosition()
	const { t } = useTranslation()
	const data = position?.filter((item: any) => !item?.status)

	const { data: tradeStore } = useTradeStore()

	const currentposition = data?.filter(
		(item: any) => item?.exchange_pairs_id == tradeStore?.ep_id
	)

	currentposition?.map((item: any) => {
		avgPrice = item?.price
	})

	return (
		<>
			<Default
				title={t('avgprice')}
				onClick={() => {
					setC(p => p + 1)
					if(ref.current == null) {
						ref.current = setTimeout(() => {
							setC(p => {
								if (p >= 10) {
									return p
								}
								return 0
							});
							
							ref.current = null
						}, 5000)
					}
				}}
				value={
					<KrwPrice
						margin={true}
						price={avgPrice}
						dp={tradeStore?.dp || 0}
						krw={false}
						color={false}
					/>
				}
			/>
			{c >= 10 && (
				<Pannel setC={setC}/>
			)}
		</>
	)
}

export default React.memo(AvgPrice)
