import { Select, Space, Tag } from 'antd'
import React, { useState } from 'react'
import EntryTime from '../../components/EntryTime'
import KrwPrice from '../../components/KrwPrice'
import TableAPI from '../../components/TableAPI'
import { COLORS } from '../../constants'

import { ENGONLY } from 'app/constants/APIKEYS'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import { Text, View } from '../../../styles/reactnative'
import { padDP } from '../../../utils/ciutils'
import { TagWrap } from '../../components/styles'
import { useToken, useWindowDimensions } from '../../hooks'
import { useCoinGlobal } from '../../hooks/stateStore/coinGlobal'
import { useExchangeStore } from '../../hooks/trade/exchangeStore'
import DateFilter from '../DateFilter'
import { Price } from '../Trade/TradeRealTime/OrderBook/styles'

const TradeList = ({ mobile = false, userid = null }) => {
	const { level } = useToken()
	const { width } = useWindowDimensions()

	let now = dayjs().format('YYYY-MM-DD')
	const today = dayjs().format('YYYY-MM-DD')
	const prev = dayjs(today).subtract(1, 'day').format('YYYY-MM-DD')

	//새벽 12시~오전 7시 사이에 조회할 경우 전날 데이터 조회
	if (dayjs().hour() < 7) {
		now = prev
	} else {
		now = today
	}
	const { data: coinglobal } = useCoinGlobal()
	const [startDate, setStartDate] = useState<any>(now)
	const [endDate, setEndDate] = useState<any>(now)
	const [orderType, setOrderType] = useState<any>('')
	const [ep, setEp] = useState<any>('*')

	const { EPs } = useExchangeStore()

	const renderItem = (text: any, record: any) => {
		const EP = EPs?.find(ep => ep?.id == record?.exchange_pairs_id)
		return (
			<View style={{ display: 'flex', flexDirection: 'column' }}>
				<View
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',
						backgroundColor:
							record?.status == 3 ? '#f1f1f1' : '#ffffff',
						borderBottom: '1px solid #f0f0f0',
						paddingBottom: 5,
					}}>
					<Text style={{ fontSize: 15, fontWeight: 'bold' }}>
						{record?.groupname} ({record?.pairs})
					</Text>
					<TagWrap>
						<Tag
							style={{
								textDecoration:
									record?.status == 3
										? 'line-through'
										: 'none',
							}}>
							{record?.id}
						</Tag>
						<TagWrap>
							{record?.platform == 0 ? (
								<Tag color="blue">WTS</Tag>
							) : record?.platform == 1 ? (
								<Tag color="green">MTS</Tag>
							) : record?.platform == 2 ? (
								<Tag color="purple">HTS</Tag>
							) : (
								<Tag color="red">서버</Tag>
							)}
							<Tag
								color={
									record?.status == 3
										? '#888888'
										: record?.open_close != 'O'
										? '#000000'
										: '#4b4b4b'
								}>
								{record?.status == 3
									? '취소'
									: record?.open_close == 'O'
									? '진입'
									: '청산'}
							</Tag>
						</TagWrap>
						<Tag
							color={
								record?.limit_market == 'M'
									? '#4b4b4b'
									: '#888888'
							}>
							{record?.limit_market == 'M' ? '시장가' : '지정가'}
						</Tag>
						<Tag
							color={
								record?.long_short == 'L'
									? '#F05551'
									: '#007EDA'
							}>
							{record?.long_short == 'L' ? '매수' : '매도'}
						</Tag>
					</TagWrap>
				</View>
				<View
					style={{
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						justifyContent: 'space-between',
						backgroundColor:
							record?.status == 3 ? '#acacac' : '#f0f0f0',
						padding: 5,
						fontSize: 12,
						gap: 10,
					}}>
					<View
						style={{
							display: 'flex',
							flexDirection: 'column',
							flex: 1,
							width: '100%',
						}}>
						<View
							style={{
								display: 'flex',
								flexDirection: 'row',
								minHeight: 22,
								width: '100%',
								justifyContent: 'space-between',
							}}>
							<Text
								style={{
									fontSize: 12,
									fontWeight: 'bold',
								}}>
								주문가
							</Text>
							<KrwPrice
								price={record?.order_price}
								dp={EP?.dp}
								theme={false}
								krw={false}
								fontSize={14}
								color={false}
							/>
						</View>
						{record?.status != 3 && (
							<>
								<View
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										width: '100%',
										minHeight: 22,
									}}>
									<Text
										style={{
											fontSize: 12,
											fontWeight: 'bold',
											marginRight: 5,
										}}>
										체결가
									</Text>
									<KrwPrice
										price={record?.trade_price}
										dp={EP?.dp}
										theme={false}
										krw={false}
										color={false}
										fontSize={14}
									/>
								</View>
								<View
									style={{
										display: 'flex',
										flexDirection: 'row',
										width: '100%',
										justifyContent: 'space-between',
										alignContent: 'center',
										alignItems: 'center',
										minHeight: 22,
									}}>
									<Text
										style={{
											fontSize: 12,
											fontWeight: 'bold',
										}}>
										수수료
									</Text>
									<KrwPrice
										price={-record?.fee_price}
										fontSize={12}
									/>
								</View>
							</>
						)}
					</View>
					<View
						style={{
							display: 'flex',
							flexDirection: 'column',
							flex: 1,
							width: '100%',
							minHeight: 22,
						}}>
						<View
							style={{
								display: 'flex',
								flexDirection: 'row',
								width: '100%',
								justifyContent: 'space-between',
							}}>
							<Text
								style={{
									fontSize: 12,
									fontWeight: 'bold',
									marginRight: 5,
								}}>
								주문수량
							</Text>
							<Text
								style={{
									fontSize: 14,
									color:
										record?.long_short == 'S'
											? COLORS.lightGreen
											: COLORS.lightRed,
								}}>
								{new BigNumber(record?.amount)
									.multipliedBy(record?.leverage)
									.toFormat(0)}
							</Text>
						</View>
						{record?.status != 3 && (
							<>
								<View
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										width: '100%',
										minHeight: 22,
									}}>
									<Text
										style={{
											fontSize: 12,
											fontWeight: 'bold',
											marginRight: 5,
										}}>
										체결수량
									</Text>
									<Text
										style={{
											fontSize: 14,
											color:
												record?.long_short == 'S'
													? COLORS.lightGreen
													: COLORS.lightRed,
										}}>
										{new BigNumber(record?.amount)
											.minus(record?.remain_amount)
											.multipliedBy(record?.leverage)
											.toFormat(0)}
									</Text>
								</View>

								<View
									style={{
										display: 'flex',
										flexDirection: 'row',
										width: '100%',
										justifyContent: 'space-between',
										minHeight: 22,
									}}>
									<Text
										style={{
											fontSize: 12,
											fontWeight: 'bold',
											marginRight: 5,
										}}>
										{ENGONLY ? 'P/L' : '매매손익'}
									</Text>
									{record?.pnl_price ? (
										<KrwPrice
											price={record?.pnl_price}
											fontSize={12}
										/>
									) : (
										<Text>-</Text>
									)}
								</View>
							</>
						)}
					</View>
				</View>

				<View
					style={{
						display: 'flex',
						width: '100%',
						padding: 5,
						backgroundColor:
							record?.status == 3 ? '#a3a1a1' : '#e2e2e2',
					}}>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							minHeight: 22,
							width: '100%',
							flex: 1,
							justifyContent: 'space-between',
						}}>
						<Text
							style={{
								fontSize: 12,
								fontWeight: 'bold',
							}}>
							주문일자
						</Text>
						<EntryTime
							time={record?.created_at}
							offset={9}
							style={{ fontSize: 11 }}
						/>
					</View>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
							minHeight: 22,
						}}>
						<Text
							style={{
								fontSize: 12,
								fontWeight: 'bold',
							}}>
							체결일자
						</Text>
						{record?.traded_at ? (
							<EntryTime
								time={record?.traded_at}
								offset={9}
								style={{ fontSize: 11 }}
							/>
						) : (
							<Text style={{ fontSize: 12 }}>미체결</Text>
						)}
					</View>
				</View>
			</View>
		)
	}

	const columns1 = () => [
		{
			title: '체결내역',
			dataIndex: 'created_at',
			align: 'center',
			render: renderItem,
		},
	]
	const columns2 = () => [
		{
			title: '주문일자',
			dataIndex: 'created_at',
			align: 'center',
			sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
			render: (text, record) => {
				return <EntryTime time={record?.created_at} offset={9} />
			},
		},
		{
			title: '체결일자',
			dataIndex: 'traded_at',
			align: 'center',
			sorter: (a, b) => new Date(a.traded_at).getTime() - new Date(b.traded_at).getTime(),
			render: (text, record) => {
				return <EntryTime time={record?.traded_at} offset={9} />
			},
		},
		mobile
			? { width: 0 }
			: {
					title: '주문',
					dataIndex: 'id',
					width: 60,
					align: 'center',
					sorter: (a, b) => a.id - b.id,
					render: (text, record) => {
						return <Text>{text}</Text>
					},
			  },
		{
			title: '원주문',
			dataIndex: 'original_id',
			width: 60,
			sorter: (a, b) => a.original_id - b.original_id,
			align: 'center',
			render: (text, record) => {
				return <Text>{text}</Text>
			},
		},

		...(coinglobal == 'G'
			? [
					!mobile && width >= 1600
						? {
								title: '종목코드',
								dataIndex: 'pairs',
								align: 'center',
								width: 80,
								sorter: (a, b) => a.pairs > b.pairs ? 1 : -1,
								render: (text, record) => {
									return <div>{text}</div>
								},
						  }
						: { width: 0 },
					{
						title: '종목명',
						dataIndex: 'groupname',
						sorter : (a, b) => a.groupname > b.groupname ? 1 : -1,
						align: 'center',
						render: (text, data) => (
							<>
								<div>{text}</div>
								{width < 1600 && (
									<div style={{ fontSize: 12 }}>
										({data?.pairs})
									</div>
								)}
							</>
						),
					},
					mobile
						? { width: 0 }
						: {
								title: '거래소',
								dataIndex: 'exchange_code',
								sorter: (a, b) => a.exchange_code > b.exchange_code ? 1 : -1,
								width: 60,
								align: 'center',
								render: (text, data) => <div>{text}</div>,
						  },
			  ]
			: [
					!mobile && width >= 1600
						? {
								title: '종목코드',
								dataIndex: 'exchange_pairs_id',
								align: 'center',
								sorter: (a, b) => a.exchange_pairs_id > b.exchange_pairs_id ? 1 : -1,
								width: 80,
								render: (text, record) => {
									return <div>{text}</div>
								},
						  }
						: { width: 0 },
					{
						title: '종목명',
						dataIndex: 'groupname',
						align: 'center',
						sorter: (a, b) => a.groupname  > b.groupname ? 1 : -1,
						render: (text, data) => {
							const EP = EPs?.find(
								ep => ep?.id == data?.exchange_pairs_id
							)
							return (
								<>
									<div>{EP?.pairs}</div>
								</>
							)
						},
					},
			  ]),

		{
			title: '매매구분',
			dataIndex: 'open_close',
			align: 'center',
			sorter: (a, b) => a.open_close > b.open_close ? 1 : -1,
			width: 60,
			render: (text, record) => {
				return (
					<Tag
						style={{
							color:
								text == 'O'
									? (record?.pnl_price || 0) != 0
										? COLORS.lightGreen
										: COLORS.black
									: COLORS.lightRed,
						}}>
						{record?.status == 3
							? record?.modi
								? '정정'
								: '취소'
							: text == 'O' || record?.status == 1
							? (record?.pnl_price || 0) != 0
								? '전환'
								: '진입'
							: '청산'}
					</Tag>
				)
			},
		},
		{
			title: '주문구분',
			dataIndex: 'long_short',
			align: 'center',
			sorter: (a, b) => a.long_short > b.long_short ? 1 : -1,
			width: 60,
			render: (text, record) => {
				return (
					<Tag
						style={{
							color:
								text == 'S'
									? COLORS.lightGreen
									: COLORS.lightRed,
						}}>
						{text == 'L' ? '매수' : '매도'}
					</Tag>
				)
			},
		},
		mobile
			? { width: 0 }
			: {
					title: '구분',
					dataIndex: 'reduce_only',
					align: 'center',
					width: 60,
					sorter: (a, b) => a.limit_market > b.limit_market ? 1 : -1,
					render: (text, record) => {
						return (
							<Tag>
								{text == 1
									? 'MIT'
									: record?.limit_market == 'M'
									? '시장가'
									: '지정가'}
							</Tag>
						)
					},
			  },
		{
			title: '주문수량',
			dataIndex: 'amount',
			align: 'center',
			sorter: (a, b) => a.amount - b.amount,
			width: 42,
			render: (text, record) => {
				return (
					<>
						{new BigNumber(text)
							.multipliedBy(record?.leverage)
							.toFormat(0)}
					</>
				)
			},
		},
		{
			title: '주문단가',
			dataIndex: 'order_price',
			sorter: (a, b) => a.order_price - b.order_price,
			align: 'center',
			render: (text, record) => {
				const EP = EPs?.find(e => e.id == record?.exchange_pairs_id)
				return (
					<Price
						style={{
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						{record?.limit_market == 'M'
							? padDP(
									record?.trade_price || 0,
									record?.dp || EP?.dp
							  )
							: padDP(text, record?.dp || EP?.dp)}
					</Price>
				)
			},
		},
		{
			title: '체결수량',
			dataIndex: 'amount_remain_amount',
			sorter: (a, b) => a.amount_remain_amount - b.amount_remain_amount,
			align: 'center',
			width: 42,
			render: (text, record) => {
				return (
					<>
						{new BigNumber(record?.amount)
							.minus(record?.remain_amount)
							.multipliedBy(record?.leverage)
							.toFormat(0)}
					</>
				)
			},
		},
		{
			title: '체결단가',
			dataIndex: 'trade_price',
			sorter: (a, b) => a.trade_price - b.trade_price,
			align: 'center',
			render: (text, record) => {
				const EP = EPs?.find(e => e.id == record?.exchange_pairs_id)

				return (
					<Price
						style={{
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						{padDP(text || 0, record?.dp || EP?.dp)}
					</Price>
				)
			},
		},
		{
			title: '수수료',
			dataIndex: 'fee_price',
			sorter: (a, b) => a.fee_price - b.fee_price,
			align: 'center',
			render: (text, record) => {
				return <KrwPrice price={-text} dp={0} coinglobal={coinglobal} />
			},
		},
		{
			title: '매매손익',
			dataIndex: 'pnl_price',
			sorter: (a, b) => a.pnl_price - b.pnl_price,
			align: 'center',
			render: (text, record) => {
				return (
					<>
						{text ? (
							<KrwPrice price={text} coinglobal={coinglobal} />
						) : null}
					</>
				)
			},
		},
		{
			title: '실잔액',
			dataIndex: 'balance_futures',
			sorter: (a, b) => a.balance_futures - b.balance_futures,
			align: 'center',
			render: (text, record) => {
				return (
					<KrwPrice
						coinglobal={coinglobal}
						price={text}
						dp={0}
						color={false}
						theme={false}
					/>
				)
			},
		},
		{
			title: '플랫폼',
			dataIndex: 'platform',
			sorter: (a, b) => a.platform - b.platform,
			align: 'center',
			width: 55,
			render: (text, record) => {
				return text == 0 ? (
					<Tag color="blue">WTS</Tag>
				) : text == 1 ? (
					<Tag color="green">MTS</Tag>
				) : text == 2 ? (
					<Tag color="purple">HTS</Tag>
				) : (
					<Tag color="red">서버</Tag>
				)
			},
		},
		{
			title: '비고',
			dataIndex: 'status',
			align: 'left',
			render: (text, record) => {
				return (
					<Space.Compact
						direction="horizontal"
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'flex-start',
							gap: 3,
						}}>
						{record?.f == 1 ? (
							<Tag color={'#F05551'}>로스컷</Tag>
						) : (
							<Tag
								color={
									text == 2
										? '#007EDA'
										: text == 3
										? '#000000'
										: 'default'
								}>
								{text == 1
									? '미체결'
									: text == 2
									? '체결'
									: text == 3
									? '취소'
									: '-'}
							</Tag>
						)}
						{record?.reduce_position == 5 ? (
							<Tag color={'#272727'}>MIT</Tag>
						) : record?.reduce_position == 2 ? (
							<Tag color={'#F05551'}>익절</Tag>
						) : record?.reduce_position == 6 ? (
							<Tag color={'#007EDA'}>손절</Tag>
						) : record?.reduce_position == 4 ? (
							<Tag color={'#F05551'}>전체청산</Tag>
						) : record?.reduce_position == 3 ? (
							<Tag color={'#F05551'}>현종목청산</Tag>
						) : null}
					</Space.Compact>
				)
			},
		},
	]

	const columns3 = () => [
		{
			title: '주문일자',
			dataIndex: 'created_at',
			sorter: (a, b) => a.created_at - b.created_at,
			align: 'center',
			render: (text, record) => {
				const time = dayjs(text)
					.utc()
					.tz('Asia/Seoul')
					.add(9, 'hours')
					.format('YYYY-MM-DD HH:mm:ss')
				return <Text>{text ? time : '-'}</Text>
			},
		},
		mobile
			? { width: 0 }
			: {
					title: '주문',
					dataIndex: 'id',
					sorter: (a, b) => a.id - b.id,
					width: 60,
					align: 'center',
			  },
		{
			title: '원주문',
			dataIndex: 'original_id',
			sorter: (a, b) => a.original_id - b.original_id,
			width: 60,
			align: 'center',
		},

		...(coinglobal == 'G'
			? [
					!mobile && width >= 1600
						? {
								title: '종목코드',
								dataIndex: 'pairs',
								sorter: (a, b) => a.pairs - b.pairs,
								align: 'center',
								width: 80,
						  }
						: { width: 0 },
					{
						title: '종목명',
						dataIndex: 'groupname',
						sorter: (a, b) => a.groupname > b.groupname ? 1 : -1,
						align: 'center',
					},
					mobile
						? { width: 0 }
						: {
								title: '거래소',
								dataIndex: 'exchange_code',
								sorter: (a, b) => a.exchange_code > b.exchange_code ? 1 : -1,
								width: 60,
								align: 'center',
						  },
			  ]
			: [
					!mobile && width >= 1600
						? {
								title: '종목코드',
								dataIndex: 'exchange_pairs_id',
								sorter: (a, b) => a.exchange_pairs_id - b.exchange_pairs_id,
								align: 'center',
								width: 80,
						  }
						: { width: 0 },
					{
						title: '종목명',
						dataIndex: 'groupname',
						sorter: (a, b) => a.groupname > b.groupname ? 1 : -1,
						align: 'center',
						render: (text, data) => {
							const EP = EPs?.find(
								ep => ep?.id == data?.exchange_pairs_id
							)
							return (
								<>
									<div>{EP?.pairs}</div>
								</>
							)
						},
					},
			  ]),

		{
			title: '매매구분',
			dataIndex: 'open_close',
			sorter: (a, b) => a.open_close > b.open_close ? 1 : -1,
			align: 'center',
			render: (text, record) => {
				return (
					<Tag
						style={{
							color:
								text == 'O'
									? (record?.pnl_price || 0) != 0
										? COLORS.lightGreen
										: COLORS.black
									: COLORS.lightRed,
						}}>
						{record?.status == 3
							? record?.modi
								? '정정'
								: '취소'
							: text == 'O' || record?.status == 1
							? (record?.pnl_price || 0) != 0
								? '전환'
								: '진입'
							: '청산'}
					</Tag>
				)
			},
		},
		{
			title: '주문구분',
			dataIndex: 'long_short',
			sorter: (a, b) => a?.long_short > b?.long_short ? 1 : -1,
			align: 'center',
			render: (text, record) => {
				return (
					<Tag
						style={{
							margin: 0,
							padding: 0,
							color:
								text == 'S'
									? COLORS.lightGreen
									: COLORS.lightRed,
						}}>
						{text == 'L' ? '매수' : '매도'}
					</Tag>
				)
			},
		},
		mobile
			? { width: 0 }
			: {
					title: '구분',
					dataIndex: 'reduce_only',
					align: 'center',
					sorter: (a, b) => a.limit_market > b.limit_market ? 1 : -1,
					render: (text, record) => {
						return (
							<>
								{text == 1
									? 'MIT'
									: record?.limit_market == 'M'
									? '시장가'
									: '지정가'}
							</>
						)
					},
			  },
		{
			title: '주문수량',
			dataIndex: 'amount',
			sorter: (a, b) => a.amount - b.amount,
			align: 'center',
			render: (text, record) => {
				return (
					<>
						{new BigNumber(text)
							//.multipliedBy(record?.leverage)
							.toFormat(0)}
					</>
				)
			},
		},
		{
			title: '주문단가',
			dataIndex: 'order_price',
			sorter: (a, b) => a.order_price - b.order_price,
			align: 'center',
			render: (text, record) => {
				const EP = EPs?.find(e => e.id == record?.exchange_pairs_id)
				return (
					<Price
						style={{
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						{record?.limit_market == 'M'
							? padDP(
									record?.trade_price || 0,
									record?.dp || EP?.dp
							  )
							: padDP(text, record?.dp || EP?.dp)}
					</Price>
				)
			},
		},
		{
			title: '체결수량',
			dataIndex: 'amount_remain_amount',
			align: 'center',
			sorter: (a, b) => a.remain_amount - b.remain_amount,
			render: (text, record) => {
				return (
					<>
						{new BigNumber(record?.amount)
							.minus(record?.remain_amount)
							.multipliedBy(record?.leverage)
							.toFormat(0)}
					</>
				)
			},
		},
		{
			title: '체결단가',
			dataIndex: 'trade_price',
			sorter: (a, b) => a.trade_price - b.trade_price,
			align: 'center',
			render: (text, record) => {
				const EP = EPs?.find(e => e.id == record?.exchange_pairs_id)

				return (
					<Price
						style={{
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						{padDP(text || 0, record?.dp || EP?.dp)}
					</Price>
				)
			},
		},
		{
			title: '수수료',
			dataIndex: 'fee_price',
			sorter: (a, b) => a.fee_price - b.fee_price,
			align: 'center',
			render: (text, record) => {
				return <KrwPrice price={-text} dp={0} coinglobal={coinglobal} />
			},
		},
		{
			title: '매매손익',
			dataIndex: 'pnl_price',
			sorter: (a, b) => a.pnl_price - b.pnl_price,
			align: 'center',
			render: (text, record) => {
				return (
					<>
						{text ? (
							<KrwPrice price={text} coinglobal={coinglobal} />
						) : null}
					</>
				)
			},
		},
		{
			title: '실잔액',
			dataIndex: 'balance_futures',
			sorter: (a, b) => a.balance_futures - b.balance_futures,
			align: 'center',
			render: (text, record) => {
				return (
					<KrwPrice
						coinglobal={coinglobal}
						price={text}
						dp={0}
						color={false}
						theme={false}
					/>
				)
			},
		},
		{
			title: '체결일시',
			dataIndex: 'traded_at',
			sorter: (a, b) => a.traded_at - b.traded_at,
			align: 'center',
			render: (text, record) => {
				const time = dayjs(text)
					.utc()
					.tz('Asia/Seoul')
					.add(9, 'hours')
					.format('YYYY-MM-DD HH:mm:ss')
				return <Text>{text ? time : '-'}</Text>
			},
		},
		{
			title: '플랫폼',
			dataIndex: 'platform',
			sorter: (a, b) => a.platform - b.platform,
			align: 'center',
			width: 55,
			render: (text, record) => {
				return text == 0 ? (
					<Tag color="blue">WTS</Tag>
				) : text == 1 ? (
					<Tag color="green">MTS</Tag>
				) : text == 2 ? (
					<Tag color="purple">HTS</Tag>
				) : (
					<Tag color="red">서버</Tag>
				)
			},
		},
		{
			title: '비고',
			dataIndex: 'status',
			align: 'left',
			render: (text, record) => {
				return (
					<Space.Compact
						direction="horizontal"
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'flex-start',
							gap: 3,
						}}>
						{/*//포지션 감소 여부 5 MIT  2 익절 6 손절  1 청산주문 0 일반주문 4: 전체청산 3: 현종목청산*/}
						{record?.f == 1 ? (
							<Tag color={'#F05551'}>로스컷</Tag>
						) : (
							<Tag
								color={
									text == 2
										? '#007EDA'
										: text == 3
										? '#000000'
										: 'default'
								}>
								{text == 1
									? '미체결'
									: text == 2
									? '체결'
									: text == 3
									? '취소'
									: '-'}
							</Tag>
						)}
						{record?.reduce_position == 5 ? (
							<Tag color={'#272727'}>MIT</Tag>
						) : record?.reduce_position == 2 ? (
							<Tag color={'#F05551'}>익절</Tag>
						) : record?.reduce_position == 6 ? (
							<Tag color={'#007EDA'}>손절</Tag>
						) : record?.reduce_position == 4 ? (
							<Tag color={'#F05551'}>전체청산</Tag>
						) : record?.reduce_position == 3 ? (
							<Tag color={'#F05551'}>현종목청산</Tag>
						) : null}
					</Space.Compact>
				)
			},
		},
	]
	return (
		<TableAPI
			coinglobal={coinglobal}
			className={'plist tradelist'}
			excel={mobile?false:true}
			title={
				<>
					<DateFilter
						setStartDate={setStartDate}
						setEndDate={setEndDate}
						startDate={startDate}
						endDate={endDate}
						mobile={mobile}
					/>

					{mobile ? (
						<Space direction="vertical" size='small'>
							<Select
								value={ep}
								onChange={e => setEp(e)}
								style={{ minWidth: 200 }}>
								<Select.Option value="*" key={'all'}>
									전체종목
								</Select.Option>
								{EPs?.map((ep, i) => (
									<Select.Option
										value={ep?.id}
										key={'option' + ep?.id}>
										{ep?.pairs} ({ep?.groupname})
									</Select.Option>
								))}
							</Select>

							<Select
								value={orderType}
								onChange={e => setOrderType(e)}
								style={{ minWidth: 150 }}>
								<Select.Option key={'s1'} value="">
									최근순
								</Select.Option>
								<Select.Option key={'s2'} value="2">
									오래된순
								</Select.Option>
								<Select.Option key={'s6'} value="6">
									체결일자 최근순
								</Select.Option>
								<Select.Option key={'s5'} value="5">
									체결일자 오래된순
								</Select.Option>
								<Select.Option key={'s3'} value="4">
									매매수익순
								</Select.Option>
								<Select.Option key={'s4'} value="3">
									매매손해순
								</Select.Option>
							</Select>
						</Space>
					) : (
						<>
							<Select
								value={ep}
								onChange={e => setEp(e)}
								style={{ minWidth: 200 }}>
								<Select.Option value="*" key={'all'}>
									전체종목
								</Select.Option>
								{EPs?.map((ep, i) => (
									<Select.Option
										value={ep?.id}
										key={'option' + ep?.id}>
										{ep?.pairs} ({ep?.groupname})
									</Select.Option>
								))}
							</Select>

							<Select
								value={orderType}
								onChange={e => setOrderType(e)}
								style={{ minWidth: 150 }}>
								<Select.Option key={'s1'} value="">
									최근순
								</Select.Option>
								<Select.Option key={'s2'} value="2">
									오래된순
								</Select.Option>
								<Select.Option key={'s6'} value="6">
									체결일자 최근순
								</Select.Option>
								<Select.Option key={'s5'} value="5">
									체결일자 오래된순
								</Select.Option>
								<Select.Option key={'s3'} value="4">
									매매수익순
								</Select.Option>
								<Select.Option key={'s4'} value="3">
									매매손해순
								</Select.Option>
							</Select>
						</>
					)}
				</>
			}
			defaultMaxData={userid ? 300 : 50}
			stylewidth={'100%'}
			height={userid ? 'default' : 500}
			width={mobile ? '100%' : 1300}
			columns={level == 5 ? columns3 : mobile ? columns1 : columns2}
			apikey={`/${userid ? 'group' : 'trade'}/orderlist?type=VER1${
				startDate ? `&sdate=${startDate}` : ''
			}${
				endDate ? `&edate=${endDate}` : ''
			}&ordertype=${orderType}&ep_id=${ep}${
				userid ? '&user_id=' + userid : ''
			}&t=`}
		/>
	)
}

export default TradeList
