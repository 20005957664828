import useSWR from 'swr'
import { TRADE_SOCKET } from '../../constants/LOCALKEYS'

import throttle from 'lodash/throttle'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useFps } from 'react-fps'
import { useToken } from '../auth/token'
import { useExchangeStore } from './exchangeStore'
import { useIndexPrice } from './indexprice'
import { useTradeStore } from './tradeStore'

import { ThemeContext } from '../../../theme/ThemeProvider'
import { CHART_SOCKET_URL } from '../../constants/APIKEYS'
import { useThemeStore } from '../stateStore'
import { useTradeModalStore } from './modalStore'

// WebSocket 인스턴스 생성
let tradeWS: WebSocket | null = null
let lastBar: any = null

let throttleOrderBook: any = []
const throttleOrderBookInterval = 50

let bufferTradeList: any = []
let bufferTradeListTimer: any = null

let current: any = null
let symbol: any = null

let ispartner = 0;

let positionsize: any = 0
let isResize = false

let lastprice = new Map()

let lastcall = new Map()
let lastTrade: any = null

let amtbuffer = 0

let EPSALL: any = []

// 종목별로 쓰로틀을 관리할 Map을 생성합니다.
const throttleMap = new Map()

const joinGroup = (symbol?: string) => {
	//url에 TEST 있을시 테스트모드
	const istest = true || window.location.href.indexOf('TEST') > -1

	//20자리 랜덤숫자
	let randompassword = 'P' + Math.random().toString(36).substr(2, 20)
	// 랜덤한위치에 401이라는 텍스트 삽입
	randompassword =
		randompassword.substr(
			0,
			Math.floor(Math.random() * randompassword.length)
		) +
		'401' +
		randompassword.substr(
			Math.floor(Math.random() * randompassword.length),
			randompassword.length
		)

	if (symbol == undefined) return

	const now = new Date().getTime()


	if (lastcall.has(symbol)) {
		if (now - lastcall.get(symbol) < 1000) {
			//console.log('SKIP', now - lastcall.get(symbol))
			return
		}
	}
	lastcall.set(symbol, now)


	if (tradeWS != null) {
		if (tradeWS && tradeWS.readyState === WebSocket.OPEN) {
			
			tradeWS.send(
				`{"type":"join", "symbol": "${symbol}", "password": "${randompassword}"}`
			)
			// if (istest) {
			// 	tradeWS.send(
			// 		`{"type":"join", "symbol": "${symbol}", "password": "${randompassword}", "tester": 1}`
			// 	)
			// } else {
			// 	tradeWS.send(
			// 		`{"type":"join", "symbol": "${symbol}", "password": "${randompassword}"}`
			// 	)

			// }
		}
	}
}

export function usePartnerSocket() {
	const { data: EPS } = useExchangeStore()

	if (EPS?.length > 0) {
		EPSALL = EPS
	}

	const { isMobile } = useContext(ThemeContext)

	const { data: token } = useToken()
	const { currentFps } = useFps(15)
	const [fps, setFps] = useState(0)

	const { data: themeStore } = useThemeStore()
	const { data: tradeStore, chart_id } = useTradeStore()

	const { data: modalStore, change } = useTradeModalStore()
	current = tradeStore?.ep_id
	symbol = tradeStore?.pairs
	ispartner = modalStore?.partner
	isResize = themeStore?.isResize


	const { mutate: mutateIndexPrice } = useIndexPrice(0)

	useEffect(() => {
		if (currentFps >= 50) {
			setFps(120)
		} else if (currentFps > 40) {
			setFps(60)
		} else if (currentFps > 20) {
			setFps(20)
		} else {
			setFps(0)
		}
	}, [currentFps])



	const handleTradePrice = useCallback(
		async trade => {
			if (!trade?.ep_id) return

			let throttleFn
			// 해당 종목의 쓰로틀 함수가 이미 존재하는지 확인합니다.
			if (throttleMap.has(trade.ep_id)) {
				// 이미 쓰로틀 함수가 존재하면 그 함수를 가져옵니다.
				throttleFn = throttleMap.get(trade.ep_id)
			} else {
				// 쓰로틀 함수가 없으면 새로 생성합니다.
				throttleFn = throttle(async () => {
					mutateIndexPrice(
						[
							{ price: lastprice.get(trade.ep_id) || 0 },
							{ price: lastprice.get(trade.ep_id) || 0 },
						],
						trade.ep_id
					)
				}, 1000 - fps)
				// 새로 생성한 쓰로틀 함수를 Map에 저장합니다.
				throttleMap.set(trade.ep_id, throttleFn)
			}

			// 쓰로틀 함수를 호출합니다.
			await throttleFn()
		},
		[lastprice, fps]
	)


	// PROCESS TX COMMON
	const processTX = useCallback(
		(trade: any, throttle: any) => {
			lastprice.set(trade.ep_id, trade.price)
			handleTradePrice(trade)
		},
		[]
	)

	// PROCESS T
	const processT = useCallback(
		(getDATA: any, throttle: any) => {
			const tempsplit = getDATA.substring(14).split('|')

			const ep_id = EPSALL?.find(item => item?.pairs == tempsplit[0])?.id

			const trade = {
				timestamp: +getDATA.substring(1, 14),
				ep_id: +ep_id,
				price: +tempsplit[1],
				amount: +tempsplit[2],
				cgubun: tempsplit[3] || '',
			}
			lastTrade = trade

			processTX(trade, throttle)
		},
		[processTX]
	)

	// PROCESS X
	const processX = useCallback(
		(getDATA: any, throttle: any) => {
			const tempsplit = getDATA.substring(1).split('!')

			const eptext = EPSALL?.find(
				(item: any) => item?.pairs == tempsplit[0]
			)?.id

			const temparray = tempsplit[1].split('@')

			temparray?.map((i: any) => {
				const temp2 = i.split('|')
				const trade = {
					timestamp: +temp2[0],
					ep_id: +eptext,
					price: +temp2[1],
					amount: +temp2[2],
					cgubun: temp2[3] || '',
				}

				lastTrade = trade
				processTX(trade, throttle)
				//console.log('X', trade)
				return trade
			})

		},
		[processTX]
	)

	const { data, mutate } = useSWR<any>(
		TRADE_SOCKET,
		async () => {
			if (tradeWS && symbol && tradeWS?.readyState === WebSocket.OPEN) {
				joinGroup(symbol)
			}

			const dataProcess = (getDATA: any) => {
				if (getDATA == null) return
				//트레이드 리스트는 쓰로틀링 제외
				if (!modalStore?.isOnline) change({ isOnline: true })

				if (getDATA[0] == 'T') {
					processT(getDATA, isResize)
				} else if (getDATA[0] == 'X') {
					processX(getDATA, isResize)
				} else if (getDATA[0] == 'O') {
				} else if (getDATA[0] == 'S') {
				} else if (getDATA?.substring(0, 2) == 'CO') {
				} else {
				}
			}

			const onmessage = async (data: any) => {
				let getDATA: any = null
				try {
					getDATA = await data?.data.text()
				} catch (e) {
					try {
						//blob to string
						const reader = new FileReader()
						reader.onload = function () {
							getDATA = reader.result
							dataProcess(getDATA)
						}
						reader.readAsText(data?.data)

						return
					} catch (e) {
						console.log('onmessage error2', e, data?.data)
						return
					}
				}

				dataProcess(getDATA)
			}

			if (tradeWS == null) {
				try {
					console.log('START SOCKET')
					tradeWS = new WebSocket(CHART_SOCKET_URL)
					tradeWS.binaryType = 'blob'

					tradeWS.onclose = () => {
						console.log('@@@@@@@@@@@tradeWS close@@@@@@@@@@@@@')
						tradeWS = null
						mutate()
					}

					tradeWS.onerror = () => {
						console.log('tradeWS error')
					}
					tradeWS.onopen = () => {
						console.log('tradeWS open')
						joinGroup(symbol)
					}
					// console.log('tradeWS', tradeWS)
				} catch (e) {
					console.log('tradeWS error', e)
				}
			}

			if (tradeWS != null) {
				tradeWS.onmessage = onmessage
			}

			return tradeWS
		},
		{
			revalidateIfStale: true,
			revalidateOnFocus: true,
			revalidateOnReconnect: true,
			dedupingInterval: 0,
		}
	)

	const joincurrent = useCallback(() => {
		if (tradeWS != null) {
			if (tradeWS?.readyState === WebSocket.OPEN) {
				joinGroup(symbol)
			}
		} else {
			mutate()
		}
	}, [symbol, tradeWS, token])

	useEffect(() => {
		const setOnline = () => {
			change({ isOnline: true })

			if (isMobile) {
				window.location.reload()
				return
			}

			setTimeout(() => {
				mutate()
				joincurrent()
			}, 500)
		}

		const setOffline = () => {

		}

		window.addEventListener('online', setOnline)
		window.addEventListener('offline', setOffline)

		return () => {
			window.removeEventListener('online', setOnline)
			window.removeEventListener('offline', setOffline)
		}
	}, [isMobile])

	useEffect(() => {
		if (tradeWS?.readyState === WebSocket.OPEN) {
			joincurrent()
			change({ isOnline: true })
		} else {
		}
	}, [tradeWS, tradeWS?.readyState])

	return {
		data,
		updateLastTrade: () => {
		},
		mutate: () => {
			return mutate()
		},
		saveLog: (data: any) => {

		},
		reset() {
			console.log('@RESET')
			current = null
			lastBar = null
			amtbuffer = 0
			bufferTradeList = []
			setTimeout(() => {
				amtbuffer = 0
				current = null
				lastBar = null
				bufferTradeList = []
			}, 500)
		},
		joinGroup: (group: string, ep_id?: string) => {
			const symbol = EPSALL?.find(item => item?.id == ep_id)?.pairs
			if (!symbol) return
			joinGroup(symbol)
			return mutate()
		},
		leaveGroup: (group: string, ep_id?: string) => {
			try {
				const istest = window.location.href.indexOf('TEST') > -1


				if (current == ep_id) {
					return
				}
				const symbol = EPSALL?.find(item => item?.id == ep_id)?.pairs

				if (!symbol) return

				if (tradeWS != null) {
					
					tradeWS &&
					tradeWS.readyState === WebSocket.OPEN &&
					tradeWS.send(`{"type":"leave", "symbol": "${symbol}"}`)
					// if (istest) {
					// 	tradeWS &&
					// 		tradeWS.readyState === WebSocket.OPEN &&
					// 		tradeWS.send(`{"type":"leave", "symbol": "${symbol}", "tester": 1}`)

					// } else {
					// 	tradeWS &&
					// 		tradeWS.readyState === WebSocket.OPEN &&
					// 		tradeWS.send(`{"type":"leave", "symbol": "${symbol}"}`)
					// }
				}
				return mutate()
			} catch (e) {
				console.error(e)
			}
		},
	}
}
