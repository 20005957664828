import { IconCopy, IconSend } from '@tabler/icons-react'
import { Button, Col, Input, InputNumber, Row, Space, message } from 'antd'
import React, { useContext, useState } from 'react'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import API from '../../../../utils/api'
import { useToken } from '../../../hooks'
import { useFetcherSetting } from '../../../hooks/fetcher'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'
import { useMyProfile } from '../../../hooks/user/myprofile'

const GDeposit = ({ mobile = false }) => {
	const { textw, onTertiary, descBox, stroke } =
		useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const { data: token } = useToken()
	const { data } = useFetcherSetting('GINFOD')
	const { data: cs1 } = useFetcherSetting('GCS1')
	const { data: cs2 } = useFetcherSetting('GCS2')
	const { data: bank1 } = useFetcherSetting('GBANK1')
	const { data: bank2 } = useFetcherSetting('GBANK2')
	const { data: bank3 } = useFetcherSetting('GBANK3')
	const [amount, setAmount] = useState<any>(0)
	const [name, setName] = useState('')
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()

	const { data: profile } = useMyProfile()


	return (
		<Space
			direction={mobile ? 'vertical' : 'horizontal'}
			size={'small'}
			style={{
				width: '100%',
				alignItems: mobile ? 'center' : 'flex-start',
			}}>
			<Space
				direction="vertical"
				style={{
					minWidth: mobile ? '100%' : 330,
					alignSelf: 'flex-start',
					height: '100%',
					width: '100%',
				}}
				size={'small'}>
				<Row
					align={'middle'}
					style={{
						background: onTertiary,
						color: textw,
						margin: '10px 0',
						padding: 5,
						width: '100%',
					}}>
					<Col span={24} style={{ textAlign: 'center' }}>
						입금액
					</Col>
				</Row>
				<Row align={'middle'}>
					<Col span={6}>신청금액</Col>
					<Col span={13}>
						<InputNumber
							value={amount}
							formatter={value =>
								`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')?.replace(/[^0-9,]/g, '')
							}
							parser={value => value!.replace(/\$\s?|(,*)/g, '')?.replace(/[^0-9]/g, '')}
							step={10000}
							onChange={e => setAmount(e)}
							style={{
								textAlign: 'right',
								width: '100%',
								flex: 1,
							}}
						/>
					</Col>
					<Col span={1}>원</Col>
					<Col span={4}>
						<Button
							size="small"
							onClick={() => {
								setAmount(0)
							}}>
							재입력
						</Button>
					</Col>
				</Row>
				<Row align={'middle'}>
					<Col span={24}>
						<Space.Compact>
							<Button
								size="small"
								onClick={() => {
									setAmount(amount + 1000000)
								}}>
								+100만
							</Button>
							<Button
								size="small"
								onClick={() => {
									setAmount(amount + 500000)
								}}>
								+50만
							</Button>
							<Button
								size="small"
								onClick={() => {
									setAmount(amount + 100000)
								}}>
								+10만
							</Button>
							<Button
								size="small"
								onClick={() => {
									setAmount(amount + 50000)
								}}>
								+5만
							</Button>
							<Button
								size="small"
								onClick={() => {
									setAmount(amount + 10000)
								}}>
								+1만
							</Button>
						</Space.Compact>
					</Col>
				</Row>
				<Row
					align={'middle'}
					style={{
						background: onTertiary,
						color: textw,
						margin: '10px 0',
						padding: 5,
					}}>
					<Col span={24} style={{ textAlign: 'center' }}>
						보내실곳
					</Col>
				</Row>
				<Row align={'middle'}>
					{profile?.vaccount ? (
						<Col span={24}>
							<Space.Compact style={{ width: '100%' }}>
								<Input
									value={
										profile?.vaccount ==
										'undefined/undefined/undefined'
											? '-'
											: ''
									}
									readOnly
								/>
								<Button
									icon={<IconCopy />}
									onClick={async () => {
										if (
											navigator.clipboard &&
											window.isSecureContext
										) {
											await navigator.clipboard.writeText(
												profile?.vaccount?.split(
													'/'
												)?.[1]
											)
										} else {
											// Use the 'out of viewport hidden text area' trick
											const textArea =
												document.createElement(
													'textarea'
												)
											textArea.value =
												profile?.vaccount?.split(
													'/'
												)?.[1]

											// Move textarea out of the viewport so it's not visible
											textArea.style.position = 'absolute'
											textArea.style.left = '-999999px'

											document.body.prepend(textArea)
											textArea.select()

											try {
												document.execCommand('copy')
											} catch (error) {
												console.error(error)
											} finally {
												textArea.remove()
											}
										}

										message.success(
											'계좌번호가 복사되었습니다.'
										)
									}}></Button>
							</Space.Compact>
						</Col>
					) : (
						<Col span={24}>
							{/*
							//현재 가상계좌사용안함
							<Button
								style={{ width: '100%' }}
								type="primary"
								onClick={() => {
									if (
										profile?.level == 6 ||
										profile?.level == 6 ||
										profile?.level == -8
									) {
										message.error(
											'모의회원은 가상계좌 신청이 불가능합니다.'
										)
										return
									}
									API.post(
										'/user/my/vaccount',
										token + '',
										{}
									).then(res => {
										if (res?.result?.success) {
											message.success(
												'가상계좌 신청이 완료되었습니다.'
											)
										} else {
											if (res?.result?.message) {
												message.error(
													res?.result?.message
												)
											} else {
												message.error(
													'가상계좌 신청에 실패하였습니다.'
												)
											}
										}
									})
								}}>
								가상계좌신청
							</Button> */}
						</Col>
					)}
				</Row>
				{/* <Row align={'middle'}>
					<Col span={6}>가입코드</Col>
					<Col span={18}>
						<Input value={profile?.referral_code || '-'} readOnly />
					</Col>
				</Row> */}
								
				<Row align={'middle'}>
					<Col span={6}>은행</Col>
					<Col span={18}>
						<Input value={bank1} readOnly />
					</Col>
				</Row>
				<Row align={'middle'}>
					<Col span={6}>계좌번호</Col>
					<Col span={18}>
						<Input value={bank2} readOnly />
					</Col>
				</Row>
				<Row align={'middle'}>
					<Col span={6}>예금주</Col>
					<Col span={18}>
						<Input value={bank3} readOnly />
					</Col>
				</Row>

				{/* <Row
					align={'middle'}
					style={{
						background: onTertiary,
						color: textw,
						margin: '10px 0',
						padding: 5,
					}}>
					<Col span={24} style={{ textAlign: 'center' }}>
						입금자
					</Col>
				</Row> */}
				{/* <Row align={'middle'}>
					<Col span={6}>입금자명</Col>
					<Col span={18}>
						<Input
							type="text"
							placeholder="가상계좌 부여시 부여됩니다."
							value={name}
							onChange={e => setName(e.target.value)}
							readOnly></Input>
					</Col>
				</Row> */}
				<Row
					align={'middle'}
					style={{
						background: onTertiary,
						color: textw,
						margin: '10px 0',
						padding: 5,
					}}>
					<Col span={24} style={{ textAlign: 'center' }}>
						고객센터
					</Col>
				</Row>
				<Row align={'middle'}>
					<Col span={6}>고객센터1</Col>
					<Col span={18}>
						<Input value={cs1}></Input>
					</Col>
				</Row>
				<Row align={'middle'}>
					<Col span={6}>고객센터2</Col>
					<Col span={18}>
						<Input value={cs2}></Input>
					</Col>
				</Row>
				<Row style={{ marginTop: 10 }}>
					<Col span={24} style={{ textAlign: 'right' }}>
						<Button
							type="primary"
							icon={<IconSend size={14} />}
							onClick={() => {
								if (amount < 1) {
									if (mobile) {
										alert('입금액을 확인해주세요.')
									} else {
										message.error('입금액을 확인해주세요.')
									}
									return
								}

								if(profile?.level == 5 || profile?.level == -10){
									message.error('입금이 불가한 계정입니다')
									return;
								}

								// if (name.length < 1) {
								// 	if (mobile) {
								// 		alert('입금자명을 확인해주세요.')
								// 	} else {
								// 		message.error(
								// 			'입금자명을 확인해주세요.'
								// 		)
								// 	}
								// 	return
								// }

								if (
									profile?.level != 6 &&
									profile?.level != 7
								) {
									/**20240320
									 * 가상계좌 체크 X 수정
									 */
									/*
									if (!profile?.vaccount) {
										if (mobile) {
											alert('가상계좌를 신청해주세요.')
										} else {
											message.error(
												'가상계좌를 신청해주세요.'
											)
										}
										return
									}
									*/
								}

								API.post('/cash/request', token + '', {
									type: 'D',
									amount,
									bankaccount: profile?.vaccount || '',
									name,
								}).then(res => {
									if (res?.result?.success) {
										if (mobile) {
											alert('입금신청이 완료되었습니다.')
										} else {
											message.success(
												'입금신청이 완료되었습니다.'
											)
										}
									} else {
										if (mobile) {
											alert(
												res?.result?.message ||
													'입금신청에 실패하였습니다.'
											)
										} else {
											message.error(
												res?.result?.message ||
													'입금신청에 실패하였습니다.'
											)
										}
									}
									mutateModalStore({
										...modalStore,
										gDeposit: false,
									})
								})
							}}>
							입금신청
						</Button>
					</Col>
				</Row>
			</Space>
			{mobile ? (
				<div
					style={{
						width: '100%',
						minWidth: 330,
						height: 200,
						overflowY: 'auto',
						overflowX: 'hidden',
						border: '1px solid #cdcdcd',
						padding: 5,
						marginTop: 10,
						fontSize: 12,
						lineHeight: 1.1,
						whiteSpace: 'pre-wrap',
					}}>
					{data}
				</div>
			) : (
				<div
					style={{
						width: '100%',
						height: 540,
						minWidth: 527,
						overflowY: 'auto',
						overflowX: 'hidden',
						border: '1px solid #cdcdcd',
						padding: 5,
						marginTop: 10,
						whiteSpace: 'pre-wrap',
					}}>
					{data}
				</div>
			)}
		</Space>
	)
}

export default React.memo(GDeposit)
