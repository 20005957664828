import React, { useContext, useState } from 'react'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { CButton } from 'app/components/Common'
import { Text } from 'styles/reactnative'
import { PaginationItems, PaginationLists } from './styles'
import { COLORS } from 'app/constants'
import { ThemeContext } from '../../../../theme/ThemeProvider'
const CPagination = ({
	count,
	dataLength,
	lastData,
	page,
	setPage,
	style,
}: any) => {
	const [startIndex, setStartIndex] = useState(1)
	const [endIndex, setEndIndex] = useState(1)

	const { textw } = useContext(ThemeContext).theme.COLORS

	const handleNext = () => {
		if (page === endIndex) {
			const newEndIndex = Math.min(endIndex + 4, dataLength)
			setEndIndex(newEndIndex)
			setStartIndex(Math.max(newEndIndex - 4, 1))
		}
		setPage(Math.min(page + 1, dataLength))
	}
	const handlePrev = () => {
		if (page === startIndex) {
			const newStartIndex = Math.max(startIndex - 5, 1)
			setStartIndex(newStartIndex)
			setEndIndex(Math.min(newStartIndex + 4, dataLength))
		}
		setPage(Math.max(page - 1, 1))
	}

	const paginationItems = Array.from(
		{ length: endIndex - startIndex + 1 },
		(_, i) => i + startIndex
	).filter(item => item <= dataLength)
	return (
		<PaginationLists style={{ ...style }}>
			{page !== 1 ? (
				<CButton
					onClick={handlePrev}
					icon={<IconChevronLeft size={14} />}
					style={{
						paddingBlock: 8,
						paddingInline: 10,
					}}
				/>
			) : null}

			{paginationItems.map((item, index) => (
				<PaginationItems
					onClick={() => setPage(item)}
					style={{
						marginRight: 5,
						marginLeft: item === startIndex ? 5 : 0,
						background:
							page === item ? COLORS.primary : 'transparent',
						color: page == item ? COLORS.white : 'black',
						border: 'none',
					}}
					key={index.toString()}>
					<Text>{item}</Text>
				</PaginationItems>
			))}
			{!lastData && dataLength > 2 ? (
				<CButton
					onClick={handleNext}
					icon={<IconChevronRight size={14} />}
					style={{
						paddingBlock: 8,
						paddingInline: 10,
						background:
							page === dataLength
								? COLORS.greyWhite
								: 'transparent',
					}}
				/>
			) : null}
		</PaginationLists>
	)
}

export default React.memo(CPagination)
