import { useToken, useWindowDimensions } from 'app/hooks'
import React from 'react'
import DerivativesFormBtns from './DerivativesFormBtns'
import OrderPriceInput from './OrderPriceInput'
import OrderValueInput from './OrderValueInput'

import { useOrderStore } from '../../../../../../hooks/trade/orderStore'
import SpaceAva from './SpaceAva'
import TabBar from './TabBar'
import { DerivativesBodyWrapper } from './styles'
import OrderValueQty from './OrderValueInput/OrderValueQty'

const DerivativesBody = ({ isMobile = false }) => {
	const { data: orderStore } = useOrderStore()
	const [tab, setTab] = React.useState(orderStore?.limit_market || 'L')
	const { data: token } = useToken()


	return (
		<DerivativesBodyWrapper
		>
			<SpaceAva isMobile={isMobile} />

			<TabBar tab={tab} setTab={setTab} />

			<OrderValueQty />
			
			{tab != 'M' && <OrderPriceInput />}
			<OrderValueInput />
			
			{token ? (
				<DerivativesFormBtns
					isMobile={isMobile}
					ismit={tab == 'MIT'}
					tab={tab}
				/>
			) : (
				<></>
			)}
		</DerivativesBodyWrapper>
	)
}

export default React.memo(DerivativesBody)
