import React, { useContext, useState } from 'react'
import {
	IconAt,
	IconMessage2Off,
	IconUserCheck,
	IconUserCircle,
} from '@tabler/icons-react'
import { IconUserEdit } from '@tabler/icons-react'
import { MButton } from 'app/mobile/components'
import { MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import SecurityLists from './SecurityLists'
import { useToken, useWindowDimensions } from 'app/hooks'
import TrustedDevices from './TrustedDevices'

// import WithDrawalLock from './WithDrawalLock'
// import WithdrawalAddressBook from './WithdrawalAddressBook'
// import WithdrawalAddressWhiteList from './WithdrawalAddressWhiteList'
import SecurityListTitle from './SecurityListTitle'
import PasswordContainer from './PasswordContainer'
import VerifyContainer from './VerifyContainer'
import SmsAuthContainer from './SmsAuthContainer'
import EmailAuthContainer from './EmailAuthContainer'
import ProfileEditContainer from './ProfileEditContainer'
const MAccountSecurity = () => {
	const { mutate } = useToken()
	const { height } = useWindowDimensions()
	const { textw } = useContext(ThemeContext).theme.COLORS

	const [openDevice, setOpenDevice] = useState(false)
	// const [openWithdrawalLock, setOpenWithdrawalLock] = useState(false)
	// const [withdrawalBook, setWithdrawalBook] = useState(false)
	// const [withdrawalWhiteList, setWithdrawalWhiteList] = useState(false)
	const [password, setPassword] = useState(false)
	const [verify, setVerify] = useState(false)
	const [smsAuth, setSmsAuth] = useState(false)
	const [emailAuth, setEmailAuth] = useState(false)
	const [profileEdit, setProfileEdit] = useState(false)
	return (
		<MView style={{ width: '100%' }}>
			<MView style={{ width: '100%', marginTop: 10 }}>
				<SecurityListTitle title={'accountInfo'} />
				<SecurityLists
					title={'profilePicture'}
					IconComp={<IconUserCircle size={14} color={textw} />}
					open={profileEdit}
					setOpen={setProfileEdit}
					BottomComp={ProfileEditContainer}
					maxHeight={Math.min(350, height / 1.4)}
				/>
				<SecurityLists
					title={'emailAuthentication'}
					IconComp={<IconAt size={14} color={textw} />}
					open={emailAuth}
					setOpen={setEmailAuth}
					BottomComp={EmailAuthContainer}
					bottomCompHeight={height / 2}
				/>

				<SecurityLists
					title={'smsAuthentication'}
					IconComp={<IconMessage2Off size={14} color={textw} />}
					open={smsAuth}
					setOpen={setSmsAuth}
					BottomComp={SmsAuthContainer}
					maxHeight={Math.min(300, height / 2)}
				/>
				<SecurityLists
					title={'identityVerification'}
					IconComp={<IconUserCheck size={14} color={textw} />}
					open={verify}
					setOpen={setVerify}
					BottomComp={VerifyContainer}
					bottomCompHeight={height / 1.1}
				/>
				<SecurityLists
					title={'password'}
					IconComp={<IconUserEdit size={14} color={textw} />}
					open={password}
					maxHeight={200}
					setOpen={setPassword}
					BottomComp={PasswordContainer}
				/>
			</MView>
			<MView style={{ width: '100%', marginTop: 10 }}>
				<SecurityListTitle title={'advancedProtection'} />
				<SecurityLists
					title={'twoFactorAuthentication'}
					IconComp={<IconUserEdit size={14} color={textw} />}
					action={true}
				/>
			</MView>
			<MView style={{ width: '100%', marginTop: 10 }}>
				<SecurityListTitle title={'withdrawalSecurity'} />
				<SecurityLists
					title={'withdrawalAddressWhiteList'}
					IconComp={<IconUserEdit size={14} color={textw} />}
					// open={withdrawalWhiteList}
					// setOpen={setWithdrawalWhiteList}
					// BottomComp={WithdrawalAddressWhiteList}
					action={true}
				/>
				<SecurityLists
					title={'withdrawalAddressBook'}
					IconComp={<IconUserEdit size={14} color={textw} />}
					// open={withdrawalBook}
					// setOpen={setWithdrawalBook}
					// BottomComp={WithdrawalAddressBook}
					action={true}
				/>
				<SecurityLists
					title={'newAddressWithdrawalLock'}
					IconComp={<IconUserEdit size={14} color={textw} />}
					// open={openWithdrawalLock}
					// setOpen={setOpenWithdrawalLock}
					// BottomComp={WithDrawalLock}
					action={true}
				/>
			</MView>
			<MView style={{ width: '100%', marginTop: 10 }}>
				<SecurityListTitle title={'accountActivities'} />
				<SecurityLists
					title={'trustedDevices'}
					IconComp={<IconUserEdit size={14} color={textw} />}
					open={openDevice}
					setOpen={setOpenDevice}
					BottomComp={TrustedDevices}
				/>
				<SecurityLists
					title={'accountActivities'}
					IconComp={<IconUserEdit size={14} color={textw} />}
					action={true}
				/>
			</MView>
			<MButton
				title={'logout'}
				style={{
					width: '100%',
					alignItems: 'flex-start',
					paddingBlock: 10,
					color: 'black',
				}}
				onClick={() => {
					mutate(null)
					window.location.href = '/logout'
				}}
			/>
		</MView>
	)
}

export default MAccountSecurity
