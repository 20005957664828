import { Tag } from 'antd'
import { useIndexPrice } from 'app/hooks/trade/indexprice'
import BigNumber from 'bignumber.js'
import React from 'react'
import { Text } from 'styles/reactnative'
import CRenderEmpty from '../../components/CRenderEmpty'
import { useTradeStore } from '../../hooks/trade/tradeStore'
import { useExchangeStore } from '../../hooks/trade/exchangeStore'
import CPartnerRenderEmpty from 'app/components/CRenderEmpty/CPartnerRenderEmpty'

const MarkPrice = ({ data, partner = false }) => {
	let { getPrice, reset } = useIndexPrice(data?.exchange_pairs_id)

	const { data: tradeStore } = useTradeStore()

	const { EPs } = useExchangeStore()

	const EP = EPs?.find(item => item.id === data?.exchange_pairs_id)

	if (
		getPrice(data?.exchange_pairs_id) === 0 ||
		!getPrice(data?.exchange_pairs_id)
	) {
		reset('MarkPrice')
	}

	return (
		<Text
			style={{
				justifyContent: 'flex-end',
				alignItems: 'center',
			}}>
			<span
				style={{
					backgroundColor: '#efefef',
					padding: 1,
					borderRadius: 2,
					fontSize: 13,
				}}>
				<span style={{ fontSize: 5 }}>현재</span>{' '}
				{BigNumber(getPrice(data?.exchange_pairs_id)).toFormat(
					EP?.dp || 3
				)}
			</span>
			{!tradeStore?.loading && (
				<>
					{partner ? (
						<CPartnerRenderEmpty id={data?.exchange_pairs_id} />
					) : (
						<CRenderEmpty id={data?.exchange_pairs_id} />
					)}
				</>
			)}
		</Text>
	)
}

export default MarkPrice
