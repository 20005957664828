import { CPagination } from 'app/components/Common'
import React, { useContext, useEffect, useState } from 'react'
import AnnouncementListItems from './AnnouncementListItems'
import { AnnouncementListSection } from './styles'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from '../../../../../theme/ThemeProvider'

const AnnouncementList = ({
	data,
	page,
	setPage,
	show,
	setShow,
	search,
	count,
	searchshow = false,
}) => {
	const [list, setList] = useState([])
	const { textsilver } = useContext(ThemeContext).theme.COLORS

	const itemsPerPage = 20
	const totalPages = Math.ceil(data?.list?.length / itemsPerPage)
	const isLastPage =
		page !== totalPages && data?.list?.length % itemsPerPage !== 0
	useEffect(() => {
		const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
		const result = data?.list?.filter(item => {
			const regex = new RegExp(escapedSearch, 'i')
			return regex.test(item?.title)
		})

		setList(result)
	}, [search, data])

	return (
		<AnnouncementListSection
			style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, paddingTop: 15, paddingLeft: 5, }}>
			{data?.list?.length ? (
				list?.map((item, index) => (
					<AnnouncementListItems
						key={index.toString()}
						item={item}
						index={index}
						show={show}
						setShow={setShow}
						searchshow={searchshow}
					/>
				))
			) : (
				<MView style={{ padding: 20 }}>
					<MText style={{ color: textsilver }}>
						공지가 없습니다.
					</MText>
				</MView>
			)}

			<CPagination
				count={count}
				dataLength={data?.list?.length}
				lastData={isLastPage}
				page={page}
				setPage={setPage}
				style={{ marginTop: 30 }}
			/>
		</AnnouncementListSection>
	)
}

export default AnnouncementList
