import styled from 'styled-components'

export const DerivativesBodyWrapper = styled.div`
	padding-inline: 5px;
	align-items: flex-start;
	justify-content: center;
	min-width: 100%;
`
export const OrderValueInputSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 10px;
`
export const PercentageFormWrapper = styled.div`
	display: flex;
	flex-direction: row;
	background-color: ${props => props.theme.COLORS.gray};
	justify-content: center;
	align-items: center;
	border-radius: 5px;
`
export const QuantityCosts = styled.div`
	display: flex;
	flex-direction: row;
	overflow-x: 'auto';
	overflow-y: hidden;
	margin-inline: 5px;
	::-webkit-scrollbar {
		height: 0px;
	}

	::-webkit-scrollbar-track {
		background-color: #f1f1f1;
	}

	::-webkit-scrollbar-thumb {
		background-color: #888;
		border-radius: 5px;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: #555;
	}
`
