import React, { useEffect, useMemo, useState } from 'react'
import ChartContainer from './ChartContainer'
import { TradeChartSectionWrapper } from './styles'
import TradeChartNavBar from './TradeChartNavBar'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { useTradeStore } from '../../../../hooks/trade/tradeStore'
import { Spin } from 'antd'
import { View } from '../../../../../styles/reactnative'
import { COLORS } from '../../../../constants'
import { ThemeContext } from '../../../../../theme/ThemeProvider'

const TradeChartSection = ({ isDragging, split = false }) => {
	const [tab, setTab] = useState('1m')

	const { theme } = React.useContext(ThemeContext)

	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()
	const { data: tradeStore } = useTradeStore()

	const tabs = useMemo(() => {
		return modalStore?.tabs
			? modalStore?.tabs
			: ['1m', '5m', '15m', '30m', '1h', '1d', '1w', '1M']
	}, [modalStore?.tabs])

	useEffect(() => {
		if (!modalStore?.tabs) mutateModalStore({ ...modalStore, tabs: tabs })
	}, [tabs])

	useEffect(() => {
		if (modalStore?.charttab) {
			if (modalStore?.activechart == 0) {
				setTab(modalStore?.charttab)
				mutateModalStore({ ...modalStore, charttab: null })
			}
		}
	}, [modalStore?.charttab])

	return (
		<TradeChartSectionWrapper>
			<TradeChartNavBar tab={tab} setTab={setTab} tabs={tabs} />
			{tradeStore?.loading ? (
				<View
					style={{
						width: '100%',
						background: theme.COLORS.Tertiary,
						height: '100%',
					}}>
					<Spin />
				</View>
			) : (
				<ChartContainer
					tab={tab}
					isDragging={isDragging}
					mobile={false}
					split={split}
				/>
			)}
		</TradeChartSectionWrapper>
	)
}

export default React.memo(TradeChartSection)
