import React, { useContext } from 'react'
import { IconMail } from '@tabler/icons-react'
import { ThemeContext } from 'theme/ThemeProvider'
import IdentifyVerification from './IdentifyVerification'
import { AuditSettingSection } from './styles'

const MAuditSetting = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const levelOne = [
		{
			title: 'successfullyRegistered',
			icon: <IconMail color={textw} size={18} />,
		},
	]
	const levelTwo = [
		{
			title: 'id',
			icon: <IconMail color={textw} size={18} />,
		},
		{
			title: 'selfie',
			icon: <IconMail color={textw} size={18} />,
		},
	]
	const levelThree = [
		{
			title: 'proofOfAddress',
			icon: <IconMail color={textw} size={18} />,
		},
	]
	return (
		<AuditSettingSection>
			<IdentifyVerification level={levelOne} />
			<IdentifyVerification level={levelTwo} />
			<IdentifyVerification level={levelThree} last={true} />
		</AuditSettingSection>
	)
}

export default MAuditSetting
