import React, { useEffect } from 'react'

import { Input, Modal, Tag } from 'antd'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { useUserSocket } from 'app/hooks/trade/userSocket'
import { MView } from 'styles/mobilePageStyles'
import { Text } from '../../../styles/reactnative'
import TableAPI from '../../components/TableAPI'
import UserContent from '../../components/UserContent'
import PartnerRealtimeCustomer from './PartnerRealtimeCustomer'
import PartnerRealtimePosition from './PartnerRealtimePosition'
import PartnerRealtimeWait from './PartnerRealtimeWait'
import { useToken } from 'app/hooks'

export function PartnerRealtime({ modalshow = false, setModalshow = e => { } }) {

	const { data: token } = useToken()
	const [modal, setModal] = React.useState<any>(false)
	const [selectedData, setSelectedData] = React.useState<any>(undefined)
	const { change } = useTradeModalStore()
	const socket = useUserSocket()

	useEffect(() => {
		change({
			partner: 1
		})

		return () => {
			change({
				partner: 0
			})
		}
	}, [])

	useEffect(() => {
		setModalshow(false)
		if (modalshow) {
			setTimeout(() => {
				setModal(true)
				setSelectedData(null)
			}, 100)
		} else {
		}
	}, [modalshow])

	return (
		<MView
			style={{
				width: '100%',
				alignContent: 'center',
				justifyContent: 'center',
				justifyItems: 'center',
				alignItems: 'center',
				marginBottom: 150,
				gap: 10,
			}}>
			{token && (
				<>
					<PartnerRealtimeCustomer />
					<PartnerRealtimePosition />
					<PartnerRealtimeWait />
					<TableAPI
						title={'실시간 장현황'}
						className={'plist'}
						columns={() => [
							{
								title: '구분코드',
								dataIndex: 'code',
								align: 'center',
								key: 'code',
								width: 80,
								render: (text, record) => {
									return <Text>{text}</Text>
								},
							},
							{
								title: '구분이름',
								dataIndex: 'groupname',
								key: 'groupname',
								align: 'center',
								render: (text, record) => {
									return record?.edit ? (
										<Input
											size="small"
											defaultValue={text}
											onChange={e => {
												record.adminname = e.target.value
											}}
										/>
									) : (
										<Text style={{ fontWeight: 'bold' }}>
											{text}
										</Text>
									)
								},
							},
							{
								title: '종목코드',
								dataIndex: 'pairs',
								key: 'pairs',
								render: (text, record) => {
									return <Text>{text}</Text>
								},
							},
							{
								title: '종목이름',
								align: 'center',
								dataIndex: 'name',
								key: 'name',
								render: (text, record) => {
									return <Text>{text}</Text>
								},
							},
							{
								title: '상태',
								dataIndex: 'openstatus',
								align: 'center',
								render: (text, record) => {
									return (
										<Tag color={text == 1 ? 'green' : 'red'}>
											{text == 1
												? '운영중'
												: text == 4
													? '월물만료'
													: text == 3
														? '강제중지'
														: '장마감'}
										</Tag>
									)
								},
							},
						]}
						usermodal={true}
						height={'default'}
						apikey={`/group/exchangepairs?ov=1&t=`}
					/>
				</>
			)}


			<Modal
				open={modal}
				onCancel={() => setModal(false)}
				width={'100%'}
				style={{ width: '90%' }}>
				<UserContent
					showOpen={modal}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					defaultfold={selectedData ? false : true}
				/>
			</Modal>
		</MView>
	)
}
