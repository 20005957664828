import React from 'react'
import { CSSProperties } from 'styled-components'

interface IProps {
	src?: any
	size?: number
	style?: CSSProperties
	isTrue?: boolean
}
const BottomLogo = ({ src, isTrue }: IProps) => {
	return (
		<img
			src={src}
			alt={'logo'}
			width={17}
			height={17}
			style={{
				backgroundSize: 'cover',
				// filter: `brightness(0.5) hue-rotate(90deg) ${
				// 	isTrue
				// 		? 'tint(#0095FF, 80%)'
				// 		: 'saturate(0%) brightness(100%)'
				// }`,
				filter: `${
					isTrue ? 'hue-rotate(35deg) saturate(100%) brightness(0.7)' : 'brightness(0.5) saturate(0%)'
				}`,
			}}
		/>
	)
}

export default React.memo(BottomLogo)
