import { Button, DatePicker, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import EntryTime from '../../components/EntryTime'
import KrwPrice from '../../components/KrwPrice'
import TableAPI from '../../components/TableAPI'
import { useTradeModalStore } from '../../hooks/trade/modalStore'

import dayjs from 'dayjs'
import { useCoinGlobal } from '../../hooks/stateStore/coinGlobal'
import { COINHIDE } from 'app/constants/APIKEYS'

const CalcList = ({ user_id = null, footer = 1, mobile = false, partner=false }) => {
	const now = dayjs()

	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()
	const [startDate, setStartDate] = useState<any>(now.format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState<any>(now.format('YYYY-MM-DD'))
	const { data: coinglobal, mutate: mutateCG } = useCoinGlobal()

	//00시 ~ 06시까지는 전날로 계산

	useEffect(() => {
		if (now.hour() < 6) {
			setStartDate(now.subtract(1, 'day').format('YYYY-MM-DD'))
			setEndDate(now.subtract(1, 'day').format('YYYY-MM-DD'))
		}
	}, [])

	const [isAll, setIsAll] = useState(false)

	const order = ' daydate asc, id asc '

	//startdate, enddate, excepttype, userid, isall, type, order, searchWord, maxData, page
	console.log(startDate, endDate)

	const url = `/${user_id ? 'group' : 'trade'}/dailyresult?t=
		${(startDate && `&startdate=${startDate}`) || ''}
		${(endDate && `&enddate=${endDate}`) || ''}
		${(isAll && `&isall=${isAll}`) || ''}
		${(order && `&order=${order}`) || ''}
		${(user_id && `&user_id=${user_id}`) || ''}
	`

	const columnmobile = () => [
		{
			title: '날짜',
			dataIndex: 'daydate',
			key: 'daydate',
			align: 'center',
			width: 80,
			render: (text, data) => {
				return <EntryTime time={text} format={'YYYY-MM-DD'} />
			},
		},
		{
			title: '입출금',
			align: 'center',
			children: [
				{
					title: '입금총액',
					dataIndex: 'total_deposit_amount',
					key: 'total_deposit_amount',
					align: 'center',
					render: (text, data) => {
						return (
							<KrwPrice
								price={
									Math.abs(text) +
									Math.abs(data.total_deposit_amount_extra)
								}
								coinglobal={coinglobal}
							/>
						)
					},
				},
				{
					title: '출금총액',
					dataIndex: 'total_withdraw_amount',
					key: 'total_withdraw_amount',
					align: 'center',
					render: (text, data) => {
						return (
							<KrwPrice
								price={
									-Math.abs(text) -
									Math.abs(data.total_withdraw_amount_extra)
								}
								coinglobal={coinglobal}
							/>
						)
					},
				},
			],
		},

		{
			title: '실현손익',
			dataIndex: 'total_trade_price',
			key: 'total_trade_price',
			align: 'center',
			render: (text, data) => {
				return <KrwPrice price={text} coinglobal={coinglobal} />
			},
		},
	]

	
	const columnpartner = () => [
		{
			title: '날짜',
			dataIndex: 'daydate',
			key: 'daydate',
			align: 'center',
			width: 80,
			render: (text, data) => {
				return <EntryTime time={text} format={'YYYY-MM-DD'} />
			},
		},
		{
			title: '입금총액',
			align: 'center',
			children: !COINHIDE
				? [
					{
						title: '일반입금',
						dataIndex: 'total_deposit_amount',
						key: 'total_deposit_amount',
						align: 'center',
						width: 150,
						render: (text, data) => {
							return (
								<KrwPrice
									price={Math.abs(text)}
									coinglobal={coinglobal}
								/>
							)
						},
					},
					{
						title: '별도입금',
						dataIndex:
							'total_deposit_amount_extra',
						key: 'total_deposit_amount_extra',
						align: 'center',
						width: 150,
						render: (text, data) => {
							return (
								<KrwPrice
									price={Math.abs(text)}
									coinglobal={coinglobal}
								/>
							)
						},
					},
					{
						title: '코인>해선',
						dataIndex:
							'total_deposit_amount_move',
						key: 'total_deposit_amount_move',
						align: 'center',
						width: 150,
						render: (text, data) => {
							return (
								<KrwPrice
									price={Math.abs(text)}
									coinglobal={coinglobal}
								/>
							)
						},
					},
				]
				: [
					{
						title: '일반입금',
						dataIndex: 'total_deposit_amount',
						key: 'total_deposit_amount',
						align: 'center',
						width: 150,
						render: (text, data) => {
							return (
								<KrwPrice
									price={Math.abs(text)}
									coinglobal={coinglobal}
								/>
							)
						},
					},
					{
						title: '별도입금',
						dataIndex:
							'total_deposit_amount_extra',
						key: 'total_deposit_amount_extra',
						align: 'center',
						width: 150,
						render: (text, data) => {
							return (
								<KrwPrice
									price={Math.abs(text)}
									coinglobal={coinglobal}
								/>
							)
						},
					},
				],
		},
		{
			title: '출금총액',
			align: 'center',
			children: !COINHIDE
				? [
					{
						title: '일반',
						dataIndex: 'total_withdraw_amount',
						key: 'total_withdraw_amount',
						align: 'center',
						width: 150,
						render: (text, data) => {
							return (
								<KrwPrice
									price={-Math.abs(text)}
									coinglobal={coinglobal}
								/>
							)
						},
					},
					{
						title: '별도',
						dataIndex:
							'total_withdraw_amount_extra',
						key: 'total_withdraw_amount_extra',
						align: 'center',
						width: 150,
						render: (text, data) => {
							return (
								<KrwPrice
									price={-Math.abs(text)}
									coinglobal={coinglobal}
								/>
							)
						},
					},
					{
						title: '해선>코인',
						dataIndex:
							'total_withdraw_amount_move',
						key: 'total_withdraw_amount_moove',
						align: 'center',
						width: 150,
						render: (text, data) => {
							return (
								<KrwPrice
									price={-Math.abs(text)}
									coinglobal={coinglobal}
								/>
							)
						},
					},
				]
				: [
					{
						title: '일반',
						dataIndex: 'total_withdraw_amount',
						key: 'total_withdraw_amount',
						align: 'center',
						width: 150,
						render: (text, data) => {
							return (
								<KrwPrice
									price={-Math.abs(text)}
									coinglobal={coinglobal}
								/>
							)
						},
					},
					{
						title: '별도',
						dataIndex:
							'total_withdraw_amount_extra',
						key: 'total_withdraw_amount_extra',
						align: 'center',
						width: 150,
						render: (text, data) => {
							return (
								<KrwPrice
									price={-Math.abs(text)}
									coinglobal={coinglobal}
								/>
							)
						},
					},
				],
		},
		{
			title: '손익현황',
			align: 'center',
			children: [
				{
					title: '매매손익',
					dataIndex: 'total_pnl_price',
					key: 'total_pnl_price',
					align: 'center',
					width: 150,
					rowScope: 'row',
					render: (text, data) => {
						return (
							<KrwPrice
								price={text}
								dp={0}
								coinglobal={coinglobal}
							/>
						)
					},
				},
				{
					title: '수수료',
					dataIndex: 'total_fee_price',
					key: 'total_fee_price',
					align: 'center',
					width: 150,
					render: (text, data) => {
						return (
							<KrwPrice
								price={-Math.abs(text)}
								dp={0}
								coinglobal={coinglobal}
							/>
						)
					},
				},
				{
					title: '실현손익',
					dataIndex: 'total_trade_price',
					key: 'total_trade_price',
					align: 'center',
					width: 150,
					render: (text, data) => {
						return <KrwPrice price={text} coinglobal={coinglobal} />
					},
				},
			],
		},
		{
			title: '마지막잔액',
			align: 'center',
			children: [
				{
					title: '예수금',
					dataIndex: 'last_balance',
					key: 'last_balance',
					align: 'center',
					width: 150,
					render: (text, data) => {
						return (
							<KrwPrice
								coinglobal={coinglobal}
								price={text}
								dp={0}
								color={false}
								theme={false}
							/>
						)
					},
				},
			],
		},
	]

	const columnpc = () => [
		{
			title: '날짜',
			dataIndex: 'daydate',
			key: 'daydate',
			align: 'center',
			width: 80,
			render: (text, data) => {
				return <EntryTime time={text} format={'YYYY-MM-DD'} />
			},
		},
		{
			title: '입출금',
			align: 'center',
			children: [
				{
					title: '입금총액',
					dataIndex: 'total_deposit_amount',
					key: 'total_deposit_amount',
					align: 'center',
					width: 150,
					render: (text, data) => {
						return (
							<KrwPrice
								price={Math.abs(text)}
								coinglobal={coinglobal}
							/>
						)
					},
				},
				{
					title: '출금총액',
					dataIndex: 'total_withdraw_amount',
					key: 'total_withdraw_amount',
					align: 'center',
					width: 150,
					render: (text, data) => {
						return (
							<KrwPrice
								price={-Math.abs(text)}
								coinglobal={coinglobal}
							/>
						)
					},
				},
			],
		},
		{
			title: '손익현황',
			align: 'center',
			children: [
				{
					title: '매매손익',
					dataIndex: 'total_pnl_price',
					key: 'total_pnl_price',
					align: 'center',
					width: 150,
					rowScope: 'row',
					render: (text, data) => {
						return (
							<KrwPrice
								price={text}
								dp={0}
								coinglobal={coinglobal}
							/>
						)
					},
				},
				{
					title: '수수료',
					dataIndex: 'total_fee_price',
					key: 'total_fee_price',
					align: 'center',
					width: 150,
					render: (text, data) => {
						return (
							<KrwPrice
								price={-Math.abs(text)}
								dp={0}
								coinglobal={coinglobal}
							/>
						)
					},
				},
				{
					title: '실현손익',
					dataIndex: 'total_trade_price',
					key: 'total_trade_price',
					align: 'center',
					width: 150,
					render: (text, data) => {
						return <KrwPrice price={text} coinglobal={coinglobal} />
					},
				},
			],
		},
		{
			title: '마지막잔액',
			align: 'center',
			children: [
				{
					title: '예수금',
					dataIndex: 'last_balance',
					key: 'last_balance',
					align: 'center',
					width: 150,
					render: (text, data) => {
						return (
							<KrwPrice
								coinglobal={coinglobal}
								price={text}
								dp={0}
								color={false}
								theme={false}
							/>
						)
					},
				},
			],
		},
	]

	return (
		<>
			<TableAPI
				coinglobal={coinglobal}
				title={
					<Space.Compact>
						<DatePicker
							placeholder="시작일"
							allowClear={true}
							onChange={e =>
								setStartDate(
									e
										? dayjs(e, 'YYYY-MM-DD').format(
												'YYYY-MM-DD'
										  )
										: null
								)
							}
							value={
								startDate
									? dayjs(startDate, 'YYYY-MM-DD')
									: undefined
							}
						/>
						<DatePicker
							placeholder="종료일"
							allowClear={true}
							onChange={e =>
								setEndDate(
									e
										? dayjs(e, 'YYYY-MM-DD').format(
												'YYYY-MM-DD'
										  )
										: null
								)
							}
							value={
								endDate
									? dayjs(endDate, 'YYYY-MM-DD')
									: undefined
							}
						/>
						<Button
							onClick={() => {
								setStartDate('')
								setEndDate('')
							}}>
							전체
						</Button>
					</Space.Compact>
				}
				defaultMaxData={50}
				stylewidth={'100%'}
				className={'plist'}
				height={'default'}
				footer={footer}
				width={mobile ? '100%' : 500}
				columns={partner? columnpartner : mobile ? columnmobile : columnpc}
				apikey={url + '&t='}
			/>
		</>
	)
}

export default CalcList
