import { Col } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { Text } from '../../../../styles/reactnative'
import { useWindowDimensions } from '../../../hooks'
import KrwPrice from '../../KrwPrice'
import { MView } from '../../../../styles/mobilePageStyles'

const Default = ({ title, value, onClick=()=>{} }) => {
	const { width } = useWindowDimensions()
	const { textw, bgColor, Secondary, onTertiary, stroke, complementary } =
		React.useContext(ThemeContext).theme.COLORS
	return (
		<Col
			onClick={onClick}
			style={{
				height: '100%',
				flex: 1,
				backgroundColor: complementary,
				padding: '0px',
				borderLeft: '1px solid ' + stroke,
				borderTop: '1px solid ' + stroke,
				borderBottom: '1px solid ' + stroke,
			}}>
			<Text
				style={{
					background: onTertiary,
					fontSize: width < 1300 ? '0.8em' : '0.9em',
					color: textw,
					padding: '5px 0',
				}}>
				{title}
			</Text>
			<div style={{ padding: '4px 0', color: textw, minHeight: 22 }}>
				{value}
			</div>
		</Col>
	)
}

export default React.memo(Default)
