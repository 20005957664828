import React, { useEffect, useState } from 'react'
import { CButton } from 'app/components/Common/'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'app/constants/STYLES'
import { apiError, checkEmail } from 'utils/ExpensiveFunction'

import ErrorHandling from './ErrorHandling'
import { InputSection, LblSignUpOrRegister, LoginTab } from '../AuthContainers'
import { BtnContainer, TabContainer } from '../styles'
import API from 'utils/api'
import { View } from 'styles/reactnative'
import { toast } from 'react-toastify'
import { Checkbox } from 'antd'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { useToken } from '../../../hooks'
import { goTo } from '../../../hooks/navigation'
import { useHistory } from 'react-router-dom'

const EMAIL = 'email'
const PHONE = 'phone'
const LoginForm = ({ setLoginSuccess }) => {
	const { t } = useTranslation()
	const [tab, setTab] = useState(EMAIL)
	const { mutate } = useToken()
	const [error, setError] = useState<string>('')
	const [success, setSuccess] = useState<string>('')

	const navigate = useHistory()

	const { textw } = React.useContext(ThemeContext).theme.COLORS

	const [saveId, setSaveId] = useState<boolean>(false)

	const [email, setEmail] = useState('')
	const [mobile, setMobile] = useState<any>({
		country_code: '',
		iso_code: '',
		mobile: '',
	})
	const [password, setPassword] = useState('')

	useEffect(() => {
		const saveId = localStorage.getItem('saveId')
		if (saveId) {
			if (saveId.indexOf('@') > -1) {
				setEmail(saveId)
			} else {
				setEmail(saveId)
				//setMobile(JSON.parse(saveId))
			}
			setSaveId(true)
		}
	}, [])

	useEffect(() => {
		if (saveId) {
			if (tab === EMAIL) {
				localStorage.setItem('saveId', email)
			} else {
				localStorage.setItem('saveId', JSON.stringify(mobile))
			}
		} else {
			localStorage.removeItem('saveId')
		}
	}, [saveId, email, mobile])

	const handleSubmit = async () => {
		if (tab === EMAIL) {
			const res = checkEmail(email)
			if (typeof res === 'string') return setError(t(res))
		}
		try {
			const res = await API.send('POST', '/auth/signin', null, {
				type: tab,
				email: tab === EMAIL ? email : '',
				iso_code: tab === PHONE ? mobile?.iso_code : '',
				country_code: tab === PHONE ? mobile?.country_code : '',
				phone: tab === PHONE ? mobile?.mobile : '',
				password: password,
				referral_code: '',
			})

			if (res?.result?.success) {
				setSuccess(t('loginSuccessMessage'))
				if (res?.result?.token) {
					mutate(res?.result?.token)
					goTo(navigate, '/')
				} else {
					setLoginSuccess(res?.result?.verifyid)
				}
			} else {
				const errorResult = apiError(
					t,
					res?.result?.msgcode || res?.msgcode
				)
				toast(errorResult, {
					type: 'error',
				})
			}
		} catch (e) {
			console.log(e, 'ERROR LOGIN')
		}
	}

	return (
		<>
			{/* Tabs Btns */}
			<TabContainer>
				<LoginTab
					fontSize={16}
					tab={tab}
					setTab={setTab}
					color={COLORS.black}
				/>
			</TabContainer>
			{error?.length > 3 ? (
				<ErrorHandling success={success} error={error} />
			) : null}
			{/* input section */}
			<InputSection
				type={tab}
				id={tab === EMAIL ? email : mobile}
				setId={tab === EMAIL ? setEmail : setMobile}
				password={password}
				setPassword={setPassword}
				onKeyDown={e => {
					e.preventDefault()
					handleSubmit()
				}}
			/>

			<View
				style={{ justifyContent: 'flex-start', flexDirection: 'row' }}>
				<Checkbox
					value={saveId}
					style={{ color: textw }}
					onChange={() => {
						setSaveId(!saveId)
					}}>
					아이디 저장
				</Checkbox>
			</View>

			<BtnContainer
				bgColor={COLORS.BTN_HOVER}
				style={{
					marginTop: 20,
					marginBottom: 20,
					background:
					`linear-gradient(to bottom, ${COLORS.grad1} 0%, ${COLORS.grad2} 100%)`,
				}}>
				<CButton
					title={t('login')}
					border={{ width: '2px' }}
					onClick={event => {
						event.preventDefault()
						handleSubmit()
					}}
					style={{
						fontSize: 14,
						paddingBlock: 10,
						width: '100%',
						color: COLORS.white,
					}}
				/>
			</BtnContainer>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
				<LblSignUpOrRegister btnLabel={t('signUp')} url={'/signup'} />
			</View>
		</>
	)
}

export default React.memo(LoginForm)
