import { Tag } from 'antd'
import { COLORS } from 'app/constants'
import { useExchangeStoreAll } from 'app/hooks/trade/exchangeStoreAll'
import React from 'react'
import { Text } from 'styles/reactnative'
import { TagWrap } from '../../components/styles'

const Contracts2 = ({ data, created_at = undefined, small = false }) => {
	const { EPs } = useExchangeStoreAll()
	const EP = EPs?.find(e => e.id === data?.exchange_pairs_id)

	return (
		<TagWrap
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				padding: 0,
				backgroundColor:
					data?.long_short == 'L' ? '#fff1f1' : '#f1f1ff',
			}}>
			<Tag
				style={{
					display: 'flex',
					justifyContent: 'center',
					padding: 0,
					flexDirection: 'row',
				}}>
				<Text
					style={{
						padding: 0,
					}}>
					{EP?.pairs}
				</Text>
			</Tag>
			<Tag
				style={{
					color:
						data?.long_short == 'L'
							? COLORS.darkRed
							: COLORS.lightBlue,
					padding: 0,
					whiteSpace: 'nowrap',
				}}>
				<Text>{data?.long_short == 'L' ? '매수' : '매도'}</Text>
			</Tag>
			<Tag
				style={{
					color: '#f08800',
					whiteSpace: 'nowrap',
					padding: 0,
				}}>
				<Text>{EP?.groupname}</Text>
			</Tag>
		</TagWrap>
	)
}

export default Contracts2
