import useSWR from 'swr'
import { useToken } from '../auth/token'
import API from '../../../utils/api'
import { useCallback, useMemo } from 'react'

let SWRDATA: any = null
let lastcheck : any = null

export function useCurrency() {
	const KEYURL = '/system/currency'
	const { data: token } = useToken()

	const { data, mutate } = useSWR(
		KEYURL+'2',
		async () => {
			if(lastcheck == null || (new Date().getTime() - lastcheck.getTime()) > 1000 * 60 * 60 * 3 || SWRDATA == null) {

				const response = await API.get(KEYURL, token + '', {}, false)
				SWRDATA = response

				lastcheck = new Date().getTime()
			}

			return SWRDATA
		},
		{
			revalidateOnFocus: true,
			revalidateOnReconnect: true,
			refreshInterval: 60 * 60 * 1000 * 3,
			revalidateOnMount: true,
		}
	)


	const response = useMemo(() => {
		return data?.result?.result
	}, [data])

	const krwrate = useMemo(() => {
		if (response) {
			return response?.find((item: any) => item.code == 'KRW')
		}
	}, [data])

	const getRate = useCallback(
		(code: string) => {
			if (data) {
				const currency = response?.find(
					(item: any) => item.code == code
				)
				return currency
			}
		},
		[data]
	)

	return {
		data: data,
		krwrate,
		getRate,
		mutate: () => {
			mutate()
		},
	}
}
