import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { toast } from 'react-toastify'
import { copyTextToClipboard, isIos } from '../../../../utils/ciutils'

const MReferralFooterBtn = ({ open, setOpen, referral }) => {
	const { t } = useTranslation()

	const { cardOpacity } = useContext(ThemeContext).theme.COLORS

	return (
		<MView
			style={{
				width: '100%',
				height: 40,
				position: 'fixed',
				bottom: 0,
				left: 0,
				background: cardOpacity,
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<CButton
				title={t('inviteFriends')}
				onClick={() => {
					//setOpen(!open)

					if (isIos()) {
						copyTextToClipboard(referral)
						toast(t('copied') + `  ${referral}`, {
							type: 'success',
							autoClose: 500,
						})
					} else {
						navigator.clipboard
							.writeText(referral)
							.then(() => {
								toast(t('copied') + `  ${referral}`, {
									type: 'success',
									autoClose: 500,
								})
							})
							.catch(error => {
								console.error('Failed to copy text: ', error)
							})
					}
				}}
				style={{
					height: 30,
					width: '80%',
					borderRadius: 10,
					background: COLORS.primary,
					color: COLORS.white,
				}}
			/>
		</MView>
	)
}

export default MReferralFooterBtn
