import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconChevronDown } from '@tabler/icons-react'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { MText, MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'

const MNotificationHeader = ({ tab, setTab, count }) => {
	const { t } = useTranslation()
	const { subHeader, textw } = useContext(ThemeContext).theme.COLORS

	const [dropdown, setDropdown] = useState(false)

	const data = [
		{
			name: 'all',
			index: 0,
		},
	]
	return (
		<MView
			style={{
				width: '100%',
				height: 50,
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				paddingInline: 16,
			}}>
			<MView>
				<MText>
					{t('total')} {count}
				</MText>
			</MView>
			<CButton
				icon={<IconChevronDown size={14} style={{ marginLeft: 5 }} />}
				title={t(tab?.name)}
				style={{
					background: subHeader,
					color: textw,
					paddingBlock: 5,
					paddingInline: 10,
					flexDirection: 'row',
					justifyContent: 'space-between',
					borderRadius: 5,
				}}
				onClick={() => {
					setDropdown(!dropdown)
				}}
			/>
			{dropdown ? (
				<MView
					style={{
						background: subHeader,
						minWidth: 100,
						borderRadius: 5,
						position: 'absolute',
						right: 10,
						marginTop: 70,
						zIndex: 1,
					}}>
					{data.map((item, index) => (
						<CButton
							onClick={() => {
								setTab(item)
								setDropdown(!dropdown)
							}}
							title={t(item?.name)}
							key={index.toString()}
							style={{
								padding: 10,
								alignSelf: 'flex-start',
								color:
									item?.index === tab?.index
										? COLORS.secondary
										: COLORS.lightGray5,
								flexDirection: 'row-reverse',
							}}
							textStyle={{
								marginLeft: 5,
								marginTop: 1,
							}}
						/>
					))}
				</MView>
			) : null}
		</MView>
	)
}

export default MNotificationHeader
