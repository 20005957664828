import styled from 'styled-components'

export const AnnouncementListSection = styled.div`
	width: 100%;

	& div p span {
		background: transparent !important;
	}
`
export const AnnouncementListItemSection = styled.div`
	display: flex;
	flex-direction: row;
	height: 90px;
	width: 100%;
	cursor: pointer;
	@media only screen and (max-width: 600px) {
		height: 120px;
	}
`
export const AnnouncementItemLeft = styled.div`
	width: 10%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 10px;
	padding-left: 10px;
	@media only screen and (max-width: 600px) {
		width: 7%;
	}
`
export const NotificationItemBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 90%;
	justify-content: space-around;
	border-bottom: 1px solid ${p => p.theme.COLORS.borderColor};
	@media only screen and (max-width: 600px) {
		width: 93%;
	}
`
