import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import * as React from 'react'
import * as ReactDOMClient from 'react-dom/client'

// Use consistent styling
import 'sanitize.css/sanitize.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
// Import root app

import { HelmetProvider } from 'react-helmet-async'

import reportWebVitals from 'reportWebVitals'

// Initialize languages
import './locales/i18n'
import WebFont from 'webfontloader'
import App from 'App'

global.Buffer = global.Buffer || require('buffer').Buffer
WebFont.load({ google: { families: ['Roboto:300,400,500'] } })
// eslint-disable-next-line prettier/prettier
const MOUNT_NODE = document.getElementById('root') as HTMLElement

ReactDOMClient.createRoot(MOUNT_NODE!).render(
	<HelmetProvider>
		<App />
	</HelmetProvider>
)

// Hot reloadable translation json files
if (module.hot) {
	module.hot.accept(['./locales/i18n'], () => {
		// No need to render the App again because i18next works with the hooks
	})
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
