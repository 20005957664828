import React, { useContext } from 'react'
import BigNumber from 'bignumber.js'
import { View } from 'styles/reactnative'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'
import { MText } from 'styles/mobilePageStyles'
import { COLORS } from 'app/constants'
import { ThemeContext } from '../../../../../../theme/ThemeProvider'

const PriceHigh = ({ indexPrice, info24H, setDisplayDrawer, item }) => {
	const navigation = useHistory()
	const { raise, fail, textw } = useContext(ThemeContext).theme.COLORS

	const before = BigNumber(BigNumber(indexPrice).minus(info24H?.close))
		.dividedBy(info24H?.close)
		.multipliedBy(100)
		.toNumber()
	const rateBackground =
		before > 0 ? raise : before < 0 ? fail : textw

	return (
		<View
			onClick={() => {
				goTo(navigation, `/trade/${item?.id}`)
				setDisplayDrawer(false)
			}}
			style={{
				width: '60%',
				alignItems: 'flex-end',
				paddingRight: 15,
			}}>
			<MText style={{ fontSize: 13 }}>
				{BigNumber(indexPrice).toFormat(item?.dp || 2)}
			</MText>
			<MText
				style={{
					fontSize: 12,
					color: rateBackground,
				}}>
				{BigNumber(isNaN(before)?0:before).toFormat(2)}%
			</MText>
		</View>
	)
}

export default React.memo(PriceHigh)
