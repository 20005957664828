import React, { useEffect, useState } from 'react'

import { MView } from '../../../../styles/mobilePageStyles'
import UserContent from '../../../components/UserContent'
export function PartnerUser() {
	const [modal, setModal] = useState(true)
	const [selectedData, setSelectedData] = useState<any>(null)

	// url 뒤에 붙는 파라미터를 받아서 처리
	const id = window.location.href.split('/').pop()

	useEffect(() => {
		if (id) {
			setSelectedData(id)
		}
	}, [id])

	return (
		<MView
			style={{
				width: '100%',
				alignContent: 'flex-start',
				justifyContent: 'flex-start',
				justifyItems: 'flex-start',
				alignItems: 'flex-start',
			}}>
			<UserContent
				showOpen={modal}
				selectedData={selectedData}
				setSelectedData={setSelectedData}
				defaultfold={selectedData ? false : true}
			/>
		</MView>
	)
}
