import styled from 'styled-components'

export const HomeSection = styled.div<{ isDarkMode?: any }>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background: transparent;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
`
