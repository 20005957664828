import { Col, Row } from 'antd'
import { useLogQueue } from 'app/hooks/trade/logQueue'
import BigNumber from 'bignumber.js'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from '../../../../../styles/reactnative'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import CRenderEmpty from '../../../../components/CRenderEmpty'
import TradeOrder from '../../../../components/Trade/TradeOrder'
import { goTo } from '../../../../hooks/navigation'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'
import PnlPrice from '../PnlPrice'

const TradeDetailListItem = ({ item, index, dataType, pos }: any) => {
	BigNumber.clone() // new line

	const { EPs } = useExchangeStore()

	const EP = EPs?.find((ep: any) => ep?.id == item?.exchange_pairs_id)

	const { COLORS } = useContext(ThemeContext).theme

	const { mutate: mutateLog } = useLogQueue()
	const [display, setDisplay] = React.useState<any>('flex' as any)

	useEffect(() => {
		setDisplay('flex')
	}, [item, index])

	const nav = useHistory()

	return (
		<Row
			style={{
				width: '100%',
				borderBottom: '1px solid #cdcdcd',
				padding: '3px 0',
				display: display,
			}}
			align={'middle'}>
			<Col style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
				<Text
					style={{ color: COLORS.textw }}
					size={pos == 'right' ? 11 : pos == 'new' ? 16 : 12}>
					{item?.status == 10
						? 'MIT'
						: item?.status == 8
						? '익절'
						: item?.status == 9
						? '손절'
						: item?.status == 1
						? '미체결'
						: '보유'}
				</Text>
			</Col>
			<Col
				style={{
					flex: 1,
					display: 'flex',
					justifyContent: 'center',
					cursor: 'pointer',
				}}
				onClick={() => {
					goTo(nav, '/trade/' + EP?.id)

					mutateLog({
						log: 'FR006',
						data: {
							ep_id: EP?.id,
						},
					})
				}}>
				<Text
					style={{ color: COLORS.textw, whiteSpace: 'nowrap' }}
					size={pos == 'right' ? 11 : pos == 'new' ? 16 : 12}>
					{EP?.groupname || `만료월물(${item?.exchange_pairs_id})`}
				</Text>
			</Col>
			<Col
				style={{
					flex: 1,
					justifyContent: 'center',
					display: 'flex',
				}}>
				<Text
					style={{
						color:
							item?.long_short === 'S'
								? COLORS.fail
								: COLORS.raise,
					}}
					size={pos == 'right' ? 11 : pos == 'new' ? 16 : 12}>
					{item?.long_short === 'L' ? '매수' : '매도'}
				</Text>
			</Col>
			<Col
				style={{
					flex: 1,
					display: 'flex',
					justifyContent: 'center',
				}}>
				<Text
					style={{ color: COLORS.textw }}
					size={pos == 'right' ? 11 : pos == 'new' ? 16 : 12}>
					{BigNumber(item?.amount).toFormat(0)}
				</Text>
			</Col>
			<Col
				style={{
					flex: 1,
					display: 'flex',
					justifyContent: 'center',
					color: COLORS.textw,
				}}>
				<Text size={pos == 'right' ? 11 : pos == 'new' ? 16 : 12}>
					{item?.status == 1 && item?.limit_market == 'M'
						? '시장가'
						: BigNumber(
								item?.price ||
									item?.trade_price ||
									item?.order_price ||
									0
						  ).toFormat(EP?.dp || 2, BigNumber.ROUND_FLOOR)}
				</Text>
			</Col>
			<Col
				style={{
					flex: 1,
					display: 'flex',
					justifyContent: 'center',
				}}>
				<PnlPrice
					item={item}
					size={pos == 'right' ? 11 : pos == 'new' ? 16 : 12}
				/>
			</Col>
			<Col style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
				<TradeOrder item={item} setDisplay={setDisplay} />
			</Col>
			{!item?.status && <CRenderEmpty id={item?.exchange_pairs_id} />}
		</Row>
	)
}

export default React.memo(TradeDetailListItem)
