import React from 'react'
import { View } from 'styles/reactnative'
import HeaderBottomLists from './HeaderBottomLists'
import { useBalance } from '../../../../hooks/user/mybalance'
import BigNumber from 'bignumber.js'

const AssetDerivativesHeaderBottom = () => {
	const { data: spot } = useBalance('KRW', 9)

	const balance = BigNumber(spot?.balance || 0)

	const data = [
		{
			title: 'totalEquity',
			usd: balance.toFormat(0, BigNumber.ROUND_FLOOR),
			lock: spot?.lockbalance || 0,
		},
	]

	console.log('data', balance, data)

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				marginTop: 20,
				width: '100%',
			}}>
			{data.map((item, index) => (
				<HeaderBottomLists
					key={index.toString()}
					item={item}
					index={index}
				/>
			))}
		</View>
	)
}

export default React.memo(AssetDerivativesHeaderBottom)
