import React, { useContext } from 'react'
import { IconCircleCheck } from '@tabler/icons-react'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'

const IdentifyHeader = () => {
	const { t } = useTranslation()
	const { header, textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				background: header,
				width: '100%',
				height: 35,
				paddingInline: 10,
				borderTopRightRadius: 10,
				borderTopLeftRadius: 10,
			}}>
			<Text size={20} style={{ color: textw }}>
				{t('basic')}
			</Text>
			<View style={{ flexDirection: 'row' }}>
				<Text
					size={14}
					style={{
						marginRight: 10,
						color: textw,
					}}>
					{t('verified')}
				</Text>
				<IconCircleCheck size={14} color={COLORS.lightGreen} />
			</View>
		</View>
	)
}

export default IdentifyHeader
