import BigNumber from 'bignumber.js'
import { useTradeStore } from './tradeStore'
import { useTradePosition } from './tradePosition'
import { useBalance } from '../user/mybalance'
import { useExchangeStore } from './exchangeStore'
import { useFetcherPublic } from '../fetcher'
import { useIndexPrice } from './indexprice'
import { useEffect, useState } from 'react'

export function useAvaiblecheck() {
	let totalpnl = 0
	let pnl = 0

	const { data: tradeStore } = useTradeStore()
	const { data } = useTradePosition()
	const { data: balance } = useBalance('KRW', 9)

	const { EPs } = useExchangeStore()
	const currencyList = useFetcherPublic('/system/currency')
	let { data: indexPrice, getPrice, reset } = useIndexPrice(tradeStore?.ep_id)

	const krwrate = currencyList?.data?.result?.result?.find(
		(item: any) => item.code == 'KRW'
	)

	if (data && data?.length > 0) {
		data?.map((item: any) => {
			const E = EPs?.find((i: any) => item.exchange_pairs_id == i.id)
			const currencyrate = currencyList?.data?.result?.result?.find(
				(item: any) => item.code == E?.currency
			)
			const rate = BigNumber(krwrate?.price || 0)
				.div(currencyrate?.price || 0)
				.toNumber()

			if (
				getPrice(item?.exchange_pairs_id) === 0 ||
				!getPrice(item?.exchange_pairs_id)
			) {
				reset('useAvaiblecheck')
			}

			if (item?.long_short === 'L') {
				pnl = BigNumber(
					getPrice(item?.exchange_pairs_id) || indexPrice?.[0]?.price
				)
					.minus(item?.price)
					.multipliedBy(item?.amount)
					.multipliedBy(E?.contractamt)
					.multipliedBy(rate)
					.toNumber()
			} else {
				pnl = BigNumber(item?.price)
					.minus(
						getPrice(item?.exchange_pairs_id) ||
							indexPrice?.[0]?.price
					)
					.multipliedBy(item?.amount)
					.multipliedBy(E?.contractamt)
					.multipliedBy(rate)
					.toNumber()
			}

			totalpnl = new BigNumber(totalpnl || 0).plus(pnl || 0).toNumber()
		})
	}

	const [availableLong, setAvailableLong] = useState(0)
	const [availableShort, setAvailableShort] = useState(0)

	const available = BigNumber(balance?.balance)
		.minus(balance?.lockbalance || 0)
		.plus(totalpnl)

	const EP = EPs?.find((i: any) => i.id == tradeStore?.ep_id)

	const margin = BigNumber(tradeStore?.minimum_margin || 0)

	const long = Math.min(
		Math.floor(available.dividedBy(margin).toNumber()),
		EP?.max_amount
	)
	const short = Math.min(
		Math.floor(available.dividedBy(margin).toNumber()),
		EP?.max_amount
	)

	let availableLongTemp = Math.min(
		Math.floor(available.dividedBy(margin).toNumber()),
		EP?.max_amount
	)
	let availableShortTemp = Math.min(
		Math.floor(available.dividedBy(margin).toNumber()),
		EP?.max_amount
	)

	useEffect(() => {
		setAvailableLong(availableLongTemp)
		setAvailableShort(availableShortTemp)
	}, [availableLongTemp, availableShortTemp, data, available])

	const poslist = data?.filter(
		(i: any) => i?.exchange_pairs_id == tradeStore?.ep_id
	)

	poslist?.map((item: any) => {
		if (item?.long_short == 'L') {
			availableShortTemp = availableShortTemp + item?.amount
			if (long >= 10) {
				availableLongTemp = availableLongTemp - item?.amount
			}
		} else {
			availableLongTemp = availableLongTemp + item?.amount

			if (short >= 10) {
				availableShortTemp = availableShortTemp - item?.amount
			}
		}
	})

	if (availableLongTemp < 0) availableLongTemp = 0
	if (availableShortTemp < 0) availableShortTemp = 0

	return { availableLong, availableShort }
}
