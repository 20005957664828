import React from 'react'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { useMyProfile } from '../../../../../hooks/user/myprofile'

const AccountInfoSecurityProcess = () => {
	const { t } = useTranslation()
	const { data: myinfo } = useMyProfile()

	return (
		<View
			style={{
				flexDirection: 'row',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				marginTop: 20,
				marginLeft: 5,
			}}>
			<Text
				size={16}
				style={{
					fontWeight: '500',
					marginRight: 5,
				}}>
				{t('SECURITYLEVEL')} :
			</Text>
			<View
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-start',
				}}>
				<Text
					size={16}
					style={{
						fontWeight: '400',
						marginRight: 10,
					}}>
					{myinfo.level === 0
						? t('LOW')
						: myinfo.level === 1
						? t('MEDIUM')
						: t('HIGH')}
				</Text>
				{[0, 1, 2].map((item, index) => (
					<View
						key={index.toString()}
						style={{
							height: 5,
							width: 50,
							background:
								parseInt(myinfo.level) >= item
									? item === 0
										? COLORS.darkRed
										: item === 1
										? COLORS.lightRed
										: COLORS.green
									: COLORS.greyWhite,
							marginRight: 5,
						}}
					/>
				))}
			</View>
		</View>
	)
}
export default AccountInfoSecurityProcess
