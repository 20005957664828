import React from 'react'
import AssetsBody from './AssetsBody'
import AssetsFooter from './AssetsFooter'
import AssetsHeader from './AssetsHeader'
import { HeaderAssetSection } from './styles'

const HeaderAssets = () => {
	return (
		<HeaderAssetSection>
			<AssetsHeader />
			<AssetsBody />
		</HeaderAssetSection>
	)
}

export default React.memo(HeaderAssets)
