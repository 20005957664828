import * as React from 'react'
import { View } from 'styles/reactnative'
import { useWindowDimensions } from 'app/hooks'
import CRenderEmpty from '../../components/CRenderEmpty'
import { Helmet } from 'react-helmet-async'
import { useTradeStore } from '../../hooks/trade/tradeStore'
import { useLocation } from 'react-router-dom'
import TradePositionsTable from '../../organisms/Trade/TradeDetail/TradePositionsTable'
import { ThemeContext } from '../../../theme/ThemeProvider'

export const PositionView = () => {
	const location = useLocation()
	const urlSegments = location.pathname?.split('/')

	console.log('urlSegments', urlSegments)

	const { toggleTheme } = React.useContext(ThemeContext)

	if (urlSegments[3] == 'dark') {
		toggleTheme(true)
	} else {
		toggleTheme(true)
	}

	const { width, height } = useWindowDimensions()
	const [height2, setHeight2] = React.useState(0)

	const { data: tradeStore } = useTradeStore()
	const parentRef = React.useRef<any>(null)
	if (parentRef.current) {
		if (parentRef?.current?.clientHeight != height2) {
			console.log(
				'Parent component height:',
				parentRef?.current?.clientHeight
			)
		}
	}

	return (
		<View style={{ width: width, height: height }} ref={parentRef}>
			<Helmet>
				<title>{tradeStore?.name}</title>
			</Helmet>

			<TradePositionsTable popup={true} />
		</View>
	)
}
