import styled from 'styled-components'

export const BottomTabContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
`
export const BottomTabSection = styled.div`
	width: 100%;
	height: 50px;
	z-index: 90005;
	position: fixed;
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
	justify-content: space-between;
	bottom: 0;
	left: 0;
	right: 0;
	background: ${p => p.theme.COLORS.background};
	box-shadow: 0px -4px 10px rgba(255, 255, 255, 0.02);
`

export const ButtonTabItems = styled.button`
	display: flex;
	background-color: transparent;
	outline: 0;
	cursor: pointer;
	transition: ease border-bottom 250ms;
	&:active {
		transform: translateY(1px);
	}
`
