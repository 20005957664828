import { Button, message } from 'antd'
import React, { useEffect } from 'react'
import API from '../../../../../../utils/api'
import { useToken } from '../../../../../hooks'
import { useExchangeStore } from '../../../../../hooks/trade/exchangeStore'
import { useOvernight } from '../../../../../hooks/trade/overnight'
import { useTradePosition } from '../../../../../hooks/trade/tradePosition'
import { useLogQueue } from 'app/hooks/trade/logQueue'

const OvernightButton = ({ ep_id }) => {
	const { data: token } = useToken()
	const { data: overnightAll, mutate: mutateOvernightAll } = useOvernight()

	const {mutate: mutateLog} = useLogQueue()

	const { data: position } = useTradePosition()
	const data = position?.filter((item: any) => !item?.status)
	const currentposition = data?.filter(
		(item: any) => item?.exchange_pairs_id == ep_id
	)

	const overnight = overnightAll?.find(
		(item: any) => item?.exchange_pairs_id == ep_id
	)

	const { EPs} = useExchangeStore()

	const EP = EPs?.find((item: any) => item?.id == ep_id)

	useEffect(() => {
		mutateOvernightAll()
	}, [data?.length])

	return (
		<Button
			type="primary"
			danger={overnight?.ovstatus == 1}
			size="small"
			onClick={() => {
				if (currentposition?.length == 0) {
					message.error('현재 포지션이 없습니다.')
					return
				}

				
				mutateLog({
					log: 'FR030',
					data: {
						ep_id,
						s: overnight?.ovstatus
					},
				})

				API.post('/trade/overnight', token + '', {
					positionid: currentposition?.[0]?.id,
				}).then(res => {
					if (res?.result?.message) {
						message.success(res?.result?.message)
					}
					setTimeout(() => {
						mutateOvernightAll()
					}, 1000)
				})
			}}
			style={{
				padding: 0,
				width: '100%',
				background: overnight?.ovstatus == 1 ? '#9f0049' : '#00499F',
				margin: 0,
				fontSize: 10,
			}}>
			{overnight?.ovstatus == 1 ? '오버취소' : '오버신청'}
		</Button>
	)
}

export default OvernightButton
