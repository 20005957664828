import React from 'react'
import FooterList from './FooterList'
import SocialButtons from './SocialButtons'
import { SubFooterLists, SubFooterListSection } from './styles'
import { data } from './footerArrayList'
import useWindowDimensions from 'app/hooks/useWindowDimensions'
const SubFooterList = () => {
	const { width } = useWindowDimensions()
	return (
		<SubFooterListSection
			style={{ flexDirection: width < 1200 ? 'column' : 'row' }}>
		</SubFooterListSection>
	)
}

export default React.memo(SubFooterList)
