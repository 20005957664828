import React from 'react'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import BigNumber from 'bignumber.js'
import { useLastPrice } from '../../../hooks/trade/lastprice'
import { useInfo24H } from '../../../hooks/trade/info24H'
import DefaultKrw from './DefaultKrw'
import { useIndexPrice } from 'app/hooks/trade/indexprice'

const CurrentPrice = () => {
	const { ep_id, symbol, dp } = useTradeStore()
	const { data: info24 } = useInfo24H(symbol)
	const { getPrice } = useIndexPrice(ep_id)

	const currentprice = BigNumber(getPrice(ep_id) || info24?.open || 0)

	const beforeclose = BigNumber(info24?.open).minus(info24?.change).toNumber()
	return (
		<DefaultKrw
			title={'현재가'}
			price={currentprice.toNumber()}
			dp={dp}
			comma={false}
			krw={false}
			color={true}
			colordiff={
				beforeclose < currentprice.toNumber()
					? 'up'
					: beforeclose > currentprice.toNumber()
					? 'down'
					: 'same'
			}
		/>
	)
}

export default React.memo(CurrentPrice)
