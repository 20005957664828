import React, { useEffect, useState } from 'react'

import { Button, Select, Space } from 'antd'
import { MView } from 'styles/mobilePageStyles'
import { useToken } from '../../hooks'
import DepositManagement from './NewWindow/AdminCash/DepositManagement'
import WithdrawalManagement from './NewWindow/AdminCash/WithdrawalManagement'
import { View } from 'styles/reactnative'
import { useUserGroup } from 'app/hooks/usergroups'

export function PartnerCash({ modalshow = false, setModalshow = e => {} }) {
	const [type, setType] = useState<any>('D')
	const { data: token } = useToken()

	const {type1} = useUserGroup()
	const [group, setGroup] = useState('*')


	if (!token) return <></>

	return (
		<MView
			style={{
				width: '100%',
				alignContent: 'flex-start',
				justifyContent: 'flex-start',
				justifyItems: 'flex-start',
				alignItems: 'center',
				marginBottom: 150,
				gap: 10,
			}}>
			<View style={{ width: '100%', borderBottom: '1px solid black' ,
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}>
				<Space.Compact>
					<Button
						type={type == '*' ? 'primary' : 'default'}
						onClick={() => {
							setType('*')
						}}>
						전체
					</Button>
					<Button
						type={type == 'D' ? 'primary' : 'default'}
						onClick={() => {
							setType('D')
						}}>
						입금
					</Button>
					<Button
						type={type == 'W' ? 'primary' : 'default'}
						onClick={() => {
							setType('W')
						}}>
						출금
					</Button>
				</Space.Compact>
				
				<Select
					defaultValue={'*'}
					onChange={e => {
						setGroup(e)
					}}
					value={group}
					style={{ width: '100%', minWidth: 150, maxWidth: 250 }}
					options={[
						{ value: '*', label: '그룹전체' },
						...(type1 || []),
					]}
				/>
			</View>

			{type == 'D' ? (
				<DepositManagement type={type} group={group}/>
			) : type == 'W' ? (
				<WithdrawalManagement type={type}  group={group} />
			) : (
				<DepositManagement type={'*'}  group={group}/>
			)}
		</MView>
	)
}
