import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, DatePicker, Space, Table } from 'antd'
import useSWR from 'swr'
import { useToken } from '../../hooks'
import API from '../../../utils/api'
import {
	DownloadOutlined,
	OrderedListOutlined,
	ReloadOutlined,
} from '@ant-design/icons'
import { goTo } from '../../hooks/navigation'
import { useHistory } from 'react-router'
import BigNumber from 'bignumber.js'

import dayjs from 'dayjs'
import KrwPrice from '../KrwPrice'
import { Text, View } from '../../../styles/reactnative'
import ReactExport from 'react-data-export-typescript'
import { API_COINURL, COINHIDE } from '../../constants/APIKEYS'
import { ThemeContext } from '../../../theme/ThemeProvider'
import { IconX } from '@tabler/icons-react'

const TableAPI = ({
	title,
	apikey,
	extra = null,
	excel = true,
	columns,
	width = '100%',
	stylewidth = '100%',
	height = 470,
	pagination = true,
	usermodal = false,
	detail = '',
	datesearch = false,
	action = () => {},
	actionmodal = () => {},
	defaultMaxData = 20,
	loading = true,
	actionrefresh = () => {},
	footer = 0,
	autorefresh = false,
	fold = false,
	sharemutate = '',
	online = false,
	onlyonline = false,
	selectedid = '',
	rowAction = () => {},
	coinglobal = 'G',
	className = undefined,
	hideIcon = false,
	closeAction = null,
}: any) => {
	const { data: token } = useToken()
	const nav = useHistory()

	const { theme } = useContext(ThemeContext)

	const [page, setPage] = useState(1)
	const [maxData, setMaxData] = useState(defaultMaxData)
	const [showModal, setShowModal] = useState(false)

	const now = dayjs()

	const [startDate, setStartDate] = useState<any>(now.format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState<any>(now.format('YYYY-MM-DD'))

	//00시 ~ 06시까지는 전날로 계산

	useEffect(() => {
		if (now.hour() < 6) {
			setStartDate(now.subtract(1, 'day').format('YYYY-MM-DD'))
			setEndDate(now.subtract(1, 'day').format('YYYY-MM-DD'))
		}
	}, [])

	const [selectedData, setSelectedData] = useState<any>(null)
	const [edit, setEdit] = useState<any>(false)

	const ExcelFile = ReactExport.ExcelFile
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

	let footerTable: any = () => {
		return null
	}
	if (footer == 5) {
		footerTable = () => {
			return (
				<View style={{ flexDirection: 'row', gap: 10 }}>
					동결금합 :{' '}
					<KrwPrice
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc).plus(curr['margin']).toNumber(),
							0
						)}
					/>
				</View>
			)
		}
	} else if (footer == 1) {
		footerTable = () => {
			const groupedData = dataSource.reduce((acc, curr) => {
				const userId = curr['id']
				const existingEntry = acc.find(
					entry => entry['user_id'] === userId
				)

				if (existingEntry) {
					// 이미 그룹이 존재하는 경우
					if (curr['daydate'] > existingEntry['daydate']) {
						// 현재 데이터가 더 최근인 경우 값을 업데이트
						existingEntry['last_balance'] = curr['last_balance']
					}
				} else {
					// 그룹이 존재하지 않는 경우 새로운 그룹 생성
					acc.push({
						user_id: userId,
						last_balance: curr['last_balance'],
						daydate: curr['daydate'], // 최근값 비교를 위한 timestamp 추가
					})
				}

				return acc
			}, [])
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						alignItems: 'center',
						flex: 1,
						width: '100%',
					}}>
					<div style={{ width: '100%' }}>
						총갯수: {data?.totalCount || 0}
					</div>

					<View style={{ flexDirection: 'row', gap: 10 }}>
						<View>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								일반 입금 합계:
								<KrwPrice
									style={{ width: 150 }}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr['total_deposit_amount']
												)
												.toNumber(),
										0
									)}
								/>
							</View>

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								별도 입금 합계:
								<KrwPrice
									style={{ width: 150 }}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr[
														'total_deposit_amount_extra'
													]
												)
												.toNumber(),
										0
									)}
								/>
							</View>
							{!COINHIDE ? (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'row',
									}}>
									{'코인>해선 합계:'}
									<KrwPrice
										style={{ width: 150 }}
										price={dataSource.reduce(
											(acc, curr) =>
												BigNumber(acc)
													.plus(
														curr[
															'total_deposit_amount_move'
														]
													)
													.toNumber(),
											0
										)}
									/>
								</View>
							) : (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'row',
									}}>
									-
								</View>
							)}

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
									borderBottom: '1px solid #efefef',
									borderTop: '1px solid #efefef',
								}}>
								{'입금총합 합계:'}
								<KrwPrice
									style={{ width: 150 }}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr[
														'total_deposit_amount_move'
													]
												)
												.plus(
													curr[
														'total_deposit_amount_extra'
													]
												)
												.plus(
													curr['total_deposit_amount']
												)
												.toNumber(),
										0
									)}
								/>
							</View>
						</View>

						<View>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								일반 출금 합계:
								<KrwPrice
									style={{ width: 150 }}
									price={
										-Math.abs(
											dataSource.reduce(
												(acc, curr) =>
													BigNumber(acc)
														.plus(
															curr[
																'total_withdraw_amount'
															]
														)
														.toNumber(),
												0
											)
										)
									}
								/>
							</View>

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								별도 출금 합계:
								<KrwPrice
									style={{ width: 150 }}
									price={
										-Math.abs(
											dataSource.reduce(
												(acc, curr) =>
													BigNumber(acc)
														.plus(
															curr[
																'total_withdraw_amount_extra'
															]
														)
														.toNumber(),
												0
											)
										)
									}
								/>
							</View>
							{!COINHIDE ? (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'row',
									}}>
									{'해선>코인 합계:'}
									<KrwPrice
										style={{ width: 150 }}
										price={
											-Math.abs(
												dataSource.reduce(
													(acc, curr) =>
														BigNumber(acc)
															.plus(
																curr[
																	'total_withdraw_amount_move'
																]
															)
															.toNumber(),
													0
												)
											)
										}
									/>
								</View>
							) : (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'row',
									}}>
									-
								</View>
							)}

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
									borderBottom: '1px solid #efefef',
									borderTop: '1px solid #efefef',
								}}>
								{'출금총합 합계:'}
								<KrwPrice
									style={{ width: 150 }}
									price={
										-Math.abs(
											dataSource.reduce(
												(acc, curr) =>
													BigNumber(acc)
														.plus(
															curr[
																'total_withdraw_amount_move'
															]
														)
														.plus(
															curr[
																'total_withdraw_amount_extra'
															]
														)
														.plus(
															curr[
																'total_withdraw_amount'
															]
														)
														.toNumber(),
												0
											)
										)
									}
								/>
							</View>
						</View>

						<View
							style={{
								borderLeft: '1px solid #ccc',
								paddingLeft: 10,
							}}>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								총매매손익 합:
								<KrwPrice
									style={{ width: 150 }}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr['total_pnl_price'] || 0
												)
												.toNumber(),
										0
									)}
								/>
							</View>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								총수수료 합계:
								<KrwPrice
									style={{ width: 150 }}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													-Math.abs(
														curr['total_fee_price']
													) || 0
												)
												.toNumber(),
										0
									)}
								/>
							</View>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								실현손익 합계:
								<KrwPrice
									style={{ width: 150 }}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr['total_trade_price'] ||
														0
												)
												.toNumber(),
										0
									)}
								/>
							</View>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'row',
								}}>
								마지막잔액 합:
								<KrwPrice
									style={{ width: 150 }}
									price={groupedData.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(curr['last_balance'] || 0)
												.toNumber(),
										0
									)}
								/>
							</View>
						</View>
					</View>
				</div>
			)
		}
	} else if (footer == 2) {
		footerTable = () => {
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						flex: 1,
						width: '100%',
					}}>
					<div style={{ width: 60 }}>
						Total: {data?.totalCount || 0}
					</div>

					{fold && (
						<>
							<KrwPrice
								style={{ width: 130 }}
								price={dataSource.reduce(
									(acc, curr) =>
										BigNumber(acc)
											.plus(curr['total_deposit_amount'])
											.toNumber(),
									0
								)}
							/>
							<KrwPrice
								style={{ width: 130 }}
								price={dataSource.reduce(
									(acc, curr) =>
										BigNumber(acc)
											.plus(curr['total_withdraw_amount'])
											.toNumber(),
									0
								)}
							/>
						</>
					)}
					<KrwPrice
						style={{ width: 130 }}
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc)
									.plus(curr['total_pnl_price'] || 0)
									.toNumber(),
							0
						)}
					/>
					<KrwPrice
						style={{ width: 130 }}
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc)
									.plus(
										-Math.abs(curr['total_fee_price']) || 0
									)
									.toNumber(),
							0
						)}
					/>
					<KrwPrice
						style={{ width: 150 }}
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc)
									.plus(curr['total_trade_price'] || 0)
									.toNumber(),
							0
						)}
					/>

					<KrwPrice
						style={{ width: 130 }}
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc)
									.plus(curr['last_sumbalance'] || 0)
									.toNumber(),
							0
						)}
					/>
				</div>
			)
		}
	} else if (footer == 3) {
		footerTable = () => {
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						flex: 1,
						width: '100%',
					}}>
					<div style={{ width: 60 }}>
						Total: {data?.totalCount || 0}
					</div>
					<div style={{ width: 480 }}></div>

					<KrwPrice
						style={{ width: 200 }}
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc)
									.plus(curr['amount'] || 0)
									.toNumber(),
							0
						)}
					/>

					<KrwPrice
						style={{ width: 180 }}
						price={dataSource.reduce(
							(acc, curr) =>
								BigNumber(acc)
									.plus(curr['confirm_amount'] || 0)
									.toNumber(),
							0
						)}
					/>
					<div style={{ flex: 1 }}></div>
				</div>
			)
		}
	}
	if (footer == 4) {
		footerTable = () => {
			const groupedData = dataSource.reduce((acc, curr) => {
				const userId = curr['id']
				const existingEntry = acc.find(
					entry => entry['user_id'] === userId
				)

				if (existingEntry) {
					// 이미 그룹이 존재하는 경우
					if (curr['daydate'] > existingEntry['daydate']) {
						// 현재 데이터가 더 최근인 경우 값을 업데이트
						existingEntry['last_balance'] = curr['last_balance']
					}
				} else {
					// 그룹이 존재하지 않는 경우 새로운 그룹 생성
					acc.push({
						user_id: userId,
						last_balance: curr['last_balance'],
						daydate: curr['daydate'], // 최근값 비교를 위한 timestamp 추가
					})
				}

				return acc
			}, [])
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						alignItems: 'center',
						flex: 1,
						width: '100%',
					}}>
					<div
						style={{
							width: '100%',
							borderBottom: '1px solid #efefef',
							marginBottom: 10,
							paddingBottom: 10,
							fontSize: 12,
						}}>
						총갯수: {data?.totalCount || 0}
					</div>

					<View
						style={{
							flexDirection: 'row',
							gap: 10,
							width: '100%',
							flex: 1,
						}}>
						<View
							style={{
								width: '50%',
								flex: 1,
							}}>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'column',
									paddingInline: 10,
									borderBottom: '1px solid #efefef',
								}}>
								<Text
									style={{
										width: '100%',
										justifyContent: 'flex-start',
										textAlign: 'left',
										fontSize: 12,
									}}>
									일반 입금 합계
								</Text>
								<KrwPrice
									w={'100%'}
									style={{ width: '100%' }}
									coinglobal={coinglobal}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr['total_deposit_amount']
												)
												.toNumber(),
										0
									)}
								/>
							</View>

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'column',
									paddingInline: 10,
									borderBottom: '1px solid #efefef',
								}}>
								<Text
									style={{
										width: '100%',
										justifyContent: 'flex-start',
										textAlign: 'left',
										fontSize: 12,
									}}>
									별도 입금 합계
								</Text>
								<KrwPrice
									w={'100%'}
									style={{ width: '100%' }}
									coinglobal={coinglobal}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr[
														'total_deposit_amount_extra'
													]
												)
												.toNumber(),
										0
									)}
								/>
							</View>

							{!COINHIDE ? (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'column',
										paddingInline: 10,
										borderBottom: '1px solid #efefef',
									}}>
									<Text
										style={{
											width: '100%',
											justifyContent: 'flex-start',
											fontSize: 12,
											textAlign: 'left',
										}}>
										{'코인>해선합계'}
									</Text>
									<KrwPrice
										w={'100%'}
										style={{ width: '100%' }}
										coinglobal={coinglobal}
										price={dataSource.reduce(
											(acc, curr) =>
												BigNumber(acc)
													.plus(
														curr[
															'total_deposit_amount_move'
														]
													)
													.toNumber(),
											0
										)}
									/>
								</View>
							) : (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'column',
										paddingInline: 10,
										borderBottom: '1px solid #efefef',
									}}>
									-
								</View>
							)}

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									borderBottom: '1px solid #efefef',
									borderTop: '1px solid #efefef',
									flexDirection: 'column',
									paddingInline: 10,
								}}>
								<Text
									style={{
										width: '100%',
										justifyContent: 'flex-start',
										textAlign: 'left',
										fontSize: 12,
									}}>
									{'입금총합 합계'}
								</Text>
								<KrwPrice
									w={'100%'}
									style={{ width: '100%' }}
									coinglobal={coinglobal}
									price={dataSource.reduce(
										(acc, curr) =>
											BigNumber(acc)
												.plus(
													curr[
														'total_deposit_amount_move'
													]
												)
												.plus(
													curr[
														'total_deposit_amount_extra'
													]
												)
												.plus(
													curr['total_deposit_amount']
												)
												.toNumber(),
										0
									)}
								/>
							</View>
						</View>

						<View
							style={{
								width: '50%',
							}}>
							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'column',
									paddingInline: 10,
									borderBottom: '1px solid #efefef',
								}}>
								<Text
									style={{
										width: '100%',
										justifyContent: 'flex-start',
										fontSize: 12,
										textAlign: 'left',
									}}>
									일반 출금 합계
								</Text>
								<KrwPrice
									w={'100%'}
									style={{ width: '100%' }}
									coinglobal={coinglobal}
									price={
										-Math.abs(
											dataSource.reduce(
												(acc, curr) =>
													BigNumber(acc)
														.plus(
															curr[
																'total_withdraw_amount'
															]
														)
														.toNumber(),
												0
											)
										)
									}
								/>
							</View>

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									flexDirection: 'column',
									paddingInline: 10,
									borderBottom: '1px solid #efefef',
								}}>
								<Text
									style={{
										width: '100%',
										justifyContent: 'flex-start',
										fontSize: 12,
										textAlign: 'left',
									}}>
									별도 출금 합계
								</Text>

								<KrwPrice
									w={'100%'}
									style={{ width: '100%' }}
									coinglobal={coinglobal}
									price={
										-Math.abs(
											dataSource.reduce(
												(acc, curr) =>
													BigNumber(acc)
														.plus(
															curr[
																'total_withdraw_amount_extra'
															]
														)
														.toNumber(),
												0
											)
										)
									}
								/>
							</View>
							{!COINHIDE ? (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'column',
										paddingInline: 10,
										borderBottom: '1px solid #efefef',
									}}>
									<Text
										style={{
											width: '100%',
											justifyContent: 'flex-start',
											textAlign: 'left',
											fontSize: 12,
										}}>
										{'해선>코인 합계'}
									</Text>

									<KrwPrice
										w={'100%'}
										style={{ width: '100%' }}
										coinglobal={coinglobal}
										price={
											-Math.abs(
												dataSource.reduce(
													(acc, curr) =>
														BigNumber(acc)
															.plus(
																curr[
																	'total_withdraw_amount_move'
																]
															)
															.toNumber(),
													0
												)
											)
										}
									/>
								</View>
							) : (
								<View
									style={{
										whiteSpace: 'nowrap',
										width: '100%',
										flexDirection: 'column',
										paddingInline: 10,
										borderBottom: '1px solid #efefef',
									}}>
									-
								</View>
							)}

							<View
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									borderBottom: '1px solid #efefef',
									borderTop: '1px solid #efefef',
									flexDirection: 'column',
									paddingInline: 10,
								}}>
								<Text
									style={{
										width: '100%',
										fontSize: 12,
										justifyContent: 'flex-start',
										textAlign: 'left',
									}}>
									{'출금총합 합계'}
								</Text>
								<KrwPrice
									w={'100%'}
									style={{ width: '100%' }}
									coinglobal={coinglobal}
									price={
										-Math.abs(
											dataSource.reduce(
												(acc, curr) =>
													BigNumber(acc)
														.plus(
															curr[
																'total_withdraw_amount_move'
															]
														)
														.plus(
															curr[
																'total_withdraw_amount_extra'
															]
														)
														.plus(
															curr[
																'total_withdraw_amount'
															]
														)
														.toNumber(),
												0
											)
										)
									}
								/>
							</View>
						</View>
					</View>

					<View
						style={{
							width: '100%',
							padding: 10,
							border: '1px solid #efefef',
							backgroundColor: theme.COLORS.tradeBG,
							borderRadius: 5,
							marginTop: 10,
							gap: 5,
						}}>
						<View
							style={{
								whiteSpace: 'nowrap',
								width: '100%',
								flexDirection: 'row',
							}}>
							<Text
								style={{
									width: '100%',
									fontSize: 12,
									justifyContent: 'flex-start',
									textAlign: 'left',
									color: theme.COLORS.textw,
								}}>
								총매매손익 합
							</Text>

							<KrwPrice
								coinglobal={coinglobal}
								w={'100%'}
								style={{ width: '100%' }}
								price={dataSource.reduce(
									(acc, curr) =>
										BigNumber(acc)
											.plus(curr['total_pnl_price'] || 0)
											.toNumber(),
									0
								)}
							/>
						</View>
						<View
							style={{
								whiteSpace: 'nowrap',
								width: '100%',
								flexDirection: 'row',
							}}>
							<Text
								style={{
									width: '100%',
									fontSize: 12,
									justifyContent: 'flex-start',
									textAlign: 'left',
									color: theme.COLORS.textw,
								}}>
								총수수료 합계
							</Text>

							<KrwPrice
								coinglobal={coinglobal}
								w={'100%'}
								style={{ width: '100%' }}
								price={dataSource.reduce(
									(acc, curr) =>
										BigNumber(acc)
											.plus(
												-Math.abs(
													curr['total_fee_price']
												) || 0
											)
											.toNumber(),
									0
								)}
							/>
						</View>
						<View
							style={{
								whiteSpace: 'nowrap',
								width: '100%',
								flexDirection: 'row',
							}}>
							<Text
								style={{
									width: '100%',
									fontSize: 12,
									justifyContent: 'flex-start',
									textAlign: 'left',
									color: theme.COLORS.textw,
								}}>
								실현손익 합계
							</Text>
							<KrwPrice
								coinglobal={coinglobal}
								w={'100%'}
								style={{ width: '100%' }}
								price={dataSource.reduce(
									(acc, curr) =>
										BigNumber(acc)
											.plus(
												curr['total_trade_price'] || 0
											)
											.toNumber(),
									0
								)}
							/>
						</View>
						<View
							style={{
								whiteSpace: 'nowrap',
								width: '100%',
								flexDirection: 'row',
							}}>
							<Text
								style={{
									width: '100%',
									fontSize: 12,
									justifyContent: 'flex-start',
									textAlign: 'left',
									color: theme.COLORS.textw,
								}}>
								마지막잔액 합
							</Text>

							<KrwPrice
								coinglobal={coinglobal}
								w={'100%'}
								style={{ width: '100%' }}
								price={groupedData.reduce(
									(acc, curr) =>
										BigNumber(acc)
											.plus(curr['last_balance'] || 0)
											.toNumber(),
									0
								)}
							/>
						</View>
					</View>
				</div>
			)
		}
	}

	const dateurl = datesearch
		? `${(startDate && `&startdate=${startDate}`) || ''}
	${(endDate && `&enddate=${endDate}`) || ''}`
		: ''

	let KEY = apikey
		? apikey + dateurl + '&maxData=' + maxData + '&page=' + page
		: null

	let SHAREKEY = sharemutate
		? sharemutate + dateurl + '&maxData=' + maxData + '&page=' + page
		: null

	const { data, mutate, isLoading, isValidating } = useSWR<any>(
		KEY,
		async () => {
			const response = await API.get(
				`${KEY}`,
				token + '',
				{},
				false,
				coinglobal == 'C' ? API_COINURL : null
			)

			console.log(
				'Table API',
				coinglobal == 'C' ? API_COINURL : null,
				response
			)

			actionrefresh && actionrefresh()
			return response?.result?.result
		},
		autorefresh
			? {
					revalidateOnFocus: false,
					dedupingInterval: 0,
					refreshInterval: autorefresh,
			  }
			: { revalidateOnFocus: false, dedupingInterval: 0 }
	)

	const { mutate: shareMutate } = useSWR<any>(
		SHAREKEY,
		async () => {
			const response = await API.get(`${SHAREKEY}`, token + '', {}, false)
			console.log('Table API', response)
			return response?.result?.result
		},
		autorefresh
			? {
					revalidateOnFocus: false,
					dedupingInterval: 0,
					refreshInterval: autorefresh,
			  }
			: { revalidateOnFocus: false, dedupingInterval: 0 }
	)

	useEffect(() => {
		KEY = apikey
			? apikey + dateurl + '&maxData=' + maxData + '&page=' + page
			: null

		SHAREKEY = sharemutate
			? sharemutate + dateurl + '&maxData=' + maxData + '&page=' + page
			: null

		mutate()
		shareMutate()
	}, [page, coinglobal])

	let dataSource = data?.list || []

	if (onlyonline) {
		dataSource = dataSource.filter(item => item?.client)
	}

	const exceldata = dataSource?.map(item => {
		//total_withdraw_amount 은 마이너스로 표기
		item.total_deposit_amount = +item.total_deposit_amount
		item.total_withdraw_amount = -1 * item.total_withdraw_amount
		item.total_withdraw_amount_extra = -1 * item.total_withdraw_amount_extra
		item.total_withdraw_amount_move = -1 * item.total_withdraw_amount_move

		item.total_withdraw_confirm_amount =
			-1 * item.total_withdraw_confirm_amount

		item.total_fee_price = -1 * item.total_fee_price || 0

		return item
	})

	const generateExcel = () => {
		const column = columns(
			mutate,
			data => {
				setSelectedData(data)
				setShowModal(true)
			},
			token,
			fold,
			edit,
			setEdit,
			shareMutate
		)
		if (!column) return null
		let excelColumn: any = []
		column.map(item => {
			if (item?.dataIndex) {
				if (item?.dataIndex == 'user_id' || item?.title == '회원') {
					excelColumn.push(
						<ExcelColumn
							label={'회원코드'}
							value={item?.dataIndex}
						/>
					)
					excelColumn.push(
						<ExcelColumn label={'회원닉네임'} value={'nickname'} />
					)
					excelColumn.push(
						<ExcelColumn label={'회원메일'} value={'email'} />
					)
					excelColumn.push(
						<ExcelColumn label={'회원연락'} value={'phone'} />
					)
				} else {
					excelColumn.push(
						<ExcelColumn
							label={item?.title || item?.dataIndex}
							value={item?.dataIndex}
						/>
					)
				}
			}
			item?.children?.map(child => {
				if (child?.dataIndex) {
					excelColumn.push(
						<ExcelColumn
							label={child?.title || child?.dataIndex}
							value={child?.dataIndex}
						/>
					)
				}
			})
		})
		return excelColumn
	}

	return (
		<Card
			key={'card' + KEY}
			title={title}
			extra={
				<Space size="small">
					{extra}
					{datesearch && (
						<Space.Compact>
							<DatePicker
								placeholder="시작일"
								allowClear={true}
								onChange={e =>
									setStartDate(
										e
											? dayjs(e, 'YYYY-MM-DD').format(
													'YYYY-MM-DD'
											  )
											: null
									)
								}
								defaultValue={
									startDate
										? dayjs(startDate, 'YYYY-MM-DD')
										: undefined
								}
							/>
							<DatePicker
								placeholder="종료일"
								allowClear={true}
								onChange={e =>
									setEndDate(
										e
											? dayjs(e, 'YYYY-MM-DD').format(
													'YYYY-MM-DD'
											  )
											: null
									)
								}
								defaultValue={
									endDate
										? dayjs(endDate, 'YYYY-MM-DD')
										: undefined
								}
							/>
						</Space.Compact>
					)}

					{detail && (
						<Button
							onClick={() => {
								goTo(nav, detail)
							}}
							type={undefined}
							size="small">
							<OrderedListOutlined />
						</Button>
					)}

					{!hideIcon && (
						<>
							{excel && (
								<ExcelFile
									filename={
										((title &&
											(title + '')?.replace(
												/\//gi,
												''
											)) ||
											'Export') + new Date().getTime()
									}
									element={
										<Button
											icon={<DownloadOutlined />}
											type={undefined}
										/>
									}>
									<ExcelSheet data={exceldata} name="excel">
										{generateExcel()}
									</ExcelSheet>
								</ExcelFile>
							)}

							<Button
								onClick={() => {
									mutate(1)
								}}
								type={undefined}
								size="small">
								<ReloadOutlined />
							</Button>
						</>
					)}

					{closeAction && (
						<Button
							onClick={() => {
								closeAction()
							}}
							style={{
								paddingInline: 10,
								marginLeft: 10,
							}}
							type={undefined}
							size="small">
							<IconX />
						</Button>
					)}

					{action && action(mutate)}
					{actionmodal && actionmodal(mutate)}
				</Space>
			}
			size="small"
			style={{
				width: '100%',
				height: '100%',
				margin: 0,
				padding: 0,
			}}>
			<Table
				key={'table' + KEY}
				rowKey={(record, index) =>
					'KEY' + index + '-' + (record.id || record.user_id)
				}
				rowClassName={(record, index) => {
					return record?.id == selectedid ? 'selected' : ''
				}}
				rootClassName={className ? className : ''}
				bordered={true}
				pagination={
					pagination
						? {
								current: page,
								total: data?.totalCount || 1,
								pageSize: maxData,
								pageSizeOptions: [
									'20',
									'50',
									'100',
									'200',
									'500',
									'1000',
									'10000',
								],
								onShowSizeChange: (current, size) => {
									setMaxData(size)
									setPage(1)
								},
								onChange: page => setPage(page),
						  }
						: false
				}
				onRow={record => {
					return {
						onClick: () => {
							console.log('record', record)
							rowAction && rowAction(record)
						},
					}
				}}
				footer={footerTable}
				dataSource={dataSource}
				columns={columns(
					mutate,
					data => {
						setSelectedData(data)
						setShowModal(true)
					},
					token,
					fold,
					edit,
					setEdit,
					shareMutate
				)}
				scroll={
					!height || height == 'default'
						? { x: width }
						: { x: width, y: height }
				}
				size="small"
				loading={loading && (isLoading || isValidating)}
				style={{
					width: stylewidth ? stylewidth : '100%',
					padding: 0,
				}}
			/>
		</Card>
	)
}

export default React.memo(TableAPI)
