import React, { useContext } from 'react'
import {
	RowView,
	SpacebetweenRowView,
	Text,
	TouchableOpacity,
} from '../../../../../styles/reactnative'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { COLORS, FONTS } from '../../../../constants'
import { CButton } from '../../../../components/Common'
import { message } from 'antd'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import API from '../../../../../utils/api'
import { useToken } from '../../../../hooks'
import { CANCELORDER } from '../../../../constants/APIKEYS'
import { useConfirmStore } from 'app/hooks/trade/confirmStore'

const TradeTabFooterHeader = ({ data, tab, setTab }) => {
	const { isMobile } = useContext(ThemeContext)
	const { textw, point } = useContext(ThemeContext).theme.COLORS
	const { data: token } = useToken()

	const { data: pos, mutate: mutatePosition } = useTradePosition()

	const waitlist = pos?.filter((i: any) => i?.status)

	const { data: modalStore, change: mutateModalStore } = useTradeModalStore()
	const { change } = useConfirmStore()

	const liquidAll = async () => {
		const commonConfirmCallback = async () => {

			const orderParams = {
				ep_id: '*',
				platform: isMobile ? 1 : 0,
			}

			const result = await API.post(
				'/trade/allcloseorder',
				'' + token,
				orderParams
			)

			if (result?.result?.success) {
				mutatePosition()
				message.success('전체청산이 완료되었습니다.')
			} else {
				if (result?.result?.message) {
					message.error(result?.result?.message)
				} else {
					message.error('청산에 실패하였습니다.')
				}
			}
		}


		if (!modalStore?.skip) {

			change({
				commonConfirm: true,
				commonConfirmTitle: '전체 청산하시겠습니까?',
				commonConfirmCallback: commonConfirmCallback,
			})

			return
		}

		commonConfirmCallback()


	}

	const cancelAll = long_short => {
		const commonConfirmCallback = async () => {

			if (waitlist?.length > 0) {
				API.post('/trade/cancellongshort', token + '', {
					ep_id: 'ALL',
					long_short: long_short,
				}).then(res => {
					mutatePosition()
				})

			
			} else {
				message.error('미체결주문 종목이 없습니다.')
			}
		}


		if (!modalStore?.skip) {

			change({
				commonConfirm: true,
				commonConfirmTitle: '전체 취소하시겠습니까?',
				commonConfirmCallback: commonConfirmCallback,
			})

			return
		}

		commonConfirmCallback()
	}
	return (
		<SpacebetweenRowView
			style={{ marginTop: 10, justifyContent: 'space-between', marginBottom: 10 }}>
			<RowView>
				{data.map((item, index) => (
					<TouchableOpacity
						key={index.toString()}
						style={{ marginRight: 10 }}
						onClick={() => setTab(index)}>
						<Text
							style={{
								...FONTS.h6,
								fontWeight: '500',
								paddingBottom: 3,
								color: tab === index ? COLORS.primary : textw,
								borderBottom:
									tab === index
										? '2px solid'
										: '2px solid transparent',
							}}>
							{item}
						</Text>
					</TouchableOpacity>
				))}
			</RowView>

			<RowView
				style={{
					justifyContent: 'flex-end',
					gap: 10
				}}>
				<CButton
					title={'전체취소'}
					onClick={() => cancelAll('ALL')}
					style={{
						flex: 1,
						background: point,
						color: COLORS.white,
						padding: 8,
						width: '100%',
						borderRadius: 3,
					}}
				/>
				<CButton
					title={'전체청산'}
					onClick={() => liquidAll()}
					style={{
						flex: 1,
						background: point,
						color: COLORS.white,
						padding: 8,
						width: '100%',
						borderRadius: 3,
					}}
				/>
			</RowView>
		</SpacebetweenRowView>
	)
}

export default TradeTabFooterHeader
