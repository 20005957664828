import React, { useContext } from 'react'

import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { CBottomSheet } from '../mobile/components'
import CrossModal from '../mobile/organisms/Trade/TradeTransaction/DerivativesTransaction/DerivativesHeader/CrossModal'
import { ThemeContext } from '../../theme/ThemeProvider'
import { MobileTradeModalView } from './TradePage/styles'
import DepositModal from '../mobile/components/DepositModal'
import WithdrawModal from '../mobile/components/WithdrawModal'
import ConvertModal from '../mobile/components/ConvertModal'
import PositionCloseContainer from '../mobile/organisms/Trade/TradeDetail/TradePositionsTable/PositionCloseContainer'
import DWTModal from '../mobile/components/DWTModal'
import { useWindowDimensions } from 'app/hooks'
import OrderPreferenceModal from 'app/mobile/organisms/Trade/TradeTransaction/DerivativesTransaction/DerivativesBody/OrderValueInput/OrderPreferenceModal'
import { TransferModal } from '../components'
import DWList from 'app/organisms/Header/DWList'
import DWListMobile from 'app/organisms/Header/DWListMobile'
import { Modal } from 'antd'

const MTradeModal = () => {
	const { height } = useWindowDimensions()
	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()
	const COLORS = useContext(ThemeContext).theme.COLORS

	//bottomsheet color
	document.documentElement.style.setProperty('--rsbs-bg', 'white')

	return (
		<MobileTradeModalView
			style={{
				color: COLORS.textGrey,
			}}>
			<Modal
				open={modalStore?.historyModal}
				wrapClassName='history-modal'
				closable={true}
				onCancel={() => {
					muatateModalStore({
						...modalStore,
						historyModal: false,
					})
				}
				}
				footer={null}
				centered={true}
				maskClosable={true}
				bodyStyle={{
					margin: 0,
					padding: 0,
				}}
				style={{
					top: 0,
					padding: 0,
					marginBottom: 50,
				}}

			>
				<DWListMobile />
			</Modal>

			<CBottomSheet
				open={modalStore?.depositModal}
				setOpen={(value: any) => {
					muatateModalStore({
						...modalStore,
						depositModal: value,
					})
				}}
				expandOnContentDrag={false}
				BottomContainer={DepositModal}
				maxHeight={height - 40}
			/>
			<CBottomSheet
				open={modalStore?.dwtModal}
				setOpen={(value: any) => {
					muatateModalStore({
						...modalStore,
						dwtModal: value,
					})
				}}
				expandOnContentDrag={false}
				BottomContainer={DWTModal}
				maxHeight={220}
			/>
			<CBottomSheet
				open={modalStore?.gDeposit}
				setOpen={(value: any) => {
					muatateModalStore({
						...modalStore,
						gDeposit: value,
					})
				}}
				expandOnContentDrag={false}
				BottomContainer={WithdrawModal}
				maxHeight={600}
			/>



			<CBottomSheet
				open={modalStore?.transferModal}
				setOpen={(value: any) => {
					muatateModalStore({
						...modalStore,
						transferModal: value,
					})
				}}
				backgroundColor={COLORS.drawerBgColor}
				maxHeight={500}
				expandOnContentDrag={false}
				BottomContainer={TransferModal}
			/>


		</MobileTradeModalView>
	)
}

export default React.memo(MTradeModal)
