import BigNumber from 'bignumber.js'
import React from 'react'
import { useInfo24H } from '../../../hooks/trade/info24H'
import { useLastPrice } from '../../../hooks/trade/lastprice'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import DefaultKrw from './DefaultKrw'
import PricePrevDay from './PricePrevDay'

const RatePrevDay = ({ mobile = false }) => {
	const { data: tradeStore, symbol } = useTradeStore()
	const { data: lastPrice } = useLastPrice(tradeStore?.ep_id)
	const { data: info24 } = useInfo24H(symbol)

	const currentprice = BigNumber(lastPrice?.[0]?.price || info24?.open || 0)
	const lastrate = BigNumber(currentprice).minus(info24?.close || 0)

	return (
		<>
			<DefaultKrw
				title={'전일비'}
				price={lastrate.toNumber()}
				dp={tradeStore?.dp}
				krw={false}
				center={true}
				color={true}
			/>
			{mobile && <PricePrevDay />}
		</>
	)
}

export default React.memo(RatePrevDay)
