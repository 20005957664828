import React, { useContext, useState } from 'react'
import { IconEdit } from '@tabler/icons-react'
import { COLORS } from 'app/constants'
import { CProfileImage, MButton } from 'app/mobile/components'
import { MView } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import { useMyProfile } from 'app/hooks/user/myprofile'
import { isMobile } from 'react-device-detect'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const ProfileImageSection = () => {
	const { gray } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const maxNumber = 69
	const { data: profile, updateProfile, mutate } = useMyProfile()
	const [image, setImage] = useState<any>(null)
	const onChange = async (
		imageList: ImageListType,
		addUpdateIndex: number[] | undefined
	) => {
		const selectedImage = imageList[0]
		const { file, dataURL } = selectedImage

		if (isMobile) {
			updateProfile({ image: { file, dataURL } })
		} else {
			setImage(selectedImage.file)
		}
		const res = await updateProfile({ image: { file, dataURL } })

		mutate()
		setImage(null)
	}
	return (
		<MView
			style={{
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				width: '100%',
				marginBottom: 30,
				marginTop: 10,
			}}>
			<MView />
			<CProfileImage
				size={40}
				src={profile?.profileimage}
				vStyle={{ marginLeft: 30 }}
			/>

			<ImageUploading
				value={image}
				onChange={onChange}
				maxNumber={maxNumber}>
				{({
					imageList,
					onImageUpload,
					onImageRemoveAll,
					onImageUpdate,
					onImageRemove,
					isDragging,
					dragProps,
				}) => (
					// write your building UI
					<div className="upload__image-wrapper">
						{/* <button
							style={isDragging ? { color: '#F05551' } : undefined}
							onClick={onImageUpload}
							{...dragProps}>
							Click or Drop here
						</button> */}
						<MButton
							onClick={onImageUpload}
							icon={
								<IconEdit size={18} color={COLORS.secondary} />
							}
							style={{
								width: 30,
								height: 30,
								background: gray,
								borderRadius: 10,
							}}
							{...dragProps}
						/>
						&nbsp;
					</div>
				)}
			</ImageUploading>
		</MView>
	)
}

export default ProfileImageSection
