import React from 'react'
import KrwPrice from '../../components/KrwPrice'
import EntryTime from '../../components/EntryTime'
import BankInfo from '../../components/BankInfo'
import { Radio, Space, Tag } from 'antd'
import TableAPI from '../../components/TableAPI'
import { useTradeModalStore } from '../../hooks/trade/modalStore'
import { COLORS } from '../../constants'
import MAllAnnouncements from '../../mobile/organisms/Announcements/AllAnnouncements'
import { Announcement } from '../Announcement'

const Notice = () => {
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()
	return <Announcement title={'공지사항'} />
}

export default Notice
