import BigNumber from 'bignumber.js'
import React, { useContext } from 'react'
import { Text, View } from '../../../../styles/reactnative'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { useExchangeStore } from '../../../hooks/trade/exchangeStore'
import { useIndexPrice } from '../../../hooks/trade/indexprice'
import { useInfo24H } from '../../../hooks/trade/info24H'
import KrwPrice from '../../KrwPrice'

const LastPrice = ({ ep_id }) => {
	BigNumber.clone() // new line
	const { getPrice } = useIndexPrice(ep_id)
	const { EPs } = useExchangeStore()

	const EP = EPs?.find(item => item.id === ep_id)

	const { data: info24H } = useInfo24H(EP.pairs)
	const { raise, fail, textw } = useContext(ThemeContext).theme.COLORS

	const currentprice = BigNumber(getPrice(ep_id) || info24H?.open || 0)
	let rate = BigNumber(currentprice)
		.minus(info24H?.close || 0)
		.multipliedBy(100)
		.dividedBy(currentprice)
		.toNumber()

	if (isNaN(rate)) rate = 0

	return (
		<View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
			<KrwPrice
				price={getPrice(ep_id) || 0}
				dp={EP?.dp}
				krw={false}
				color={false}
				theme={false}
				style={{
					fontSize: 12,
					color:
						rate > 0
							? raise
							: rate < 0
							? fail
							: textw,
				}}
			/>

			<Text
				style={{
					fontSize: 12,
					marginLeft: 5,
					letterSpacing: -0.2,
					color:
					rate > 0
							? raise
							: rate < 0
							? fail
							: textw,
				}}>
				(
				{BigNumber(
					rate || !isNaN(rate) ? rate : 0
				).toFormat(2) + '%'}
				)
			</Text>
		</View>
	)
}

export default React.memo(LastPrice)
