import React from 'react'
import KrwPrice from '../../KrwPrice'
import Default from './Default'
import { useTradePosition } from '../../../hooks/trade/tradePosition'

const RealizePnl = () => {
	const { total_trade_price } = useTradePosition()
	const realizepnl = total_trade_price || 0

	return (
		<Default
			title={'실현손익'}
			value={
				<KrwPrice
					margin={true}
					price={realizepnl || 0}
					dp={0}
					krw={false}
				/>
			}
		/>
	)
}

export default React.memo(RealizePnl)
