import React from 'react'
import { CInput } from 'app/components/Common/'
import { useTranslation } from 'react-i18next'
import { InputContainer } from './styles'
import PhoneInputSection from './PhoneInputSection'
import { ThemeContext } from 'theme/ThemeProvider'
interface IProps {
	type: string
	id: string
	setId: (params?: any) => void
	password: string
	setPassword: (params?: any) => void
	onKeyDown?: (params?: any) => void
}

const InputSection = ({
	type,
	id,
	setId,
	password,
	setPassword,
	onKeyDown,
}: IProps) => {
	const { gray, textw } = React.useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()
	const onChangeId = e => {
		setId(e.target.value)
	}
	const onChangePassword = e => {
		setPassword(e.target.value)
	}

	return (
		<InputContainer>
			{type === 'phone' ? (
				<PhoneInputSection
					id={id}
					onChangeId={onChangeId}
					type={type}
				/>
			) : (
				<CInput
					placeholder={t(type)}
					value={id}
					type={'text'}
					onChange={onChangeId}
					style={{ border: 'none', background: gray, color: textw }}
				/>
			)}

			<CInput
				placeholder={t('password')}
				value={password}
				type={'password'}
				onChange={onChangePassword}
				style={{
					border: 'none',
					background: gray,
					color: textw,
					borderRadius: 0,
				}}
				onKeyDown={onKeyDown}
			/>
		</InputContainer>
	)
}

export default React.memo(InputSection)
