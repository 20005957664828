import { useState, useEffect, useContext, useCallback } from 'react'
import { ThemeContext } from '../../theme/ThemeProvider'

function getWindowDimensions() {
	const {
		innerWidth: width,
		innerHeight: height,
		outerHeight,
		outerWidth,
	} = window
	const isMobile: any = /iphone|ipad|ipod|android|mobile/i.test(
		navigator.userAgent?.toLowerCase()
	)

	return {
		width,
		height,
		outerHeight,
		outerWidth,
		isMobile: isMobile,
	}
}

let timer: any = null

export default function useMobileDimensions() {
	const { isMobile, toggleMobile, changeResize } = useContext(ThemeContext)

	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	)

	useEffect(() => {
		const dimensions = getWindowDimensions()
		if (isMobile !== null) {
			dimensions.isMobile = isMobile
		} else {
			toggleMobile(dimensions.isMobile)
		}
		setWindowDimensions(dimensions)

		console.log('useMobileDimensions', dimensions)
	}, [isMobile])

	useEffect(() => {
		let windowWidth = window.innerWidth

		let handleResize = () => {

			if (window.innerWidth != windowWidth) {
				windowWidth = window.innerWidth

				console.log('resize start')
				changeResize(true)

				clearTimeout(timer)
				timer = setTimeout(() => {
					console.log('resize end')
					changeResize(false)
				}, 100)
			}

		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowDimensions
}
