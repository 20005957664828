import React, { useEffect, useState } from 'react'
import { CFlatList } from '../../../../../components/Common'
// import { useTradePosition } from '../../../../../hooks/trade';
import OrderItems from './OrderItems'
import { useTradePosition } from '../../../../../hooks/trade/tradePosition'
import { MView } from '../../../../../../styles/mobilePageStyles'
import MTradeModal from '../../../../../pages/MTradeModal'
import { useMitOrder } from '../../../../../hooks/trade/mitorder'
import { Text, View } from '../../../../../../styles/reactnative'
import BigNumber from 'bignumber.js'
import { useExchangeStore } from '../../../../../hooks/trade/exchangeStore'
import { ThemeContext } from '../../../../../../theme/ThemeProvider'
import { IconMoodEmpty } from '@tabler/icons-react'
import { useTPLStore } from '../../../../../hooks/trade/tplStore'
import { useTradeStore } from '../../../../../hooks/trade/tradeStore'

const TradeTabPositions = ({ current = false, zoom = 1 }) => {
	const { data } = useTradePosition()
	const { ep_id } = useTradeStore()
	const { data: tplStore } = useTPLStore()

	const { EPs } = useExchangeStore()
	const { data: mitdata } = useMitOrder()

	const [mit, setMit] = useState<any>([])

	const { theme } = React.useContext(ThemeContext)

	useEffect(() => {
		const keys = Object.keys(mitdata || [])

		let mitlist: any = []
		for (const key of keys) {
			if (key == 'NO') continue

			const item = mitdata[key]
			item?.map((mit: any) => {
				const temp = {
					status: 10,
					exchange_pairs_id: parseInt(key?.replace('MIT', '')),
					price: mit.price,
					long_short: mit.longshort,
					amount: mit?.amount,
				}
				mitlist.push(temp)
			})
		}

		setMit([...mitlist])
		return () => {
			setMit([])
		}
	}, [mitdata, data])

	let list = data || []

	list = list.filter((item: any) => item?.limit_market != 'M' && !item?.reduce_only)

	if (current) {
		list?.sort((a: any, b: any) => {
			// status 가 없는게 맨앞
			if (!a?.status) return -1
			if (!b?.status) return 1
			// status 가 있는것중에 status 순서대로
			return a?.status - b?.status
		})

		list = [...list, ...mit]
	} else {
		list = [...mit, ...list]
	}

	data?.filter((item: any) => !item?.status)?.map((item: any) => {
		let tt = tplStore
			?.filter((i: any) => i.key == 'TPL' + item?.exchange_pairs_id)
			?.map(d => {
				const [profit, loss, p, l] = d?.value?.split(':') || [
					5, 5, 0, 0,
				]
				return {
					profit: p == 1 ? true : false,
					profittick: +profit || 5,
					loss: l == 1 ? true : false,
					losstick: +loss || 5,
				}
			}) || [
				{
					profit: false,
					profittick: 5,
					loss: false,
					losstick: 5,
				},
			]

		if (tt.length == 0) {
			tt = [
				{
					profit: false,
					profittick: 5,
					loss: false,
					losstick: 5,
				},
			]
		}

		const t = tt?.[0]

		if (t?.profit) {
			let calcprice = new BigNumber(item?.price || 0)
			let ep = EPs?.find((ep: any) => ep?.id == item?.exchange_pairs_id)
			if (item?.long_short == 'L') {
				calcprice = calcprice.plus(
					new BigNumber(t?.profittick || 0).multipliedBy(ep?.unit)
				)
			} else {
				calcprice = calcprice.minus(
					new BigNumber(t?.profittick || 0).multipliedBy(ep?.unit)
				)
			}

			if (current) {
				list = [
					...list,
					{
						status: 8,
						exchange_pairs_id: item?.exchange_pairs_id,
						price: calcprice.toNumber(),
						long_short: item?.long_short == 'L' ? 'S' : 'L',
						amount: item?.amount,
					},
				]
			} else {
				list = [
					{
						status: 8,
						exchange_pairs_id: item?.exchange_pairs_id,
						price: calcprice.toNumber(),
						long_short: item?.long_short == 'L' ? 'S' : 'L',
						amount: item?.amount,
					},
					...list,
				]
			}
		}

		if (t?.loss) {
			let calcprice = new BigNumber(item?.price || 0)
			let ep = EPs?.find((ep: any) => ep?.id == item?.exchange_pairs_id)
			if (item?.long_short == 'L') {
				calcprice = calcprice.minus(
					new BigNumber(t?.losstick || 0).multipliedBy(ep?.unit)
				)
			} else {
				calcprice = calcprice.plus(
					new BigNumber(t?.losstick || 0).multipliedBy(ep?.unit)
				)
			}
			if (current) {
				list = [
					...list,
					{
						status: 9,
						exchange_pairs_id: item?.exchange_pairs_id,
						price: calcprice.toNumber(),
						long_short: item?.long_short == 'L' ? 'S' : 'L',
						amount: item?.amount,
					},
				]
			} else {
				list = [
					{
						status: 9,
						exchange_pairs_id: item?.exchange_pairs_id,
						price: calcprice.toNumber(),
						long_short: item?.long_short == 'L' ? 'S' : 'L',
						amount: item?.amount,
					},
					...list,
				]
			}
		}
	})

	if (current) {
		list = list.filter((item: any) => item?.exchange_pairs_id == ep_id)
	}

	const renderItem = (item: any, index: any) => {
		return <OrderItems item={item} index={'item' + (item?.id) + (item?.status) + index} current={current} />
	}

	const heightsize = (Math.min(data?.length || 1, 5) + 1) * 130 + 10

	return (
		<MView
			style={{
				width: '100%',
				height: heightsize + 'px',
				maxHeight: current ? 250 : heightsize,
				overflowX: 'hidden',
				overflowY: 'auto',
				marginBottom: current ? 0 : 30,
			}}>
			{list?.map((item: any, index: any) => {
				return renderItem(item, index)
			})}
			{list?.length == 0 && (
				<>
					{data?.length ? (
						<View
							style={{
								marginTop: 20,
								width: '100%',
								minHeight: 100,
								alignContent: 'center',
								alignItems: 'center',
								justifyItems: 'center',
								justifyContent: 'center',
								border: `1px solid ${theme.COLORS.raise}`,
								background: 'rgba(0,0,0,0.2)',
								borderRadius: 5,
							}}>
							<Text
								style={{
									textAlign: 'center',
									color: theme.COLORS.raise,
									fontSize: 16,
									marginBottom: 5,
									fontWeight: 'bold',
								}}>※ 주 의 ※
							</Text>
							<Text
								style={{
									textAlign: 'center',
									fontWeight: 'bold',
									marginBottom: 5,
									fontSize: 12,
									letterSpacing: -0.5,
									color: theme.COLORS.textw,
								}}>다른 종목 주문 및 포지션이 있습니다.
							</Text>
							<Text
								style={{
									textAlign: 'center',
									fontWeight: 'bold',
									fontSize: 12,
									letterSpacing: -0.5,
									color: theme.COLORS.textw,
								}}>아래로 스크롤하여 리스트를 확인해주세요.
							</Text>
						</View>
					) : (
						<View
							style={{
								marginTop: 20,
								width: '100%',
								minHeight: 100,
								alignContent: 'center',
								alignItems: 'center',
								justifyItems: 'center',
								justifyContent: 'center',
								background: 'rgba(0,0,0,0.2)',
								borderRadius: 5,
							}}>
							<IconMoodEmpty
								size={17}
								color={theme.COLORS.textw}
								style={{ marginBottom: 10 }}
							/>
							<Text
								style={{
									textAlign: 'center',
									color: theme.COLORS.textw,
									padding: 10,
								}}>보유 주문 및 포지션이 없습니다.
							</Text>
						</View>
					)}
				</>

			)
			}
			<MTradeModal />
		</MView >
	)
}

export default TradeTabPositions
