import React, { useCallback, useContext, useEffect } from 'react'
import { Button, Space, message } from 'antd'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { useToken, useWindowDimensions } from '../../../../hooks'
import { useTradeStore } from '../../../../hooks/trade/tradeStore'
import { useOrderStore } from '../../../../hooks/trade/orderStore'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'
import { IconPinnedFilled } from '@tabler/icons-react'
import { IconPinned } from '@tabler/icons-react'
import { Text } from '../../../../../styles/reactnative'
import API from '../../../../../utils/api'
import { useLogQueue } from '../../../../hooks/trade/logQueue'
import { useUserSocket } from '../../../../hooks/trade/userSocket'

const OrderBookTopWidget = ({
	height = 35,
	newwindow = false,
	parentWidth = 600,
}) => {
	const { width } = useWindowDimensions()
	let size: any = width < 1450 ? 'small' : 'middle'
	const DELAY = 90

	if (parentWidth < 500) {
		size = 'small'
	}
	const { sendOrder } = useUserSocket()

	const [button1, setButton1] = React.useState(false)
	const [button2, setButton2] = React.useState(false)
	const [button3, setButton3] = React.useState(false)
	const [button4, setButton4] = React.useState(false)

	const { data: token } = useToken()
	const { theme, isMobile } = useContext(ThemeContext)

	const { COLORS } = theme

	const { mutate: mutateLog } = useLogQueue()

	const { ep_id } = useTradeStore()
	const { data: orderStore, change } = useOrderStore()
	const { data: pos, mutate: mutatePosition } = useTradePosition()
	const position = pos?.filter((item: any) => !item?.status)

	const {
		data: modalStore,
		change: changeStore,
		mutateMsg,
	} = useTradeModalStore()
	const [fixed, setFixed] = React.useState(false)

	useEffect(() => {
		setFixed(modalStore?.fixed_orderbook)
	}, [modalStore?.fixed_orderbook])

	const marketOrder = long_short => {
		change({
			long_short: long_short,
			limit_market: 'M',
		})

		if (!modalStore?.skip) {
			changeStore({ showconfirmorder: true, mitlong: null })
			return
		}

		sendOrder(
			isMobile ? 1 : 0,
			ep_id,
			long_short,
			'M',
			long_short == 'L' ? 99999999 : 0.000001,
			orderStore?.amount,
			'',
			token,
			() => {
				setTimeout(() => {
					mutatePosition()
				}, 1000)
			},
			() => {},
			0,
			msg => mutateMsg(msg)
		)
	}
	const changeFixed = data => {
		setFixed(data)
		changeStore({
			fixed_orderbook: data,
		})
	}

	const handleKeyPress = useCallback(
		event => {
			//shift + 1 = 매도
			if (event.key === '!') {
				marketOrder('S')
				setButton1(true)
				setTimeout(() => {
					setButton1(false)
				}, DELAY)

				mutateLog({
					log: 'ST001',
					data: {
						current_ep_id: ep_id,
						device: 'WTS',
						isMobile,
						KEY: 'SHIFT+2',
					},
				})
			} else if (event.key === '@') {
				marketOrder('L')
				setButton4(true)
				setTimeout(() => {
					setButton4(false)
				}, DELAY)
				mutateLog({
					log: 'ST004',
					data: {
						current_ep_id: ep_id,
						device: 'WTS',
						isMobile,
						KEY: 'SHIFT+2',
					},
				})
			}
		},
		[marketOrder, orderStore]
	)

	useEffect(() => {
		// attach the event listener
		document.addEventListener('keydown', handleKeyPress)

		// remove the event listener
		return () => {
			document.removeEventListener('keydown', handleKeyPress)
		}
	}, [handleKeyPress])
	const liquidCurrent = async () => {
		if (position?.length > 0) {
			const orderParams = {
				ep_id: ep_id,
				platform: isMobile ? 1 : 0,
			}

			const result = await API.post(
				'/trade/allcloseorder',
				'' + token,
				orderParams
			)

			if (result?.result?.success) {
				mutatePosition()
				if (newwindow) {
					mutateMsg('현종목 청산이 완료되었습니다.')
				} else {
					message.success('현종목 청산이 완료되었습니다.')
				}
			} else {
				if (result?.result?.message) {
					if (newwindow) {
						mutateMsg(result?.result?.message)
					} else {
						message.error(result?.result?.message)
					}
				} else {
					if (newwindow) {
						message.error('청산에 실패하였습니다.')
					} else {
						message.error('청산에 실패하였습니다.')
					}
				}
			}
		} else {
			message.error('현 보유종목이 없습니다.')
		}
	}

	const liquidAll = async () => {
		if (position?.length > 0) {
			const orderParams = {
				ep_id: '*',
				platform: isMobile ? 1 : 0,
			}

			const result = await API.post(
				'/trade/allcloseorder',
				'' + token,
				orderParams
			)

			if (result?.result?.success) {
				mutatePosition()
				if (newwindow) {
					mutateMsg('전체청산이 완료되었습니다.')
				} else {
					message.success('전체청산이 완료되었습니다.')
				}
			} else {
				if (result?.result?.message) {
					if (newwindow) {
						mutateMsg(result?.result?.message)
					} else {
						message.error(result?.result?.message)
					}
				} else {
					if (newwindow) {
						message.error('청산에 실패하였습니다.')
					} else {
						message.error('청산에 실패하였습니다.')
					}
				}
			}
		}

		/*
		if (position?.length > 0) {
			position?.map((item: any) => {
				setImmediate(() => {
					const long_short = item?.long_short
					const amount = item?.amount

					sendOrder(
						item?.exchange_pairs_id,
						long_short == 'L' ? 'S' : 'L',
						'M',
						long_short == 'S' ? 999999999 : 0.000001,
						amount,
						'',
						token,
						() => {
							mutatePosition()
						}
					)
				})
			})
		} else {
			message.error('현 보유종목이 없습니다.')
		}
		*/
	}
	return (
		<Space
			size={'small'}
			style={{
				height: 40,
				display: 'flex',
				flex: 1,
				justifyContent: 'space-between',
				width: '100%',
			}}>
			<Button
				size={size}
				type={'primary'}
				disabled={button1}
				style={{
					background: COLORS.fail,
					color: 'white',
					display: 'flex',
					flex: 1,
				}}
				onClick={() => {
					marketOrder('S')
					setButton1(true)
					setTimeout(() => {
						setButton1(false)
					}, DELAY)

					mutateLog({
						log: 'ST001',
						data: {
							current_ep_id: ep_id,
							device: 'WTS',
						},
					})
				}}>
				시장가매도(!)
			</Button>
			<Button
				size={size}
				type="default"
				style={{
					display: 'flex',
					flex: 1,
				}}
				disabled={button2}
				onClick={() => {
					liquidAll()
					setButton2(true)
					setTimeout(() => {
						setButton2(false)
					}, DELAY)
					mutateLog({
						log: 'ST002',
						data: {
							current_ep_id: ep_id,
							device: 'WTS',
							isMobile,
						},
					})
				}}>
				전체청산
			</Button>
			<Button
				type={undefined}
				size="small"
				onClick={() => {
					changeFixed(!fixed)
				}}
				style={{
					display: 'flex',
					flexDirection: 'row',
					padding: 0,
					margin: 0,
					flexWrap: 'nowrap',
					alignItems: 'center',
				}}>
				{fixed ? (
					<IconPinnedFilled
						size={14}
						style={{ color: COLORS.fail }}
					/>
				) : (
					<IconPinned size={14} style={{ color: COLORS.textw }} />
				)}
				<Text style={{ fontSize: '0.8em', color: COLORS.textw }}>
					호가고정
				</Text>
			</Button>
			<Button
				size={size}
				style={{
					display: 'flex',
					flex: 1,
				}}
				disabled={button3}
				onClick={() => {
					liquidCurrent()
					setButton3(true)
					setTimeout(() => {
						setButton3(false)
					}, DELAY)
					mutateLog({
						log: 'ST003',
						data: {
							current_ep_id: ep_id,
							device: 'WTS',
							isMobile,
						},
					})
				}}>
				현종목청산
			</Button>
			<Button
				size={size}
				type={'primary'}
				danger
				disabled={button4}
				style={{
					background: COLORS.raise,
					color: 'white',
					display: 'flex',
					flex: 1,
				}}
				onClick={() => {
					marketOrder('L')
					setButton4(true)
					setTimeout(() => {
						setButton4(false)
					}, DELAY)
					mutateLog({
						log: 'ST004',
						data: {
							current_ep_id: ep_id,
							device: 'WTS',
							isMobile,
						},
					})
				}}>
				시장가매수(@)
			</Button>
		</Space>
	)
}

export default React.memo(OrderBookTopWidget)
