import React from 'react'
import KrwPrice from '../../KrwPrice'
import BigNumber from 'bignumber.js'
import Default from './Default'
import { useOvernight } from '../../../hooks/trade/overnight'

const Overmargin = () => {
	const { data: overnight } = useOvernight()

	let sum = 0
	if (overnight?.length > 0) {
		overnight?.map((item: any) => {
			sum = BigNumber(item?.lockbalance).plus(sum).toNumber()
		})
	}

	return (
		<Default
			title={'총오버증거금'}
			value={
				<KrwPrice
					margin={true}
					price={sum}
					dp={0}
					krw={false}
					color={false}
				/>
			}
		/>
	)
}

export default Overmargin
