import React, { useContext, useState } from 'react'
import { Checkbox, Image, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { usePopup } from '../../hooks/popup'
import { MarkdownComponent } from '../../components'
import PopupModal from './PopupModal'

const Popup = () => {
	const { data } = usePopup()
	return (
		<>
			{data?.map((item: any) => {
				const vis = localStorage.getItem('POPUP' + item.id)
				if (vis == '1') return null

				return (
					<PopupModal key={'POPUP' + item.id} item={item} vis={vis} />
				)
			})}
		</>
	)
}

export default Popup
