import { Button, Space, message } from 'antd'
import React, { useContext, useEffect } from 'react'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import API from '../../../../../utils/api'
import { CANCELORDER } from '../../../../constants/APIKEYS'
import { useToken, useWindowDimensions } from '../../../../hooks'
import { useLogQueue } from '../../../../hooks/trade/logQueue'
import { useMitOrder } from '../../../../hooks/trade/mitorder'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'
import { useTradeStore } from '../../../../hooks/trade/tradeStore'

const OrderBookBottomWidget = ({ newwindow = false }) => {
	const { width } = useWindowDimensions()
	const size = width < 1450 ? 'small' : 'middle'
	const DELAY = 90
	const { data: token } = useToken()

	const { isMobile } = useContext(ThemeContext)

	const [show, setShow] = React.useState(false)

	const { data: tradeStore } = useTradeStore()

	const {  cancel } = useMitOrder()
	const {
		mutateMsg,
	} = useTradeModalStore()
	const { data: pos, mutate: mutatePosition } = useTradePosition()

	const { mutate: mutateLog } = useLogQueue()

	const waitlist = pos?.filter(
		(item: any) =>
			item?.status && item?.exchange_pairs_id == tradeStore?.ep_id
	)

	const [button5, setButton5] = React.useState(false)
	const [button6, setButton6] = React.useState(false)
	const [button7, setButton7] = React.useState(false)
	const [button8, setButton8] = React.useState(false)
	const [button9, setButton9] = React.useState(false)

	useEffect(() => {
		if (show) {
			message.success('주문이 일괄 취소되었습니다.')
			setTimeout(() => {
				setShow(false)
			}, 500)
		}
	}, [show])

	const cancelCurrent = long_short => {
		if (waitlist?.length > 0) {
			API.post('/trade/cancellongshort', token + '', {
				ep_id: tradeStore?.ep_id,
				long_short: long_short,
			}).then(res => {
				mutatePosition()
				setShow(true)
			})
		} else {
			message.error('현 주문 종목이 없습니다.')
		}
	}

	return (
		<>
			<Space
				className={'ordersmall'}
				style={{
					height: 40,
					justifyContent: 'space-between',
					width: '100%',
					marginTop: 5,
				}}>
				<Button
					size={size}
					type={'primary'}
					
					disabled={button5}
					style={{
						lineHeight: 1,
					}}
					onClick={() => {
						setButton5(true)
						mutateLog({
							log: 'GT004',
							data: {
								type: 'MIT',
								longshort: 'S',
								isMobile,
							},
						})
						cancel(tradeStore?.ep_id, 0, 'S')

						if (newwindow) {
							mutateMsg('매도MIT 주문 취소 완료되었습니다.')
						} else {
							message.success('매도MIT 주문 취소 완료되었습니다.')
						}
						setTimeout(() => {
							setButton5(false)
						}, DELAY)
					}}>
					<div style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
						MIT
					</div>
					<div>취소</div>
				</Button>
				<Button
					size={size}
					type={'primary'}
					disabled={button6}
					style={{
						lineHeight: 1,
					}}
					onClick={() => {
						cancelCurrent('S')
						setButton6(true)
						setTimeout(() => {
							setButton6(false)
						}, DELAY)
					}}>
					<div style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
						매도
					</div>
					<div>취소</div>
				</Button>
				<Button
					size={size}
					type={undefined}
					onClick={() => {
						cancelCurrent('ALL')
						setButton7(true)
						setTimeout(() => {
							setButton7(false)
						}, DELAY)
					}}
					disabled={button7}
					style={{
						background: '#FFCD4B',
						color: '#3a3a3a',
						lineHeight: 1,
					}}>
					<div style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
						현종목
					</div>
					<div>취소</div>
				</Button>
				<Button
					size={size}
					type={'primary'}
					danger
					disabled={button8}
					style={{
						lineHeight: 1,
					}}
					onClick={() => {
						cancelCurrent('L')
						setButton8(true)
						setTimeout(() => {
							setButton8(false)
						}, DELAY)
					}}>
					<div style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
						매수
					</div>
					<div>취소</div>
				</Button>
				<Button
					type={'primary'}
					danger
					size={size}
					disabled={button9}
					style={{
						lineHeight: 1,
					}}
					onClick={() => {
						cancel(tradeStore?.ep_id, 0, 'L')
						mutateLog({
							log: 'GT004',
							data: {
								type: 'MIT',
								longshort: 'L',
								isMobile,
							},
						})
						if (newwindow)
							mutateMsg('매수MIT 주문 취소 완료되었습니다.')
						else message.info('매수MIT 주문 취소 완료되었습니다.')
						mutatePosition()
						setButton9(true)
						setTimeout(() => {
							setButton9(false)
						}, DELAY)
					}}>
					<div style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
						MIT
					</div>
					<div>취소</div>
				</Button>
			</Space>
		</>
	)
}

export default React.memo(OrderBookBottomWidget)
