import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
	IconBell,
	IconBrandKickstarter,
	IconStar,
	IconStarFilled,
	IconUserCircle,
} from '@tabler/icons-react'
import { View } from 'styles/reactnative'
import AuthHeaderButton from './AuthHeaderButton'
import { AuthHeaderMobile } from './styles'
import { useHistory } from 'react-router-dom'
import { goTo } from '../../../hooks/navigation'
import { Button, Modal, Rate, Select, Tooltip } from 'antd'
import AuthButtonLists from './AuthButtonLists'
import API from '../../../../utils/api'
import { COINHIDE } from '../../../constants/APIKEYS'
import HeaderButton from '../HeaderButton'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'

const AuthHeaderMenu = () => {
	const { t } = useTranslation()

	const nav = useHistory()

	const [star1, setStar1] = React.useState(true)
	const [star2, setStar2] = React.useState(true)
	const [star3, setStar3] = React.useState(true)

	const [turotial, setTutorial] = React.useState(false)

	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()

	return (
		<View
			style={{
				flexDirection: 'row',
				paddingInline: '1rem',
				marginRight: 0,
			}}>
			{COINHIDE && (
				<HeaderButton
					label={t('notice')}
					onPress={() =>
						mutateModalStore({
							...modalStore,
							notice: true,
						})
					}
				/>
			)}
			<AuthHeaderButton
				type={'tutorial'}
				label={'경제지표'}
				onPress={() => {
					setTutorial(true)
				}}

				// onPress={() => {
				// 	goTo(nav, '/assets')
				// }}
			/>

			<AuthHeaderButton
				type={'asset'}
				label={t('assets')}
				// onPress={() => {
				// 	goTo(nav, '/assets')
				// }}
			/>
			<AuthHeaderButton
				type={'user'}
				Icon={() => <IconUserCircle size={20} />}
			/>
			<AuthHeaderButton
				type={'noti'}
				Icon={() => <IconBell size={20} />}
			/>
			<Modal
				title={
					<View
						style={{
							flexDirection: 'column',
							width: '100%',
							justifyContent: 'center',
							flex: 1,
							alignItems: 'center',
							paddingInline: '1rem',
						}}>
						경제지표
						<View
							style={{
								flexDirection: 'row',
								flex: 1,
								width: '100%',
							}}>
							<Tooltip title="중요도1인 경제지표만 표기합니다">
								<Button
									type={undefined}
									style={{ width: 100 }}
									icon={
										star1 ? (
											<>
												<IconStarFilled
													size={20}
													style={{ color: '#ffcc00' }}
												/>
												<IconStar size={20} />
												<IconStar size={20} />
											</>
										) : (
											<>
												<IconStarFilled
													size={20}
													style={{ color: '#cdcdcd' }}
												/>
												<IconStar size={20} />
												<IconStar size={20} />
											</>
										)
									}
									onClick={() => setStar1(!star1)}
								/>
							</Tooltip>
							<Tooltip title="중요도2인 경제지표만 표기합니다">
								<Button
									type={undefined}
									style={{ width: 100 }}
									icon={
										star2 ? (
											<>
												<IconStarFilled
													size={20}
													style={{ color: '#ffcc00' }}
												/>
												<IconStarFilled
													size={20}
													style={{ color: '#ffcc00' }}
												/>
												<IconStar size={20} />
											</>
										) : (
											<>
												<IconStarFilled
													size={20}
													style={{ color: '#cdcdcd' }}
												/>
												<IconStarFilled
													size={20}
													style={{ color: '#cdcdcd' }}
												/>
												<IconStar size={20} />
											</>
										)
									}
									onClick={() => setStar2(!star2)}
								/>
							</Tooltip>
							<Tooltip title="중요도3인 경제지표만 표기합니다">
								<Button
									type={undefined}
									style={{ width: 100 }}
									icon={
										star3 ? (
											<>
												<IconStarFilled
													size={20}
													style={{ color: '#ffcc00' }}
												/>
												<IconStarFilled
													size={20}
													style={{ color: '#ffcc00' }}
												/>
												<IconStarFilled
													size={20}
													style={{ color: '#ffcc00' }}
												/>
											</>
										) : (
											<>
												<IconStarFilled
													size={20}
													style={{ color: '#cdcdcd' }}
												/>
												<IconStarFilled
													size={20}
													style={{ color: '#cdcdcd' }}
												/>
												<IconStarFilled
													size={20}
													style={{ color: '#cdcdcd' }}
												/>
											</>
										)
									}
									onClick={() => setStar3(!star3)}
								/>
							</Tooltip>
						</View>
					</View>
				}
				open={turotial}
				footer={null}
				width={700}
				onCancel={() => {
					setTutorial(false)
				}}>
				<View
					dangerouslySetInnerHTML={{
						__html: `<iframe src="https://sslecal2.forexprostools.com?columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&importance=${(
							(star1 ? '1,' : '') +
							(star2 ? '2,' : '') +
							(star3 ? '3' : '')
						).replace(
							/,\s*$/,
							''
						)}&features=datepicker,timeselector&countries=110,43,17,42,5,178,32,12,26,36,4,72,10,14,48,35,37,6,122,41,22,11,25,39&calType=day&timeZone=88&lang=18" width="650" height="467" frameborder="0" allowtransparency="true" marginwidth="0" marginheight="0"></iframe>`,
					}}></View>
			</Modal>
		</View>
	)
}

export default AuthHeaderMenu
