import React from 'react'
import { View } from 'styles/reactnative'
import CModalHeader from '../Modal/CModalHeader'
import DepositLeftSection from './DepositLeftSection'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'

const DepositModal = ({ setShowModal }) => {
	const { data, mutate: mutateModalStore } = useTradeModalStore()

	return (
		<View
			style={{
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				width: '100%',
			}}>
			<CModalHeader
				title={'deposit'}
				closeModal={() =>
					mutateModalStore({ ...data, depositModal: false })
				}
			/>
			<DepositLeftSection />
		</View>
	)
}

export default React.memo(DepositModal)
