import useSWR from 'swr'
import { getPersistData } from '../persist'
import { PROFILESTORE } from '../../constants/LOCALKEYS'

const KEY = PROFILESTORE

let swrData: any = getPersistData(KEY) || []
let mutateArray: any = []

export function useProfile(userid: any) {
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			return swrData[userid]
		},
		{
			revalidateOnFocus: false,
			revalidateIfStale: false,
			revalidateOnMount: false,
		}
	)

	mutateArray[userid] = mutate

	return {
		data,
		mutate: (userid: any, value: any) => {
			swrData[userid] = value

			if (mutateArray[userid]) mutateArray[userid]()

			return mutate()
		},
	}
}
