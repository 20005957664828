import { message } from 'antd'
import useSWR from 'swr'
import API from '../../../utils/api'
import { USERINFO } from '../../constants/APIKEYS'
import { USERLEVEL } from '../../constants/LEVEL'
import { useToken } from '../auth/token'
import { getPersistData } from '../persist'

const KEY = USERINFO

let swrData: any = getPersistData(KEY)

export function useMyProfile() {
	const { data: token, mutate: mutateToken } = useToken()
	const { data, mutate } = useSWR<any>(
		token ? KEY : null,
		async () => {
			const response = await API.get(KEY, token + '', {})
			// console.log(response)

			swrData = response.result.result

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	if (
		[
			USERLEVEL.BLACK,
			USERLEVEL.SIM_BLACK,
			USERLEVEL.NEW_REQUEST,
			USERLEVEL.REJECT,
			USERLEVEL.WAIT,
		].includes(data?.level)
	) {
		message.error('접근 권한이 없습니다.')
		swrData = null
		mutate(null)
		mutateToken(null)
	}


	return {
		data,
		groups: data?.groups || [],
		mutate: (value?: any) => {
			swrData = value
			return mutate()
		},

		updateProfile: async ({ image }) => {
			// const resultImage = await awsUploadImage(
			// 	image,
			// 	1,
			// 	'profile',
			// 	'user'
			// )

			// try {
			// 	await API.put(KEY, token + '', {
			// 		profileimage: resultImage,
			// 	})
			// 	return mutate()
			// } catch (error) {
			// 	console.log(error, 'PROFILE IMAGE ERROR hooks/user/myprofile')
			// }
		},
	}
}
