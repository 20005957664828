import React, { useContext } from 'react'
import { View } from 'styles/reactnative'
import CModalHeader from '../Modal/CModalHeader'
import { useWSize } from 'utils/ExpensiveFunction'
import { ThemeContext } from 'theme/ThemeProvider'
import WithdrawLeftSection from './WithdrawLeftSection'
import WithdrawRightSection from './WithdrawRightSection'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'
import GDeposit from '../../../organisms/Header/GDeposit'
import GWithdraw from '../../../organisms/Header/GWithdraw'

const WithdrawModal = ({ setShowModal }) => {
	const { data, mutate: mutateModalStore } = useTradeModalStore()

	return (
		<View
			style={{
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				backgroundColor: '#fff',
			}}>
			<CModalHeader
				title={'입출금'}
				closeModal={() =>
					mutateModalStore({
						...data,
						gDeposit: false,
					})
				}
			/>
			{data?.gDepositType === 'W' ? (
				<GWithdraw mobile={true} />
			) : (
				<GDeposit mobile={true} />
			)}
		</View>
	)
}

export default React.memo(WithdrawModal)
