import { Button } from 'antd'
import { Button as ButtonMobile } from 'antd-mobile'
import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'

const CButton = ({ onClick, style, type = 'default', size = 'small', danger = false, children }: any) => {
    const { isMobile } = useContext(ThemeContext)

    let mobilecolor: any = 'default'
    let mobiletype: any = 'outline'

    if (type === 'default') {

    } else if (type === 'primary') {
        mobilecolor = 'primary'
        mobiletype = 'fill'

    } else if (type == {undefined}) {
        mobilecolor = 'default'
        mobiletype = 'none'
    }

    if (danger) {
        mobilecolor = 'danger'
        mobiletype = 'fill'
    }

    return (
        <>
            {isMobile ? (
                <ButtonMobile onClick={onClick} style={style} color={mobilecolor} fill={mobiletype} size={size}>{children}</ButtonMobile>
            ) : (
                <Button onClick={onClick} style={style} type={type} size={size} danger={danger}>{children}</Button>
            )}

        </>
    )
}

export default React.memo(CButton)
