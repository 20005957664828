import BigNumber from 'bignumber.js'
import React, { useContext } from 'react'
import {
	SpacebetweenRowView,
	Text,
	View,
} from '../../../../../../styles/reactnative'
import { ThemeContext } from '../../../../../../theme/ThemeProvider'
import { FONTS } from '../../../../../constants'
import { useExchangeStore } from '../../../../../hooks/trade/exchangeStore'
import { useTradeModalStore } from '../../../../../hooks/trade/modalStore'
import { useTradeStore } from '../../../../../hooks/trade/tradeStore'

const OrderBody = ({ item, current = false }) => {
	const { data: store, mutate } = useTradeStore()
	const { textw, textGray, textsilver } =
		useContext(ThemeContext).theme.COLORS
	BigNumber.clone() // new line

	const { EPs } = useExchangeStore()
	const { data: modalStore } = useTradeModalStore()

	//console.log('item', item)
	const orderprice = !item?.status
		? item?.price
		: item?.status >= 8
		? item?.price || 0
		: item?.order_price || 0
		? item?.order_price || 0
		: item?.open_price || 0

	const EP = EPs?.find(ep => ep.id == item?.exchange_pairs_id)

	return (
		<SpacebetweenRowView style={{ marginTop: 10, marginBottom: 10 }}>
			<SpacebetweenRowView>
				<View style={{ alignItems: 'flex-start' }}>
					<Text
						style={{
							...FONTS.h7,
							color: textsilver,
							paddingBottom: 3,
						}}>
						수량
					</Text>
					<Text style={{ ...FONTS.h7, color: textw }}>
						{BigNumber(item?.amount)?.toFormat(0)}
					</Text>
				</View>
				<View style={{ alignItems: 'center' }}>
					<Text
						style={{
							...FONTS.h7,
							color: textsilver,
							paddingBottom: 3,
						}}>
						주문가
					</Text>
					<Text style={{ ...FONTS.h7, color: textw }}>
						{+orderprice > 0
							? BigNumber(orderprice).toFormat(EP?.dp || 3)
							: '미체결'}
					</Text>
				</View>
				<View style={{ alignItems: 'center' }}>
					<Text
						style={{
							...FONTS.h7,
							color: textsilver,
							paddingBottom: 3,
						}}>
						진입가
					</Text>
					<Text
						style={{
							...FONTS.h7,
							color: item?.price ? textw : '#F05551',
						}}>
						{item?.status == 10
							? 'MIT주문'
							: item?.status >= 8
							? '미체결'
							: item?.price
							? BigNumber(item?.price).toFormat(EP?.dp || 3)
							: '미체결'}
					</Text>
				</View>
			</SpacebetweenRowView>
		</SpacebetweenRowView>
	)
}
export default React.memo(OrderBody)
