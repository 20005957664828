import React, { useContext } from 'react'
import { MView } from 'styles/mobilePageStyles'
import { Text } from 'styles/reactnative'
import {
	PrivacyPolicyListContent,
	PrivacyPolicyListSection,
	PrivacyPolicyListTitle,
} from './styles'
import { ThemeContext } from '../../../theme/ThemeProvider'

const PrivacyPolicyList = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<MView style={{ width: '100%' }}>
			<PrivacyPolicyListSection>
				<PrivacyPolicyListTitle>
					<Text size={20} style={{ fontWeight: '400', color:textw }}>
						EFFECTIVE DATE : 23 February 2023
					</Text>
				</PrivacyPolicyListTitle>
				<PrivacyPolicyListContent>
					<Text size={14} style={{ lineHeight: 1.5, color:textw }}>
						This Privacy Policy (this “Policy”) applies to the
						trading platform (including any applicable mobile
						applications and websites used to access the same)
						(collectively the “Platform”) provided by 
						Limited (the “Company,” “we,” “us” or “our”). It
						describes how the Company collects, uses, and discloses
						Personal Information that we obtain from Users of the
						Platform and any account services provided through the
						Platform, and how we use and disclose that information.
						For purposes of this Policy, “Personal Information”
						refers to information supplied by a User from which the
						identity of such User may be directly or indirectly
						determined.By registering for and using the Platform,
						you agree that your Personal Information will be handled
						as described in this Policy and the Terms and Conditions
						applicable to the Platform (the “Service Agreement”);
						capitalized terms used herein shall have the same
						meaning as set forth in the Service Agreement.
					</Text>
				</PrivacyPolicyListContent>
			</PrivacyPolicyListSection>

			{/* 2 */}
			<PrivacyPolicyListSection>
				<PrivacyPolicyListTitle>
					<Text size={20} style={{ fontWeight: '400', color:textw }}>
						The Information We Collect About You and How We Collect
						It
					</Text>
				</PrivacyPolicyListTitle>
				<PrivacyPolicyListContent>
					<Text size={14} style={{ lineHeight: 1.5, color:textw }}>
						We collect and process Personal Information about you
						directly from you when you register to use the Platform
						or submit such information as a part of the
						Know-Your-Client (“KYC”), as well as automatically
						through your use of the Platform.
					</Text>
				</PrivacyPolicyListContent>
			</PrivacyPolicyListSection>
			{/* 3 */}
			<PrivacyPolicyListSection>
				<PrivacyPolicyListTitle>
					<Text size={20} style={{ fontWeight: '400', color:textw }}>
						Information We Collect Directly From You.
					</Text>
				</PrivacyPolicyListTitle>
				<PrivacyPolicyListContent
					style={{
						flexDirection: 'column',
					}}>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						Personal Account and Profile Information: There are
						several types of accounts that can be established on the
						Platform. We collect the following information when you
						register an Account: name, mobile phone number and/or
						email address.
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						When your Account meets certain standard, we may collect
						additional information: name as in identity
						card/passport, identity card/passport number, date of
						birth, nationality, mailing address, residential
						address, mobile phone number and email addressFinancial
						Information: We may collect additional financial and
						investment information from you to confirm your status
						and eligibility to set up other types of accounts or
						conduct certain transcactions on the Platform, including
						your investment experience, finance-related
						qualifications and training (including any
						certifications received, issuing organization and date
						of issuance), your annual income level, liquid net
						worth, estimated net worth, source of funds, and source
						of wealth and whether you have had the opening of an
						account declined by another financial institution.
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						Other Required Information: We may need to collect
						certain additional information to comply with legal
						requirements, such as whether you are a Politically
						Exposed Individual or on any restricted persons list,
						and applicable tax reporting forms, such as a W8-BEN.
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						Communications with Us: We collect information you give
						us during any support and feedback communications via
						email or when you contact us through contact forms on
						the Platform. We use this information to respond to your
						inquiries, provide support, facilitate transactions, and
						improve our Platform.
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						Please note that if you are acting as an Authorized
						Individual on behalf of a User and are providing
						Personal Information for such User, you are responsible
						for ensuring that you have all required permissions and
						consents to provide such Personal Information to us for
						use in connection with the Platform and that our use of
						such Personal Information you provide to the Platform
						does not violate any applicable law, rule, regulation or
						order.
					</Text>
				</PrivacyPolicyListContent>
			</PrivacyPolicyListSection>

			{/* 4 */}
			<PrivacyPolicyListSection>
				<PrivacyPolicyListTitle>
					<Text size={20} style={{ fontWeight: '400', color:textw }}>
						Information We Collect Automatically.
					</Text>
				</PrivacyPolicyListTitle>
				<PrivacyPolicyListContent
					style={{
						flexDirection: 'column',
					}}>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						When you use the Platform, our servers automatically
						record information using cookies and other tracking
						technologies, including information that your browser
						sends whenever you visit the Platform or your mobile
						application sends when you’re using it. This log data
						may include your Internet Protocol address, the address
						of the web page you visited before coming to the
						Platform, your browser type and settings, the date and
						time of your request, information about your browser
						configuration and plug-ins, language preferences, and
						cookie data. Such log data is not routinely deleted.
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						In addition to log data, we may also collect information
						about the device you use for the Platform, including
						what type of device it is, what operating system you’re
						using, device settings, unique device identifiers, and
						crash data. Whether we collect some or all of this
						information may depend on what type of device you’re
						using and its settings.
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						We may combine this information with other information
						that we have collected about you, including, where
						applicable, your name, user name, email address, and
						other Personal Information.
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						Geolocation. If you have provided permission through
						your mobile device to allow us to collect location
						information through a mobile application, we may obtain
						your physical location information in terms of latitude
						and longitude from technologies like GPS, Wi-Fi, or cell
						tower proximity. You are able to withdraw your
						permission for us to acquire such physical location
						information from your mobile device through your mobile
						device settings, although we do not control this
						process. If you have questions about how to disable your
						mobile device’s location services, we recommend you
						contact your mobile device service provider or the
						mobile device manufacturer.
					</Text>
				</PrivacyPolicyListContent>
			</PrivacyPolicyListSection>

			{/* 4 */}
			<PrivacyPolicyListSection>
				<PrivacyPolicyListTitle>
					<Text size={20} style={{ fontWeight: '400' }}>
						How We Use Your Information
					</Text>
				</PrivacyPolicyListTitle>
				<PrivacyPolicyListContent
					style={{
						flexDirection: 'column',
					}}>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						We use your information, including your Personal
						Information, for the following purposes:
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						·To provide our Platform to you, to facilitate
						communications and transactions on the Platform, to
						communicate with you about your use of our Platform, to
						respond to your inquiries, to fulfill your orders, and
						for other customer service purposes;
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						·To tailor the content and information that we may send
						or display to you, to offer location customization, and
						personalized help and instructions, and to otherwise
						personalize your experiences while using our Platform;
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						·To better understand how users access and use the
						Platform, both on an aggregated and individualized
						basis, in order to improve our Platform and respond to
						user desires and preferences, and for other research and
						analytical purposes;
					</Text>
					<Text
						size={14}
						style={{ lineHeight: 1.5, marginBottom: 20, color:textw }}>
						·For marketing and promotional purposes. For example, we
						may use your information, such as your email address or
						contact number registered with us, to send you news and
						newsletters, special offers, and promotions, to conduct
						sweepstakes and contests, or to otherwise contact you
						about products or information we think may interest you.
						We also may use the information that we learn about you
						to assist us in advertising our Platform on third-party
						websites.
					</Text>
				</PrivacyPolicyListContent>
			</PrivacyPolicyListSection>
		</MView>
	)
}

export default PrivacyPolicyList
