import React, { useContext } from 'react'
import { IconX } from '@tabler/icons-react'
import { DrawerHeaderSection, DrawerCloseIcon } from './styles'
import { Text } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'

interface IProps {
	onClick?: () => void
	title?: string
}
const CDrawerHeader = ({ onClick, title }: IProps) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<DrawerHeaderSection>
			{title ? (
				<Text size={18} style={{ color: textw }}>
					{title}
				</Text>
			) : null}
			<DrawerCloseIcon onClick={onClick}>
				<IconX size={20} color={textw} />
			</DrawerCloseIcon>
		</DrawerHeaderSection>
	)
}

export default React.memo(CDrawerHeader)
