import React, { useState } from 'react'
import { View } from '../../../../../styles/reactnative'
import CalcList from '../../../../organisms/Header/CalcList'
import TradeList from '../../../../organisms/Header/TradeList'
import TradeTabPositions from './TradeTabPositions'
import TradeTabsHeader from './TradeTabsHeader'

const TradeTabFooter = () => {
	const [tab, setTab] = useState(0)
	const data = ['주문내역', '체결내역']

	return (
		<View style={{ width: '100%', padding: 10 }}>
			<TradeTabsHeader data={data} tab={tab} setTab={setTab} />
			{tab === 0 ? <TradeTabPositions /> : null}
			{tab === 1 ? <TradeList mobile={true} /> : null}
		</View>
	)
}
export default React.memo(TradeTabFooter)
