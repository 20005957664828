import React from 'react'
import { Button, Row, Space, Switch } from 'antd'
import { useWindowDimensions } from '../../hooks'
import { ThemeContext } from '../../../theme/ThemeProvider'
import AvBalance from '../../components/Table/AntCell2/AvBalance'
import TotalPnl from '../../components/Table/AntCell2/TotalPnl'
import CurrentPnl from '../../components/Table/AntCell2/CurrentPnl'
import RealtimePnl from '../../components/Table/AntCell2/RealtimePnl'
import RealizePnl from '../../components/Table/AntCell2/RealizePnl'
import HasStock from '../../components/Table/AntCell2/HasStock'
import LossCut from '../../components/Table/AntCell2/LossCut'
import AvgPrice from '../../components/Table/AntCell2/AvgPrice'
import Overmargin from '../../components/Table/AntCell2/Overmargin'
import Overnight from '../../components/Table/AntCell2/Overnight'
import { IconArrowsMove } from '@tabler/icons-react'
import { useTradeModalStore } from '../../hooks/trade/modalStore'

const InfoCurrent = () => {
	const { textw } = React.useContext(ThemeContext).theme.COLORS
	const { width } = useWindowDimensions()
	const { data: modalStore, change: mutateModalStore } = useTradeModalStore()

	return (
		<div style={{ padding: '5px 3px' }}>
			<Button
				className={'drag-handle'}
				style={{
					position: 'absolute',
					top: 0,
					right: 2,
					padding: 0,
					zIndex: 1000,
				}}
				size={'small'}
				icon={<IconArrowsMove size={13} color={'#888'} />}
			/>
			<Row>
				<AvBalance />
				<TotalPnl />
				<CurrentPnl />
			</Row>
			<Row>
				<RealtimePnl />
				<RealizePnl />
				<AvgPrice />
			</Row>
			<Row>
				<LossCut />
				<Overmargin />
				<Overnight />
			</Row>
			<Row>
				<HasStock />
			</Row>
			<Space
				style={{
					width: '100%',
					justifyContent: 'space-between',
					marginTop: 10,
				}}>
				<Space.Compact direction="vertical" style={{ width: '100%' }}>
					<label
						style={{
							color: textw,
							fontSize: 11,
							whiteSpace: 'nowrap',
						}}>
						{width < 1300 ? '확인생략' : '주문확인생략'}
					</label>
					<Switch
						size={width < 1300 ? 'small' : 'default'}
						checkedChildren="ON"
						unCheckedChildren="OFF"
						checked={modalStore?.skip ? true : false}
						onChange={checked => {
							setImmediate(() => {
								mutateModalStore({
									skip: checked,
								})
							})
						}}
					/>
				</Space.Compact>

				<Space.Compact direction="vertical" style={{ width: '100%' }}>
					<label
						style={{
							color: textw,
							fontSize: 11,
							whiteSpace: 'nowrap',
						}}>
						{width < 1300 ? '음성' : '음성'}
					</label>
					<Switch
						size={width < 1300 ? 'small' : 'default'}
						checkedChildren="ON"
						unCheckedChildren="OFF"
						checked={modalStore?.sound ? true : false}
						onChange={checked => {
							setImmediate(() => {
								mutateModalStore({
									sound: checked,
								})
							})
						}}
					/>
				</Space.Compact>
				<Space.Compact direction="vertical" style={{ width: '100%' }}>
					<label
						style={{
							color: textw,
							fontSize: 11,
							whiteSpace: 'nowrap',
						}}>
						{width < 1300 ? '원클릭' : '원클릭주문'}
					</label>
					<Switch
						size={width < 1300 ? 'small' : 'default'}
						checkedChildren="ON"
						unCheckedChildren="OFF"
						checked={modalStore?.oneClick ? true : false}
						onChange={checked => {
							setImmediate(() => {
								mutateModalStore({
									oneClick: checked,
								})
							})
						}}
					/>
				</Space.Compact>
				<Space.Compact direction="vertical" style={{ width: '100%' }}>
					<label
						style={{
							color: textw,
							fontSize: 11,
							whiteSpace: 'nowrap',
						}}>
						{width < 1300 ? '호가고정' : '호가창고정'}
					</label>
					<Switch
						size={width < 1300 ? 'small' : 'default'}
						checkedChildren="ON"
						unCheckedChildren="OFF"
						checked={modalStore?.fixed_orderbook ? true : false}
						onChange={checked => {
							setImmediate(() => {
								mutateModalStore({
									fixed_orderbook: checked,
								})
							})
						}}
					/>
				</Space.Compact>
			</Space>
		</div>
	)
}
export default React.memo(InfoCurrent)
