import styled from 'styled-components'

export const AnnouncementDetailSection = styled.div``
export const AnnouncementText = styled.div`
	font-size: 14px;
	color: ${p => p.theme.COLORS.textw};
	margin-top: 20px;
	width: 100%;

`
