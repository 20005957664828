import { STORE_HEADER } from 'app/constants/LOCALKEYS'
import useSWR from 'swr'

const KEY = STORE_HEADER

let swrData: any = {}
export function useHeader(initData?: any, reset?: boolean) {
	const { data, mutate } = useSWR<any>(KEY, async () => {
		if (reset === true) {
			swrData = null
			return null
		}
		if (initData !== undefined) {
			swrData = initData
		}
		return swrData
	})

	return {
		data,
		change: (value: any) => {
			return mutate((prev: any) => {
				swrData = { ...prev, ...value }
				return swrData
			})
		},
		mutate: (value: any) => {
			swrData = value
			return mutate()
		},
	}
}
