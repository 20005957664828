import React, { useContext, useState } from 'react'
import { CButton } from 'app/components/Common'
import {
	IconDeviceDesktop,
	IconDeviceMobile,
	IconMoon,
	IconSunHigh,
} from '@tabler/icons-react'
import { ThemeContext } from 'theme/ThemeProvider'
import styled from 'styled-components'

import { useThemeStore } from 'app/hooks'

const MobileToggle = ({ size = 20 }) => {
	const { isMobile, toggleMobile, theme } = useContext(ThemeContext)
	const [rotated, setRotated] = useState<boolean>(false)
	const { data: themeStore, mutate: mutateThemeStore } = useThemeStore()
	if (themeStore?.darkMode === undefined) {
		mutateThemeStore({
			darkMode: false,
		})
	}

	const icon = isMobile ? (
		<IconDeviceMobile
			color={theme.COLORS.textw}
			size={size}
			style={{ marginRight: 5 }}
		/>
	) : (
		<IconDeviceDesktop
			color={theme.COLORS.textw}
			size={size}
			style={{ marginRight: 5 }}
		/>
	)
	return (
		<Wrapper
			rotated={rotated}
			onClick={() => {
				toggleMobile()
				setRotated(true)
				mutateThemeStore({
					...themeStore,
					isMobile: isMobile ? false : true,
				})
			}}>
			<CButton icon={icon} />
		</Wrapper>
	)
}

const Wrapper = styled.div<{ rotated?: boolean }>`
	transition: transform 0.2s ease-in-out;
	display: flex;
	cursor: pointer;
	align-items: center;
	&:active {
		transform: scale(0.95);
	}
	&:focus {
		outline: none;
		transform: scale(1.05);
	}
`
export default MobileToggle
