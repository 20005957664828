import useSWR from 'swr'
import { THEME_STORE } from 'app/constants/LOCALKEYS'

const KEY = THEME_STORE

let swrData: string | null = JSON.parse(localStorage.getItem(KEY) || '{}')
export function useThemeStore(initData?: any) {
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			swrData = JSON.parse(localStorage.getItem(KEY) || '{}')
			return swrData
		},
		{ refreshInterval: 20 }
	)

	return {
		data,
		mutate: (value: any) => {
			if (value.isResize === true || value.isResize === false) {
				return mutate((prev: any) => {
					if (prev.isResize === value.isResize) {
						return prev
					} else {
						const newValue = { ...prev, ...value }
						localStorage.setItem(KEY, JSON.stringify(newValue))
						swrData = newValue
						return newValue
					}
				})
			} else {
				return mutate((prev: any) => {
					const newValue = { ...prev, ...value }
					localStorage.setItem(KEY, JSON.stringify(newValue))
					swrData = newValue
					return newValue
				})
			}
		},
	}
}
