import React, { useEffect, useState } from 'react'

import { Space, Switch } from 'antd'
import { Text } from '../../../styles/reactnative'
import KrwPrice from '../../components/KrwPrice'
import TableAPI from '../../components/TableAPI'
import UserTag from '../../components/UserTag'
import { useCoinGlobal } from '../../hooks/stateStore/coinGlobal'
import { useExchangeStore } from '../../hooks/trade/exchangeStore'
import Contracts2 from './Contracts2'
import MarkPrice from './MarkPrice'

const PartnerRealtimeWait = ({ modalshow = false, setModalshow = e => { } }) => {
	const [modal, setModal] = React.useState<any>(false)
	const [selectedData, setSelectedData] = React.useState<any>(undefined)

	const { data: coinglobal } = useCoinGlobal()

	const { EPs } = useExchangeStore()

	const [exceptType1, setExceptType1] = useState(true)
	const [exceptType2, setExceptType2] = useState(true)
	const [exceptType3, setExceptType3] = useState(true)
	const [exceptType4, setExceptType4] = useState(true)
	const [exceptType5, setExceptType5] = useState(true)

	const [exceptType, setExceptType] = useState('')


	useEffect(() => {
		let exceptType = ''
		if (exceptType1) exceptType += `${exceptType ? ',' : ''}-2`
		if (exceptType2) exceptType += `${exceptType ? ',' : ''}-1`
		if (exceptType3) exceptType += `${exceptType ? ',' : ''}-9`
		if (exceptType4) exceptType += `${exceptType ? ',' : ''}6`
		if (exceptType5) exceptType += `${exceptType ? ',' : ''}7`
		setExceptType(exceptType)
	}, [exceptType1, exceptType2, exceptType3, exceptType4, exceptType5])

	useEffect(() => {
		setModalshow(false)
		if (modalshow) {
			setTimeout(() => {
				setModal(true)
				setSelectedData(null)
			}, 100)
		} else {
		}
	}, [modalshow])

	return (
		<TableAPI
			title={'미체결주문'}
			coinglobal={coinglobal}
			className={'plist'}
			autorefresh={15000}
			loading={false}
			extra={
				<Space
					style={{
						whiteSpace: 'nowrap',
						alignContent: 'center',
						justifyItems: 'center',
						justifyContent: 'center',
					}}>
					제외
					<Space
						size={'small'}
						style={{
							border: '1px solid #ccc',
							padding: 5,
							borderRadius: 5,
						}}>
						<Space.Compact
							size="small"
							style={{
								alignItems: 'center',
								whiteSpace: 'nowrap',
								fontSize: 10,
							}}>
							거절
							<Switch
								onChange={e => setExceptType1(e)}
								defaultChecked={exceptType1}
							/>
						</Space.Compact>
						<Space.Compact
							size="small"
							style={{
								alignItems: 'center',
								whiteSpace: 'nowrap',
								fontSize: 10,
							}}>
							대기
							<Switch
								onChange={e => setExceptType2(e)}
								defaultChecked={exceptType2}
							/>
						</Space.Compact>
						<Space.Compact
							size="small"
							style={{
								alignItems: 'center',
								whiteSpace: 'nowrap',
								fontSize: 10,
							}}>
							블랙
							<Switch
								onChange={e => setExceptType3(e)}
								defaultChecked={exceptType3}
							/>
						</Space.Compact>
						<Space.Compact
							size="small"
							style={{
								alignItems: 'center',
								whiteSpace: 'nowrap',
								fontSize: 10,
							}}>
							모의
							<Switch
								onChange={e => setExceptType4(e)}
								defaultChecked={exceptType4}
							/>
						</Space.Compact>

						<Space.Compact
							size="small"
							style={{
								alignItems: 'center',
								whiteSpace: 'nowrap',
								fontSize: 10,
							}}>
							페이크
							<Switch
								onChange={e => setExceptType5(e)}
								defaultChecked={exceptType5}
							/>
						</Space.Compact>
					</Space>
				</Space>
			}
			columns={() => [
				{
					title: '번호',
					dataIndex: 'id',
					key: 'id',
					align: 'center',
					width: 80,
					render: (text, record) => <Text>{text}</Text>,
				},
				{
					title: '회원',
					dataIndex: 'user_id',
					key: 'user_id',
					align: 'center',
					sorter: (a: any, b: any) => b.user_id - b.user_id,
					render: (text, record) => (
						<UserTag
							userid={record?.user_id}
							nickname={record?.fullname || record?.nickname}
							setSelectedData={() => {
								setModal(true)
								setSelectedData(record?.user_id)
							}}
						/>
					),
				},
				{
					title: '종목',
					dataIndex: 'pairs',
					key: 'pairs',

					render: (text, record) => (
						<Contracts2
							data={record}
							created_at={record?.created_at}
						/>
					),
				},
				{
					title: '수량',
					dataIndex: 'amount',
					align: 'center',
					sorter: (a, b) => a.amount - b.amount,
					render: (text, data) => <Text>{text}</Text>,
				},
				{
					title: '주문가',
					align: 'center',
					dataIndex: 'order_price',
					key: 'order_price',
					sorter: (a, b) => a.order_price - b.order_price,
					render: (text, record) => {
						const EP = EPs.find(
							item => item.id === record?.exchange_pairs_id
						)

						return (
							<>
								{record?.limit_market == 'L' ? (
									<KrwPrice
										price={text}
										coinglobal={coinglobal}
										color={false}
										theme={false}
										ep_id={record?.exchange_pairs_id}
										dp={EP?.dp}
										krw={false}
									/>
								) : (
									<>-</>
								)}
							</>
						)
					},
				},
				{
					title: '현재가',
					align: 'center',
					dataIndex: 'mark_price',
					key: 'mark_price',
					render: (text, record) => <MarkPrice data={record} partner={true} />,
				},
				{
					title: '매매손익',
					align: 'center',
					dataIndex: 'pnl_price',
					key: 'pnl_price',
					sorter: (a, b) => a.pnl_price - b.pnl_price,
					render: (text, record) => (
						<>
							<KrwPrice
								price={text}
								ep_id={record?.exchange_pairs_id}
								coinglobal={coinglobal}
								dp={0}
								krw={true}
							/>
						</>
					),
				},
				{
					title: '수수료',
					align: 'center',
					dataIndex: 'fee_price',
					key: 'fee_price',
					sorter: (a, b) => a.fee_price - b.fee_price,
					render: (text, record) => (
						<KrwPrice
							price={-text}
							coinglobal={coinglobal}
							ep_id={record?.exchange_pairs_id}
							dp={0}
							krw={true}
						/>
					),
				},
			]}
			usermodal={true}
			height={'default'}
			apikey={`/group/history/orders?ep_id=*&status=1
				${(exceptType && `&excepttype=${exceptType}`) || ''}
				&=`}
		/>

	)
}

export default React.memo(PartnerRealtimeWait)