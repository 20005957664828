import React from 'react'
import { Text, View } from 'styles/reactnative'

const ErrorHandling = ({ success, error }) => {
	return (
		<View style={{ alignItems: 'flex-start', marginBottom: 5 }}>
			<Text size={14} style={{ color: success ? 'green' : '#F05551' }}>
				{success ? success : error}
			</Text>
		</View>
	)
}

export default ErrorHandling
