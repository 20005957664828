import React from 'react'
import { CFloatBtnsSection, FloatBtnSection2 } from './styles'
import { CImage } from '../Common'
import { IMAGES } from 'app/constants'
import { FloatBtnSection } from './styles'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Text } from '../../../styles/reactnative'
import Draggable, { DraggableCore } from 'react-draggable' // Both at the same time
import { IconHandMove } from '@tabler/icons-react'
const CFloatBtns = () => {
	const { t } = useTranslation()
	const [isShow, setIsShow] = React.useState(true)

	return (
		<Draggable handle=".handmove">
			<CFloatBtnsSection>
				<FloatBtnSection2
					className="handmove"
					style={{ marginRight: 15 }}>
					<IconHandMove size={18}></IconHandMove>
				</FloatBtnSection2>

				<FloatBtnSection
					style={{ marginRight: 14 }}
					onClick={() => {
						toast(`${t('notWorkingNow')}`)
					}}>
					<CImage
						src={IMAGES.callCenter}
						alt="logo"
						width={50}
						height={50}
						resizeMode={'cover'}
						style={{}}
					/>
				</FloatBtnSection>
				<FloatBtnSection
					style={{}}
					onClick={() => {
						toast(`${t('notWorkingNow')}`)
					}}>
					<CImage
						src={IMAGES.helpCenter}
						alt="logo"
						width={'auto'}
						height={'auto'}
						resizeMode={'cover'}
						style={{}}
					/>
				</FloatBtnSection>
			</CFloatBtnsSection>
		</Draggable>
	)
}

export default CFloatBtns
