import { IconStar, IconStarFilled } from '@tabler/icons-react'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { useFavourite } from 'app/hooks'
import { goTo } from 'app/hooks/navigation'
import { useLogQueue } from 'app/hooks/trade/logQueue'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { MText, MView } from 'styles/mobilePageStyles'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useTradeStore } from '../../../../../hooks/trade/tradeStore'
const ListCoin = ({ item, volume, setDisplayDrawer, ep_id }) => {
	const navigation = useHistory()
	const { textw } = useContext(ThemeContext).theme.COLORS

	const { mutate: mutateLog } = useLogQueue()

	const { data: favourites, mutate: mutateFavourite } = useFavourite()
	const favourite = favourites?.find?.((f: any) => f?.id === item?.id)
	const removeFavourite = favourites?.filter?.((f: any) => f?.id !== item?.id)
	const icon = favourite ? (
		<IconStarFilled size={20} style={{ color: COLORS.primary }} />
	) : (
		<IconStar color={textw} size={20} />
	)

	const { data: tradeStore, mutate: mutateTradeStore } = useTradeStore()

	return (
		<View
			style={{
				width: '160px',
				alignItems: 'center',
				justifyContent: 'flex-start',
				flexDirection: 'row',
			}}>
			<CButton
				icon={icon}
				style={{ marginRight: 5 }}
				onClick={() => {
					if (!favourite) {
						mutateFavourite([
							...(favourites ? favourites : []),
							item,
						])
					} else {
						mutateFavourite(removeFavourite)
					}
				}}
			/>

			<View
				style={{ flexDirection: 'row' }}
				onClick={() => {
					if (tradeStore?.ep_id !== item?.id) {
						mutateTradeStore({
							chart_id: tradeStore?.ep_id,
							ep_id: item?.id,
							loading: true,
						})

						mutateLog({
							log: 'FR006',
							data: {
								ep_id: item?.id,
							},
						})

						goTo(navigation, `/trade/${item?.id}`)
					}
					setDisplayDrawer(false)
				}}>
				<MView style={{ marginLeft: 10 }}>
					<MText
						style={{
							fontWeight: item?.id == ep_id ? 500 : 300,
							color: item?.id == ep_id ? COLORS.primary : textw,
							fontSize: 12,
						}}>
						{item?.pairs}
					</MText>
					<MText
						style={{
							paddingTop: 5,
							fontWeight: '300',
							fontSize: 11,

							color: item?.id == ep_id ? COLORS.primary : textw,
						}}>
						{item?.groupname}
					</MText>
				</MView>
			</View>
		</View>
	)
}

export default React.memo(ListCoin)
