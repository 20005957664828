import { Button, message } from 'antd'
import React from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
import API from '../../../../utils/api'
import { CANCELORDER } from '../../../constants/APIKEYS'
import { useToken } from '../../../hooks'
import { useLogQueue } from '../../../hooks/trade/logQueue'
import { useMitOrder } from '../../../hooks/trade/mitorder'
import { useTPLStore } from '../../../hooks/trade/tplStore'
import { useTradePosition } from '../../../hooks/trade/tradePosition'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import { useUserSocket } from '../../../hooks/trade/userSocket'

interface IProps {
	item: any
	setDisplay: any
}
const TradeOrder = ({ item, setDisplay }: IProps) => {
	const { data: token } = useToken()
	const { mutate: mutatePosition } = useTradePosition()
	const { sendOrder } = useUserSocket()

	const { changeTPL } = useTPLStore()
	const { ep_id } = useTradeStore()

	const { cancel } = useMitOrder()
	const [liquid, setLiquid] = React.useState(false)
	const { theme, isMobile } = React.useContext(ThemeContext)
	const { mutate: mutateLog } = useLogQueue()
	const { COLORS } = theme
	return (
		<Button
			type="primary"
			size="small"
			style={{ background: COLORS.raise }}
			disabled={liquid || item.liquid}
			onClick={() => {
				if (item?.status == 1) {
					if (liquid) {
						message.error('취소중입니다.')
						return
					} else {
						setLiquid(true)
						setDisplay('none')
					}

					mutateLog({
						log: 'ST009',
						data: {
							ep_id: item?.exchange_pairs_id,
							current_ep_id: ep_id,
							type: 'ST009',
							device: isMobile ? 'MTS' : 'WTS',
							price: item?.price,
							amount: item?.amount,
							long_short: item?.long_short == 'L' ? 'S' : 'L',
							id: item?.id,
						},
					})

					API.post(CANCELORDER, token + '', {
						orderid: item?.id,
					}).then(res => {
						mutatePosition()
						setLiquid(false)
						if (res?.message == 'success') {
							message.success('주문 취소 완료되었습니다.')
						}
					})
				} else if (item?.status == 10) {
					cancel(
						item?.exchange_pairs_id,
						item?.price,
						item?.long_short
					)
					setTimeout(() => {
						mutatePosition()
					}, 100)

					mutateLog({
						log: 'ST010',
						data: {
							ep_id: item?.exchange_pairs_id,
							current_ep_id: ep_id,
							price: item?.price,
							type: 'ST010',
							device: isMobile ? 'MTS' : 'WTS',
							amount: item?.amount,
							long_short: item?.long_short == 'L' ? 'S' : 'L',
							id: item?.id,
						},
					})

					message.success('MIT 취소 완료되었습니다.')
				} else if (item?.status == 8 || item?.status == 9) {
					const temp =
						item?.status == 9
							? changeTPL(
									{
										loss: false,
									},
									item?.exchange_pairs_id
							  )
							: changeTPL(
									{
										profit: false,
									},
									item?.exchange_pairs_id
							  )
				} else {
					if (liquid || item.liquid) {
						message.error('청산중입니다.')
						return
					} else {
						item.liquid = true
						setLiquid(true)
						setTimeout(() => {
							setLiquid(false)
						}, 200)
						//setDisplay('none')
					}

					mutateLog({
						log: 'ST008',
						data: {
							ep_id: item?.exchange_pairs_id,
							current_ep_id: ep_id,
							type: 'ST008',
							device: isMobile ? 'MTS' : 'WTS',
							amount: item?.amount,
							long_short: item?.long_short == 'L' ? 'S' : 'L',
							id: item?.id,
						},
					})

					sendOrder(
						isMobile ? 1 : 0,
						item?.exchange_pairs_id,
						item?.long_short == 'L' ? 'S' : 'L',
						'M',
						item?.long_short == 'L' ? 0.000001 : 999999999,
						item?.amount,
						'',
						token,
						result => {
							setTimeout(() => {
								if (!result?.result?.success) {
									//setDisplay('flex')
									setLiquid(true)
									item.liquid = true
									setTimeout(() => {
										setLiquid(false)
										item.liquid = false
									}, 100)
								} else {
									item.liquid = false
									setLiquid(false)
									mutatePosition()
								}
							}, 100)
						},
						() => {},
						1
					)
				}
			}}>
			{!item?.status ? '청산' : '취소'}
		</Button>
	)
}

export default TradeOrder
