import { STORE_TPL } from 'app/constants/LOCALKEYS'
import useSWR from 'swr'
import API from '../../../utils/api'
import { useToken } from '../auth/token'

const KEY = STORE_TPL

let swrData: any = []

export function useTPLStore() {
	const { data: token, mutate: mutateToken } = useToken()
	const { data, mutate } = useSWR<any>(
		token ? KEY : null,
		async () => {
			const res = await API.get('/trade/tpl', token + '', {}, true)
			if (res?.result?.success) {
				let result = res?.result?.result
				swrData = result

				localStorage.setItem(KEY, JSON.stringify(swrData))
				return swrData
			} else {
				mutateToken(null)
			}

			return swrData
		},
		{
			dedupingInterval: 1000,
		}
	)

	return {
		data: data,
		mutate: (value: any) => {
			//console.log('@FUNC MUTATE')
			return mutate(data => {
				swrData = { ...data, ...value }
				localStorage.setItem(KEY, JSON.stringify(swrData))
				return { ...data, ...value }
			})
		},
		updateTPL: () => {
			//console.log('@FUNC MUTATE')
			return mutate()
		},
		reset: () => {
			//console.log('@FUNC RESET')
			swrData = null
			localStorage.removeItem(KEY)
			return mutate(null)
		},
		change: (value: any) => {
			//console.log('@FUNC CHANGE', value)
			return mutate((data: any) => {
				const temp = [...data, ...value]
				swrData = temp
				localStorage.setItem(KEY, JSON.stringify(swrData))
				return temp
			})
		},
		changeTPL: (value: any, ep_id: any) => {
			//console.log('@FUNC CHANGETPL')
			API.post('/trade/tpl', token + '', {
				...value,
				ep_id: ep_id,
			}).then(res => {
				if (res?.result?.success) {
					if (res?.result?.result)
						mutate((data: any) => res?.result?.result)
				}
			})
		},
	}
}
