import React, { useState } from 'react'
import { View } from 'styles/reactnative'
import AnnouncementDetail from './AnnouncementDetail'
import AnnouncementList from './AnnouncementList'
import { AnnouncementContainer, AnnouncementSection } from './styles'
import { useFetcherPublic } from 'app/hooks/fetcher'

const Announcement = ({ title, searchshow = true }) => {
	const [show, setShow] = useState({
		show: false,
	})
	const [search, setSearch] = useState('')
	const [page, setPage] = useState(1)
	const { data } = useFetcherPublic('/notice/list?page=' + page)
	const count = data?.result?.count || 0
	return (
		<AnnouncementSection style={{}}>
			<AnnouncementContainer style={{ height: show ? 'auto' : 1200 }}>
				{show?.show ? (
					<AnnouncementDetail
						show={show}
						setShow={setShow}
						searchshow={title ? true : false}
					/>
				) : (
					<View
						style={{
							width: '100%',
							height: '100%',
							alignItems: 'flex-start',
							justifyContent: 'flex-start',
						}}>
						<AnnouncementList
							data={data?.result}
							page={page}
							setPage={setPage}
							setShow={setShow}
							show={show}
							searchshow={title ? true : false}
							search={search}
							count={count}
						/>
					</View>
				)}
			</AnnouncementContainer>
		</AnnouncementSection>
	)
}

export default Announcement
