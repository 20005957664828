import React from 'react'
import { useTranslation } from 'react-i18next'
import { QrContainer } from '../AuthContainers'
import { Text } from 'styles/reactnative'
import { SectionContainer, SectionWrap } from './styles'
import { COLORS } from 'app/constants/STYLES'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { AppLogo } from '../../../components'
import { CImage } from '../../../components/Common'

import qrcodetext from '../../../../assets/images/qrcode.png'
import { useWindowDimensions } from 'app/hooks'

const QrSection = () => {
	const { width } = useWindowDimensions()
	const { t } = useTranslation()
	// const navigate = useHistory()
	// const nav = (url: string) => {
	// 	navigate.push(url)
	// }

	const { isDarkMode, theme } = React.useContext(ThemeContext)
	const bgColor = isDarkMode ? COLORS.white : 'rgba(32, 25, 56, 0.8)'
	return (
		<SectionContainer>
			<SectionWrap
				style={{
					width: width > 970 ? 300 : '90%',
					background: bgColor,
				}}>
				<div
					style={{
						display: 'flex',
						width: '100%',
						justifyContent: 'center',
						textAlign: 'center',
						alignContent: 'center',
						alignItems: 'center',
						marginBottom: 30,
						marginLeft: 22,
					}}>
					<AppLogo />
				</div>
				<QrContainer
					width={120}
					height={120}
					// onPress={() => nav('/')}
				/>

				<CImage
					src={qrcodetext}
					alt={''}
					style={{ marginTop: 20, marginBottom: 20 }}
				/>

				<Text style={{ color: theme.COLORS.textsilver, width: 200 }}>
					{t('requestAppVersion')}{' '}
				</Text>
			</SectionWrap>
			{/* <TelegramContainer /> */}
		</SectionContainer>
	)
}

export default React.memo(QrSection)
