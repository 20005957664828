import React, { useState, useContext } from 'react'
import { IconChevronDown, IconChevronUp, IconEdit } from '@tabler/icons-react'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { RiskLimitListRight, RiskLimitLists } from '../styles'
import { ThemeContext } from 'theme/ThemeProvider'
import { useTradeStore } from '../../../../../../hooks/trade/tradeStore'
import { useExchangeSetting } from '../../../../../../hooks/trade/exchangeSetting'
import { useExchangeStore } from '../../../../../../hooks/trade/exchangeStore'

const PreferenceRiskLimits = () => {
	const { textw, gray } = useContext(ThemeContext).theme.COLORS
	const [display, setDisplay] = useState(false)
	const { t } = useTranslation()

	const { data } = useTradeStore()

	const icons = display ? (
		<IconChevronUp color={COLORS.lightGray5} size={16} />
	) : (
		<IconChevronDown color={COLORS.lightGray5} size={16} />
	)
	return (
		<View
			style={{
				width: '100%',
				borderBottom: `1px solid ${gray}`,
				paddingBottom: 15,
			}}>
			<CButton
				title={t('riskLimits')}
				onClick={() => {
					setDisplay(!display)
				}}
				style={{
					color: textw,
					justifyContent: 'space-between',
					width: '100%',
					marginTop: 20,
				}}
				icon={icons}
			/>
			{display ? (
				<RiskLimitLists>
					<Text size={13}>{data?.pairs}</Text>
					<RiskLimitListRight>
						<Text size={13}>{data?.maximun_price || 999999999} USDT</Text>
						<CButton
							onClick={() => {}}
							icon={
								<IconEdit color={COLORS.lightGray} size={16} />
							}
						/>
					</RiskLimitListRight>
				</RiskLimitLists>
			) : null}
		</View>
	)
}

export default PreferenceRiskLimits
