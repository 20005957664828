import { Button, Radio, Select, Space, Tag } from 'antd'
import { useToken } from 'app/hooks'
import React from 'react'
import BankInfo from '../../components/BankInfo'
import EntryTime from '../../components/EntryTime'
import KrwPrice from '../../components/KrwPrice'
import TableAPI from '../../components/TableAPI'
import { COLORS } from '../../constants'
import { useCoinGlobal } from '../../hooks/stateStore/coinGlobal'
import { useTradeModalStore } from '../../hooks/trade/modalStore'

const DWList = ({ user_id = null }) => {
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()
	const { level } = useToken()
	const [dtype, setDType] = React.useState<any>(null)
	const { data: coinglobal } = useCoinGlobal()

	return (
		<TableAPI
			coinglobal={coinglobal}
			title={
				<Space.Compact>
					입출금내역
					{level == 5 && (
						<Radio.Group
							value={modalStore?.depositType || '*'}
							buttonStyle="solid"
							onChange={e => {
								mutateModalStore({
									...modalStore,
									depositType: e.target.value,
								})
							}}>
							<Radio.Button value="*">전체</Radio.Button>
							<Radio.Button value="D">입금</Radio.Button>
							<Radio.Button value="W">출금</Radio.Button>
						</Radio.Group>
					)}

				</Space.Compact>
			}
			extra={
				<Space.Compact size="small">
					{level == 5 && (
						<Button
							danger
							onClick={() => {
								window.open('/partner/cash', "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=1450,height=800");
							}}
							type="primary">
							전체회원
						</Button>
					)}
					<Button
						onClick={() => {
							setDType(null)
						}}
						type={dtype == null ? 'primary' : 'default'}
						style={{
							backgroundColor: dtype == null ? '#000' : '#fff',
						}}>
						전체
					</Button>
					<Button
						onClick={() => {
							setDType(0)
						}}
						type={dtype == 0 ? 'primary' : 'default'}
						>
						일반입출
					</Button>
					<Button
						onClick={() => {
							setDType(2)
						}}
						type={dtype == 2 ? 'primary' : 'default'}
						danger>
						별도입출
					</Button>
					{level == 5 && (
					<Select
						value={dtype}
						onChange={e => {
							setDType(e)
						}}
						style={{ minWidth: 120 }}
						placeholder={'타입'}>
						<Select.Option value={null}>전체</Select.Option>
						<Select.Option value={0}>일반</Select.Option>
						<Select.Option value={2}>별도</Select.Option>
						<Select.Option value={3}>{'코인<>해선'}</Select.Option>
						<Select.Option value={9}>{'모의'}</Select.Option>
					</Select>

					)}

				</Space.Compact>
			}
			defaultMaxData={50}
			pagenation={true}
			stylewidth={'100%'}
			height={level == 5 ? 'default': 650 }
			width={500}
			columns={() => [
				{
					title: '일자',
					dataIndex: 'created_at',
					align: 'center',
					render: (text, record) => {
						return (
							<EntryTime
								time={record?.created_at}
								style={{ color: 'black' }}
								offset={9}
							/>
						)
					},
				},
				{
					title: '신청금액',
					dataIndex: 'amount',
					align: 'center',
					render: (text, record) => {
						return (
							<KrwPrice
								coinglobal={coinglobal}
								price={
									record?.type == 'W'
										? -Math.abs(text)
										: Math.abs(text)
								}
							/>
						)
					},
				},
				{
					title: '거래번호',
					dataIndex: 'id',
					align: 'center',
					render: (text, record) => {
						return <div>{text}</div>
					},
				},
				{
					title: '상태',
					dataIndex: 'process',
					align: 'center',
					render: (text, data) => (
						<div>
							{text == 1 ? (
								<Tag
									style={{
										backgroundColor: '#f79336',
										color: 'white',
									}}>
									{data?.type == 'W' ? '출금' : '입금'}
									신청
								</Tag>
							) : text == 2 ? (
								<Tag
									style={{
										backgroundColor:
											data?.type == 'W'
												? COLORS.darkRed
												: COLORS.darkGreen,
										color: 'white',
									}}>
									{data?.confirm_amount
										? `${data?.type == 'W'
											? '인출'
											: '지급'
										}완료`
										: `${data?.type == 'W'
											? '출금'
											: '입금'
										}승인`}
								</Tag>
							) : text == 3 ? (
								<Tag
									style={{
										backgroundColor: '#444',
										color: 'white',
									}}>
									{data?.type == 'W'
										? '출금검토'
										: '입금검토'}
								</Tag>
							) : text == 4 ? (
								<Tag style={{ color: '#F05551' }}>
									{data?.type == 'W'
										? '출금거절'
										: '입금거절'}
								</Tag>
							) : (
								<Tag>
									{data?.type == 'W' ? '출금' : '입금'}
									취소
								</Tag>
							)}
						</div>
					),
				},
				{
					title: '지급일자',
					dataIndex: 'confirm_at',
					align: 'center',
					render: (text, record) => {
						return (
							<EntryTime
								time={record?.confirm_at}
								style={{ color: 'black' }}
								offset={9}
							/>
						)
					},
				},
				{
					title: '지급금액',
					dataIndex: 'confirm_amount',
					align: 'center',
					render: (text, record) => {
						return (
							<KrwPrice
								coinglobal={coinglobal}
								theme={false}
								price={
									record?.type == 'W'
										? -Math.abs(text)
										: Math.abs(text)
								}
							/>
						)
					},
				},
				{
					title: '은행',
					dataIndex: 'bankaccount',
					align: 'center',
					render: (text, record) => {
						return (
							<BankInfo
								text={
									text == 'undefined/undefined/undefined'
										? '-'
										: text
								}
								theme={false}
							/>
						)
					},
				},
			]}
			apikey={
				`/${user_id ? 'group' : 'cash'}/list?${dtype ? `dtype=${dtype}&` : ''
				}type=` +
				(modalStore?.depositType || '*') +
				(user_id ? '&user_id=' + user_id : '') +
				'&t='
			}
		/>
	)
}

export default DWList
