import React, { useState } from 'react'
import { View } from 'styles/reactnative'

import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, Select, Space } from 'antd'
import { AdminWithdrawalTableColumn } from './AdminWithdrawalTableColumn'

import dayjs from 'dayjs'
import TableAPI from '../../../../../components/TableAPI'
const AdminWithdrawalManagement = ({ title, type, group }: any) => {
	const params = new URLSearchParams(window.location.search)
	const idquery = params.get('userid')
	const process = params.get('process')

	const [user_id, setUser_id] = useState<any>(idquery || '')
	const [status, setStatus] = useState<any>(process || '')
	const [startDate, setStartDate] = useState<any>(null)
	const [endDate, setEndDate] = useState<any>(null)

	const [DTYPE, setDTYPE] = useState<any>('')

	const makeurl = (check, query) => {
		if (check) {
			return `&${query}=${check}`
		} else {
			return ''
		}
	}

	return (
		<View style={{ width: '100%' }}>
			<View
				style={{
					width: '100%',
					padding: 10,
					flexDirection: 'row',
					background: '#fff',
					marginTop: 5,
					marginBottom: 5,
					borderRadius: 10,
					flex: 1,
					justifyContent: 'space-between',
					alignItems: 'center',
				}}>
				<View
					style={{
						flexDirection: 'row',
					}}>
					<Button
						type="primary"
						style={{ marginRight: 10 }}
						onClick={() => {
							setStatus('')
							setUser_id('')
						}}>
						전체보기
					</Button>
					<Select
						value={status}
						onChange={e => {
							setStatus(e)
						}}
						filterOption={true}
						options={[
							{ value: '', label: '진행상태' },
							{ value: '1', label: '승인대기' },
							{ value: '3', label: '검토중' },
							{ value: '4', label: '거절' },
							{ value: '2', label: '출금완료' },
						]}
						style={{ marginRight: 10 }}
					/>

					<Select
						value={DTYPE}
						onChange={e => {
							setDTYPE(e)
						}}
						filterOption={true}
						options={[
							{ value: '', label: '전체보기' },
							{ value: '1', label: '현금입출금만' },
							{ value: '2', label: '별도입출금만' },
							{ value: '3', label: '해선<>코인' },
						]}
						style={{ marginRight: 10 }}
					/>
					<Space.Compact direction="horizontal">
						<Input
							placeholder="회원코드/회원명/아이디"
							value={user_id}
							onChange={e => {
								setUser_id(e.target.value)
							}}
						/>
						<Button
							onClick={() => {
								setUser_id('')
							}}
							style={{ paddingTop: 0 }}
							size="small">
							{user_id ? <CloseOutlined /> : <SearchOutlined />}
						</Button>
						<Button
							onClick={() => {
								setUser_id('')
							}}
							style={{ paddingTop: 0 }}
							size="small">
							전체
						</Button>
					</Space.Compact>
				</View>

				<Space>
					기간
					<Space.Compact>
						<DatePicker
							onChange={e =>
								setStartDate(
									dayjs(e, 'YYYY-MM-DD').format('YYYY-MM-DD')
								)
							}
							defaultValue={
								startDate
									? dayjs(startDate, 'YYYY-MM-DD')
									: undefined
							}
						/>
						<DatePicker
							onChange={e =>
								setEndDate(
									dayjs(e, 'YYYY-MM-DD').format('YYYY-MM-DD')
								)
							}
							defaultValue={
								endDate
									? dayjs(endDate, 'YYYY-MM-DD')
									: undefined
							}
						/>
						<Button
							onClick={() => {
								setStartDate(null)
								setEndDate(null)
							}}>
							전체
						</Button>
					</Space.Compact>
				</Space>
			</View>

			<TableAPI
				title={title}
				columns={AdminWithdrawalTableColumn}
				usermodal={true}
				height={'default'}
				width={1400}
				className={'plist'}
				footer={3}
				apikey={`/group/user/cash?group=${group}&dtype=${DTYPE}&type=W${makeurl(
					startDate,
					'startdate'
				)}${makeurl(endDate, 'enddate')}${makeurl(
					user_id,
					'userid'
				)}${makeurl(status, 'process')}`}
			/>
		</View>
	)
}

export default React.memo(AdminWithdrawalManagement)
