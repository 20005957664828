import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useSystemStore } from '../../../../hooks/stateStore/systemStore'

import { useHistory } from 'react-router-dom'

const AssetSpotHeaderTop = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const navigate = useHistory()
	const { data: systemStore, mutate: mutateSystemStore } = useSystemStore()
	return (
		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				height: '30%',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}>
			<View
				style={{
					alignItems: 'center',
					flexDirection: 'row',
					color: textw,
				}}>
				입출금
			</View>
		</View>
	)
}

export default React.memo(AssetSpotHeaderTop)
