/* eslint-disable prettier/prettier */
import { COLORS } from 'app/constants/STYLES'
import styled from 'styled-components'

export const TradeListItemSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	&:hover {
		color: ${COLORS.primary};
	}
	/* @media (max-width: 1200px) {
        font-size: 12px;
    } */
`
export const TradeTextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

export const TradeTextInnerTitle = styled.div<{
	titleColor?: string
	titleBold?: boolean
}>`
	margin-left: 1rem;
	padding-right: 0.5rem;
	color: ${props => props.theme.COLORS.textw};
	font-weight: 500;
	font-size: 20px;
	${({ titleBold }) => titleBold && ` font-weight: bold;`}
	${({ titleColor }) => titleColor && ` color:${titleColor}`}
`
export const TradeTextInnerContent = styled.div<{
	contentColor?: string
	contentBold?: boolean
}>`
	${({ contentBold }) => contentBold && ` font-weight: bold;`}
	margin-left: 1rem;
	padding-right: 2rem;
	color: ${props => props.theme.COLORS.textsilver};
	font-size: 10px;
	${({ contentColor }) => contentColor && ` color:${contentColor}`}
`
