import React, { Suspense } from 'react'
import OrderBookTable from './OrderBookTable'
import OrderBookTopWidget from './OrderBookTopWidget'
import OrderBookBottomWidget from './OrderBookBottomWidget'
import { View } from '../../../../../styles/reactnative'
import { Spin } from 'antd'

const OrderBook = ({ parentHeight, parentWidth, newwindow = false }: any) => {
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		setTimeout(() => {
			setLoading(false)
		}, 1500)
	}, [])

	const loadComponent = () => {
		return (
			<div
				style={{
					display: 'flex',
					flex: 1,
					flexGrow: 1,
					height: '100%',
					width: '100%',
					justifyContent: 'center',
					alignContent: 'center',
				}}>
				<Spin spinning={loading}></Spin>
			</div>
		)
	}

	if (loading) return loadComponent()

	const OrderBookLazy = React.lazy(() => import('./OrderBookTable'))

	return (
		<Suspense fallback={loadComponent()}>
			<View
				className={'ordersmall'}
				style={{
					height: parentHeight - (newwindow ? 0 : 90),
					display: 'flex',
					alignItems: 'flex-start',
					width: '100%',
					justifyContent: 'center',
					overflow: 'hidden',
					flexDirection: 'column',
				}}>
				{loading ? (
					loadComponent()
				) : (
					<>
						<OrderBookTopWidget
							parentWidth={parentWidth}
							newwindow={newwindow}
						/>
						<OrderBookLazy
							newwindow={newwindow}
							parentHeight={parentHeight}
							parentWidth={parentWidth}
						/>
					</>
				)}

				<OrderBookBottomWidget newwindow={newwindow} />
			</View>
		</Suspense>
	)
}
export default React.memo(OrderBook)
