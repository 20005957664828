import { Table } from 'antd'
import { useTradeStore } from 'app/hooks/trade/tradeStore'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Text, View } from 'styles/reactnative'
import { goTo } from '../../../../../hooks/navigation'
import { useExchangeStore } from '../../../../../hooks/trade/exchangeStore'
import OvernightButton from './OvernightButton'

const OvernightSetting = () => {
	const { data: tradeStore, mutate: mutateTradeStore } = useTradeStore()

	const { EPs } = useExchangeStore()
	const nav = useHistory()

	return (
		<Table
			size="small"
			dataSource={EPs}
			pagination={false}
			rowKey={record => record.id}
			scroll={{ y: 250 }}
			rootClassName={'ovlist '}
			onRow={(record, rowIndex) => {
				return {
					onClick: event => {
						mutateTradeStore({
							ep_id: record?.id,
							...record,
						})
						goTo(nav, '/trade/' + record?.id)
					},
				}
			}}
			columns={[
				{
					title: '종목',
					dataIndex: 'groupname',
					align: 'center',
					render: (text, record) => (
						<View
							style={{
								flexDirection: 'row',
								cursor: 'pointer',
								height: 20,
								fontWeight:
									tradeStore?.ep_id === record?.id
										? 'bold'
										: 'normal',
							}}>
							<Text>{text}</Text>
						</View>
					),
				},
				{
					title: '코드',
					dataIndex: 'symbol',
					align: 'center',
					render: (text, record) => (
						<View
							style={{
								flexDirection: 'row',
								cursor: 'pointer',
								height: 20,
								fontWeight:
									tradeStore?.ep_id === record?.id
										? 'bold'
										: 'normal',
							}}>
							{text}
						</View>
					),
				},
				{
					title: '오버설정',
					dataIndex: 'symbol',
					align: 'center',
					render: (text, record) => (
						<View
							style={{
								flexDirection: 'row',
								cursor: 'pointer',
								height: 20,
								fontWeight:
									tradeStore?.ep_id === record?.id
										? 'bold'
										: 'normal',
							}}>
							<OvernightButton ep_id={record?.id} />
						</View>
					),
				},
			]}
		/>
	)
}

export default OvernightSetting
