import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useTradeStore } from '../../../../hooks/trade/tradeStore'
import { Col, Row } from 'antd'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'
import BigNumber from 'bignumber.js'
import { throttle } from '@rowsncolumns/grid'
import { useMitOrder } from '../../../../hooks/trade/mitorder'

const OrderBookBottom = ({ height = 35, data, parentWidth }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { currentposition: current } = useTradePosition()
	const { data: mitdata } = useMitOrder()
	const { data: tradeStore } = useTradeStore()

	const mitsum = useMemo(() => {
		let longsum = 0
		let shortsum = 0
		if (mitdata && mitdata['MIT' + tradeStore?.ep_id] !== undefined) {
			mitdata['MIT' + tradeStore?.ep_id]?.map((item: any) => {
				if (item?.longshort == 'S') {
					shortsum = new BigNumber(shortsum || 0)
						.plus(item?.amount || 0)
						.toNumber()
				} else {
					longsum = new BigNumber(longsum || 0)
						.plus(item?.amount || 0)
						.toNumber()
				}
			})
		}
		return [shortsum, longsum]
	}, [mitdata, tradeStore?.ep_id])

	data.sum1 = mitsum[0]
	data.sum8 = mitsum[1]

	let currentAmount = 0
	let longshort = ''
	if (current && current?.length > 0) {
		current?.map((item: any) => {
			longshort = item?.long_short
			currentAmount = new BigNumber(currentAmount || 0)
				.plus(item?.amount || 0)
				.toNumber()
		})
	}

	const [data2, setData] = useState(data)

	// useCallback을 사용하여 함수 메모이제이션
	const throttledUpdatePrice = useCallback(
		throttle(newPrice => {
			// 여기서 가격 업데이트 로직을 실행합니다.
			setData(newPrice) // 쓰로틀된 가격을 상태로 업데이트
		}, 100), // 2초마다 최대 1번 실행
		[] // 의존성 배열 비움
	)

	useEffect(() => {
		// price 값이 변경될 때 쓰로틀링된 함수 실행
		throttledUpdatePrice(data)
	}, [data, throttledUpdatePrice]) // 함수와 price를 의존성 배열에 포함

	useEffect(() => {
		setTimeout(() => {
			setCOL_WIDTH(Math.floor((parentWidth + 20) / 10) + 2)
		}, 200)
	}, [parentWidth])

	const ROW_HEIGHT = 20
	const [COL_WIDTH, setCOL_WIDTH] = useState(0)

	const CENTERCELL = useMemo(
		() => COL_WIDTH * Math.max(Math.min(1.8, parentWidth / 400), 1.55),
		[COL_WIDTH, parentWidth]
	)

	const AMOUNTCELL = useMemo(
		() => COL_WIDTH * Math.max(Math.min(0.9, parentWidth / 850), 0.78),
		[COL_WIDTH, parentWidth]
	)

	return (
		<Row
			justify="space-between"
			align={'middle'}
			className={'orderbookrow drag-handle'}
			style={{
				width: '100%',
				height: height,
				borderTop: '1px solid #bbb',
				borderBottom: '1px solid #cdcdcd',
				flexWrap: 'nowrap',
				alignItems: 'center',
				justifyContent: 'flex-start',
			}}>
			<Col
				style={{
					height: '100%',
					width: COL_WIDTH,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '0 5px',
					color: '#007EDA',
					borderRight: '1px solid #cdcdcd',
				}}>
				{data2?.sum1}
			</Col>
			<Col
				style={{
					height: '100%',
					width: COL_WIDTH,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '0 5px',
					color: '#007EDA',
					borderRight: '1px solid #cdcdcd',
				}}>
				{data2?.sum2}
			</Col>
			<Col
				style={{
					height: '100%',
					width: COL_WIDTH,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '0 5px',
					color: textw,
					borderRight: '1px solid #cdcdcd',
				}}>
				{data2?.sum3}
			</Col>
			<Col
				style={{
					height: '100%',
					width: AMOUNTCELL,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: textw,
					borderRight: '1px solid #cdcdcd',
				}}>
				{data2?.sum4}
			</Col>
			<Col
				style={{
					height: '100%',
					width: CENTERCELL,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color:
						BigNumber(data2?.sum5 || 0)
							.minus(data2?.sum4 || 0)
							.toNumber() > 0
							? '#F05551'
							: BigNumber(data2?.sum5 || 0)
									.minus(data2?.sum4 || 0)
									.toNumber() < 0
							? '#007EDA'
							: textw,
					borderRight: '1px solid #cdcdcd',
				}}>
				{BigNumber(data2?.sum5 || 0)
					.minus(data2?.sum4 || 0)
					.toNumber()}
			</Col>
			<Col
				style={{
					height: '100%',
					display: 'flex',
					width: AMOUNTCELL,
					alignItems: 'center',
					justifyContent: 'center',
					color: textw,
					borderRight: '1px solid #cdcdcd',
				}}>
				{data2?.sum5}
			</Col>
			<Col
				style={{
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					width: COL_WIDTH,
					justifyContent: 'center',
					color: textw,
					padding: '0 5px',
					borderRight: '1px solid #cdcdcd',
				}}>
				{data2?.sum6}
			</Col>
			<Col
				style={{
					height: '100%',
					display: 'flex',
					width: COL_WIDTH,
					alignItems: 'center',
					justifyContent: 'center',
					padding: '0 5px',
					color: '#F05551',
					borderRight: '1px solid #cdcdcd',
				}}>
				{data2?.sum7}
			</Col>
			<Col
				style={{
					height: '100%',
					display: 'flex',
					width: COL_WIDTH,
					alignItems: 'center',
					justifyContent: 'center',
					padding: '0 5px',
					color: '#F05551',
					borderRight: '1px solid #cdcdcd',
				}}>
				{data2?.sum8}
			</Col>
		</Row>
	)
}

export default OrderBookBottom
