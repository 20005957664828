import useSWR from 'swr'
import { TRADE_SOCKET } from '../../constants/LOCALKEYS'

import throttle from 'lodash/throttle'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useFps } from 'react-fps'
import { useToken } from '../auth/token'
import { useChartDataFeed } from './chartDataFeed'
import { useExchangeStore } from './exchangeStore'
import { useIndexPrice } from './indexprice'
import { useOrderBook } from './orderBook'
import { useTradeList } from './tradeList'
import { useTradeStore } from './tradeStore'

import BigNumber from 'bignumber.js'
import { ThemeContext } from '../../../theme/ThemeProvider'
import { CHART_SOCKET_URL } from '../../constants/APIKEYS'
import { useThemeStore } from '../stateStore'
import { useInfo24H } from './info24H'
import { useTradeModalStore } from './modalStore'
import useWindowFocus from './windowFocus'

// WebSocket 인스턴스 생성
let tradeWS: WebSocket | null = null
let lastBar: any = null

let throttleOrderBook: any = []
const throttleOrderBookInterval = 50

let chartloaded = false

let bufferTradeList: any = []
let bufferTradeListTimer: any = null

let current: any = null
let symbol: any = null

let ispartner = 0

let positionsize: any = 0
let isResize = false

let lastprice = new Map()

let lastcall = new Map()
let lastcall2: any = null
let lastTrade: any = null

let amtbuffer = 0

let joinedgroup: any = new Set()

let restarttimeout: any = null

let EPSALL: any = []

let lastjoin: any = null

let speed = 0

// 종목별로 쓰로틀을 관리할 Map을 생성합니다.
const throttleMap = new Map()

const joinGroup = (sym?: string) => {
	let randompassword = 'P' + Math.random().toString(36).substr(2, 20)
	randompassword =
		randompassword.substr(
			0,
			Math.floor(Math.random() * randompassword.length)
		) +
		'401' +
		randompassword.substr(
			Math.floor(Math.random() * randompassword.length),
			randompassword.length
		)

	if (tradeWS != null) {
		if (tradeWS && tradeWS.readyState === WebSocket.OPEN) {
			const array = Array.from(joinedgroup)

			const json = {
				type: 'join2',
				symbol: ispartner ? null : symbol,
				symbols: array,
				speed: speed,
				password: randompassword,
			}

			//symbol, symbols, speed만 비교
			if (lastjoin) {
				console.log(
					'lastjoin',
					lastjoin.symbol == json.symbol,
					JSON.stringify(lastjoin.symbols) ==
						JSON.stringify(json.symbols),
					lastjoin.speed == json.speed
				)
				if (
					lastjoin.symbol == json.symbol &&
					JSON.stringify(lastjoin.symbols) ==
						JSON.stringify(json.symbols) &&
					lastjoin.speed == json.speed
				)
					return
			}
			lastjoin = json
			restarttimeout = null

			tradeWS.send(JSON.stringify(json))
		} else {
			if (restarttimeout == null) {
				restarttimeout = setTimeout(() => {
					joinGroup(sym)
				}, 5000)
			}
		}
	} else {
		if (restarttimeout == null) {
			restarttimeout = setTimeout(() => {
				joinGroup(sym)
				restarttimeout = null
			}, 5000)
		}
	}
}

export function useTradeSocket() {
	const { data: EPS } = useExchangeStore()

	if (EPS?.length > 0) {
		EPSALL = EPS
	}

	const { isMobile } = useContext(ThemeContext)

	const { data: token } = useToken()

	const { currentFps } = useFps(15)
	const [fps, setFps] = useState(0)

	const { data: themeStore } = useThemeStore()
	const { data: tradeStore, chart_id } = useTradeStore()

	const { data: modalStore, change } = useTradeModalStore()

	const [changedSymbol, setChangedSymbol] = useState<any>(null)

	current = tradeStore?.ep_id
	symbol = tradeStore?.pairs
	ispartner = modalStore?.partner
	chartloaded = modalStore?.chartloaded

	speed = modalStore?.speed || 0

	useEffect(() => {
		if (symbol == null) return
		console.log('speed', speed, symbol)
		restarttimeout = null
		joinGroup(symbol)
	}, [speed, symbol])

	useEffect(() => {
		if (tradeStore?.pairs == null) return
		if (tradeStore?.pairs != changedSymbol) {
			joinedgroup.delete(changedSymbol)
			joinedgroup.add(tradeStore?.pairs)
			if (lastcall.has(tradeStore?.pairs)) {
				lastcall.delete(tradeStore?.pairs)
			}
			if (lastcall.has(changedSymbol)) {
				lastcall.delete(changedSymbol)
			}
			joinGroup(tradeStore?.pairs)
			setChangedSymbol(tradeStore?.pairs)
		}
	}, [tradeStore?.pairs, changedSymbol])

	useEffect(() => {
		if (modalStore?.chartloaded) {
			if (lastjoin) {
				setTimeout(() => {
					if (tradeWS && tradeWS.readyState === WebSocket.OPEN) {
						tradeWS.send(JSON.stringify(lastjoin))
					}
				}, 1000)
			}
		}
	}, [modalStore?.chartloaded, tradeWS])

	const { updatehighlow } = useInfo24H(symbol)

	/** 화면으로 복귀했을때 **/
	const onWindowFocus = useCallback(() => {
		if (tradeWS) {
			if (tradeWS.readyState === WebSocket.CLOSED) {
				tradeWS = null
				mutate()
			}
		} else {
			mutate()
		}
	}, [])

	const onWindowBlur = useCallback(() => {
		if (!isMobile) return

		if (tradeWS) {
			if (tradeWS.readyState === WebSocket.OPEN) {
				tradeWS.close()
			}
		}
	}, [])

	useWindowFocus(onWindowFocus, onWindowBlur)

	isResize = themeStore?.isResize

	const { data: updatechart } = useChartDataFeed()

	const { mutate: mutateIndexPrice } = useIndexPrice(0)

	const { data: orderbook, mutate: mutateOrderBook } = useOrderBook(
		current || EPSALL?.[0]?.id
	)
	const { pushmutate: mutateTradeList, reset } = useTradeList(
		current || EPSALL?.[0]?.id
	)
	useEffect(() => {
		if (currentFps >= 50) {
			setFps(120)
		} else if (currentFps > 40) {
			setFps(60)
		} else if (currentFps > 20) {
			setFps(20)
		} else {
			setFps(0)
		}
	}, [currentFps])

	useEffect(() => {
		joinGroup()
	}, [])

	useEffect(() => {
		if (bufferTradeListTimer == null) {
			bufferTradeListTimer = setInterval(() => {
				for (let key in bufferTradeList) {
					if (current != key) continue
					if (bufferTradeList[key]?.length > 0) {
						const tempbuffer = bufferTradeList[key] || []
						bufferTradeList[key] = []
						try {
							mutateTradeList(tempbuffer?.reverse() || [], key)
						} catch (e) {
							console.error(e)
						}
					}
				}
			}, 200)
		}
	}, [])

	const handleOrderBook = useCallback(
		throttle(async getDATA => {
			//최적화 호가//////////////////////
			//Tep_id|숏|롱
			const tempsplit = getDATA?.substring(1)?.split('@')

			if (symbol != tempsplit[0]) return

			const ep_id = EPSALL?.find(
				(item: any) => item?.pairs == tempsplit[0]
			)?.id

			const trade = {
				ep_id: +ep_id,
				short: JSON.parse(tempsplit[1]),
				long: JSON.parse(tempsplit[2]),
			}
			if (throttleOrderBook[trade?.ep_id] === undefined) {
				throttleOrderBook[trade?.ep_id] = false
			}

			if (!throttleOrderBook[trade?.ep_id]) {
				throttleOrderBook[trade?.ep_id] = true
				setTimeout(() => {
					throttleOrderBook[trade?.ep_id] = false
				}, throttleOrderBookInterval)

				if (trade.ep_id == current) {
					if (mutateOrderBook) {
						mutateOrderBook({
							long: trade?.long,
							short: trade?.short,
						})
					}
				}
			}
		}, 250 - fps),
		[mutateOrderBook, current, fps]
	)

	const handleTradePrice = useCallback(
		async trade => {
			if (!trade?.ep_id) return

			let throttleFn
			// 해당 종목의 쓰로틀 함수가 이미 존재하는지 확인합니다.
			if (throttleMap.has(trade.ep_id)) {
				// 이미 쓰로틀 함수가 존재하면 그 함수를 가져옵니다.
				throttleFn = throttleMap.get(trade.ep_id)
			} else {
				// 쓰로틀 함수가 없으면 새로 생성합니다.
				throttleFn = throttle(async () => {
					mutateIndexPrice(
						[
							{ price: lastprice.get(trade.ep_id) || 0 },
							{ price: lastprice.get(trade.ep_id) || 0 },
						],
						trade.ep_id
					)
				}, 500 - fps)
				// 새로 생성한 쓰로틀 함수를 Map에 저장합니다.
				throttleMap.set(trade.ep_id, throttleFn)
			}

			// 쓰로틀 함수를 호출합니다.
			await throttleFn()
		},
		[lastprice, fps]
	)

	const handleCurrentTradePrice = useCallback(async trade => {
		if (!trade?.ep_id) return
		mutateIndexPrice(
			[
				{ price: lastprice.get(trade?.ep_id) || 0 },
				{ price: lastprice.get(trade?.ep_id) || 0 },
			],
			trade.ep_id
		)
	}, [])

	const handleCurrentTradePriceMobile = useCallback(
		throttle(async trade => {
			if (!trade?.ep_id) return
			mutateIndexPrice(
				[
					{ price: lastprice.get(trade?.ep_id) || 0 },
					{ price: lastprice.get(trade?.ep_id) || 0 },
				],
				trade.ep_id
			)
		}, 250 - fps + (isResize ? 150 : 0)),
		[lastprice, fps, isResize]
	)

	const handleTradeMessage = useCallback(
		async trade => {
			if (
				(chart_id || current) == trade.ep_id &&
				updatechart?.updatebars !== null &&
				typeof updatechart?.updatebars === 'function'
			) {
				let time: any = new BigNumber(trade.timestamp - 1000)
					.dividedBy(60000)
					.toFixed(0, BigNumber.ROUND_FLOOR)

				time = new BigNumber(time).multipliedBy(60000).toNumber()

				let bar = {
					ep_id: trade?.ep_id,
					time: +time,
					close: +lastprice.get(trade.ep_id) || trade.price,
					high: +lastprice.get(trade.ep_id) || trade.price,
					low: +lastprice.get(trade.ep_id) || trade.price,
					open: +lastprice.get(trade.ep_id) || trade.price,
					volume: amtbuffer,
				}

				amtbuffer = 0
				if (lastBar === null || bar.time > lastBar?.time) {
					//바가 없거나 새로운 바일때

					updatechart?.updatebars(bar)
					lastBar = bar
					//console.log('@new',new Date(trade.timestamp).getMinutes(), new Date(trade.timestamp).getSeconds(), trade.timestamp, time, bar)
				} else if (
					// 같은 바일때
					Math.floor(bar.time / 60000) ==
					Math.floor(lastBar?.time / 60000)
				) {
					bar = {
						...bar,
						time: +time,
						high: Math.max(lastBar.high, bar.high),
						low: Math.min(lastBar.low, bar.low),
						open: lastBar.open,
						volume: lastBar.volume + bar.volume,
					}
					//console.log('@same',new Date(trade.timestamp).getMinutes(), new Date(trade.timestamp).getSeconds(), trade.timestamp, time, bar)
					updatechart?.updatebars(bar)
					lastBar = bar
				} else {
					updatechart?.updatebars(bar)
					lastBar = bar
				}
			} else {
				if (window.location.href.indexOf('DEBUG') > -1) {
					console.log('NO CHART', trade.ep_id, chart_id, current)
				}
			}
		},
		[updatechart, chart_id]
	)

	// PROCESS TX COMMON
	const processTX = useCallback(
		(trade: any, throttle: any) => {
			if (trade.ep_id == current) {
				amtbuffer += trade.amount
			}

			lastprice.set(trade.ep_id, trade.price)
			if (trade?.ep_id == current) {
				handleCurrentTradePriceMobile(trade)
				handleTradeMessage(trade)

				if (
					isMobile ||
					throttle ||
					new Date().getHours() >= 22 ||
					positionsize > 3
				) {
					handleCurrentTradePriceMobile(trade)
				} else {
					handleCurrentTradePrice(trade)
				}
			} else {
				handleTradePrice(trade)
			}

			if (bufferTradeList[trade?.ep_id] == undefined)
				bufferTradeList[trade?.ep_id] = []

			bufferTradeList[trade?.ep_id].push(trade)
		},
		[updatechart]
	)

	// PROCESS T
	const processT = useCallback(
		(getDATA: any, throttle: any) => {
			const tempsplit = getDATA.substring(14).split('|')

			const ep_id = EPSALL?.find(item => item?.pairs == tempsplit[0])?.id

			const trade = {
				timestamp: +getDATA.substring(1, 14),
				ep_id: +ep_id,
				price: +tempsplit[1],
				amount: +tempsplit[2],
				cgubun: tempsplit[3] || '',
			}
			lastTrade = trade

			processTX(trade, throttle)
		},
		[processTX]
	)

	// PROCESS X
	const processX = useCallback(
		(getDATA: any, throttle: any) => {
			const tempsplit = getDATA.substring(1).split('!')

			const eptext = EPSALL?.find(
				(item: any) => item?.pairs == tempsplit[0]
			)?.id

			const temparray = tempsplit[1].split('@')
			let high: any = null
			let low: any = null

			temparray?.map((i: any) => {
				const temp2 = i.split('|')
				const trade = {
					timestamp: +temp2[0],
					ep_id: +eptext,
					price: +temp2[1],
					amount: +temp2[2],
					cgubun: temp2[3] || '',
				}
				if (high == null || high < trade.price) high = trade.price
				if (low == null || low > trade.price) low = trade.price

				lastTrade = trade
				processTX(trade, throttle)
				//console.log('X', trade)
				return trade
			})

			updatehighlow(high, low, eptext)
		},
		[processTX]
	)

	const { data, mutate } = useSWR<any>(
		TRADE_SOCKET,
		async () => {
			if (tradeWS && symbol && tradeWS?.readyState === WebSocket.OPEN) {
				if (symbol) {
					if (!joinedgroup.has(symbol)) {
						joinedgroup.add(symbol)
						joinGroup(symbol)
					}
				}
			}

			const dataProcess = (getDATA: any) => {
				if (getDATA == null) return
				//트레이드 리스트는 쓰로틀링 제외
				if (!modalStore?.isOnline) change({ isOnline: true })

				if (getDATA[0] == 'T') {
					processT(getDATA, isResize)
				} else if (getDATA[0] == 'X') {
					processX(getDATA, isResize)
				} else if (getDATA[0] == 'O') {
					//호가
					const tempsplit = getDATA?.substring(1)?.split('@')

					const ep_id = EPSALL?.find(
						item => item?.pairs == tempsplit[0]
					)?.id

					if (+ep_id == current) {
						handleOrderBook(getDATA)
					}
				} else if (getDATA[0] == 'S') {
				} else if (getDATA?.substring(0, 2) == 'CO') {
				} else {
				}
			}

			const onmessage = async (data: any) => {
				let getDATA: any = null
				try {
					getDATA = await data?.data.text()
				} catch (e) {
					try {
						//blob to string
						const reader = new FileReader()
						reader.onload = function () {
							getDATA = reader.result
							dataProcess(getDATA)
						}
						reader.readAsText(data?.data)

						return
					} catch (e) {
						console.log('onmessage error2', e, data?.data)
						return
					}
				}

				dataProcess(getDATA)
			}

			if (tradeWS == null || tradeWS?.readyState === WebSocket.CLOSED) {
				try {
					console.log('START SOCKET')
					tradeWS = new WebSocket(CHART_SOCKET_URL)
					tradeWS.binaryType = 'blob'

					tradeWS.onclose = () => {
						console.log('@@@@@@@@@@@tradeWS close@@@@@@@@@@@@@')
						tradeWS = null
						lastjoin = null

						setTimeout(() => {
							mutate()
							joinGroup(symbol)
						}, 1000)
					}

					tradeWS.onerror = () => {
						console.log('tradeWS error')
					}
					tradeWS.onopen = () => {
						console.log('tradeWS open')
						if (symbol) {
							if (!joinedgroup.has(symbol)) {
								joinedgroup.add(symbol)
							}
							joinGroup(symbol)

							setTimeout(() => {
								mutate()
								joinGroup(symbol)
							}, 1000)
						}
					}
					// console.log('tradeWS', tradeWS)
				} catch (e) {
					console.log('tradeWS error', e)
				}
			}

			if (tradeWS != null) {
				tradeWS.onmessage = onmessage
			}

			return tradeWS
		},
		{
			revalidateIfStale: true,
			revalidateOnFocus: true,
			revalidateOnReconnect: true,
			dedupingInterval: 0,
		}
	)

	const joincurrent = useCallback(() => {
		if (tradeWS != null) {
			if (tradeWS?.readyState === WebSocket.OPEN) {
				if (symbol) {
					if (!joinedgroup.has(symbol)) {
						joinedgroup.add(symbol)
						joinGroup(symbol)
					}
				}
			}
		} else {
			mutate()
		}
	}, [symbol, tradeWS, token])

	useEffect(() => {
		const setOnline = () => {
			change({ isOnline: true })

			if (isMobile) {
				window.location.reload()
				return
			}

			setTimeout(() => {
				mutate()
				joincurrent()
			}, 500)
		}

		const setOffline = () => {}

		window.addEventListener('online', setOnline)
		window.addEventListener('offline', setOffline)

		return () => {
			window.removeEventListener('online', setOnline)
			window.removeEventListener('offline', setOffline)
		}
	}, [isMobile])

	useEffect(() => {
		if (tradeWS?.readyState === WebSocket.OPEN) {
			joincurrent()
			change({ isOnline: true })
		} else {
		}
	}, [tradeWS, tradeWS?.readyState])

	return {
		data,
		updateLastTrade: () => {
			if (lastTrade) handleTradeMessage(lastTrade)
		},
		mutate: () => {
			return mutate()
		},
		saveLog: (data: any) => {},
		reset: () => {
			console.log('@RESET')
			current = null
			lastBar = null
			lastjoin = null

			joinedgroup = new Set()

			amtbuffer = 0
			bufferTradeList = []
			reset()

			if(tradeWS){
				tradeWS.close()
			}

			setTimeout(() => {
				amtbuffer = 0
				current = null
				lastBar = null
				bufferTradeList = []
				reset()
			}, 500)
		},
		joinGroup: (group: string, ep_id?: string) => {
			const symbol = EPSALL?.find(item => item?.id == ep_id)?.pairs
			if (symbol) {
				if (!joinedgroup.has(symbol)) {
					joinedgroup.add(symbol)
				}
				joinGroup(symbol)
			}
			return mutate()
		},
		leaveGroup: (group: string, ep_id?: string) => {
			try {
				const symbol = EPSALL?.find(item => item?.id == ep_id)?.pairs
				return mutate()
			} catch (e) {
				console.error(e)
			}
		},
	}
}
