import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import { IconSearch } from '@tabler/icons-react'
import { Button, Input, Modal, Space } from 'antd'
import KrwPrice from 'app/components/KrwPrice'
import { MView } from 'styles/mobilePageStyles'
import { Text } from '../../../../styles/reactnative'
import { typeFromLevelPartner } from '../../../../utils/ciutils'
import CRenderEmpty from '../../../components/CRenderEmpty'
import EntryTime from '../../../components/EntryTime'
import TableAPI from '../../../components/TableAPI'
import UserContent from '../../../components/UserContent'
import UserTag from '../../../components/UserTag'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import { useUserList } from '../../../hooks/trade/userList'
export function PartnerNewwindow() {
	const { data: tradeStore } = useTradeStore()

	const [modalshow, setModalshow] = React.useState<any>(false)

	//now 변경
	//다음날 06:59 까지는 전날로 계산
	const now = dayjs()
	if (now.hour() < 7) now.subtract(1, 'day')

	const [modal, setModal] = React.useState<any>(false)
	const [selectedData, setSelectedData] = React.useState<any>(undefined)
	const [level, setLevel] = React.useState<any>('1')

	const [search, setSearch] = React.useState(selectedData || '')
	const [search2, setSearch2] = React.useState(null)

	const [api, setApi] = React.useState<any>(``)

	const { userlist, sendwho } = useUserList()

	useEffect(() => {
		setApi(
			`/group/users?level=${level}&${
				search2 ? 'searchWord=' + search2 + '&' : ''
			}t=`
		)
		sendwho()
	}, [level, search2])

	useEffect(() => {
		sendwho()
	}, [])

	useEffect(() => {
		setModalshow(false)
		if (modalshow) {
			setTimeout(() => {
				setModal(true)
				setSelectedData(null)
			}, 20)
		} else {
		}
	}, [modalshow])

	return (
		<MView
			style={{
				width: '100%',
				alignContent: 'flex-start',
				justifyContent: 'flex-start',
				justifyItems: 'flex-start',
				alignItems: 'flex-start',
			}}>
			{!tradeStore?.loading && <CRenderEmpty />}
			<TableAPI
				title={'고객관리'}
				defaultMaxData={50}
				className="plist"
				autorefresh={false}
				stylewidth={'100%'}
				loading={true}
				actionrefresh={() => {
					sendwho()
				}}
				extra={
					<Space.Compact size="small">
						<Button
							type={level === '*' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('*')
							}}>
							전체회원
						</Button>
						<Button
							type={level === '-1, 0' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('-1, 0')
							}}>
							승인대기
						</Button>
						<Button
							type={level === '1' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('1')
							}}>
							활동회원
						</Button>
						<Button
							type={level === '-8' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('-8')
							}}>
							모의신청
						</Button>
						<Button
							type={level === '6' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('6')
							}}>
							모의회원
						</Button>
						<Button
							type={level === '7' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('7')
							}}>
							페이크
						</Button>
						<Button
							type={level === '-2' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('-2')
							}}>
							거절회원
						</Button>
						<Button
							type={level === '-9' ? 'primary' : 'default'}
							onClick={() => {
								setLevel('-9')
							}}>
							블랙회원
						</Button>
						<Input
							placeholder="회원검색"
							defaultValue={search}
							onChange={e => {
								setSearch(e.target.value)
							}}
						/>
						<Button
							icon={<IconSearch />}
							size="small"
							onClick={() => {
								setSearch2(search)
							}}
						/>
					</Space.Compact>
				}
				height={'default'}
				width={'100%'}
				columns={() => [
					{
						title: '접속중',
						dataIndex: 'is_online',
						key: 'is_online',
						align: 'center',
						width: 80,
						sorter: (a, b) => a.client - b.client,
						render: (text, data) => {
							//users  = []
							const user = userlist?.find(
								(user: any) => user == data?.id
							)
							data.client = user ? 1 : 0

							return (
								<Text
									style={{
										color: user ? 'green' : 'red',
									}}>
									{user ? '접속중' : '미접속'}
								</Text>
							)
						},
					},
					{
						title: '실명',
						dataIndex: 'id',
						key: 'id',
						align: 'center',
						width: 80,
						render: (text, data) => {
							return (
								<UserTag
									userid={data?.id}
									email={data?.email}
									phone={data?.phone}
									nickname={data?.fullname || data?.nickname}
									level={data?.level}
									setSelectedData={() => {
										setModal(true)
										setSelectedData(null)
										setTimeout(() => {
											setSelectedData(data?.id)
										}, 100)
									}}
								/>
							)
						},
					},
					{
						title: 'ID',
						dataIndex: 'loginid',
						key: 'loginid',
						align: 'center',
						width: 80,
						render: (text, data) => {
							return <Text>{text}</Text>
						},
					},
					{
						title: '상태',
						dataIndex: 'level',
						key: 'level',
						align: 'center',
						width: 80,
						sorter: (a, b) =>
							typeFromLevelPartner(a.level).localeCompare(
								typeFromLevelPartner(b.level)
							),
						render: (text, data) => {
							return (
								<Text
									style={{
										color:
											typeFromLevelPartner(text) ==
											'접속불가'
												? 'red'
												: typeFromLevelPartner(text) ==
												  '거래가능'
												? 'green'
												: typeFromLevelPartner(text) ==
												  '승인대기'
												? 'blue'
												: 'black',
									}}>
									{typeFromLevelPartner(text)}
								</Text>
							)
						},
					},
					{
						title: '보유금',
						dataIndex: 'balance',
						key: 'balance',
						align: 'center',
						sorter: (a, b) => a.balance - b.balance,
						render: (text, data) => {
							return <KrwPrice price={text} />
						},
					},
					{
						title: '가입일',
						dataIndex: 'created_at',
						key: 'created_at',
						align: 'center',
						sorter: (a, b) =>
							new Date(a.created_at).getTime() -
							new Date(b.created_at).getTime(),
						render: (text, data) => {
							return <EntryTime time={text} offset={9} />
						},
					},
					{
						title: '마지막로그인',
						dataIndex: 'lastlogin_at',
						key: 'lastlogin_at',
						align: 'center',
						sorter: (a, b) =>
							new Date(a.lastlogin_at).getTime() -
							new Date(b.lastlogin_at).getTime(),
						render: (text, data) => {
							return <EntryTime time={text} offset={9} />
						},
					},
				]}
				online={true}
				apikey={api}
			/>
			<Modal
				open={modal}
				onCancel={() => setModal(false)}
				width={'100%'}
				style={{ width: '90%' }}>
				<UserContent
					showOpen={modal}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					defaultfold={selectedData ? false : true}
				/>
			</Modal>
		</MView>
	)
}
