import React, { useContext } from 'react'
import { IconChevronDown } from '@tabler/icons-react'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'

const CustomCoinComp = ({ coinType, setDisplay, setAlert }) => {
	const { t } = useTranslation()
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<View
			onClick={() => {
				setDisplay(false)
				setAlert(false)
			}}
			style={{
				position: 'absolute',
				left: 30,
				flexDirection: 'row',
				justifyContent: 'space-between',
				width: '85%',
			}}>
			<View style={{ flexDirection: 'row' }}>
				<Text
					size={14}
					style={{
						paddingInline: 10,
					}}>
					{coinType}
				</Text>
				<Text size={12} style={{ color: COLORS.lightGray }}>
					{t(coinType)}
				</Text>
			</View>
			<IconChevronDown size={14} color={textw} />
		</View>
	)
}

export default CustomCoinComp
