import { AuthCheckText } from 'app/components'
import { useToken } from 'app/hooks'
import React, { useEffect, useState } from 'react'

import BigNumber from 'bignumber.js'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'
import { useMitOrder } from '../../../../hooks/trade/mitorder'
import { useTPLStore } from '../../../../hooks/trade/tplStore'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'
import TradeDetailListHeader from './TradeDetailListHeader'
import TradeDetailListItem from './TradeDetailListItem'
import { TradeDetailListSection } from './styles'

const TradePositionsTable = ({ popup = false, pos = 'bottom' }) => {
	const { data: token } = useToken()
	const { EPs } = useExchangeStore()
	const { data: tplStore } = useTPLStore()
	const { data: mitdata } = useMitOrder()
	const { data } = useTradePosition()
	const dataType = ['타입', '종목명', '구분', '수량', '가격', '평가손익', '']

	const [mit, setMit] = useState<any>([])

	let list =
		data?.filter(
			(item: any) =>
				!item?.status || (item?.status && item?.limit_market != 'M')
		) || []
	list = [...mit, ...list]

	useEffect(() => {
		const keys = Object.keys(mitdata || [])

		console.log('MITDATA', mitdata, keys)

		let mitlist: any = []


		for (const key of keys) {
			if (key == 'NO') continue

			const item = mitdata[key]
			item?.map((mit: any) => {
				const temp = {
					status: 10,
					exchange_pairs_id: parseInt(key?.replace('MIT', '')),
					price: mit.price,
					long_short: mit.longshort,
					amount: mit?.amount,
				}
				mitlist.push(temp)
			})
		}
		setMit([...mitlist])

		return () => {
			setMit([])
		}
	}, [mitdata])

	data?.filter((item: any) => !item?.status)?.map((item: any) => {
		let tt = tplStore
			?.filter((i: any) => i.key == 'TPL' + item?.exchange_pairs_id)
			?.map(d => {
				const [profit, loss, p, l] = d?.value?.split(':') || [
					5, 5, 0, 0,
				]
				return {
					profit: p == 1 ? true : false,
					profittick: +profit || 5,
					loss: l == 1 ? true : false,
					losstick: +loss || 5,
				}
			}) || [
			{
				profit: false,
				profittick: 5,
				loss: false,
				losstick: 5,
			},
		]

		const t = tt?.[0]

		if (t?.profit) {
			let calcprice = new BigNumber(item?.price || 0)
			let ep = EPs?.find((ep: any) => ep?.id == item?.exchange_pairs_id)
			if (item?.long_short == 'L') {
				calcprice = calcprice.plus(
					new BigNumber(t?.profittick || 0).multipliedBy(ep?.unit)
				)
			} else {
				calcprice = calcprice.minus(
					new BigNumber(t?.profittick || 0).multipliedBy(ep?.unit)
				)
			}
			list = [
				{
					status: 8,
					exchange_pairs_id: item?.exchange_pairs_id,
					price: calcprice.toNumber(),
					long_short: item?.long_short == 'L' ? 'S' : 'L',
					amount: item?.amount,
				},
				...list,
			]
		}

		if (t?.loss) {
			let calcprice = new BigNumber(item?.price || 0)
			let ep = EPs?.find((ep: any) => ep?.id == item?.exchange_pairs_id)
			if (item?.long_short == 'L') {
				calcprice = calcprice.minus(
					new BigNumber(t?.losstick || 0).multipliedBy(ep?.unit)
				)
			} else {
				calcprice = calcprice.plus(
					new BigNumber(t?.losstick || 0).multipliedBy(ep?.unit)
				)
			}
			list = [
				{
					status: 9,
					exchange_pairs_id: item?.exchange_pairs_id,
					price: calcprice.toNumber(),
					long_short: item?.long_short == 'L' ? 'S' : 'L',
					amount: item?.amount,
				},
				...list,
			]
		}
	})

	const renderItem = (item: any, index: any) => {
		return (
			<TradeDetailListItem
				key={'ORDER' + item?.id + item?.status+ index}
				item={item}
				index={index}
				pos={pos}
				dataType={dataType.length}
			/>
		)
	}

	return (
		<TradeDetailListSection
			style={{
				height: pos == 'right' ? 200 : '100%',
				overflowY: 'auto',
				
			}}>
			<TradeDetailListHeader
				dataType={dataType}
				popup={popup}
				pos={pos}
			/>
			{token ? null : <AuthCheckText />}

			{list?.length ? list?.map(renderItem) : null}
		</TradeDetailListSection>
	)
}

export default React.memo(TradePositionsTable)
