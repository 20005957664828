import React from 'react'
import { AssetSpotContainer, AssetDerivativeSection } from './styles'
import AssetDerivativesTable from './AssetDerivativesTable'
import AssetDerivativesHeader from './AssetDerivativesHeader'

const AssetDerivatives = () => {
	return (
		<AssetDerivativeSection>
			<AssetSpotContainer>
				<AssetDerivativesHeader />
				<AssetDerivativesTable />
			</AssetSpotContainer>
		</AssetDerivativeSection>
	)
}

export default AssetDerivatives
