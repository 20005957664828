import ClosePrice from 'app/components/Table/AntCell/ClosePrice'
import HighPrice from 'app/components/Table/AntCell/HighPrice'
import LowPrice from 'app/components/Table/AntCell/LowPrice'
import RatePrevDay from 'app/components/Table/AntCell/RatePrevDay'
import React from 'react'
import { View } from '../../../styles/reactnative'
import TradeNavBar from '../../mobile/organisms/Trade/TradeNavBar'
import InfoDataMobile from '../../organisms/Trade/TradeNavBar/InfoData/mobile'

// Soe Thet
const TradeMobileTop = () => {
	return (
		<>
			<TradeNavBar />
			<View
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'space-between',
					padding: '1px 0',
				}}>
				<RatePrevDay mobile={true} />
				<ClosePrice />
				<HighPrice />
				<LowPrice />
			</View>
			<InfoDataMobile />
		</>
	)
}

export default React.memo(TradeMobileTop)
