import React from 'react'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { NotificationListItemSection } from './styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useTimezone } from 'app/hooks/timezone'
import { truncateText } from 'utils/ExpensiveFunction'

const NotificationLists = ({ item, index }) => {
	const { t } = useTranslation()

	const navigation = useHistory()
	const getType = type => {
		switch (type) {
			case 1:
				return 'SYSTEM'
			case 2:
				return 'TRADE'
			case 3:
				return 'NEWS'
			default:
				return 'SYSTEM'
		}
	}
	return (
		<NotificationListItemSection
			style={{
				background: index % 2 === 0 ? COLORS.white : '#efefef',
			}}
			onClick={() => {
				navigation.push('/notifications')
			}}>
			<View
				style={{
					marginRight: 10,
					paddingTop: 3,
				}}>
				<Text size={12}> [{t(getType(item?.type) + '')}]</Text>
			</View>
			<View
				style={{
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
				}}>
				<Text
					size={14}
					style={{
						marginBottom: 4,
					}}>
					{truncateText(item?.title, 18)}
				</Text>
				<Text size={12} style={{ marginBottom: 6 }}>
					{truncateText(item?.content, 18)}
				</Text>
				<Text size={11} style={{ color: COLORS.lightGray }}>
					{useTimezone(item?.senddate, 'YYYY-MM-DD HH:mm:ss', 9)}
				</Text>
			</View>
		</NotificationListItemSection>
	)
}

export default React.memo(NotificationLists)
