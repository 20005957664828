import React from 'react'
import { useMobileDimensions } from 'app/hooks'

import PrivacyPolicyDesktop from './PrivacyPolicyDesktop'
import { MView } from 'styles/mobilePageStyles'
import { CToast } from 'app/components/Common'
import BottomTabs from 'app/mobile/BottomTabs'

export function PrivacyPolicyPage() {
	const { isMobile } = useMobileDimensions()

	return (
		<MView>
			{isMobile ? <BottomTabs /> : <PrivacyPolicyDesktop />}
			<CToast />
		</MView>
	)
}
