import styled from 'styled-components'

export const HTMLCompSection = styled.div<{ style: any }>`
	* {
		${p => p?.style?.color && `color: ${p.style.color} !important;`}
		background: transparent !important;
	}

	ul {
		list-style: disc;
		margin-left: 20px;
	}
	ol {
		list-style: decimal;
		margin-left: 20px;
	}
	li {
		margin-bottom: 10px;
	}
	li::marker {
		color: #fff;
	}
	strong {
		font-weight: bold;
	}
`
