/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useToken } from 'app/hooks'
import { useChartDataFeed } from 'app/hooks/trade/chartDataFeed'
import { useExchangeStore } from 'app/hooks/trade/exchangeStore'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { useTradePosition } from 'app/hooks/trade/tradePosition'
import { useTradeSocket } from 'app/hooks/trade/tradeSocket'
import { useTradeStore } from 'app/hooks/trade/tradeStore'
import BigNumber from 'bignumber.js'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
import { widgetOptions } from './ChartUtiles'
import { message } from 'antd'
import { useExchangeStoreAll } from 'app/hooks/trade/exchangeStoreAll'

declare global {
	interface Window {
		Datafeeds: any
	}
}
let orderlines: any = []

const Chart = ({ tref, tvWidgetRef, tab, setLoading, mobile }) => {
	const CHART_PARAM_STORAGE = 'TVWIDGET'

	const prevep = useRef<any>(null)
	const { bgColor, raise, fail } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)

	const socket = useTradeSocket()

	const { userid } = useToken()

	const { EPs } = useExchangeStoreAll()

	const [loadedEps, setLoadedEps] = React.useState(false)
	const { data: tradeModalStore, change } = useTradeModalStore()

	const { data: updatechart, mutate: mutateUpdateChart } = useChartDataFeed()
	const { data: tradeStore, changeTradeStore } = useTradeStore()
	const { reset, joinGroup } = socket

	const { currentposition, waitlist } = useTradePosition()

	const [CURRENT_EP, setCURRENT_EP] = React.useState(
		tradeStore?.chart_id || tradeStore?.ep_id
	)

	useEffect(() => {
		reset()
		setCURRENT_EP(tradeStore?.chart_id || tradeStore?.ep_id)
	}, [tradeStore?.chart_id, tradeStore?.ep_id])

	useEffect(() => {
		if (!tvWidgetRef.current) return
		if (!tvWidgetRef?.current?.onChartReady) return

		setTimeout(() => {
			try {
				tvWidgetRef?.current?.onChartReady(async () => {
					for (const orderline of orderlines) {
						orderline?.line?.remove()
					}
					orderlines = []

					if (tradeModalStore?.showpos) {
						for (const position of currentposition) {
							if (
								orderlines.find(
									orderline => orderline?.id == position?.id
								)
							)
								return

							const orderLine = tvWidgetRef?.current
								?.chart()
								?.createPositionLine()

							orderlines.push({
								id: position?.id,
								line: orderLine,
							})

							orderLine
								.setText(
									'보유: ' +
										(position?.long_short === 'L'
											? '매수'
											: '매도')
								)
								.setBodyTextColor(
									position?.long_short === 'S' ? fail : raise
								)
								.setLineColor(
									position?.long_short === 'S' ? fail : raise
								)
								.setQuantity(
									BigNumber(position.amount).toFormat(0)
								)
								.setPrice(position?.price)
						}

						for (const item of waitlist) {
							const orderLine = tvWidgetRef?.current
								?.chart()
								?.createOrderLine()

							orderlines?.push({ id: item?.id, line: orderLine })

							orderLine
								.setText(
									item?.long_short === 'L'
										? '미체결: 매수'
										: '미체결: 매도'
								)
								// 점선
								.setLineWidth(1)

								.setBodyTextColor(
									item?.long_short === 'L' ? fail : raise
								)
								.setBodyBorderColor(
									item?.long_short === 'L' ? fail : raise
								)
								.setQuantityBorderColor(
									item?.long_short === 'L' ? fail : raise
								)
								.setQuantityBackgroundColor(
									item?.long_short === 'L' ? fail : raise
								)

								.setLineColor(
									item?.long_short === 'L' ? fail : raise
								)
								.setQuantity(
									BigNumber(item?.amount)
										.multipliedBy(item?.leverage)
										.toFormat(0)
								)
								.setPrice(item?.order_price)
						}
					}
				})
			} catch (e) {}
		}, 150)
	}, [
		currentposition,
		waitlist,
		tradeModalStore?.showpos,
		tvWidgetRef.current,
		CURRENT_EP,
	])

	useEffect(() => {
		if (tradeStore?.ep_id != CURRENT_EP) {
			joinGroup('user', CURRENT_EP)
			console.log('@@join', 1)
		}

		if (tradeStore?.chart_id != CURRENT_EP) {
			changeTradeStore({ chart_id: CURRENT_EP })
		}
	}, [CURRENT_EP])

    useEffect(() => {

        return () => {
            if (tvWidgetRef.current) {
                tvWidgetRef.current.remove()
            }
        }
    }, [])

	useEffect(() => {
		if (!tvWidgetRef.current) return

		const res = tab?.includes('m') ? tab?.split('m')[0] : tab?.toUpperCase()

		if (tvWidgetRef?.current && tab) {
            try{
                if (tvWidgetRef?.current?.activeChart){
                    tvWidgetRef?.current?.activeChart()?.setResolution(res)
                }

                prevep.current = null
                loadchart()
            }catch(e){
                console.error('@@loadchart', e)
            }
		}

		console.log('@@updateLastTrade')
	}, [tab])

	useEffect(() => {
		return () => {
			changeTradeStore({ chart_id: undefined })
		}
	}, [])

	//메인차트
	const loadchart = useCallback(
		(reset = false) => {
			const CURRENTEP = EPs?.find(item => item?.id == CURRENT_EP)

			if (!CURRENTEP) {
				return
			}

			console.log('@@loadchart', CURRENT_EP, tvWidgetRef.current)

			if (prevep.current == CURRENT_EP && tvWidgetRef.current) {
				return
			}

			setLoading(true)
			const tvWidget = new (window as any).TradingView.widget(
				widgetOptions(
					tvWidgetRef,
					CURRENTEP?.pairs,
					CURRENT_EP,
					updatechart,
					mutateUpdateChart,
					tab,
					'ko-KR',
					isDarkMode,
					mobile,
					EPs,
					setCURRENT_EP,
					userid
				)
			)
			tvWidgetRef.current = tvWidget
			tvWidget.subscribe('chart_loaded', event => {
				tvWidget.changeTheme(isDarkMode ? 'light' : 'dark').then(() => {
					tvWidget.chart().applyOverrides({
						'candleStyle.upColor': '#F05551',
						'candleStyle.downColor': '#007EDA',
						'candleStyle.wickUpColor': '#F05551',
						'candleStyle.wickDownColor': '#007EDA',
						'candleStyle.borderUpColor': '#F05551',
						'candleStyle.borderDownColor': '#007EDA',
						'candleStyle.volumeUpColor': '#F05551',
						'candleStyle.volumeDownColor': '#007EDA',
						'plotLineColor.priceLine.long': '#F05551',
						'plotLineWidth.priceLine.long': 2,
						'plotLineColor.priceLine.short': '#007EDA',
						'plotLineWidth.priceLine.short': 2,
						'mainSeriesProperties.showCountdown': true,
					})
				})
			})

			tvWidget.subscribe('chart_load_requested', function (saveddata) {
				const savedTemplate = tvWidgetRef.current
					.activeChart()
					.createStudyTemplate({ saveInterval: false })

				localStorage.setItem(
					CHART_PARAM_STORAGE,
					JSON.stringify({
						studyTemplate: savedTemplate,
						content: saveddata,
					})
				)
			})

			function createHeaderButton(text, title, clickHandler, options?) {
				var button = tvWidgetRef?.current?.createButton(options)
				button.setAttribute('title', title)
				button.textContent = text
				button.addEventListener('click', () => {
					clickHandler(button)
				})
			}

			const zoom = function (button) {
				try {
					console.log(tvWidgetRef.current)
					if (!mobile) tvWidgetRef.current.startFullscreen()
					if (
						navigator?.userAgent
							?.toLocaleLowerCase()
							?.indexOf('iphone') != -1 ||
						mobile
					) {
						tref.current.style.display = 'block'
						tref.current.style.position = 'fixed'
						tref.current.style.top = '0px'
						tref.current.style.left = '0px'
						tref.current.style.width = '100%'
						tref.current.style.height = '100%'
						tref.current.style.zIndex = '99999'
						button.textContent = '축소'
						button.addEventListener('click', () => {
							button.textContent = '전체화면'
							tref.current.style.zIndex = '0'
							tref.current.style.position = 'relative'
							button.addEventListener('click', () => {
								zoom(button)
							})
							tvWidgetRef.current.exitFullscreen()
						})
					}
				} catch (e) {
					message.error('차트화면 전환에 실패하였습니다.')
				}
			}

			tvWidgetRef.current.headerReady().then(function () {
				createHeaderButton('차트리셋', '차트리셋', function () {
					localStorage.removeItem(CHART_PARAM_STORAGE)
					prevep.current = null
					loadchart()
				})
				createHeaderButton('전체화면', '전체화면', zoom)
			})

			setInterval(() => {
				try {
					if (
						tvWidgetRef.current._iFrame.contentDocument.querySelector(
							'.layout__area--center'
						).style.top == '0px'
					) {
						tvWidgetRef.current._iFrame.contentDocument
							.querySelector('.layout__area--top.js-hidden')
							.classList.remove('js-hidden')
						tvWidgetRef.current._iFrame.contentDocument.querySelector(
							'.layout__area--top'
						).style.zIndex = 5

						tvWidgetRef.current._iFrame.contentDocument
							.querySelector('.layout__area--left.js-hidden')
							.classList.remove('js-hidden')

						tvWidgetRef.current._iFrame.contentDocument.querySelector(
							'.layout__area--left'
						).style.zIndex = 6

						tvWidgetRef.current._iFrame.contentDocument.querySelector(
							'.layout__area--left'
						).style.width = '53px'

						tvWidgetRef.current._iFrame.contentDocument.querySelector(
							'#drawing-toolbar > div > div > div> div'
						).style.display = 'block'

						tvWidgetRef.current._iFrame.contentDocument.querySelector(
							'.chart-gui-wrapper div[data-name=legend]'
						).style.marginLeft = '50px'
						tvWidgetRef.current._iFrame.contentDocument.querySelector(
							'.chart-gui-wrapper div[data-name=legend]'
						).style.marginTop = '30px'
					} else {
						tvWidgetRef.current._iFrame.contentDocument.querySelector(
							'.chart-gui-wrapper div[data-name=legend]'
						).style.marginLeft = '16px'
						tvWidgetRef.current._iFrame.contentDocument.querySelector(
							'.chart-gui-wrapper div[data-name=legend]'
						).style.marginTop = '0px'
					}
				} catch (e) {}
			}, 1000)

			setTimeout(() => {
				setLoading(false)
				loadStudy()
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, 500)
		},
		[
			EPs,
			CURRENT_EP,
			tab,
			isDarkMode,
			mobile,
			updatechart?.updatebars,
			loadedEps,
		]
	)

	useEffect(() => {
		if (!EPs) return
		setLoadedEps(true)
	}, [EPs])

	useEffect(() => {
		if (!EPs) return
		if (!CURRENT_EP) return

		loadchart()

		return () => {
			tvWidgetRef.current && tvWidgetRef.current.remove()
		}
	}, [CURRENT_EP, isDarkMode, mobile, loadedEps])

	const loadStudy = useCallback(() => {
		const savedStr = localStorage.getItem(CHART_PARAM_STORAGE)
		if (savedStr) {
			try {
				const saved = JSON.parse(savedStr)
				if (!saved) {
					return
				}

				if (saved.studyTemplate) {
					tvWidgetRef.current
						.activeChart()
						.applyStudyTemplate(saved.studyTemplate)
				}
			} catch (e) {}
		} else {
		}

		setTimeout(() => {
			joinGroup('user', CURRENT_EP)
		}, 2000)

		tvWidgetRef.current.subscribe('onAutoSaveNeeded', function () {
			tvWidgetRef.current.save(function (savedContent) {
				//console.log('onAutoSaveNeeded', savedContent)
				const savedTemplate = tvWidgetRef.current
					.activeChart()
					.createStudyTemplate({ saveInterval: false })

				localStorage.setItem(
					CHART_PARAM_STORAGE,
					JSON.stringify({
						studyTemplate: savedTemplate,
						content: savedContent,
					})
				)
			})
		})
	}, [CURRENT_EP])

	useEffect(() => {
		if (!updatechart?.updatebars) return

		tvWidgetRef.current.onChartReady(() => {
			change({
				chartloaded: true,
			})

			loadStudy()
			setTimeout(() => {
				joinGroup('user', CURRENT_EP)
				console.log('@@join', 1)
			}, 5000)
		})
	}, [
		tradeModalStore?.showpos,
		tradeStore?.ep_id,
		tradeStore?.chart_id,
		updatechart?.updatebars,
		CURRENT_EP,
		tab,
	])

	if (!EPs) return <></>

	return null
}

export default React.memo(Chart)