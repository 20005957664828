import React from 'react'

import useWindowDimensions from 'app/hooks/useWindowDimensions'

import TradingListItem from './TradingListItem'
import { SectionContainer, SectionWrap } from '../../globalOrganismStyles'
import { SectionHeader } from 'app/components'

import { useFetcherPublic } from '../../../hooks/fetcher'
import DerivativesBody from '../../Trade/TradeTransaction/DerivativesTransaction/DerivativesBody'

const TradingListSection = () => {
	const { width } = useWindowDimensions()
	const { data: res } = useFetcherPublic('/exchanges/?id=1', false)

	const listData = res?.result
	return (
		<SectionContainer>
			<SectionHeader title={'markets'} marginLeft={true} />
			<SectionWrap>
				<DerivativesBody height={555} />
			</SectionWrap>
		</SectionContainer>
	)
}

export default React.memo(TradingListSection)
