import React from 'react'
import { CImage, CText } from 'app/components/Common/'
import { useTranslation } from 'react-i18next'
import { COLORS, FONTS } from 'app/constants/STYLES'

import {
	QrSectionWrapper,
	QrSectionTitle,
	// TopLeftCorner,
	// TopRightCorner,
	// BottomLeftCorner,
	// BottomRightCorner,
} from './styles'
import { Text, View } from 'styles/reactnative'
import { useToken, useWindowDimensions } from 'app/hooks'
import QRCode from 'react-qr-code'
import { useFetcherPublic } from '../../../../hooks/fetcher'
import { toast } from 'react-toastify'

interface IProps {
	width?: number
	height?: number
	onPress?: () => void
}

const QrContainer = ({ width, height, onPress }: IProps) => {
	const { width: wSize } = useWindowDimensions()
	const { t } = useTranslation()

	const { data: token, mutate } = useToken()

	const { data: res } = useFetcherPublic(`/auth/qrlogin`)
	const code = res?.result?.code

	const { data: checkres } = useFetcherPublic(
		`/auth/qrlogincheck?code=${code}`,
		false,
		{ refreshInterval: 1500, revalidateOnFocus: false }
	)
	if (checkres?.result?.token && checkres?.result?.token !== 'YET') {
		toast(t('LOGINSUCCESS') + '')
		mutate(checkres?.result?.token)
	}

	return (
		<QrSectionWrapper
			onClick={onPress}
			style={{
				backgroundColor: 'rgba(255,255,255,0.05)',
			}}>
			<QRCode
				size={180}
				style={{ height: 'auto', maxWidth: 180, width: 170 }}
				value={'LOGINPC' + code}
				viewBox={`0 0 180 180`}
			/>
		</QrSectionWrapper>
	)
}

export default React.memo(QrContainer)
