import { COLORS } from 'app/constants'
import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'
import IdentifyBody from './IdentifyBody'
import IdentifyHeader from './IdentifyHeader'
import { IdentifyVerificationSection } from '../styles'

const IdentifyVerification = ({ level, last }: any) => {
	const { isDarkMode } = useContext(ThemeContext)
	return (
		<IdentifyVerificationSection
			style={{
				boxShadow: `1px 1px 10px ${
					isDarkMode ? COLORS.lightGray2 : COLORS.grey
				}`,
			}}>
			<IdentifyHeader />
			<IdentifyBody level={level} last={last} />
		</IdentifyVerificationSection>
	)
}

export default IdentifyVerification
