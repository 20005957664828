import { IconSend } from '@tabler/icons-react'
import { Button, Col, Input, InputNumber, Row, Space, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { useFetcher, useFetcherSetting } from '../../../hooks/fetcher'
import { useBalance } from '../../../hooks/user/mybalance'
import BigNumber from 'bignumber.js'
import API from '../../../../utils/api'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'
import { useToken } from '../../../hooks'
import { useLockBalance } from '../../../hooks/user/lockbalance'
import { useMyProfile } from '../../../hooks/user/myprofile'
import { Text } from '../../../../styles/reactnative'

const GWithdraw = ({ mobile = false }) => {
	const { textw, cardOpacity, descBox } =
		useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)

	const [amount, setAmount] = useState(0)

	const { data: profile } = useMyProfile()

	const [bank, setBank] = useState(profile?.bank_name || '')
	const [account, setAccount] = useState(profile?.bank_account || '')
	const [name, setName] = useState(profile?.bank_owner || '')
	const { data: token } = useToken()
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()

	const { data } = useFetcherSetting('GINFOW')
	const { data: cs1 } = useFetcherSetting('GCS1')
	const { data: cs2 } = useFetcherSetting('GCS2')
	const { data: balanceData, mutate } = useBalance('KRW', 9)
	const { data: lockbalance, mutate: mutateLock } = useLockBalance('KRW', 9)

	let available = BigNumber(balanceData?.balance).minus(
		balanceData?.lockbalance
	)

	useEffect(() => {
		mutate()
	}, [])

	useEffect(() => {
		mutateLock()
	}, [modalStore?.gDeposit])

	useEffect(() => {
		setBank(profile?.bank_name)
		setAccount(profile?.bank_account)
		setName(profile?.bank_owner)
	}, [profile])

	return (
		<Space
			direction={mobile ? 'vertical' : 'horizontal'}
			size={'small'}
			style={{ width: '100%', alignItems: 'center' }}>
			<Space
				direction="vertical"
				style={{ minWidth: mobile ? '100%' : 330 }}
				size={'small'}>
				<Row
					style={{
						background: descBox,
						margin: '10px 0',
						padding: 5,
						color: textw,
					}}>
					<Col span={24} style={{ textAlign: 'center' }}>
						출금액
					</Col>
				</Row>
				<Row>
					<Col span={6}>가능금액</Col>
					<Col span={16}>
						{lockbalance?.lockamount > 0 ? (
							<Text
								style={{ background: '#444', color: 'white' }}>
								매매횟수부족, 출금불가
							</Text>
						) : (
							<Input
								type="text"
								readOnly
								value={available.toFormat(0, BigNumber.ROUND_FLOOR)}
								style={{ textAlign: 'right' }}></Input>
						)}
					</Col>
					<Col span={1}>원</Col>
				</Row>
				<Row align={'middle'}>
					<Col span={6}>신청금액</Col>
					<Col span={9}>
						<InputNumber
							value={amount}
							formatter={value =>
								`${value}`
									.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
									?.replace(/[^0-9,]/g, '')
							}
							parser={(value: any) =>
								value!
									.replace(/\$\s?|(,*)/g, '')
									?.replace(/[^0-9]/g, '')
							}
							step={10000}
							
							onChange={(e: any) =>
								setAmount(
									Math.min(
										parseInt(available.toNumber() + ''),
										+BigNumber(e).toFixed(0)
									)
								)
							}
							style={{
								textAlign: 'right',
								width: '100%',
								flex: 1,
							}}
						/>
					</Col>
					<Col span={1}>원</Col>
					<Col
						span={8}
						style={{
							textAlign: 'right',
							color: textw,
							fontSize: 12,
							fontWeight: 'bold',
						}}>
						<Button
							size="small"
							onClick={() => {
								setAmount(0)
							}}>
							재입력
						</Button>
						<Button
							size="small"
							onClick={() => {
								setAmount(parseInt(available?.toNumber() + ''))
							}}>
							최대
						</Button>
					</Col>
				</Row>

				<Row align={'middle'}>
					<Col span={24}>
						<Space.Compact>
							<Button
								size="small"
								onClick={() => {
									setAmount(
										Math.min(
											parseInt(available.toNumber() + ''),
											amount + 1000000
										)
									)
								}}>
								+100만
							</Button>
							<Button
								size="small"
								onClick={() => {
									setAmount(
										Math.min(
											parseInt(available.toNumber() + ''),
											amount + 500000
										)
									)
								}}>
								+50만
							</Button>
							<Button
								size="small"
								onClick={() => {
									setAmount(
										Math.min(
											parseInt(available.toNumber() + ''),
											amount + 100000
										)
									)
								}}>
								+10만
							</Button>
							<Button
								size="small"
								onClick={() => {
									setAmount(
										Math.min(
											parseInt(available.toNumber() + ''),
											amount + 50000
										)
									)
								}}>
								+5만
							</Button>
							<Button
								size="small"
								onClick={() => {
									setAmount(
										Math.min(
											parseInt(available.toNumber() + ''),
											amount + 10000
										)
									)
								}}>
								+1만
							</Button>
						</Space.Compact>
					</Col>
				</Row>
				<Row
					style={{
						background: descBox,
						margin: '10px 0',
						padding: 5,
						color: textw,
					}}>
					<Col span={24} style={{ textAlign: 'center' }}>
						받으실곳
					</Col>
				</Row>
				<Row>
					<Col span={6}>은행</Col>
					<Col span={18}>
						<Input
							value={profile?.level != 6 ? bank : '모의회원'}
							readOnly
							onChange={e => {
								setBank(e.target.value)
							}}></Input>
					</Col>
				</Row>
				<Row>
					<Col span={6}>계좌번호</Col>
					<Col span={18}>
						<Input
							value={profile?.level != 6 ? account : '모의회원'}
							readOnly
							onChange={e => {
								setAccount(e.target.value)
							}}></Input>
					</Col>
				</Row>
				<Row>
					<Col span={6}>예금주</Col>
					<Col span={18}>
						<Input
							value={profile?.level != 6 ? name : '모의회원'}
							readOnly
							onChange={e => {
								setName(e.target.value)
							}}></Input>
					</Col>
				</Row>
				<Row align={'middle'}>
					<Col span={6}>가입코드</Col>
					<Col span={18}>
						<Input value={profile?.referral_code || '-'} readOnly />
					</Col>
				</Row>
				<Row
					style={{
						background: descBox,
						margin: '10px 0',
						padding: 5,
						color: textw,
					}}>
					<Col span={24} style={{ textAlign: 'center' }}>
						고객센터
					</Col>
				</Row>
				<Row>
					<Col span={6}>고객센터1</Col>
					<Col span={18}>
						<Input value={cs1}></Input>
					</Col>
				</Row>
				<Row>
					<Col span={6}>고객센터2</Col>
					<Col span={18}>
						<Input value={cs2}></Input>
					</Col>
				</Row>
				<Row style={{ marginTop: 10 }}>
					<Col span={24} style={{ textAlign: 'right' }}>
						<Button
							type="primary"
							icon={<IconSend size={14} />}
							onClick={() => {
								if (amount < 1) {
									message.error('출금액을 확인해주세요.')
									return
								}

								if(profile?.level == 5 || profile?.level == -10){
									message.error('출금이 불가한 계정입니다')
									return;
								}

								if (profile?.level != 6 && profile?.level != 7){
									if (name?.length < 1) {
										message.error(
											'출금자명을 확인해주세요.'
										)
										return
									}
									if (!bank) {
										message.error('은행명을 확인해주세요.')
										return
									}
									if (!account) {
										message.error(
											'계좌번호를 확인해주세요.'
										)
										return
									}
								}

								API.post('/cash/request', token + '', {
									type: 'W',
									amount,
									bankaccount:
										bank + ' ' + account + ' ' + name,
									name,
								}).then(res => {
									if (res?.result?.success) {
										message.success(
											'출금신청이 완료되었습니다.'
										)
									} else {
										message.error(
											res?.result?.message ||
												'출금신청에 실패하였습니다.'
										)
									}
									mutateModalStore({
										...modalStore,
										gDeposit: false,
									})
								})
							}}>
							출금신청
						</Button>
					</Col>
				</Row>
			</Space>

			{mobile ? (
				<div
					style={{
						width: '100%',
						minWidth: 330,
						height: 200,
						overflowY: 'auto',
						overflowX: 'hidden',
						border: '1px solid #cdcdcd',
						padding: 5,
						marginTop: 10,
						whiteSpace: 'pre-wrap',
					}}>
					{data}
				</div>
			) : (
				<div
					style={{
						width: '100%',
						minWidth: 527,
						height: 540,
						overflowY: 'auto',
						overflowX: 'hidden',
						border: '1px solid #cdcdcd',
						padding: 5,
						marginTop: 10,
						whiteSpace: 'pre-wrap',
					}}>
					{data}
				</div>
			)}
		</Space>
	)
}

export default React.memo(GWithdraw)
