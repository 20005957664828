import { message } from 'antd'
import React, {
	useContext
} from 'react'
import {
	Text,
	View
} from '../../../../../../../../styles/reactnative'
import { ThemeContext } from '../../../../../../../../theme/ThemeProvider'
import API from '../../../../../../../../utils/api'
import { FONTS } from '../../../../../../../constants'
import { CANCELORDER } from '../../../../../../../constants/APIKEYS'
import { useToken } from '../../../../../../../hooks'
import { useLogQueue } from '../../../../../../../hooks/trade/logQueue'
import { useMitOrder } from '../../../../../../../hooks/trade/mitorder'
import { useTradeModalStore } from '../../../../../../../hooks/trade/modalStore'
import { useTPLStore } from '../../../../../../../hooks/trade/tplStore'
import { useTradePosition } from '../../../../../../../hooks/trade/tradePosition'
import { useTradeStore } from '../../../../../../../hooks/trade/tradeStore'
import { useUserSocket } from '../../../../../../../hooks/trade/userSocket'
import { useOrderStore } from 'app/hooks/trade/orderStore'
import { useConfirmStore } from 'app/hooks/trade/confirmStore'

const CloseOrderItem = ({ item }) => {
	const THEMECOLORS = useContext(ThemeContext).theme.COLORS
	const { isMobile } = useContext(ThemeContext)

	const { data: token } = useToken()
	const { tempmutate, requestStatus } = useTradePosition()

	const { sendOrder } = useUserSocket()

	const { ep_id } = useTradeStore()
	const { cancel } = useMitOrder()
	const { changeTPL } = useTPLStore()
	const { data: modalStore } = useTradeModalStore()
	const { change } = useConfirmStore()
	const { mutate: mutateLog } = useLogQueue()

	const [touched, setTouched] = React.useState(false)

	const cancelTPL = () => {
		if (item?.status == 8) {
			changeTPL({ profit: false }, item?.exchange_pairs_id)
		}
		if (item?.status == 9) {
			changeTPL({ loss: false }, item?.exchange_pairs_id)
		}
	}


	const cancelMit = () => {
		const commonConfirmCallback = () => {
			try {
				mutateLog({
					log: 'ST013',
					data: {
						type: 'MIT',
						ep_id: item?.exchange_pairs_id,
						longshort: item?.long_short,
						price: item?.price,
						amount: item?.amount,
						isMobile: isMobile,
					},
				})
				cancel(item?.exchange_pairs_id, item?.price, item?.long_short)
			} catch (e) {

			}
			message.success('MIT 주문이 취소되었습니다.')
		}


		if (!modalStore?.skip) {

			change({
				commonConfirm: true,
				commonConfirmTitle: 'MIT 주문을 취소하시겠습니까?',
				commonConfirmCallback: commonConfirmCallback,
			})

			return
		}

		commonConfirmCallback()

	}

	const openModal = () => {

		const commonConfirmCallback = () => {
			mutateLog({
				log: 'ST008',
				data: {
					ep_id: item?.exchange_pairs_id,
					current_ep_id: ep_id,
					type: 'ST008',
					device: 'MTS',
					amount: item?.amount,
					long_short: item?.long_short == 'L' ? 'S' : 'L',
					id: item?.id,
				}
			})

			sendOrder(
				isMobile ? 1 : 0,
				item?.exchange_pairs_id,
				item?.long_short == 'L' ? 'S' : 'L',
				'M',
				item?.long_short == 'S' ? 999999999 : 0.000001,
				item?.amount,
				'',
				token,
				res => {
					if (res?.result?.order) {
						tempmutate('SP', res?.result?.order)
						requestStatus && requestStatus(undefined, true)
					}
				},
				() => { },
				1
			)
		}


		if (!modalStore?.skip) {

			change({
				commonConfirm: true,
				commonConfirmTitle: '주문을 청산하시겠습니까?',
				commonConfirmCallback: commonConfirmCallback,
			})

			return
		}

		commonConfirmCallback()
	}

	const cancelOrder = () => {
		const commonConfirmCallback = () => {
			API.post(CANCELORDER, token + '', {
				orderid: item?.id,
			}).then(res => {
				console.log(res)
				setTimeout(() => {
					requestStatus('SP')
				}, 500)
				message.success('주문이 취소되었습니다.')
			})

			mutateLog({
				log: 'ST009',
				data: {
					ep_id: item?.exchange_pairs_id,
					current_ep_id: ep_id,
					type: 'ST009',
					device: 'MTS',
					amount: item?.amount,
					long_short: item?.long_short == 'L' ? 'S' : 'L',
					id: item?.id,
				}
			})
		}


		if (!modalStore?.skip) {

			change({
				commonConfirm: true,
				commonConfirmTitle: '주문을 취소하시겠습니까?',
				commonConfirmCallback: commonConfirmCallback,
			})

			return
		}

		commonConfirmCallback()


	}
	return (
		<View
			onClick={
				item?.status == 8 || item?.status == 9
					? cancelTPL
					: item?.status == 10
						? cancelMit
						: item?.status
							? cancelOrder
							: openModal
			}
			style={{
				flex: 1,
				background: touched ? THEMECOLORS.drawerBgColor : THEMECOLORS.descBox,
				borderRadius: 5,
				padding: 5,
				minHeight: 30,
			}}>
			<Text style={{ ...FONTS.h6, color: THEMECOLORS.textw }}>
				{item?.status ? '취소' : '청산'}
			</Text>
		</View>
	)
}

export default React.memo(CloseOrderItem)
