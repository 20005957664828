import React, { useEffect, useState } from 'react'
import { IconCategory } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { CButton, CImage } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View } from 'styles/reactnative'
import kakaotalk from 'assets/icons/kakao-talk.png'
import telegram from 'assets/icons/telegram.png'
import { InviteFriendSection } from './styles'
import { toast } from 'react-toastify'
import { copyTextToClipboard, isIos } from '../../../../../utils/ciutils'

const InviteFriend = ({ referralLink }) => {
	const { t } = useTranslation()
	const [shareButton, setShareButton] = useState(false)

	return (
		<InviteFriendSection>
			<View
				style={{
					width: '100%',
					alignSelf: 'center',
					marginTop: 17,
					flexDirection: 'row',
				}}>
				<CButton
					onClick={() => {
						toast(`${t('copied')}`)
						copyTextToClipboard(referralLink)
					}}
					title={t('inviteFriends')}
					style={{
						background: `linear-gradient(to right, #7438FF, #3860FF)`,
						color: COLORS.white,
						width: '40%',
						height: 35,
						alignSelf: 'flex-start',
					}}
				/>
				<View
					style={{
						width: '60%',
						flexDirection: 'row',
						justifyContent: 'space-evenly',
					}}>
					<CButton
						onClick={() => {
							window.open(
								'https://telegram.me/share/url?url=' +
									referralLink +
									'&text=초대합니다.'
							)
						}}
						icon={
							<CImage
								src={telegram}
								alt="logo"
								width={24}
								height={24}
								resizeMode={'cover'}
							/>
						}
						style={{
							background: COLORS.lightGray2,
							width: '25%',
							height: 35,
						}}
					/>
				</View>
			</View>
		</InviteFriendSection>
	)
}

export default InviteFriend
