import { STORE_TRADE } from 'app/constants/LOCALKEYS'
import { useMemo } from 'react'
import useSWR from 'swr'
import { getPersistData } from '../persist'

const KEY = STORE_TRADE

let swrData: any = getPersistData(KEY)

export function useTradeStore(initData?: any, reset?: boolean) {
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (reset === true) {
				swrData = null
				return null
			}
			if (initData !== undefined && swrData == undefined) {
				swrData = initData
			}
			return swrData
		},
		{ dedupingInterval: 1 }
	)

	const ep_id = useMemo(() => {
		return data?.ep_id
	}, [data?.ep_id])

	const symbol = useMemo(() => {
		return data?.pairs
	}, [data?.pairs])

	const chart_id = useMemo(() => {
		return data?.chart_id
	}, [data?.chart_id])

	const dp = useMemo(() => {
		return data?.dp || 0
	}, [data?.dp])

	const unit = useMemo(() => {
		return data?.unit || 2
	}, [data?.unit])

	return {
		data,
		ep_id,
		symbol,
		chart_id,
		dp,
		unit,
		mutate: (value: any) => {
			return mutate((data: any) => {
				const temp = { ...value }
				swrData = temp
				return temp
			})
		},
		changeTradeStore: (value: any) => {
			return mutate((data: any) => {
				const temp = { ...data, ...value }
				swrData = temp
				return temp
			})
		},
	}
}
