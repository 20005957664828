import React, { useContext, useState } from 'react'
import { COLORS, IMAGES } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import AuthButtonLists from '../AuthButtonLists'
import { useHeader, useToken } from 'app/hooks'
import { useMyProfile } from '../../../../hooks/user/myprofile'
import CIUtils from '../../../../../utils/ciutils'
import { HeaderProfieSection, HeaderProfieUid } from '../styles'
import { CImage } from 'app/components/Common'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { Button, Input, Popconfirm, Popover, Space, message } from 'antd'
import API from '../../../../../utils/api'

const HeaderProfie = () => {
	const { data: token, mutate } = useToken()
	const { data: myinfo, mutate: mutateProfile } = useMyProfile()
	const [id, setId] = useState('')

	const { change } = useHeader()

	const { textw } = useContext(ThemeContext).theme.COLORS

	return (
		<HeaderProfieSection>
			<View
				style={{
					width: '100%',
					height: 50,
					marginTop: 20,
				}}>
				<CImage
					src={
						myinfo?.profileimage
							? myinfo?.profileimage
							: IMAGES.avatar
					}
					alt="logo"
					width={40}
					height={40}
					resizeMode={'cover'}
					style={{ borderRadius: 50 }}
				/>
			</View>
			<HeaderProfieUid>
				<Text size={16} style={{ color: textw }}>
					{myinfo?.nickname}
				</Text>
				{myinfo?.loginid ? (
					<Text
						size={14}
						style={{ color: COLORS.lightGray, marginTop: 5 }}>
						ID : {myinfo?.loginid}
					</Text>
				) : (
					<Popconfirm
						title={
							<>
								<Text>로그인 아이디를 설정하시겠습니까?</Text>
								<Input
									placeholder="아이디를 입력해주세요"
									value={id}
									onChange={e => setId(e.target.value)}
								/>
							</>
						}
						okText="설정"
						cancelText="취소"
						onConfirm={() => {
							if (!id)
								return message.error('아이디를 입력해주세요')
							if (id.includes('@') || id.includes(' '))
								return message.error(
									'아이디는 이메일 형식이 아닌 문자로 입력해주세요'
								)
							API.post('/auth/setid', token + '', {
								loginid: id,
							}).then(res => {
								if (res?.result?.success) {
									mutateProfile()
									message.success(
										'로그인 아이디가 설정되었습니다.'
									)
								}
								if (res?.result?.msgcode)
									message.error(res?.result?.msgcode)
							})
						}}>
						<Button
							size="small"
							type="primary"
							style={{
								margin: '5px 0',
							}}>
							로그인 아이디설정
						</Button>
					</Popconfirm>
				)}
				<Text
					size={14}
					style={{ color: COLORS.lightGray, marginTop: 5 }}>
					UID : {CIUtils.padId(myinfo?.id)}
				</Text>
			</HeaderProfieUid>
			{myinfo?.level == 5 && (
				<AuthButtonLists
					title={'referralProgram'}
					go={myinfo?.level == 5 ? '/partner' : '/referral'}
				/>
			)}
			{/* <AuthButtonLists
				title={'tradingViewAlerts'}
				go={'/settings?tab=4'}
			/> */}
			{/* <AuthButtonLists title={'myFeeRates'} go={'settings'} />
			<AuthButtonLists title={'myRewards'} go={'settings'} /> */}
			<AuthButtonLists
				title={'account'}
				action={() => {
					window.location.href = '/settings'
				}}
			/>
			<AuthButtonLists
				title={'log'}
				action={() => {
					change({ logmodal: true })
				}}
			/>
			{/* <AuthButtonLists title={'api'} go={'/settings'} /> */}

			<AuthButtonLists
				title={'logout'}
				action={() => {
					mutate(null)
					window.location.href = '/logout'
					// setTimeout(() => window.location.reload(), 100)
				}}
				style={{
					width: '50%',
					marginTop: 15,
					marginLeft: 130,
					alignItems: 'flex-end',
				}}
			/>
		</HeaderProfieSection>
	)
}

export default React.memo(HeaderProfie)
