import React, { useContext } from 'react'
import PageBorder from 'app/mobile/components/PageBorder'
import { MView } from 'styles/mobilePageStyles'
import ProfileSettingBody from './ProfileSettingBody'
import ProfileSettingInfo from './ProfileSettingInfo'
import { useToken } from '../../../hooks'
import AuthButton from '../../../components/AuthButton'
import { ThemeContext } from 'theme/ThemeProvider'

const MProfileSetting = () => {
	const { data: token } = useToken()
	const { mdrawerBgColor, textw } = useContext(ThemeContext).theme.COLORS

	//bottomsheet color
	document.documentElement.style.setProperty('--rsbs-bg', 'white')
	document.documentElement.style.setProperty('--rsbs-handle-bg', textw)

	return (
		<MView style={{ width: '100%', height: '100%' }}>
			{token ? <ProfileSettingInfo /> : <AuthButton />}
			<PageBorder />
			<ProfileSettingBody />
		</MView>
	)
}

export default MProfileSetting
