import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Group, Text, Rect, Line } from 'react-konva'
import Grid, { useTouch } from '@rowsncolumns/grid'
import { message } from 'antd'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import { useOrderStore } from '../../../hooks/trade/orderStore'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'
import { useMitOrder } from '../../../hooks/trade/mitorder'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { useTradePosition } from '../../../hooks/trade/tradePosition'
import { useIndexPrice } from '../../../hooks/trade/indexprice'
import { useLogQueue } from '../../../hooks/trade/logQueue'
import BigNumber from 'bignumber.js'
import API from '../../../../utils/api'

let timer: any = null
let velocity = 0

const CanvasList = ({
	data,
	parentHeight = 400,
	parentWidth = 400,
	maxSize = 10,
	max = 0,
	send = (type, price, num) => {
		console.log('orderbooklist', type, price, num)
	},
	is_mobile = false,
	setMaxSize = e => { },
	token = '',
}: any) => {
	const gridRef: any = useRef()

	const touchProps = useTouch({
		gridRef,
	})

	const realDevice = /iphone|ipad|ipod|android|mobile/i.test(
		navigator.userAgent?.toLowerCase()
	)

	const [startY, setStartY] = useState(0)

	const ROW_HEIGHT = is_mobile ? 34 : 20
	const [COL_WIDTH, setCOL_WIDTH] = useState(0)

	const { theme, isMobile } = useContext(ThemeContext)

	const {
		bgColor,
		textw,
		raise,
		raise_sub,
		fail,
		fail_sub,
		background,
		stroke,
		mitBG,
		shadow,
	} = theme.COLORS

	const { data: store, ep_id } = useTradeStore()
	const { getPrice } = useIndexPrice(store?.ep_id)

	const { data: orderStore, change: mutateOrderStore } = useOrderStore()
	const { data: modalStore, change: mutateModal } = useTradeModalStore()
	const { data: mitdata, mutate: mutateMit, modify } = useMitOrder()

	const { mutate: mutateLog } = useLogQueue()

	const [loading, setLoading] = useState(false)

	const [dragobject, setDragobject] = useState<any>(null)
	const [dragobjectsell, setDragobjectsell] = useState<any>(null)
	const [dragobjectbuy, setDragobjectbuy] = useState<any>(null)

	useEffect(() => {
		if (!modalStore?.fixed_orderbook) {
			setTimeout(() => {
				setLoading(true)
			}, 1000)
		} else {
			setLoading(false)
		}
	}, [modalStore?.fixed_orderbook])

	const { waitlist } = useTradePosition()

	const setOrderPrice = price => {
		mutateOrderStore({
			price: price,
		})
	}

	useEffect(() => {
		setTimeout(() => {
			setCOL_WIDTH(Math.floor((parentWidth + 20) / 10) + 2)

			if (gridRef?.current) {
				gridRef?.current?.resetAfterIndices({
					columnIndex: 0,
					rowIndex: 0,
				})
			}
		}, 300)
	}, [parentHeight, parentWidth])

	useEffect(() => {
		if (!modalStore?.fixed_orderbook) {
			if (loading && data?.length > 160) {
				if (gridRef?.current?.getScrollPosition()?.scrollTop <= 400) {
					if (gridRef?.current)
						gridRef?.current?.scrollTo({
							scrollLeft: 0,
							scrollTop: 30 * ROW_HEIGHT,
						})
				}
				return
			}

			setTimeout(() => {
				if (gridRef?.current)
					gridRef?.current?.scrollTo({
						scrollLeft: 0,
						scrollTop:
							(data?.length * ROW_HEIGHT) / 2 -
							parentHeight / 2 +
							120,
					})
			}, 200)
		}
	}, [modalStore?.fixed_orderbook, parentHeight, ep_id, data?.length])

	const CENTERCELL = useMemo(
		() => COL_WIDTH * Math.max(Math.min(1.8, parentWidth / 420), 1.55),
		[COL_WIDTH, parentWidth]
	)

	const AMOUNTCELL = useMemo(
		() => COL_WIDTH * Math.max(Math.min(0.9, parentWidth / 850), 0.78),
		[COL_WIDTH, parentWidth]
	)

	const sellbuychange = (longshort, price, change) => {
		console.log('sellchange', longshort, price, change)
		API.put('/trade/modify', token + '', {
			ep_id: ep_id,
			price: price,
			long_short: longshort,
			change_price: change,
		}).then(res => {
			if (res?.result?.result) {
				message.success('주문이 정정되었습니다.')
			} else {
				message.error(res?.result?.message)
			}
		})
	}

	const mitchange = (longshort, price, change) => {
		modify(ep_id, price, longshort, change)

		mutateLog({
			log: 'ST012',
			data: {
				ep_id: ep_id,
				longshort: longshort,
				price: change,
				amount: orderStore?.amount,
				type: 'MIT',
				isMobile: isMobile,
			},
		})
	}

	const mitorder = useMemo(() => {
		return (longshort, price) => {
			if (price <= 0) {
				message.error('가격을 입력해주세요.')
				return
			}

			if (!orderStore?.amount) {
				message.error('수량을 입력해주세요.')
				return
			}
			mutateLog({
				log: 'ST011',
				data: {
					ep_id: ep_id,
					longshort: longshort,
					price: price,
					amount: orderStore?.amount,
					type: 'MIT',
					isMobile: isMobile,
				},
			})

			const currentprice = getPrice(ep_id)
			let BS = 'B'

			if (longshort == 'L') {
				if (price > currentprice) {
					BS = 'S'
				} else {
					BS = 'A'
				}
			} else {
				if (price > currentprice) {
					BS = 'A'
				} else {
					BS = 'B'
				}
			}

			if (!modalStore?.skip) {
				mutateModal({
					showconfirmorder: true,
					mitlong: longshort,
					mitprice: price,
					BS: BS,
				})
				return
			}

			mutateMit(
				{
					amount: orderStore?.amount,
					longshort: longshort,
					price: price,
					BS: BS,
				},
				ep_id
			)
			message.success('MIT 주문이 등록되었습니다.')
		}
	}, [ep_id, mutateMit, orderStore?.amount, isMobile, modalStore?.skip])

	if (COL_WIDTH < 9) return null

	const CELLRENDRER = (props: any): any => {
		const item = data[props.rowIndex]
		const column = props.columnIndex

		if (column == 0) {
			if (item?.header) {
				return (
					<Group key={'cell' + props.rowIndex + props.columnIndex}>
						<Rect
							fill={mitBG}
							x={props.x}
							y={props.y}
							width={COL_WIDTH}
							height={ROW_HEIGHT}
							stroke={stroke}
							strokeWidth={0.5}
						/>
						<Text
							x={props.x + props.width / 2 - 12}
							y={
								props.rowIndex * ROW_HEIGHT +
								(is_mobile ? 10 : 6.5)
							}
							text={'MIT'}
							fontFamily="Tahoma"
							fill={textw}
						/>
					</Group>
				)
			}

			//MIT매도
			const amount =
				mitdata?.['MIT' + ep_id]?.find(
					i => i?.price === item?.price && i?.longshort === 'S'
				)?.amount || ''

			return (
				<Group
					key={'cell' + props.rowIndex + props.columnIndex}
					onMouseEnter={(e: any) => {
						// style stage container:
						const container = e.target.getStage().container()
						container.style.cursor = 'pointer'
					}}
					onMouseLeave={(e: any) => {
						const container = e.target.getStage().container()
						container.style.cursor = 'default'
					}}
					onTap={() => {
						if (!modalStore?.oneClick) return
						mitorder('S', item?.price)
					}}
					onDblTap={() => {
						if (modalStore?.oneClick) return
						mitorder('S', item?.price)
					}}
					onDblClick={() => {
						if (modalStore?.oneClick) return
						mitorder('S', item?.price)
					}}
					onClick={() => {
						if (!modalStore?.oneClick) return
						mitorder('S', item?.price)
					}}
					onMouseDown={(e: any) => {
						if (amount) {
							setDragobject({
								longshort: 'S',
								price: item?.price,
								amount: amount,
							})
						}
					}}
					onMouseUp={(e: any) => {
						if (!dragobject) return
						if (dragobject?.price == item?.price) return

						mitchange(
							dragobject?.longshort,
							dragobject?.price,
							item?.price
						)
						setDragobject(null)
					}}>
					<Rect
						onMouseEnter={(e: any) => {
							// style stage container:
							e.target.stroke(textw)
						}}
						onMouseLeave={(e: any) => {
							e.target.stroke(stroke)
						}}
						fill={mitBG}
						x={props.x}
						y={props.y}
						width={COL_WIDTH}
						height={ROW_HEIGHT}
						stroke={stroke}
						strokeWidth={0.5}
					/>
					<Text
						x={props.x + 5}
						y={props.rowIndex * ROW_HEIGHT + (is_mobile ? 10 : 6.5)}
						text={amount ? amount : ''}
						fontFamily="Tahoma"
						fill={textw}
					/>
				</Group>
			)
		} else if (column == 1) {
			if (item?.header) {
				return (
					<Group key={'cell' + props.rowIndex + props.columnIndex}>
						<Rect
							fill={mitBG}
							x={props.x}
							y={props.y}
							width={COL_WIDTH}
							height={ROW_HEIGHT}
							stroke={stroke}
							strokeWidth={0.5}
						/>
						<Text
							x={props.x + props.width / 2 - 12}
							y={
								props.rowIndex * ROW_HEIGHT +
								(is_mobile ? 10 : 6.5)
							}
							text={'매도'}
							fontFamily="Tahoma"
							fill={textw}
						/>
					</Group>
				)
			}

			let check: any = waitlist?.filter(
				(i: any) =>
					i?.status === 1 &&
					+i?.order_price === +item?.price &&
					i?.long_short == 'S' && i?.limit_market != 'M' && i?.reduce_position != 1
			)

			const sum_reamin_amount = check?.reduce(
				(a: any, b: any) => a + +b?.remain_amount,
				0
			)
			//매도
			return (
				<Group
					key={'cell' + props.rowIndex + props.columnIndex}
					onMouseEnter={(e: any) => {
						// style stage container:
						const container = e.target.getStage().container()
						container.style.cursor = 'pointer'
					}}
					onMouseLeave={(e: any) => {
						const container = e.target.getStage().container()
						container.style.cursor = 'default'
					}}
					onTap={() => {
						if (!modalStore?.oneClick) return
						send('S', item?.price, 1)
					}}
					onDblTap={() => {
						if (modalStore?.oneClick) return
						send('S', item?.price, 2)
					}}
					onDblClick={() => {
						if (modalStore?.oneClick) return
						send('S', item?.price, 2)
					}}
					onClick={() => {
						if (!modalStore?.oneClick) return
						send('S', item?.price, 1)
					}}
					onMouseDown={(e: any) => {
						if (sum_reamin_amount > 0) {
							setDragobjectsell({
								longshort: 'S',
								price: item?.price,
								amount: sum_reamin_amount,
							})
						}
					}}
					onMouseUp={(e: any) => {
						if (!dragobjectsell) return
						if (dragobjectsell?.price == item?.price) return

						sellbuychange(
							dragobjectsell?.longshort,
							dragobjectsell?.price,
							item?.price
						)

						setDragobjectsell(null)
					}}>
					<Rect
						onMouseEnter={(e: any) => {
							// style stage container:
							e.target.stroke(textw)
						}}
						onMouseLeave={(e: any) => {
							e.target.stroke(stroke)
						}}
						fill={bgColor}
						x={props.x}
						y={props.y}
						width={COL_WIDTH}
						height={ROW_HEIGHT}
						stroke={stroke}
						strokeWidth={0.5}
					/>
					<Text
						x={props.x + 5}
						y={props.rowIndex * ROW_HEIGHT + (is_mobile ? 10 : 6.5)}
						text={sum_reamin_amount > 0 ? sum_reamin_amount : ''}
						fontFamily="Tahoma"
						fill={textw}
					/>
				</Group>
			)
		} else if (column == 2) {
			if (item?.header) {
				return (
					<Group key={'cell' + props.rowIndex + props.columnIndex}>
						<Rect
							fill={mitBG}
							x={props.x}
							y={props.y}
							width={COL_WIDTH}
							height={ROW_HEIGHT}
							stroke={stroke}
							strokeWidth={0.5}
						/>
						<Text
							x={props.x + props.width / 2 - 12}
							y={
								props.rowIndex * ROW_HEIGHT +
								(is_mobile ? 10 : 6.5)
							}
							text={'건수'}
							fontFamily="Tahoma"
							fill={textw}
						/>
					</Group>
				)
			}
			//매도건수
			let left = 0
			if (item?.type == 'S' && item?.count && item?.sumAmount) {
				left = COL_WIDTH - (item?.sumAmount / max) * COL_WIDTH
			}

			return (
				<Group key={'cell' + props.rowIndex + props.columnIndex}>
					<Rect
						fill={
							item?.count && item?.type == 'S'
								? item?.type == 'L'
									? raise_sub
									: fail_sub
								: bgColor
						}
						x={props.x + left}
						y={props.y}
						width={COL_WIDTH - left}
						height={ROW_HEIGHT}
						stroke={stroke}
						strokeWidth={0.5}
					/>
					<Text
						x={props.x + 5}
						y={props.rowIndex * ROW_HEIGHT + (is_mobile ? 10 : 6.5)}
						fontFamily="Tahoma"
						fill={textw}
						text={
							item?.type == 'S'
								? item?.count
									? item?.count
									: ''
								: ''
						}
					/>

					<Line
						points={[
							props.x,
							0,
							props.x,
							data?.length * ROW_HEIGHT + 10,
						]}
						stroke={fail}
						strokeWidth={0.2}
					/>
				</Group>
			)
		} else if (column == 3) {
			if (item?.header) {
				return (
					<Group key={'cell' + props.rowIndex + props.columnIndex}>
						<Rect
							fill={mitBG}
							x={props.x}
							y={props.y}
							width={COL_WIDTH}
							height={ROW_HEIGHT}
							stroke={stroke}
							strokeWidth={0.5}
						/>
						<Text
							x={props.x + props.width / 2 - 12}
							y={
								props.rowIndex * ROW_HEIGHT +
								(is_mobile ? 10 : 6.5)
							}
							text={'잔량'}
							fontFamily="Tahoma"
							fill={textw}
						/>
					</Group>
				)
			}
			//매도수량
			return (
				<Group key={'cell' + props.rowIndex + props.columnIndex}>
					<Rect
						fill={
							item?.amount && item?.type == 'S'
								? item?.type == 'L'
									? raise_sub
									: fail_sub
								: bgColor
						}
						x={props.x}
						y={props.y}
						width={AMOUNTCELL}
						height={ROW_HEIGHT}
						stroke={stroke}
						strokeWidth={0.5}
					/>
					<Text
						x={props.x + 5}
						y={props.rowIndex * ROW_HEIGHT + (is_mobile ? 10 : 6.5)}
						fontFamily="Tahoma"
						fill={textw}
						text={
							item?.type == 'S'
								? item?.amount
									? item?.amount
									: ''
								: ''
						}
					/>
				</Group>
			)
		} else if (column == 4) {
			if (item?.header) {
				return (
					<Group
						key={'cell' + props.rowIndex + props.columnIndex}
						onMouseUp={(e: any) => {
							mutateModal({
								fixed_orderbook: !modalStore?.fixed_orderbook,
							})
						}}
						onTouchEnd={() => {
							mutateModal({
								fixed_orderbook: !modalStore?.fixed_orderbook,
							})
						}}>
						<Rect
							fill={mitBG}
							x={props.x}
							y={props.y}
							width={CENTERCELL}
							height={ROW_HEIGHT}
							stroke={stroke}
							strokeWidth={0.5}
						/>
						{is_mobile ? (
							<>
								<Rect
									fill={
										modalStore?.fixed_orderbook
											? shadow
											: bgColor
									}
									x={props.x + 8}
									y={props.y + 8}
									width={CENTERCELL - 16}
									height={ROW_HEIGHT - 16}
									stroke={mitBG}
									strokeWidth={0.8}
								/>

								<Text
									x={props.x + props.width / 2 - 25}
									y={
										props.rowIndex * ROW_HEIGHT +
										(is_mobile ? 10 : 6.5)
									}
									text={
										'고정(' +
										(!modalStore?.fixed_orderbook
											? 'OFF'
											: 'ON') +
										')'
									}
									fontFamily="Tahoma"
									fill={
										!modalStore?.fixed_orderbook
											? textw
											: raise
									}
								/>
							</>
						) : (
							<>
								<Text
									x={props.x + props.width / 2 - 12}
									y={
										props.rowIndex * ROW_HEIGHT +
										(is_mobile ? 10 : 6.5)
									}
									text={'호가'}
									fontFamily="Tahoma"
									fill={textw}
								/>
							</>
						)}
					</Group>
				)
			}
			// 가격
			return (
				<Group
					key={'cell' + props.rowIndex + props.columnIndex}
					onMouseEnter={(e: any) => {
						// style stage container:
						const container = e.target.getStage().container()
						container.style.cursor = 'pointer'
					}}
					onMouseLeave={(e: any) => {
						const container = e.target.getStage().container()
						container.style.cursor = 'default'
					}}
					onTap={() => {
						setOrderPrice(item?.price)
					}}
					onClick={() => {
						setOrderPrice(item?.price)
					}}>
					<Rect
						fill={
							+item?.price == +getPrice(ep_id)
								? '#ebb55e'
								: item?.type == 'L'
									? raise_sub
									: fail_sub
						}
						x={props.x}
						y={props.y}
						width={CENTERCELL}
						height={ROW_HEIGHT}
						stroke={stroke}
						strokeWidth={0.5}
					/>
					<Text
						x={
							props.x +
							props.width / 2 -
							item?.padprice?.length * 3
						}
						y={props.rowIndex * ROW_HEIGHT + (is_mobile ? 10 : 6.5)}
						text={item?.padprice}
						fill={item?.type == 'L' ? raise : fail}
					/>
				</Group>
			)
		} else if (column == 5) {
			if (item?.header) {
				return (
					<Group key={'cell' + props.rowIndex + props.columnIndex}>
						<Rect
							fill={mitBG}
							x={props.x}
							y={props.y}
							width={COL_WIDTH}
							height={ROW_HEIGHT}
							stroke={stroke}
							strokeWidth={0.5}
						/>
						<Text
							x={props.x + props.width / 2 - 12}
							y={
								props.rowIndex * ROW_HEIGHT +
								(is_mobile ? 10 : 6.5)
							}
							text={'잔량'}
							fontFamily="Tahoma"
							fill={textw}
						/>
					</Group>
				)
			}
			//매수수량
			return (
				<Group key={'cell' + props.rowIndex + props.columnIndex}>
					<Rect
						fill={
							item?.amount && item?.type == 'L'
								? item?.type == 'L'
									? raise_sub
									: fail_sub
								: bgColor
						}
						x={props.x}
						y={props.y}
						width={AMOUNTCELL}
						height={ROW_HEIGHT}
						stroke={stroke}
						strokeWidth={0.5}
					/>
					<Text
						x={props.x + 5}
						y={props.rowIndex * ROW_HEIGHT + (is_mobile ? 10 : 6.5)}
						fontFamily="Tahoma"
						fill={textw}
						text={
							item?.type == 'L'
								? item?.amount
									? item?.amount
									: ''
								: ''
						}
					/>

					{item?.currentposition && (
						<>
							<Rect
								fill={textw}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'◀'}
								fontSize={9}
								fill={bgColor}
							/>
						</>
					)}

					{item?.loss && (
						<>
							<Rect
								fill={fail}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'손'}
								fontSize={9}
								fill={'white'}
							/>
						</>
					)}

					{item?.profit && (
						<>
							<Rect
								fill={raise}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'익'}
								fontSize={9}
								fill={'white'}
							/>
						</>
					)}

					{item?.profit && (
						<>
							<Rect
								fill={raise}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'익'}
								fontSize={9}
								fill={'white'}
							/>
						</>
					)}

					{item?.open && (
						<>
							<Rect
								fill={textw}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'시'}
								fontSize={9}
								fill={background}
							/>
						</>
					)}

					{item?.high && (
						<>
							<Rect
								fill={raise}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'고'}
								fontSize={9}
								fill={'white'}
							/>
						</>
					)}

					{item?.low && (
						<>
							<Rect
								fill={fail}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'저'}
								fontSize={9}
								fill={'white'}
							/>
						</>
					)}
				</Group>
			)
		} else if (column == 6) {
			//매수건수
			if (item?.header) {
				return (
					<Group key={'cell' + props.rowIndex + props.columnIndex}>
						<Rect
							fill={mitBG}
							x={props.x}
							y={props.y}
							width={COL_WIDTH}
							height={ROW_HEIGHT}
							stroke={stroke}
							strokeWidth={0.5}
						/>
						<Text
							x={props.x + props.width / 2 - 12}
							y={
								props.rowIndex * ROW_HEIGHT +
								(is_mobile ? 10 : 6.5)
							}
							text={'건수'}
							fontFamily="Tahoma"
							fill={textw}
						/>
					</Group>
				)
			}

			let left = COL_WIDTH
			if (item?.type == 'L' && item?.count && item?.sumAmount) {
				left = (item?.sumAmount / max) * COL_WIDTH
			}

			return (
				<Group key={'cell' + props.rowIndex + props.columnIndex}>
					<Rect
						fill={
							item?.count && item?.type == 'L'
								? item?.type == 'L'
									? raise_sub
									: fail_sub
								: bgColor
						}
						x={props.x}
						y={props.y}
						width={left}
						height={ROW_HEIGHT}
						stroke={stroke}
						strokeWidth={0.5}
					/>
					<Text
						x={props.x + 5}
						y={props.rowIndex * ROW_HEIGHT + (is_mobile ? 10 : 6.5)}
						fontFamily="Tahoma"
						fill={textw}
						text={
							item?.type == 'L'
								? item?.count
									? item?.count
									: ''
								: ''
						}
					/>
				</Group>
			)
		} else if (column == 7) {
			if (item?.header) {
				return (
					<Group key={'cell' + props.rowIndex + props.columnIndex}>
						<Rect
							fill={mitBG}
							x={props.x}
							y={props.y}
							width={COL_WIDTH}
							height={ROW_HEIGHT}
							stroke={stroke}
							strokeWidth={0.5}
						/>
						<Text
							x={props.x + props.width / 2 - 12}
							y={
								props.rowIndex * ROW_HEIGHT +
								(is_mobile ? 10 : 6.5)
							}
							text={'매수'}
							fontFamily="Tahoma"
							fill={textw}
						/>
					</Group>
				)
			}
			//매수
			let check: any = waitlist?.filter(
				(i: any) =>
					i?.status === 1 &&
					+i?.order_price === +item?.price &&
					i?.long_short == 'L' && i?.limit_market != 'M' && i?.reduce_position != 1
			)

			const sum_reamin_amount = check?.reduce(
				(a: any, b: any) => a + +b?.remain_amount,
				0
			)
			//매도
			return (
				<Group
					key={'cell' + props.rowIndex + props.columnIndex}
					onDblClick={() => {
						if (modalStore?.oneClick) return
						send('L', item?.price, 2)
					}}
					onClick={() => {
						if (!modalStore?.oneClick) return
						send('L', item?.price, 1)
					}}
					onDblTap={e => {
						if (modalStore?.oneClick) return
						send('L', item?.price, 2)
					}}
					onTap={e => {
						if (!modalStore?.oneClick) return
						send('L', item?.price, 1)
					}}
					onMouseDown={(e: any) => {
						if (sum_reamin_amount > 0) {
							setDragobjectsell({
								longshort: 'L',
								price: item?.price,
								amount: sum_reamin_amount,
							})
						}
					}}
					onMouseUp={(e: any) => {
						if (!dragobjectsell) return
						if (dragobjectsell?.price == item?.price) return

						sellbuychange(
							dragobjectsell?.longshort,
							dragobjectsell?.price,
							item?.price
						)
						setDragobjectsell(null)
					}}
					onMouseEnter={(e: any) => {
						// style stage container:
						const container = e.target.getStage().container()
						container.style.cursor = 'pointer'
					}}
					onMouseLeave={(e: any) => {
						const container = e.target.getStage().container()
						container.style.cursor = 'default'
					}}>
					<Rect
						onMouseEnter={(e: any) => {
							// style stage container:
							e.target.stroke(textw)
						}}
						onMouseLeave={(e: any) => {
							e.target.stroke(stroke)
						}}
						fill={bgColor}
						stroke={stroke}
						strokeWidth={0.5}
						x={props.x}
						y={props.y}
						width={COL_WIDTH}
						height={ROW_HEIGHT}
					/>
					<Text
						x={props.x + 5}
						y={props.rowIndex * ROW_HEIGHT + (is_mobile ? 10 : 6.5)}
						text={sum_reamin_amount > 0 ? sum_reamin_amount : ''}
						fontFamily="Tahoma"
						fill={textw}
					/>
					<Line
						points={[
							props.x,
							0,
							props.x,
							data?.length * ROW_HEIGHT + 10,
						]}
						stroke={raise}
						strokeWidth={0.2}
					/>
				</Group>
			)
		} else if (column == 8) {
			//MIT매수
			if (item?.header) {
				return (
					<Group key={'cell' + props.rowIndex + props.columnIndex}>
						<Rect
							fill={mitBG}
							x={props.x}
							y={props.y}
							width={COL_WIDTH}
							height={ROW_HEIGHT}
							stroke={stroke}
							strokeWidth={0.5}
						/>
						<Text
							x={props.x + props.width / 2 - 12}
							y={
								props.rowIndex * ROW_HEIGHT +
								(is_mobile ? 10 : 6.5)
							}
							text={'MIT'}
							fontFamily="Tahoma"
							fill={textw}
						/>
					</Group>
				)
			}

			const amount =
				mitdata?.['MIT' + ep_id]?.find(
					i => i?.price === item?.price && i?.longshort === 'L'
				)?.amount || ''

			return (
				<Group
					key={'cell' + props.rowIndex + props.columnIndex}
					onMouseEnter={(e: any) => {
						// style stage container:
						const container = e.target.getStage().container()
						container.style.cursor = 'pointer'
					}}
					onMouseLeave={(e: any) => {
						const container = e.target.getStage().container()
						container.style.cursor = 'default'
					}}
					onDblTap={() => {
						if (modalStore?.oneClick) return
						mitorder('L', item?.price)
					}}
					onTap={() => {
						if (!modalStore?.oneClick) return
						mitorder('L', item?.price)
					}}
					onDblClick={() => {
						if (modalStore?.oneClick) return
						mitorder('L', item?.price)
					}}
					onClick={() => {
						if (!modalStore?.oneClick) return
						mitorder('L', item?.price)
					}}
					onMouseDown={(e: any) => {
						if (amount) {
							setDragobject({
								longshort: 'L',
								price: item?.price,
								amount: amount,
							})
						}
					}}
					onMouseUp={(e: any) => {
						if (!dragobject) return
						if (dragobject?.price == item?.price) return
						mitchange(
							dragobject?.longshort,
							dragobject?.price,
							item?.price
						)
						setDragobject(null)
					}}>
					<Rect
						onMouseEnter={(e: any) => {
							// style stage container:
							e.target.stroke(textw)
						}}
						onMouseLeave={(e: any) => {
							e.target.stroke(stroke)
						}}
						fill={mitBG}
						x={props.x}
						y={props.y}
						stroke={stroke}
						strokeWidth={0.5}
						width={COL_WIDTH}
						height={ROW_HEIGHT}
					/>
					<Text
						x={props.x + 5}
						y={props.rowIndex * ROW_HEIGHT + (is_mobile ? 10 : 6.5)}
						text={amount}
						fontFamily="Tahoma"
						fill={textw}
					/>

					{item?.last && (
						<Line
							points={[
								0,
								props.y + ROW_HEIGHT,
								parentWidth,
								props.y + ROW_HEIGHT,
							]}
							stroke={textw}
							strokeWidth={1}
						/>
					)}
				</Group>
			)
		} else {
			return <></>
		}
	}

	return (
		<Group>
			{/* <Grid  rowCount={1} columnCount={10} width={parentWidth} height={30} rowHeight={rowIndex => ROW_HEIGHT} 			columnWidth={columnIndex => {
			if (columnIndex == 4) {
				return CENTERCELL
			} else if (columnIndex == 3 || columnIndex == 5) {
				return AMOUNTCELL
			} else {
				return COL_WIDTH
			}
		}}
		
		itemRenderer={CELLRENDRERHEADER}
		{...touchProps}
		/> */}

			<Grid
				ref={gridRef}
				rowCount={data?.length}
				frozenRows={1}
				columnCount={10}
				width={
					is_mobile
						? parentWidth
						: parentWidth - (modalStore?.fixed_orderbook ? 0 : 10)
				}
				onTouchEnd={(e: any) => {
					document.body.style.overflow = 'auto'

					if (timer) {
						clearInterval(timer)
					}

					console.log('end', velocity)
					timer = setInterval(() => {
						if (velocity > 2 || velocity < -2) {
							velocity = velocity * 0.98

							const moveY =
								gridRef?.current?.getScrollPosition()
									.scrollTop + velocity
							if (isNaN(moveY)) {
								clearInterval(timer)
								timer = null
								return
							}

							gridRef?.current?.scrollTo({
								scrollLeft: 0,
								scrollTop: moveY,
							})
						} else {
							velocity = 0
							clearInterval(timer)
							timer = null
						}
					}, 10)
				}}
				onTouchStart={(e: any) => {
					setStartY(e.touches[0].clientY)
					document.body.style.overflow = 'hidden'
					if (timer) {
						clearInterval(timer)

						timer = null
					}
					velocity = 0
				}}
				onTouchMove={(e: any) => {
					setStartY(e.touches[0].clientY)
					const diffVertical = startY - e.touches[0].clientY

					velocity = diffVertical

					const moveY =
						gridRef?.current?.getScrollPosition().scrollTop +
						diffVertical

					console.log('move', moveY, diffVertical)

					gridRef?.current?.scrollTo({
						scrollLeft: 0,
						scrollTop: moveY,
					})

					document.body.style.overflow = 'hidden'
				}}
				onScroll={(e: any) => {
					if (!loading) return

					if (!modalStore?.fixed_orderbook) {
						if (e.scrollTop <= 300) {
							setMaxSize(maxSize + 20)
						} else if (
							e.scrollTop >=
							ROW_HEIGHT * data?.length - parentHeight
						) {
							setMaxSize(maxSize + 20)
						}
					}
				}}
				scrollbarSize={is_mobile ? 13 : 5}
				height={parentHeight - 200}
				rowHeight={rowIndex => ROW_HEIGHT}
				columnWidth={columnIndex => {
					if (columnIndex == 4) {
						return CENTERCELL
					} else if (columnIndex == 3 || columnIndex == 5) {
						return AMOUNTCELL
					} else {
						return COL_WIDTH
					}
				}}
				itemRenderer={CELLRENDRER}
				{...touchProps}
			/>
		</Group>
	)
}

export default CanvasList
