import React, { CSSProperties, useContext } from 'react'
import { CText } from 'app/components/Common/'
import { LoginHeaderWrap } from './styles'
import { ThemeContext } from 'theme/ThemeProvider'

interface IProps {
	label: string
	style?: CSSProperties
}

const LoginHeader = ({ label, style }: IProps) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	return (
		<LoginHeaderWrap>
			<CText
				text={label}
				style={{
					fontSize: 18,
					color: textw,
					fontWeight: '600',
					...style,
				}}
			/>
		</LoginHeaderWrap>
	)
}

export default React.memo(LoginHeader)
