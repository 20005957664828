import React, { useContext, useEffect } from 'react'
import { Header } from 'app/mobile/components'
import { MContentContainer } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import AssetOverview from '../../mobile/organisms/AssetOverview'
import MTradeModal from '../MTradeModal'
import AssetSpot from '../../mobile/organisms/AssetSpot'
import AssetDerivatives from '../../mobile/organisms/AssetDerivatives'
import AssetUsdcDerivatives from '../../mobile/organisms/AssetUsdcDerivatives'
import { useToken } from '../../hooks'
import LoginMobile from '../AuthenticationPages/LoginPage/LoginMobile'
import { useUserSocket } from 'app/hooks/trade/userSocket'
import { useTradePosition } from 'app/hooks/trade/tradePosition'

const AssetMobile = () => {
	const { isDarkMode } = useContext(ThemeContext)
	const socket = useUserSocket()

	const { setRequestStatus } = useTradePosition()

	setRequestStatus(socket.requestStatus)

	useEffect(() => {
		socket.joinGroup()
	}, [socket.isConnectd])

	const param = new URLSearchParams(window.location.search)

	const tab = param.get('tab')

	const { data: token } = useToken()

	if (!token) {
		return <LoginMobile />
	}

	return (
		<MContentContainer isDarkMode={isDarkMode}>
			<Header />
			<AssetDerivatives />
			<MTradeModal />
		</MContentContainer>
	)
}

export default AssetMobile
