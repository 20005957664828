import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'

const TransferableAmount = ({ balance, coinType }) => {
	BigNumber.clone() // new line
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				width: '100%',
				justifyContent: 'space-between',
			}}>
			<Text size={13}>{t('transferableAmount')}</Text>
			<Text size={13}>
				{balance >= 0.001
					? new BigNumber(balance)?.toFormat(
							coinType == 'KRW' ? 0 : 3
					  )
					: 0}{' '}
				{coinType}
			</Text>
		</View>
	)
}

export default TransferableAmount
