import BigNumber from 'bignumber.js'
import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from '../../../../../theme/ThemeProvider'

const HeaderBottomLists = ({ item, index }) => {
	BigNumber.clone() // new line
	const { textw } = useContext(ThemeContext).theme.COLORS

	return (
		<View
			style={{
				alignItems: index === 0 ? 'flex-start' : 'flex-end',
				width: '100%',
			}}>
			<Text
				size={12}
				style={{
					color: textw,
				}}>
				총담보금
			</Text>
			<Text
				size={16}
				style={{
					fontWeight: '500',
					marginBlock: 5,
					marginRight: -2,
					color: textw,
				}}>
				{item?.usd}원
			</Text>
		</View>
	)
}

export default React.memo(HeaderBottomLists)
