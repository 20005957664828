import React, { useContext, useEffect, useRef, useState } from 'react'
import { View } from 'styles/reactnative'
import { TradeRealTimeContainer } from './styles'

import OrderBook from './OrderBook'
import OrderBookMobile from './OrderBook/mobile'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import OrderSetting from '../TradeNavBar/OrderSetting'
import { useWindowDimensions } from '../../../hooks'
import MobileTop from './MobileTop'
import DesktopTop from './DesktopTop'

const TradeRealTime = ({ style = {}, mobile = false }) => {
	const [height, setHeight] = useState(0)
	const [width, setWidth] = useState(0)
	const { stroke } = useContext(ThemeContext).theme.COLORS
	const { width: w, height: h } = useWindowDimensions()

	const parentRef = useRef<any>(null)

	useEffect(() => {
		if (parentRef.current) {
			if (parentRef.current?.clientHeight != height) {
				setTimeout(() => {
					setHeight(parentRef.current?.clientHeight)
				}, 100)
			}
			if (parentRef.current?.clientWidth != width) {
				setTimeout(() => {
					setWidth(parentRef.current?.clientWidth)
				}, 100)
			}
		}
	}, [w, h, parentRef.current?.clientHeight, parentRef.current?.clientWidth])

	return (
		<TradeRealTimeContainer
			ref={parentRef}
			style={{
				...style,
				borderLeft: `1px solid ${stroke}`,
				borderRight: `1px solid ${stroke}`,
				borderBottom: `1px solid ${stroke}`,
			}}>
			<View
				style={{
					width: '100%',
					flex: 1,
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
				}}>
				<div
					style={{
						right: 3,
						top: 0,
						height: 30,
						position: !mobile ? 'absolute' : 'static',
						zIndex: !mobile ? 9999 : 0,
						paddingTop: 3,
						flexDirection: 'row',
						justifyContent: 'flex-end',
						paddingLeft: 3,
					}}>
					{mobile ? <MobileTop /> : <DesktopTop />}
				</div>

				{mobile ? (
					<OrderBookMobile
						parentRef={parentRef}
						parentHeight={height}
						parentWidth={width}
					/>
				) : (
					<>
						<OrderSetting />
						<OrderBook parentHeight={height} parentWidth={width} />
					</>
				)}
			</View>
		</TradeRealTimeContainer>
	)
}

export default React.memo(TradeRealTime)
