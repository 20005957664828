import React from 'react'
import { CButton } from 'app/components/Common'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'
import { COLORS } from 'app/constants'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'
import { useWindowDimensions } from 'app/hooks'

import mainbggrow from 'assets/images/mainbggrow.png'
import { useExchangeStore } from '../../../hooks/trade/exchangeStore'

const HomeLeftSide = () => {
	const navigation = useHistory()
	const { t } = useTranslation()
	const { width } = useWindowDimensions()
	const { textw } = React.useContext(ThemeContext).theme.COLORS
	const checkTablet = width > 900
	const { EPs } = useExchangeStore()
	return (
		<View
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
			}}>
			<View style={{}}>
				<Text
					size={25}
					style={{
						color: '#ffffff',
						fontWeight: 'bold',
						fontFamily:
							'pretendard, Spoqa Han Sans Neo, sans-serif',
						whiteSpace: 'nowrap',
					}}>
					{t('homeEventTitle')}
				</Text>
				<Text
					size={50}
					style={{
						color: '#1C1C1C',
						fontWeight: 'bolder',
						fontFamily:
							'pretendard, Spoqa Han Sans Neo, sans-serif',
						marginTop: 20,
					}}>
					{t('GLOBALTITLE')}
				</Text>
				<Text
					size={20}
					style={{
						color: '#1C1C1C',
						marginTop: 20,
						fontFamily:
							'pretendard, Spoqa Han Sans Neo, sans-serif',
					}}>
					{t('homeSubEventTitle')}
				</Text>
				<CButton
					title={t('getStartedNow')}
					onClick={() => {
						goTo(navigation, '/trade/' + EPs[0].id)
					}}
					style={{
						background: `linear-gradient(45deg, ${COLORS.grad1} 0%, ${COLORS.grad2} 100%)`,
						color: COLORS?.gradtext || COLORS.white,
						width: 160,
						height: 40,
						marginTop: 100,
					}}
				/>
			</View>
		</View>
	)
}

export default HomeLeftSide
