import React, { useContext, useEffect } from 'react'

import { Button, Space } from 'antd'
import { useTradeModalStore } from 'app/hooks/trade/modalStore'
import { ThemeContext } from 'theme/ThemeProvider'
import { useOrderStore } from '../../../../../../../hooks/trade/orderStore'
import { useLogQueue } from 'app/hooks/trade/logQueue'
import { useTradeStore } from 'app/hooks/trade/tradeStore'
import { useMobileDimensions } from 'app/hooks'

const OrderValueQty = () => {
	const { textw, onSecondary, point } = useContext(ThemeContext).theme.COLORS

	const { isMobile } = useMobileDimensions()

	const { ep_id } = useTradeStore()
	const { data: orderStore, change: mutateOrderStore } = useOrderStore()
	const { change: mutateModalStore } = useTradeModalStore()
	const { mutate: mutateLog } = useLogQueue()

	if (orderStore?.amount === undefined) {
		mutateOrderStore({ amount: 1 })
	}


	const changeOrderStore = (amt) => {
		mutateOrderStore({
			amount: amt,
		})

		mutateLog({
			log: 'GT006',
			data: {
				amount: amt,
				ep_id: ep_id,
				isMobile: isMobile,
			},
		})
	}


	return (

		<Space.Compact
			style={{
				alignItems: 'center',
				marginBottom: 10,
				width: '100%',
				marginTop: 10,
			}}
			size="small">
			<Button
				size="small"
				style={{ minWidth: 25, flex: 1 }}
				onClick={() => {
					changeOrderStore( orderStore?.order_short1 || 1)
				}}>
				{isNaN(orderStore?.order_short1)
					? 1
					: orderStore?.order_short1}
			</Button>
			<Button
				size="small"
				style={{ minWidth: 25, flex: 1 }}
				onClick={() => {
					changeOrderStore(orderStore?.order_short2 || 2,
					)
				}}>
				{isNaN(orderStore?.order_short2)
					? 2
					: orderStore?.order_short2}
			</Button>
			<Button
				size="small"
				style={{ minWidth: 25, flex: 1 }}
				onClick={() => {
					changeOrderStore(orderStore?.order_short3 || 3)
				}}>
				{isNaN(orderStore?.order_short3)
					? 3
					: orderStore?.order_short3}
			</Button>
			<Button
				size="small"
				style={{ minWidth: 25, flex: 1 }}
				onClick={() => {
					changeOrderStore(orderStore?.order_short4 || 4)
				}}>
				{isNaN(orderStore?.order_short4)
					? 4
					: orderStore?.order_short4}
			</Button>
			<Button
				size="small"
				style={{ minWidth: 25, flex: 1 }}
				onClick={() => {
					changeOrderStore( orderStore?.order_short5 || 5)
				}}>
				{isNaN(orderStore?.order_short5)
					? 5
					: orderStore?.order_short5}
			</Button>
			<Button
				size="small"
				style={{ minWidth: 25, flex: 1 }}
				onClick={() => {
					changeOrderStore(orderStore?.order_short6 || 10)
				}}>
				{isNaN(orderStore?.order_short6)
					? 10
					: orderStore?.order_short6}
			</Button>
			<Button
				size="small"
				style={{ minWidth: 25, flex: 1, background: onSecondary }}
				onClick={() => {
					mutateModalStore({
						setOrder: true,
					})
				}}>
				설정
			</Button>
		</Space.Compact>
	)
}

export default OrderValueQty
