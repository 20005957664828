import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { SETTING_TYPE_SYSTEM } from '../constants/SETTING'
import { getSetting, useSetting } from './user/mysetting'
dayjs.extend(utc)
dayjs.extend(timezone)

export function useTimezone(
	date: Date,
	format: string = 'YYYY-MM-DD HH:mm:ss',
	offset: number = 9
) {
	const { data: setting } = useSetting(SETTING_TYPE_SYSTEM)

	const timezone = getSetting(setting, 'TIMEZONE', 'Asia/Seoul')

	const result = dayjs(date).utc().tz(timezone).add(offset, 'hours')
	return result.format(format)
}
