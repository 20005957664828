import { ScrollableTabs } from 'app/mobile/components'
import React, { useState } from 'react'
import MAccountSettings from './AccountSettings'
import MAccountSecurity from './MAccountSecurity'
import MAuditSetting from './MAuditSetting'
import { ProfileSettingBodySection } from './styles'
import { useToken } from '../../../../hooks'

const ProfileSettingBody = () => {
	const { data: token } = useToken()
	const [tab, setTab] = useState(token ? 0 : 2)

	let tabs = [
		{ title: 'accountSecurity', tab: 0 },
	]

	if (!token) tabs = tabs.slice(-1)

	return (
		<ProfileSettingBodySection>
			<ScrollableTabs tabs={tabs} tab={tab} setTab={setTab} />
			{tab === 0 && token ? <MAccountSecurity /> : null}
			{tab === 1 && token ? <MAuditSetting /> : null}
			{tab === 2 ? <MAccountSettings /> : null}
		</ProfileSettingBodySection>
	)
}

export default ProfileSettingBody
