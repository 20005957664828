import React from 'react'
import CTab from 'app/components/Common/CTab'

const LoginTab = ({ fontSize, tab, setTab, color }) => {
	const types = ['email', 'phone']

	return (
		<CTab
			fontSize={fontSize}
			color={color}
			active={tab}
			setActive={setTab}
			style={{ width: '80px' }}
			wrapstyle={{ justifyContent: 'space-evenly', marginBottom: 20 }}
			types={types}
			onPressOrClick={value => {
				if (value === 'email') {
				} else if (value === 'phone') {
				}
			}}
		/>
	)
}

export default React.memo(LoginTab)
