import { COLORS } from 'app/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'styles/reactnative'
import SettingTitle from '../SettingTitle'
import AlertInputLists from './AlertInputLists'
import LearnTradingAlert from './LearnTradingAlert'
import ProfileTradingAlertHeader from './ProfileTradingAlertHeader'
import { TradingAlertContainer, TradingAlertSettingSection } from './styles'

const TradingAlertSetting = () => {
	const { t } = useTranslation()
	const data = [
		{
			title: `1.${t('setAlertsOnTradingView')}`,
			link: ' https://www.bybit.com/app/user/tradingview-alert',
		},
		{
			title: `2.${t('copyAndPasteThisTemplateAlert')}`,
			link: ' https://www.bybit.com/app/user/tradingview-alert',
		},
	]
	return (
		<TradingAlertSettingSection>
			<SettingTitle title={'tradingViewAlerts'} />
			<TradingAlertContainer>
				<ProfileTradingAlertHeader />
				{data.map((item, index) => (
					<AlertInputLists
						key={index.toString()}
						item={item}
						index={index}
					/>
				))}
				<Text
					size={14}
					style={{ color: COLORS.lightRed, alignSelf: 'flex-start' }}>
					{t('pleaseDoNotShareWebHookURL')}
				</Text>
				<LearnTradingAlert />
			</TradingAlertContainer>
		</TradingAlertSettingSection>
	)
}

export default TradingAlertSetting
