import { VERSION } from 'app/constants/APIKEYS'
import useSWR from 'swr'
import API from 'utils/api'
import { useToken } from '../auth/token'
import { getPersistData } from '../persist'

let swrData: any = getPersistData('LOCAL@LOGQUEUE') || []

let myip = ''

export function useLogQueue() {
	const { token } = useToken()
	const { data, mutate } = useSWR<any>('LOCAL@LOGQUEUE', async () => {
		return swrData || []
	})

	return {
		myip,
		data,
		setIp: ip => {
			myip = ip
		},
		popLog: () => {
			try {
				if (swrData.length > 0) {
					const log = swrData.shift()
					mutate([...swrData])
					return log
				} else {
					return null
				}
			} catch (e) {
				return mutate()
			}
		},
		saveLog: (logdata, device='MTS') => {
			try {
				if (logdata !== undefined) {
					if (logdata?.data) {
						logdata.data.version = VERSION
						logdata.data.device = device
						logdata.data.time = new Date().getTime()
					}
				} else {
				}
			} catch (e) { }

			API.post('/user/savelog', token + '', {
				token,
				logtype: logdata.log,
				log: JSON.stringify(logdata.data),
			})
		},
		mutate: logdata => {
			try {
				if (logdata !== undefined) {
					logdata.data.version = VERSION;
					logdata.data.time = new Date().getTime()
					swrData.push(logdata)
					return mutate([...swrData])
				} else {
					return mutate()
				}
			} catch (e) {
				return mutate()
			}
		},
	}
}
