import React, { useContext } from 'react'
import { useTradeStore } from '../../../../hooks/trade/tradeStore'
import { Row } from 'antd'
import { TradeListItemSection } from '../../../../components/Trade/TradeListItem/styles'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import CurrentPrice from '../../../../components/Table/AntCell/CurrentPrice'
import PricePrevDay from '../../../../components/Table/AntCell/PricePrevDay'
import RatePrevDay from '../../../../components/Table/AntCell/RatePrevDay'
import ClosePrice from '../../../../components/Table/AntCell/ClosePrice'
import HighPrice from '../../../../components/Table/AntCell/HighPrice'
import LowPrice from '../../../../components/Table/AntCell/LowPrice'
import TickValue from '../../../../components/Table/AntCell/TickValue'
import CurrencyRate from '../../../../components/Table/AntCell/CurrencyRate'
import EndDate from '../../../../components/Table/AntCell/EndDate'
import { TradeNavBarLeft } from '../styles'
import { TradeNavBarListItem } from '../../../../components/Trade'
import { useWindowDimensions } from '../../../../hooks'
import { View } from '../../../../../styles/reactnative'
import DefaultKrw from '../../../../components/Table/AntCell/DefaultKrw'

const InfoData = ({ openDrawer = () => {} }) => {
	const { textw, bgColor, Secondary, stroke } =
		useContext(ThemeContext).theme.COLORS

	const { width } = useWindowDimensions()

	const { data: tradeStore } = useTradeStore()

	return (
		<TradeListItemSection
			style={{
				flex: 1,
				flexDirection: 'row',
				alignContent: 'flex-start',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				width: '100%',
				height: '100%',
				backgroundColor: bgColor,
				borderBottom: '1px solid ' + stroke,
			}}>
			<TradeNavBarLeft
				style={{
					flex: 1,
					flexDirection: 'row',
					alignItems: 'center',
					height: '100%',
					cursor: 'pointer',
				}}>
				<TradeNavBarListItem
					title={tradeStore?.pairs?.toUpperCase()}
					content={tradeStore?.name}
					onPress={() => {
						openDrawer()
					}}
					titleColor={textw}
					contentColor={Secondary}
					titleBold={true}
				/>
			</TradeNavBarLeft>
			{width >= 1250 ? (
				<Row
					justify="space-evenly"
					align={'middle'}
					style={{
						width: '100%',
						height: '100%',
						paddingTop: '15px',
					}}>
					<CurrentPrice />
					<RatePrevDay />
					<PricePrevDay />
					<ClosePrice />
					<HighPrice />
					<LowPrice />
					<CurrencyRate />
					<TickValue />
					<EndDate />
				</Row>
			) : (
				<View
					style={{
						width: '100%',
						flexDirection: 'column',
						alignItems: 'space-between',
					}}>
					<Row
						justify="space-between"
						align={'middle'}
						style={{
							width: '100%',
							height: '100%',
							padding: 0,
							margin: 0,
						}}>
						<CurrentPrice />
						<RatePrevDay />
						<PricePrevDay />
						<ClosePrice />
						<DefaultKrw title={''} price={0} dp={0} krw={false} />
					</Row>
					<Row
						justify="space-between"
						align={'middle'}
						style={{
							width: '100%',
							height: '100%',
							padding: 0,
							margin: 0,
						}}>
						<HighPrice />
						<LowPrice />
						<CurrencyRate />
						<TickValue />
						<EndDate />
					</Row>
				</View>
			)}
		</TradeListItemSection>
	)
}

export default InfoData
