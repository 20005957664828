import { Button, Col, Modal, Row, message } from 'antd'
import React, { useContext } from 'react'
import { useToken } from '../../hooks'
import { useIndexPrice } from '../../hooks/trade/indexprice'
import { useLogQueue } from '../../hooks/trade/logQueue'
import { useMitOrder } from '../../hooks/trade/mitorder'
import { useTradeModalStore } from '../../hooks/trade/modalStore'
import { useOrderStore } from '../../hooks/trade/orderStore'
import { useTradePosition } from '../../hooks/trade/tradePosition'
import { useTradeStore } from '../../hooks/trade/tradeStore'
import { ThemeContext } from '../../../theme/ThemeProvider'
import { useUserSocket } from '../../hooks/trade/userSocket'
import { VERSION } from 'app/constants/APIKEYS'
import { useConfirmStore } from 'app/hooks/trade/confirmStore'
import { View } from 'styles/reactnative'
import { PrevButton } from 'app/components/Modal/styles'
const ConfirmOrderModal = () => {
	const { data: modalStore, change } = useTradeModalStore()
	const { data: orderStore } = useOrderStore()
	const { data: tradeStore } = useTradeStore()
	const { mutate: mutateLog } = useLogQueue()
	const { sendOrder } = useUserSocket()

	const { data: token } = useToken()

	const { data: mitdata, mutate: mutateMit } = useMitOrder()
	const { data: confirmStore, change: changeStore } = useConfirmStore()

	const { isMobile } = useContext(ThemeContext)

	const ep_id = tradeStore?.ep_id

	const { getPrice } = useIndexPrice(ep_id)
	const { mutate: mutatePosition } = useTradePosition()

	const mitorder = (longshort, price) => {
		mutateLog({
			log: 'ST011',
			data: {
				longshort: longshort,
				price: price,
				amount: orderStore?.amount,
				type: 'MIT',
				isMobile: true,
				ep_id: tradeStore?.ep_id,
				platform: isMobile ? 1 : 0,
				version: VERSION,
			},
		})

		if (price <= 0) {
			message.error('가격을 입력해주세요.')
			return
		}

		if (orderStore?.amount <= 0) {
			message.error('수량을 입력해주세요.')
			return
		}

		const currentprice = getPrice(ep_id)
		let BS = 'B'

		if (longshort == 'L') {
			if (price > currentprice) {
				BS = 'S'
			} else {
				BS = 'A'
			}
		} else {
			if (price > currentprice) {
				BS = 'A'
			} else {
				BS = 'B'
			}
		}

		mutateMit(
			{
				amount: orderStore?.amount,
				longshort: longshort,
				price: price,
				BS: BS,
			},
			tradeStore?.ep_id
		)

		message.success('MIT 주문이 등록되었습니다.')
	}
	return (
		<>
			<Modal
				open={modalStore?.showconfirmorder}
				cancelText="취소"
				zIndex={9999}
				okText="주문승인"
				onCancel={() => {
					mutateLog({
						log: 'FR011',
						data: {
							ep_id: ep_id,
						},
					})
					
					change({
						showconfirmorder: false,
						mitlong: null,
					})
				}}
				onOk={() => {
					if (modalStore?.mitlong == 'L' || modalStore?.mitlong == 'S') {
						mitorder(modalStore?.mitlong, modalStore?.mitprice)
						change({
							showconfirmorder: false,
							mitlong: null,
							mitprice: null,
						})
							
						mutateLog({
							log: 'FR012',
							data: {
								ep_id: ep_id,
								mit: modalStore?.mitlong,
								p: modalStore?.mitprice
							},
						})

					} else {
						
						mutateLog({
							log: 'FR013',
							data: {
								ep_id: tradeStore?.ep_id,
								m: orderStore?.limit_market == 'M' ? 'M' : 'L',
								p: orderStore?.price,
								a: orderStore?.amount,
								t: orderStore?.type
							},
						})

						sendOrder(
							isMobile ? 1 : 0,
							tradeStore?.ep_id,
							orderStore?.long_short,
							orderStore?.limit_market == 'M' ? 'M' : 'L',
							orderStore?.price,
							orderStore?.amount,
							orderStore?.type,
							token,
							() => {
								mutatePosition()
							}
						)
						change({
							showconfirmorder: false,
						})
					}
				}}
				title="주문확인">
				<Row>
					<Col span={6}>타입</Col>
					<Col span={18} style={{ background: '#efefef', padding: 5 }}>
						{orderStore?.type} 신규주문
					</Col>
				</Row>
				<Row style={{ marginTop: 5 }}>
					<Col span={6}>매매유형</Col>
					<Col span={18} style={{ background: '#efefef', padding: 5 }}>
						{modalStore?.mitlong
							? 'MIT'
							: orderStore?.limit_market == 'L'
								? '지정가'
								: '시장가'}
					</Col>
				</Row>
				<Row style={{ marginTop: 5 }}>
					<Col span={6}>구분</Col>
					<Col span={18} style={{ background: '#efefef', padding: 5 }}>
						{modalStore?.mitlong == 'L'
							? '매수'
							: modalStore?.mitlong == 'S'
								? '매도'
								: orderStore?.long_short == 'L'
									? '매수'
									: '매도'}
					</Col>
				</Row>
				<Row style={{ marginTop: 5 }}>
					<Col span={6}>종목</Col>
					<Col
						span={18}
						style={{
							background: '#efefef',
							padding: 5,
							fontWeight: 'bold',
						}}>
						{tradeStore?.pairs}
					</Col>
				</Row>
				<Row style={{ marginTop: 5 }}>
					<Col span={6} style={{
					}}>주문가격</Col>
					<Col
						span={18}
						style={{
							background: '#efefef',
							padding: 5,
							fontWeight: 'bold',
						}}>
						{modalStore?.mitlong
							? modalStore?.mitprice
							: orderStore?.limit_market == 'M'
								? getPrice(tradeStore?.ep_id)
								: orderStore?.price}
					</Col>
				</Row>
				<Row style={{ marginTop: 5 }}>
					<Col span={6}>주문수량</Col>
					<Col
						span={18}
						style={{
							background: '#efefef',
							padding: 5,
							fontWeight: 'bold',
						}}>
						{orderStore?.amount}
					</Col>
				</Row>
			</Modal>

			<Modal
				open={confirmStore?.commonConfirm}
				title={confirmStore?.commonConfirmTitle}
				closeIcon={null}
				closable={false}
				centered
				footer={
					<View style={{ flexDirection: 'row', justifyContent: 'center', flex:1, width:'100%' }}>
						<Button
							type='primary'
							style={{flex:1, width:'100%'}}
							danger
							onClick={() => {
								changeStore({
									commonConfirm: false,
									commonConfirmItem: null,
									commonConfirmTitle: '',
								})
							}}>
							취소
						</Button>
						<Button
						
							type='primary'
							
							style={{flex:1, width:'100%'}}
							onClick={() => {
								if (confirmStore?.commonConfirmCallback) {
									confirmStore?.commonConfirmCallback()
								}
								changeStore({
									commonConfirm: false,
									commonConfirmItem: null,
									commonConfirmTitle: '',
								})
							}}>
							확인
						</Button>
					</View>
				}
			>

			</Modal>
		</>
	)
}

export default React.memo(ConfirmOrderModal)
