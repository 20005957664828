import useSWR from 'swr'

let swrData = null

export function useChartDataFeed() {
	const { data, mutate } = useSWR<any>(
		'CHARTFEED',
		async () => {
			return swrData
		},
		{
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			revalidateIfStale: false,
		}
	)

	return {
		data,
		mutate: (value: any) => {
			if (value !== undefined) swrData = value
			return mutate()
		},
	}
}
