import React, { useContext } from 'react'
import { ToastContainer } from 'react-toastify'
import { IconX } from '@tabler/icons-react'
import { COLORS } from 'app/constants'
import { ThemeContext } from 'theme/ThemeProvider'
import CButton from '../CButton'
import { useWindowDimensions } from 'app/hooks'
const CToast = ({ style }: any) => {
	const { width } = useWindowDimensions()
	const { bgColor, textw, gray } = useContext(ThemeContext).theme.COLORS
	const CloseButton = ({ closeToast }) => (
		<CButton
			icon={<IconX size={14} color={COLORS.secondary} />}
			style={{
				fontSize: '1rem',
				background: gray,
				color: textw,
				borderRadius: 50,
				border: 'none',
				padding: 3,
			}}
			onClick={closeToast}
		/>
	)
	const wStyle =
		width <= 600 ? { zIndex: 99999, marginTop: 10, ...style } : null

	return (
		<ToastContainer
			position="top-center"
			autoClose={1000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			closeButton={CloseButton}
			toastStyle={{
				backgroundColor: bgColor,
				color: textw,
				zIndex: 99999,
				borderRadius: '5px',
			}}
			style={wStyle ? wStyle : { zIndex: 99999 }}
		/>
	)
}

export default React.memo(CToast)
