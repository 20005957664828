import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'

export const SectionContainer = styled.div`
	position: static;
	top: 0;
	width: 100%;
	height: 100%;
	color: #ccc;
	font-size: 1.4rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;

	@media only screen and (max-width: 968px) {
		align-items: center;
		height: 600px;
		margin-top: 30px;
	}
`

export const SectionWrap = styled.div`
	height: 100%;
	width: 80%;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	background-color: ${COLORS.white};
	border-radius: 10px;
`

export const QrSectionBody = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	width: 100%;
	height: 30%;
	margin: auto;
`
export const QrSectionBodySection = styled.div`
	width: 80%;
	background: ${COLORS.lightGray2};
	padding: 10px;
	border-radius: 10px;
`
export const QrCodeLoginTitle = styled.div`
	display: flex;
	justify-content: center;
	font-size: 18px;
	color: ${COLORS.black};
	font-weight: '500' 18px;
	padding-bottom: 10px;
`
