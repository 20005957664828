import { Button, Popover, Space, Tag } from 'antd'
import React from 'react'
import { Text } from '../../../styles/reactnative'
import LevelIcon from '../LevelIcon'

const UserTag = ({
	userid,
	nickname = '',
	email = '',
	phone = '',
	uid = '',
	onClick,
	selected = false,
	style = {},
	level = undefined,
	setSelectedData = () => {},
	popup = true,
	noname = false,
	maxWidth = '100%',
}: any) => {
	let showid = userid

	if (uid) {
		showid = uid
	} else if (email) {
		const tempemail = email.split('@')[0]
		showid = tempemail
	} else if (phone) {
		phone = phone.replace(/-/g, '')
		showid = phone
	}

	if (nickname && nickname != email) {
		showid = nickname
	}

	return (
		<Space.Compact
			direction="horizontal"
			size={'small'}
			onClick={onClick}
			style={{
				width: '100%',
				maxWidth: maxWidth,
				...style,
			}}>
			{popup ? (
				<Popover
					trigger={onClick ? 'click' : 'hover'}
					content={
						<Button
							danger
							type="primary"
							onClick={() => {
								window.open(
									`/partner/user/${userid}`,
									'_blank',
									'width=1750,height=760,resizable=yes, status=no, toolbar=no, menubar=no, location=no'
								)
							}}
							style={{
								justifySelf: 'center',
								alignSelf: 'center',
							}}>
							{userid + '번회원 상세보기'}
						</Button>
					}
					style={{ width: 50 }}
					placement="right">
					<Space.Compact
						direction="horizontal"
						size={'small'}
						style={{ marginLeft: 0, flex: 1, width: '100%' }}>
						{level != undefined && (
							<Text
								size={9}
								style={{
									background: level == 1 ? '#000' : '#969696',
									color: level == 1 ? '#fff' : '#ececec',
									minWidth: level != undefined ? 35 : 20,
									borderRadius: 5,
									maxWidth: maxWidth,
								}}>
								{level != undefined && (
									<LevelIcon level={level} />
								)}
								{/* {userid} */}
							</Text>
						)}

						<Tag
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'row',
								cursor: 'pointer',
								flex: 1,
								width: '100%',
								maxWidth: maxWidth,
								fontSize: 13,
								margin: 0,
								fontWeight: 'bold',
								backgroundColor: '#f1f1f1',
								color: 'black',
							}}>
							{showid}
						</Tag>
					</Space.Compact>
				</Popover>
			) : (
				<>
					{level != undefined && (
						<Text
							size={9}
							style={{
								background: level == 1 ? '#000' : '#969696',
								color: level == 1 ? '#fff' : '#ececec',
								minWidth: level != undefined ? 50 : 20,
								borderRadius: 5,
							}}>
							{level != undefined && <LevelIcon level={level} />}
							{/* {userid} */}
						</Text>
					)}
					{!noname && (
						<Space.Compact
							direction="horizontal"
							size={'small'}
							style={{ marginLeft: 0 }}>
							<Tag
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									flexDirection: 'row',
									flex: 1,
									width: '100%',
									cursor: 'pointer',
									margin: 0,
									fontWeight: 'bold',
									backgroundColor: '#f1f1f1',
									color: 'black',
								}}>
								{showid}
							</Tag>
						</Space.Compact>
					)}
				</>
			)}
		</Space.Compact>
	)
}

export default React.memo(UserTag)
