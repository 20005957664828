import Grid from '@rowsncolumns/grid'
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { Group, Rect, Text } from 'react-konva'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { useTradeModalStore } from '../../../hooks/trade/modalStore'
import { useOrderStore } from '../../../hooks/trade/orderStore'

let scroll2 = false
let loading = false
let timer: any = null
let velocity = 0

const CanvasList2 = ({
	data,
	parentHeight = 400,
	parentWidth = 400,
	maxSize = 10,
	max = 0,
	send = (type, price, num) => {
		console.log('orderbooklist', type, price, num)
	},
	is_mobile = false,
	setMaxSize = e => { },
}: any) => {
	const gridRef: any = useRef()

	const [startY, setStartY] = useState(0)

	const ROW_HEIGHT = 38
	const [COL_WIDTH, setCOL_WIDTH] = useState(0)

	const [first, setFirst] = useState(true)

	const { theme } = useContext(ThemeContext)

	const {
		bgColor,
		textw,
		raise,
		raise_sub,
		fail,
		fail_sub,
		stroke,
		mitBG,
		shadow,
		background,
	} = theme.COLORS

	const { data: orderStore, change: mutateOrderStore } = useOrderStore()
	const { data: modalStore, change } = useTradeModalStore()

	useEffect(() => {
		if (!modalStore?.fixed_orderbook) {
			setTimeout(() => {
				loading = true
			}, 1000)
			setTimeout(() => {
				if (gridRef?.current) {
					gridRef?.current?.scrollTo({
						scrollLeft: 0,
						scrollTop:
							(40 * ROW_HEIGHT) / 2 - parentHeight / 2 + 120,
					})
				}
			}, 100)
		} else {
			setFirst(true)
			loading = false
		}
	}, [modalStore?.fixed_orderbook])

	const setOrderPrice = useCallback(price => {
		mutateOrderStore({
			price: price,
		})
	}, [])

	useEffect(() => {
		setTimeout(() => {
			setCOL_WIDTH(Math.floor(parentWidth / 4))

			if (gridRef?.current) {
				gridRef?.current?.resetAfterIndices({
					columnIndex: 0,
					rowIndex: 0,
				})
			}
		}, 300)
	}, [parentHeight, parentWidth])

	useEffect(() => {
		if (scroll2) return
		if (!modalStore?.fixed_orderbook) {
			if (loading && data?.length > 83) {
				if (gridRef?.current?.getScrollPosition()?.scrollTop <= 250) {
					if (gridRef?.current) {
						console.log(
							'scroll1',
							gridRef?.current?.getScrollPosition()
						)
						gridRef?.current?.scrollTo({
							scrollLeft: 0,
							scrollTop: 30 * ROW_HEIGHT,
						})
					}
				}
				return
			}

			if (first) {
				setTimeout(() => {
					if (gridRef?.current) {
						gridRef?.current?.scrollTo({
							scrollLeft: 0,
							scrollTop:
								(data?.length * ROW_HEIGHT) / 2 -
								parentHeight / 2 +
								120,
						})
					}
				}, 100)
			}
		}
	}, [modalStore?.fixed_orderbook, parentHeight, data?.length, first])

	const CENTERCELL = useMemo(
		() => COL_WIDTH * Math.max(Math.min(2, parentWidth / 420), 1.55) + 11,
		[COL_WIDTH, parentWidth]
	)

	if (COL_WIDTH < 9) return null

	const CELLRENDRER = (props: any): any => {
		const item = data[props.rowIndex]
		const column = props.columnIndex
		if (column == 0) {
			if (item?.header) {
				return (
					<Group key={'cell' + props.rowIndex + props.columnIndex}>
						<Rect
							fill={mitBG}
							x={props.x}
							y={props.y}
							width={COL_WIDTH}
							height={ROW_HEIGHT}
							stroke={stroke}
							strokeWidth={0.5}
						/>
						<Text
							x={props.x + props.width / 2 - 12}
							y={props.rowIndex * ROW_HEIGHT + 14.5}
							text={'건수'}
							fontFamily="Tahoma"
							fill={textw}
						/>
					</Group>
				)
			}
			//매도건수
			let left = 0
			if (item?.count && item?.sumAmount) {
				left = COL_WIDTH - (item?.sumAmount / max) * COL_WIDTH
			}

			return (
				<Group key={'cell' + props.rowIndex + props.columnIndex}>
					<Rect
						fill={
							!item?.count
								? bgColor
								: item?.type == 'S'
									? fail_sub
									: item?.type == 'L'
										? raise_sub
										: bgColor
						}
						x={props.x + left}
						y={props.y}
						width={COL_WIDTH - left}
						height={ROW_HEIGHT}
						stroke={stroke}
						strokeWidth={0.5}
					/>
					<Text
						x={props.x + 5}
						y={props.rowIndex * ROW_HEIGHT + 14.5}
						fontFamily="Tahoma"
						fontSize={11}
						fill={textw}
						text={
							item?.type == 'S' || item?.type == 'L'
								? item?.count
									? item?.count
									: ''
								: ''
						}
					/>
				</Group>
			)
		} else if (column == 1) {
			if (item?.header) {
				return (
					<Group key={'cell' + props.rowIndex + props.columnIndex}>
						<Rect
							fill={mitBG}
							x={props.x}
							y={props.y}
							width={COL_WIDTH}
							height={ROW_HEIGHT}
							stroke={stroke}
							strokeWidth={0.5}
						/>
						<Text
							x={props.x + props.width / 2 - 12}
							y={props.rowIndex * ROW_HEIGHT + 14.5}
							text={'잔량'}
							fontFamily="Tahoma"
							fill={textw}
						/>
					</Group>
				)
			}
			//매도수량
			return (
				<Group key={'cell' + props.rowIndex + props.columnIndex}>
					<Rect
						fill={
							!item?.count
								? bgColor
								: item?.type == 'S'
									? fail_sub
									: item?.type == 'L'
										? raise_sub
										: bgColor
						}
						x={props.x}
						y={props.y}
						width={COL_WIDTH}
						height={ROW_HEIGHT}
						stroke={stroke}
						strokeWidth={0.5}
					/>
					<Text
						x={props.x + 5}
						y={props.rowIndex * ROW_HEIGHT + 14.5}
						fontFamily="Tahoma"
						fill={textw}
						fontSize={11}
						text={
							item?.type == 'S' || item?.type == 'L'
								? item?.count
									? item?.count
									: ''
								: ''
						}
					/>
				</Group>
			)
		} else if (column == 2) {
			if (item?.header) {
				return (
					<Group
						key={'cell' + props.rowIndex + props.columnIndex}
						onMouseEnter={(e: any) => {
							// style stage container:
							const container = e.target.getStage().container()
							container.style.cursor = 'pointer'
						}}
						onMouseLeave={(e: any) => {
							const container = e.target.getStage().container()
							container.style.cursor = 'default'
						}}
						onMouseUp={() => {
							change({
								fixed_orderbook: !modalStore?.fixed_orderbook,
							})
						}}
						onTouchEnd={() => {
							console.log(
								'fixed_orderbook2',
								!modalStore?.fixed_orderbook
							)
							change({
								fixed_orderbook: !modalStore?.fixed_orderbook,
							})
						}}>
						<Rect
							fill={mitBG}
							x={props.x}
							y={props.y}
							width={CENTERCELL}
							height={ROW_HEIGHT}
							stroke={stroke}
							strokeWidth={0.5}
						/>
						<Rect
							x={props.x + 8}
							y={props.y + 8}
							width={CENTERCELL - 16}
							height={ROW_HEIGHT - 16}
							stroke={mitBG}
							strokeWidth={0.8}
						/>

						<Text
							x={props.x + props.width / 2 - 12}
							y={props.rowIndex * ROW_HEIGHT + 14.5}
							text={
								'고정(' +
								(!modalStore?.fixed_orderbook ? 'OFF' : 'ON') +
								')'
							}
							fontFamily="Tahoma"
							fill={!modalStore?.fixed_orderbook ? textw : fail}
						/>
					</Group>
				)
			}
			// 가격
			return (
				<Group
					key={'cell' + props.rowIndex + props.columnIndex}
					onMouseEnter={(e: any) => {
						// style stage container:
						const container = e.target.getStage().container()
						container.style.cursor = 'pointer'
					}}
					onMouseLeave={(e: any) => {
						const container = e.target.getStage().container()
						container.style.cursor = 'default'
					}}
					onTouchEnd={(e: any) => {
						setOrderPrice(item?.price)
					}}
					onTap={() => {
						setOrderPrice(item?.price)
					}}
					onClick={() => {
						setOrderPrice(item?.price)
					}}>
					<Rect
						fill={
							item?.last
								? '#ebb55e'
								: item?.type == 'L'
									? raise_sub
									: fail_sub
						}
						x={props.x}
						y={props.y}
						width={CENTERCELL}
						height={ROW_HEIGHT}
						stroke={stroke}
						strokeWidth={0.5}
					/>
					<Text
						x={
							props.x +
							props.width / 2 -
							item?.padprice?.length * 1.3
						}
						y={props.rowIndex * ROW_HEIGHT + 14.5}
						text={item?.padprice}
						fill={
							orderStore?.price == item?.price
								? textw
								: item?.type == 'L'
									? raise
									: fail
						}
						fontSize={orderStore?.price == item?.price ? 13 : 12}
					/>

					{item?.currentposition && (
						<>
							<Rect
								fill={textw}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'◀'}
								fontSize={9}
								fill={bgColor}
							/>
						</>
					)}

					{item?.loss && (
						<>
							<Rect
								fill={fail}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'손'}
								fontSize={9}
								fill={'white'}
							/>
						</>
					)}

					{item?.profit && (
						<>
							<Rect
								fill={raise}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'익'}
								fontSize={9}
								fill={'white'}
							/>
						</>
					)}

					{item?.open && (
						<>
							<Rect
								fill={textw}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'시'}
								fontSize={9}
								fill={background}
							/>
						</>
					)}

					{item?.high && (
						<>
							<Rect
								fill={raise}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'고'}
								fontSize={9}
								fill={'white'}
							/>
						</>
					)}

					{item?.low && (
						<>
							<Rect
								fill={fail}
								x={props.x - 10}
								y={props.y + 6}
								width={12}
								height={12}
							/>
							<Text
								x={props.x - 9}
								y={props.y + 8}
								text={'저'}
								fontSize={9}
								fill={'white'}
							/>
						</>
					)}

				</Group>
			)
		} else {
			return <></>
		}
	}

	return (
		<Group>
			<Grid
				ref={gridRef}
				rowCount={data?.length}
				columnCount={3}
				width={
					is_mobile
						? parentWidth
						: parentWidth - (modalStore?.fixed_orderbook ? 0 : 10)
				}
				onTouchStart={(e: any) => {
					setStartY(e.touches[0].clientY)
					loading = true
					console.log('start', e.touches[0].clientY)
					document.body.style.overflow = 'hidden'
					if (timer) {
						clearInterval(timer)

						timer = null
					}
					velocity = 0
				}}
				onTouchEnd={(e: any) => {
					document.body.style.overflow = 'auto'
					loading = false

					if (timer) {
						clearInterval(timer)
					}

					console.log('end', velocity)
					timer = setInterval(() => {
						if (velocity > 2 || velocity < -2) {
							velocity = velocity * 0.98

							const moveY =
								gridRef?.current?.getScrollPosition()
									.scrollTop + velocity
							if (isNaN(moveY)) {
								clearInterval(timer)
								timer = null
								return
							}

							gridRef?.current?.scrollTo({
								scrollLeft: 0,
								scrollTop: moveY,
							})
						} else {
							velocity = 0
							clearInterval(timer)
							timer = null
						}
					}, 10)
				}}
				onTouchMove={(e: any) => {
					if (scroll2) return
					setStartY(e.touches[0].clientY)
					const diffVertical = startY - e.touches[0].clientY

					velocity = diffVertical

					const moveY =
						gridRef?.current?.getScrollPosition().scrollTop +
						diffVertical

					console.log('move', moveY, diffVertical)

					gridRef?.current?.scrollTo({
						scrollLeft: 0,
						scrollTop: moveY,
					})

					document.body.style.overflow = 'hidden'
				}}
				onScroll={(e: any) => {
					if (!loading) return

					if (!modalStore?.fixed_orderbook) {
						if (e.scrollTop >= 50 && e.scrollTop <= 300) {
							setMaxSize((prev: any) => prev + 20)
							setFirst(false)
							loading = true
						} else if (
							e.scrollTop >=
							ROW_HEIGHT * data?.length - parentHeight
						) {
							setMaxSize((prev: any) => prev + 20)
							setFirst(false)
							loading = true
						}
					}
				}}
				frozenRows={1}
				scrollbarSize={is_mobile ? 13 : 5}
				height={parentHeight - 200}
				rowHeight={rowIndex => ROW_HEIGHT}
				columnWidth={columnIndex => {
					if (columnIndex == 4) {
						return CENTERCELL
					} else {
						return COL_WIDTH
					}
				}}
				itemRenderer={CELLRENDRER}
			/>
		</Group>
	)
}

export default CanvasList2
