import React, { useCallback, useContext, useEffect } from 'react'
import { View } from 'styles/reactnative'

import Button from 'app/components/CButton'
import { ThemeContext } from '../../../../../../../theme/ThemeProvider'
import { useOrderStore } from '../../../../../../hooks/trade/orderStore'
import { useLogQueue } from 'app/hooks/trade/logQueue'
import { COLORS } from 'app/constants'

const TabBar = ({ tab, setTab }) => {
	const { data: orderStore, change } = useOrderStore()
	const { Prinary, background } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const { mutate: mutateLog } = useLogQueue()

	useEffect(() => {
		if (!orderStore?.limit_market) {
			change({ limit_market: 'L' })
		}
	}, [orderStore?.limit_market, change])

	const changeTab = useCallback((data) => {
		setTab(data)

		mutateLog({
			log: 'FR001',
			data: {
				tab: data,
			},
		})

		if (data == 'MIT') {
			change({
				limit_market: 'M',
			})
			return
		}



		change({
			limit_market: data || 'M',
		})
	}, [change])

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'flex-start',
				alignContent: 'flex-start',
				alignItems: 'flex-start',
				flex: 1,
				gap: 5,
				marginTop: 3
			}}>
			<Button
				onClick={() => changeTab('L')}
				style={{
					paddingInline: 3,
					color: tab == 'L' ? '#fff' : isDarkMode ? '#1d1d1d' : '#fff',
					backgroundColor: tab == 'L' ? COLORS.lightBlue : 'transparent',
					border: `1px solid ${tab == 'L' ? COLORS.lightBlue : '#cdcdcd'}`,
				}}>
				지정가
			</Button>
			<Button
				onClick={() => changeTab('M')}
				style={{
					paddingInline: 3,
					color: tab == 'M' ? '#fff' : isDarkMode ? '#1d1d1d' : '#fff',
					backgroundColor: tab == 'M' ? COLORS.darkRed : 'transparent',
					border: `1px solid ${tab == 'M' ? COLORS.darkRed : '#cdcdcd'}`,

				}}>
				시장가
			</Button>
			<Button
				onClick={() => changeTab('MIT')}
				style={{
					paddingInline: 3, 
					color: tab == 'MIT' ? '#fff': isDarkMode ? '#1d1d1d' : '#fff',
					backgroundColor: tab == 'MIT' ? '#555555' : 'transparent',
					border: `1px solid ${tab == 'MIT' ? '#555555' : '#cdcdcd'}`,

				}}>
				MIT
			</Button>
		</View>
	)
}

export default React.memo(TabBar)
