import React, { useContext } from 'react'
import KrwPrice from '../../KrwPrice'
import { useTradeStore } from '../../../hooks/trade/tradeStore'
import BigNumber from 'bignumber.js'
import Default from './Default'
import { useTradePosition } from '../../../hooks/trade/tradePosition'
import { useExchangeStore } from '../../../hooks/trade/exchangeStore'
import { useFetcherPublic } from '../../../hooks/fetcher'
import { useIndexPrice } from '../../../hooks/trade/indexprice'
import { Text } from '../../../../styles/reactnative'
import { useWindowDimensions } from '../../../hooks'
import { ThemeContext } from '../../../../theme/ThemeProvider'

const HasStock = () => {
	const { width } = useWindowDimensions()
	let longshort = ''
	const { data: position } = useTradePosition()
	const data = position?.filter((item: any) => !item?.status)

	const { raise, fail } = useContext(ThemeContext).theme.COLORS

	const { data: tradeStore } = useTradeStore()

	const currentposition = data?.filter(
		(item: any) => item?.exchange_pairs_id == tradeStore?.ep_id
	)

	longshort = currentposition?.[0]?.long_short || ''
	const currentAmount = currentposition?.[0]?.amount || 0

	return (
		<Default
			title={'보유잔고'}
			value={
				<Text
					style={{
						color: longshort == 'L' ? raise : fail,
						fontWeight: 500,
						letterSpacing: -0.6,
					}}>
					{longshort == ''
						? ''
						: longshort == 'L'
						? '매수:'
						: '매도:'}
					{currentAmount}
				</Text>
			}
		/>
	)
}

export default React.memo(HasStock)
