import React, { useContext } from 'react'
import { Text } from 'styles/reactnative'
import { COLORS } from 'app/constants'
import { ThemeContext } from 'theme/ThemeProvider'
import {
	NotificationItemBody,
	AnnouncementItemLeft,
	AnnouncementListItemSection,
} from './styles'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const AnnouncementListItems = ({ item, index, show, setShow, searchshow }) => {
	const { textsilver, drawerBgColor, textw } =
		useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()
	//거래소 이름 치환
	const list = ['TheBIT', 'THEBIT', 'TheBit', '[[거래소]]']

	const currentname = t('GLOBALTITLE2')

	const title = item.title?.replace(
		new RegExp(list.join('|'), 'gi'),
		currentname
	)

	return (
		<AnnouncementListItemSection
			style={{
				height: 60,
				background: searchshow ? 'white' : 'transparent',
				color: searchshow ? 'black' : textw,
			}}
			onClick={() => {
				setShow({
					...show,
					show: !show.show,
					...item,
				})
			}}>
			<AnnouncementItemLeft>
				<Text style={{ color: searchshow ? 'black' : textw }}>
					{item?.id}
				</Text>
			</AnnouncementItemLeft>
			<NotificationItemBody style={{ paddingBlock: 5 }}>
				<Text>{title}</Text>
				<Text
					style={{
						color: searchshow ? 'black' : textw,
						width: '100%',
					}}></Text>
				<Text style={{ color: searchshow ? 'black' : textsilver }}>
					{dayjs(item.rdate).format('YYYY-MM-DD')}
				</Text>
			</NotificationItemBody>
		</AnnouncementListItemSection>
	)
}

export default AnnouncementListItems
