import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'
import { DropdownContainer } from 'app/components/Common/CDropDown/AdvancedDropdown/styles'

export const TradeNavBarContainer = styled.div`
	width: 100%;
	height: 100px;
	position: static;
	top: 0;
	padding-left: 30px;
	color: #ccc;
	font-size: 1.4rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 1600px) {
		height: 52px;
		padding-left: 0px;
	}
`

export const TradeNavBarLeft = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	flex-direction: row;
`
export const TradeNavBarLeftLists = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-direction: row;
	@media (max-width: 1600px) {
		display: none;
	}
`

export const TradeNavBarRightLists = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-right: 10px;
	flex-direction: row;
	@media (max-width: 1600px) {
		display: none;
	}
`
// eslint-disable-next-line prettier/prettier
export const TradeNavBarListsMenu = styled.div<{ left?: boolean }>`
	align-self: center;
	margin-right: 20px;
	${({ left }) =>
		left &&
		` @media (min-width: 1200px) {
        display: none;
    }`}
	${DropdownContainer}:after {
		content: ' ';
		position: absolute;
		right: 105px;
		top: -15px;
		border-top: none;
		border-right: 15px solid transparent;
		border-left: 15px solid transparent;
		border-bottom: 15px solid ${props => props.theme.COLORS.gray};
	}
	@media (max-width: 600px) {
		${DropdownContainer}:after {
			border: 0px;
		}
	}
`

export const TradeNavBarRight = styled.div``

export const TradeNavBarRightListsMobile = styled.div`
	display: none;
	@media (max-width: 1600px) {
		display: block;
	}

	${DropdownContainer}:after {
		content: ' ';
		position: absolute;
		right: 0px;
		top: -14px;
		border-top: none;
		border-right: 15px solid transparent;
		border-left: 15px solid transparent;
		border-bottom: 15px solid ${props => props.theme.COLORS.gray};
	}
`

export const DropdownText = styled.div<{ selected: any }>`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	cursor: pointer;
	margin-block: 3px;
	padding-inline: 5px;
	&:hover {
		background-color: ${props => props.theme.COLORS.bgColor};
		color: ${COLORS.primary};
	}
	${({ selected }) =>
		selected &&
		`
 		background-color: ${props => props.theme.COLORS.bgColor};
    color: ${COLORS.primary};
    `}
`
