import React from 'react'
import { IconCircleCheck } from '@tabler/icons-react'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'

const IdentifyHeader = () => {
	const { t } = useTranslation()
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				background: '#F0FFF6',
				width: '100%',
				height: '10%',
				paddingInline: 10,
				borderTopRightRadius: 10,
				borderTopLeftRadius: 10,
			}}>
			<Text size={20} style={{ color: COLORS.black }}>
				{t('basic')}
			</Text>
			<View style={{ flexDirection: 'row' }}>
				<Text
					size={14}
					style={{
						marginRight: 10,
						color: COLORS.black,
					}}>
					{t('verified')}
				</Text>
				<IconCircleCheck color={COLORS.lightGreen} />
			</View>
		</View>
	)
}

export default IdentifyHeader
