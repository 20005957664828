import React from 'react'
import { useWindowDimensions } from 'app/hooks'
import LanguageModalHeader from './LanguageModalHeader'
import LanguageModalBody from './LanguageModalBody'
import { LanguageModalWrapper } from './styles'

const LanguageModal = ({ closeModal }: any) => {
	const { width: w, height } = useWindowDimensions()
	const wSize =
		w > 1400 ? w / 3.4 : w > 1200 ? w / 2.8 : w > 1200 ? w / 2.2 : w / 1.5

	return (
		<LanguageModalWrapper
			style={{
				width: '100%',
				maxWidth: wSize,
				height: height / 3.6,
				minHeight: 230,
			}}>
			<LanguageModalHeader closeModal={closeModal} />
			<LanguageModalBody closeModal={closeModal} />
		</LanguageModalWrapper>
	)
}

export default LanguageModal
