import React, { useContext, useState } from 'react'
import { IconEdit, IconShieldCheck } from '@tabler/icons-react'
import { CButton, CImage, CModal } from 'app/components/Common'
import { COLORS, IMAGES } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import AccountInfoSecurityProcess from './AccountInfoSecurityProcess'
import { useMyProfile } from '../../../../../hooks/user/myprofile'
import CIUtils from '../../../../../../utils/ciutils'
import { useTranslation } from 'react-i18next'
import { useTimezone } from '../../../../../hooks/timezone'
import { ThemeContext } from 'theme/ThemeProvider'
import ProfileEditModal from './ProfileEditModal'

const AccountInfoNavBarLeftSide = () => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { data: myinfo } = useMyProfile()
	const { t } = useTranslation()
	const [showModal, setShowModal] = useState(false)
	const closeModal = () => setShowModal && setShowModal(false)
	const openModal = () => {
		setShowModal(true)
	}
	return (
		<View
			style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ marginRight: 20 }}>
					<CImage
						src={
							myinfo?.profileimage
								? myinfo?.profileimage
								: IMAGES.avatar
						}
						alt="logo"
						width={64}
						height={64}
						resizeMode={'cover'}
					/>
				</View>
				<View
					style={{
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
					}}>
					<View style={{ flexDirection: 'row', marginBottom: 10 }}>
						<Text
							size={20}
							style={{
								fontWeight: '500',
								marginRight: 10,
								color:textw
							}}>
							{myinfo?.nickname}
						</Text>
						{myinfo?.level > 0 && (
							<CButton
								icon={
									<IconShieldCheck
										size={17}
										style={{
											background: COLORS.primary,
											color: textw,
										}}
									/>
								}
								title={'Verified'}
								onClick={() => {
									console.log('Login')
								}}
								border={{ width: '1px', color: COLORS.primary }}
								style={{
									justifyContent: 'space-between',
									alignItems: 'flex-start',
									marginRight: 10,
									flexDirection: 'row-reverse',
									padding: 0,
									color: COLORS.primary,
								}}
							/>
						)}
						<CButton
							onClick={openModal}
							icon={
								<IconEdit
									size={16}
									style={{ color: COLORS.lightGray5 }}
								/>
							}
						/>
						<CModal
							visible={showModal}
							onClose={closeModal}
							bgColor={'transparent'}>
							<ProfileEditModal closeModal={closeModal} />
						</CModal>
					</View>
					<Text size={16} style={{ fontWeight: '300', color:textw }}>
						UID: {CIUtils.padId(myinfo?.id)}
					</Text>
				</View>
			</View>
			<AccountInfoSecurityProcess />
			<Text
				size={16}
				style={{
					fontWeight: '400',
					marginTop: 20,
					marginLeft: 5,
					color:textw
				}}>
				{t('lastLogin')} : {useTimezone(myinfo?.lastlogin_at)}
			</Text>
		</View>
	)
}

export default React.memo(AccountInfoNavBarLeftSide)
