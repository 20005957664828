import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IconChevronRight } from '@tabler/icons-react'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const PreferenceListBtns = ({ title, content }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<CButton
			title={t(title)}
			onClick={() => {}}
			style={{
				color: textw,
				justifyContent: 'space-between',
				width: '100%',
				marginTop: 20,
				marginLeft: -5,
			}}
			icon={
				<View style={{ flexDirection: 'row' }}>
					<Text size={12}>{t(content)}</Text>
					<IconChevronRight color={COLORS.lightGray} size={16} />
				</View>
			}
		/>
	)
}

export default PreferenceListBtns
