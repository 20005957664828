import useSWR from 'swr'
import { STORE_FAVOURITE } from 'app/constants/LOCALKEYS'
import { getPersistData } from '../persist'
import { useExchangeStore } from '../trade/exchangeStore'
import { useEffect } from 'react'

const KEY = STORE_FAVOURITE

let swrData: any = getPersistData(KEY)
export function useFavourite(initData?: any) {

	const { EPs } = useExchangeStore()

	const { data, mutate } = useSWR<any>(KEY, async () => {
		if (initData !== undefined) {
			swrData = initData
		}
		return swrData
	})


	useEffect(() => {
		//EPs에 없으면 제거
		if(!EPs || EPs?.length == 0) {
			return
		}

		if(!data || data?.length == 0) {
			return
		}
		try{

			if (data) {
				const newData = data?.filter((item: any) => {
					const isExist = EPs?.find((ep: any) => ep?.id == item?.id)
					return isExist
				})
				if (newData?.length != data?.length) {
					swrData = newData
					mutate(newData)
				}
			}
		}catch(e){
			console.log(data)
			console.error(e);
		}
	}, [EPs, data])

	return {
		data,
		mutate: (value: any) => {
			swrData = value
			return mutate((data: any) => ([ ...data, ...swrData ]))
		},
		removeFav: (value: any) => {
			return mutate((data: any) => {
				const newData = data?.filter((item: any) => item?.id != value)
				swrData = newData
				return newData
			})
		},
	}
}
