import React, { useContext } from 'react'
import { IconArrowRight } from '@tabler/icons-react'
import { View } from 'styles/reactnative'
import { TransferFromToWrapper } from '../styles'
import TransferFromDropdown from './TransferFromDropdown'
import TransferToDropdown from './TransferToDropdown'
import { ThemeContext } from 'theme/ThemeProvider'

const TransferFromTo = ({
	transferFrom,
	setTransferFrom,
	transferTo,
	setTransferTo,
}) => {
	const { gray, textw } = useContext(ThemeContext).theme.COLORS
	return (
		<TransferFromToWrapper>
			<TransferFromDropdown
				transferFrom={transferFrom}
				setTransferFrom={setTransferFrom}
				transferTo={transferTo}
				action={() => {
					setTransferTo(transferFrom)
				}}
			/>
			<View style={{ width: '10%', marginTop: 15 }}>
				<View
					style={{
						width: 25,
						height: 25,
						background: gray,
						borderRadius: 50,
						alignSelf: 'center',
					}}>
					<IconArrowRight
						size={14}
						color={textw}
						onClick={() => {
							setTransferFrom(transferTo)
							setTransferTo(transferFrom)
						}}
					/>
				</View>
			</View>
			<TransferToDropdown
				transferTo={transferTo}
				setTransferTo={setTransferTo}
				transferFrom={transferFrom}
				action={() => {
					setTransferFrom(transferTo)
				}}
			/>
		</TransferFromToWrapper>
	)
}

export default TransferFromTo
