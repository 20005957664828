import React, { useContext } from 'react'
import { Header } from 'app/mobile/components'
import { MContentContainer } from 'styles/mobilePageStyles'
import { ThemeContext } from 'theme/ThemeProvider'
import { ContentSplit, ContentWrapper } from '../../TradePage/styles'
import QrSection from '../../../mobile/organisms/QrSection'
import LoginSection from '../../../mobile/organisms/LoginSection'

const LoginMobile = () => {
	const { isDarkMode, isMobile } = useContext(ThemeContext)

	return (
		<MContentContainer isDarkMode={isDarkMode}>
			<Header />
			<ContentWrapper>
				<ContentSplit>
					{!isMobile && <QrSection />}
					<LoginSection />
				</ContentSplit>
			</ContentWrapper>
		</MContentContainer>
	)
}

export default LoginMobile
