import { Button } from 'antd'
import Modal from 'antd/es/modal/Modal'
import React, { useEffect } from 'react'
import { useToken } from '../hooks'
import API from '../../utils/api'
import { PARTNERURL } from '../constants/APIKEYS'

const ScreenShot = ({ data }: any) => {
	const [visible, setVisible] = React.useState(false)
	const { data: token } = useToken()
	const [data2, setData2] = React.useState<any>(null)

	useEffect(() => {
		if (!visible) return
		API.get(
			'/group/sc?id=' + data?.id + '&user_id=' + data?.user_id,
			token + '',
			'',
			false,
			PARTNERURL
		).then(res => {
			if (res?.result?.success) {
				setData2(res?.result?.result)
			}
		})
	}, [visible])

	return (
		<>
			<Modal
				open={visible}
				onCancel={() => setVisible(false)}
				width={'100%'}>
				<img
					src={data2?.data}
					style={{ width: '100%', maxWidth: 'fit-content' }}
				/>
			</Modal>
			<Button onClick={() => setVisible(true)} style={{ marginRight: 5 }}>
				스크린샷
			</Button>
		</>
	)
}

export default React.memo(ScreenShot)
