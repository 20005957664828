import { IconBook } from '@tabler/icons-react'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'

const LearnTradingAlert = () => {
	const { textw, borderColor } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	return (
		<View style={{ alignItems: 'flex-start', marginTop: 30 }}>
			<Text size={14} style={{ fontWeight: '500' }}>
				{t('wantToLearnMore')}
			</Text>
			<View
				style={{
					alignItems: 'flex-start',
					marginTop: 10,
					cursor: 'pointer',
				}}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-start',
						marginBottom: 5,
					}}>
					<IconBook size={18} color={textw} />
					<Text
						size={14}
						style={{
							fontWeight: '400',
							marginLeft: 10,
							borderBottom: `1px solid ${borderColor}`,
							paddingBottom: 2,
						}}>
						{t('howToConnectTradingViewToExChange')}
					</Text>
				</View>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-start',
						cursor: 'pointer',
					}}>
					<IconBook size={18} color={textw} />
					<Text
						size={14}
						style={{
							fontWeight: '400',
							marginLeft: 10,
							borderBottom: `1px solid ${borderColor}`,
							paddingBottom: 2,
						}}>
						{t('learnMoreAboutWebhookOnTradingview')}
					</Text>
				</View>
			</View>
		</View>
	)
}

export default LearnTradingAlert
