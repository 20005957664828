import React from 'react'

import { Button, Popconfirm, message } from 'antd'
import { useLogQueue } from 'app/hooks/trade/logQueue'
import { View } from '../../../../styles/reactnative'
import API from '../../../../utils/api'
import { useToken } from '../../../hooks'
import { useOvernight } from '../../../hooks/trade/overnight'
import { useTradePosition } from '../../../hooks/trade/tradePosition'
import Default from './Default'
import { useTradeStore } from 'app/hooks/trade/tradeStore'

const Overnight = () => {
	const { currentposition } = useTradePosition()
	const { data: token } = useToken()

	const { data: overnightAll, mutate: mutateOvernightAll } = useOvernight()
	const { ep_id } = useTradeStore()
	const { mutate: mutateLog } = useLogQueue()

	const overnight = overnightAll?.find(
		(item: any) => item?.positionid == currentposition?.[0]?.id
	)

	return (
		<Default
			title={'오버나잇'}
			value={
				<View style={{ height: 14 }}>
					<Popconfirm
						title={
							overnight?.ovstatus == 1
								? '오버나잇을 취소하시겠습니까?'
								: '오버나잇을 신청하시겠습니까?'
						}
						onCancel={()=>{
							
							mutateLog({
								log: 'FR032',
								data: {
									ep_id,
									s: overnight?.ovstatus
								},
							})
						}}
						onConfirm={() => {
							if (currentposition?.length == 0) {
								message.error('현재 포지션이 없습니다.')
								return
							}

							mutateLog({
								log: 'FR030',
								data: {
									ep_id,
									s: overnight?.ovstatus
								},
							})
							API.post('/trade/overnight', token + '', {
								positionid: currentposition?.[0]?.id,
							}).then(res => {
								if (res?.result?.message) {
									message.success(res?.result?.message)
								}

								console.log(res)
								mutateOvernightAll()
							})
						}}
						okText="네"
						cancelText="아니오">
						<Button
							type="primary"
							danger={overnight?.ovstatus == 1}
							size="small"
							style={{
								padding: 0,
								width: '100%',
								background: overnight?.ovstatus
									? '#9f0049'
									: '#00499F',
								margin: 0,
								fontSize: 12,
							}}>
							{overnight?.ovstatus == 1 ? '오버취소' : '오버신청'}
						</Button>
					</Popconfirm>
				</View>
			}
		/>
	)
}

export default React.memo(Overnight)
