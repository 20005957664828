import styled from 'styled-components'

export const DepositLeftSectionWrapper = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	width: 100%;
`
export const DepositRightSectionWrapper = styled.div`
	width: 40%;
	height: 100%;
`

export const DepositCoinListWrapper = styled.div`
	position: absolute;
	width: 90%;
	top: 142px;
	left: 20px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 150px;
	overflow-y: scroll;
	overflow-x: hidden;
	z-index: 9;
	background-color: ${props => props.theme.COLORS.gray};
`

export const DepositChainTypeListWrapper = styled.div`
	position: absolute;
	width: 90%;
	top: 240px;
	left: 20px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100px;
	overflow-y: scroll;
	overflow-x: hidden;
	z-index: 9;
	background-color: ${props => props.theme.COLORS.gray};
`
