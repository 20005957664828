import React, { useEffect, useMemo, useRef, useState } from 'react'
import OrderBookTableMobile3 from './OrderBookTableMobile3'
// import useSWR from 'swr'
// import { FLASH_QUEUE } from '../../../../constants/LOCALKEYS'

const OrderBookMobile3 = ({
	parentRef,
	parentHeight,
	parentWidth,
	newwindow = false,
	isblur,
}: any) => {
	return (
		<div
			style={{
				height: '600px',
				display: 'flex',
				alignItems: 'flex-start',
				width: '100%',
				justifyContent: 'flex-start',
				overflowY: 'auto',
				overflowX: 'hidden',
				flexDirection: 'column',
				marginTop: '0px',
			}}>
			<OrderBookTableMobile3
				parentHeight={parentHeight + 185}
				parentWidth={parentWidth}
				newwindow={false}
				isblur={isblur}
			/>
		</div>
	)
}
export default OrderBookMobile3
