import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const VerifyContainer = styled.div`
	height: 100%;
	display: flex;
	color: #000;
`

export const VerifyWrapper = styled.div`
	height: 'auto';
	width: 100%;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-self: center;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: ${COLORS.white};
	padding: 30px;
	margin: auto;
`

export const VerfiyHeader = styled.div`
	display: flex;
	align-items: flex-start;
`
