import useSWR from 'swr'
import { getPersistData } from '../persist'
import { useToken } from '../auth/token'
import API from '../../../utils/api'


export function useOvernight() {
	const { data: token } = useToken()
	const key: any = token ? '/trade/overnight?positionid=0' : null

	const { data, mutate } = useSWR(
		key,
		async () => {
			const response = await API.get(key, token + '', {}, false)
			return response?.result?.result
		},
		{
			revalidateOnFocus: true,
			dedupingInterval: 1000,
		}
	)
	return {
		data,
		mutate: () => {
			return mutate()
		},
	}
}
