import useSWR from 'swr'
import API from '../../../utils/api'
import { ORDERBOOK } from '../../constants/APIKEYS'
import { useToken } from '../auth/token'
import { TRADEAPI_URL } from '../../constants/TRADEAPIKEYS'

let swrData: any = { data: { long: [], short: [], ep_id: 0 } }

export function useOrderBook(ep_id: string | number) {
	const { data: token } = useToken()
	const KEY = `${ORDERBOOK}?ep_id=${ep_id}`

	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			if (swrData.data !== null) return swrData?.data

			const response = await API.get(
				KEY,
				token + '',
				{},
				false,
				TRADEAPI_URL
			)
			const data = JSON.parse(response.result)

			swrData.data.long = data?.long || []
			swrData.data.short = data?.short || []
			swrData.data.ep_id = ep_id

			return swrData.data
		},
		{
			revalidateIfStale: false,
			revalidateOnReconnect: false,
			revalidateOnFocus: false,
			dedupingInterval: 50,
		}
	)
	return {
		data,
		long:
			data?.long?.map((item: any) => {
				const i = item?.split('|')
				return {
					price: +i[0],
					amount: +i[1],
					remain: +i[2],
				}
			}) || [],

		short:
			data?.short?.map((item: any) => {
				const i = item?.split('|')
				return {
					price: +i[0],
					amount: +i[1],
					remain: +i[2],
				}
			}) || [],

		mutate: async (value?: any) => {
			if (value !== undefined) {
				swrData.data = value
			}
			if (value === null) {
				swrData.data = null
			}
			return mutate()
		},
	}
}
