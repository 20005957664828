import React from 'react'
import { View } from 'styles/reactnative'
import { DepositLeftSectionWrapper } from '../styles'
import DepositAutoChanneled from './DepositAutoChanneled'
import DepositChainType from './DepositChainType'
import DepositCoins from './DepositCoins'
import DepositQrCode from './DepositQrCode'
import DepositWalletAddress from './DepositWalletAddress'

const DepositLeftSection = () => {
	return (
		<DepositLeftSectionWrapper>
			<View style={{ paddingInline: 15, paddingBlock: 10 }}>
				
			</View>
		</DepositLeftSectionWrapper>
	)
}

export default DepositLeftSection
