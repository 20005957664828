import React, { useContext } from 'react'
import { AssetDerivativesHeaderSection } from '../styles'
import AssetDerivativesHeaderBottom from './AssetDerivativesHeaderBottom'
import AssetDerivativesHeaderTop from './AssetDerivativesHeaderTop'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { View } from '../../../../../styles/reactnative'
import { CButton } from '../../../../components/Common'
import { useTranslation } from 'react-i18next'
import { COINHIDE } from '../../../../constants/APIKEYS'
import { Button } from 'antd'

const AssetDerivativesHeader = () => {
	const { t } = useTranslation()
	const btnData = [
		{ title: 'deposit', goTo: '' },
		{ title: 'withdraw', goTo: '' },
		{ title: 'history', goTo: '' },
		{ title: 'transfer', goTo: '' },
	]

	if (COINHIDE) {
		btnData.pop()
	}

	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()
	const THEMECOLORS = useContext(ThemeContext).theme.COLORS

	return (
		<AssetDerivativesHeaderSection>
			<AssetDerivativesHeaderTop />
			<AssetDerivativesHeaderBottom />

			<View
				style={{
					alignItems: 'center',
					flexDirection: 'row',
					flexWrap: 'wrap',
					width: '100%',
					justifyContent: 'space-between',
					marginTop: 10,
				}}>
				{btnData?.map((item: any, index: any) => (
					<Button
						key={index.toString()}
						onClick={() => {
							if (item?.title === 'deposit') {
								muatateModalStore({
									...modalStore,
									gDeposit: true,
									gDepositType: 'D',
								})
							} else if (item?.title === 'withdraw') {
								muatateModalStore({
									...modalStore,
									gDeposit: true,
									gDepositType: 'W',
								})
							} else if (item?.title === 'transfer') {
								muatateModalStore({
									...modalStore,
									transferModal: true,
								})
							}else if (item?.title === 'history') {
								muatateModalStore({
									...modalStore,
									historyModal: true,
								})
							}
						}}
						style={{
							justifyContent: 'center',
							padding: 8,
							minWidth: '30%',
							flex: 1,
							marginRight: 1,
							fontSize: 10,
							fontWeight: '500',
						}}>
						{t(item.title)}
					</Button>
				))}
			</View>
		</AssetDerivativesHeaderSection>
	)
}

export default AssetDerivativesHeader
