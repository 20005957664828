import { Button, DatePicker, Space } from 'antd'
import dayjs from 'dayjs'
import React, { useContext, useState } from 'react'
import { Text, View } from '../../../styles/reactnative'
import { ThemeContext } from '../../../theme/ThemeProvider'
import EntryTime from '../../components/EntryTime'
import TableAPI from '../../components/TableAPI'
import { useWindowDimensions } from '../../hooks'
import { useTradeModalStore } from '../../hooks/trade/modalStore'
import TranslateText from './TranslateText'
const MyLog = ({ mobile = false }) => {
	const { width } = useWindowDimensions()
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()
	const [startDate, setStartDate] = useState<any>('')
	const [endDate, setEndDate] = useState<any>('')


	const { textw } = useContext(ThemeContext).theme.COLORS

	const fail = '#007EDA'
	const raise = '#F05551'

	return (
		<TableAPI
			label={'내 사용기록'}
			title={
				<Space.Compact>
					<DatePicker
						allowClear={true}
						placeholder="시작일"
						onChange={e =>
							setStartDate(
								e
									? dayjs(e, 'YYYY-MM-DD').format(
											'YYYY-MM-DD'
									  )
									: null
							)
						}
						value={
							startDate
								? dayjs(startDate, 'YYYY-MM-DD')
								: undefined
						}
					/>
					<DatePicker
						allowClear={true}
						placeholder="종료일"
						onChange={e =>
							setEndDate(
								e
									? dayjs(e, 'YYYY-MM-DD').format(
											'YYYY-MM-DD'
									  )
									: null
							)
						}
						value={
							endDate ? dayjs(endDate, 'YYYY-MM-DD') : undefined
						}
					/>
					<Button
						onClick={() => {
							setStartDate('')
							setEndDate('')
						}}>
						전체
					</Button>
				</Space.Compact>
			}
			defaultMaxData={50}
			stylewidth={'100%'}
			height={500}
			width={800}
			columns={() => [
				{
					title: '기록일시',
					dataIndex: 'created_at',
					align: 'center',
					width: 130,
					render: (text, record) => {
						return <EntryTime time={record?.created_at} offset={9}/>
					},
				},
				{
					title: '기록',
					dataIndex: 'log',
					align: 'left',
					render: (text, record) => {
						return (
							<View
								style={{
									flexDirection: 'row',
									justifyContent: 'flex-start',
								}}>
								<TranslateText text={text} data={record} />
							</View>
						)
					},
				},
				{
					title: '비고',
					dataIndex: 'data',
					align: 'center',
					width: 200,
					render: (text, record) => {
						console.log('record', text)

						if (record?.log.includes('GT')) {
							const split = record?.data?.split(':')
							if (split?.length > 1) {
								if (split[8] == '1') {
									return <Text>청산</Text>
								}
								if (split[8] == '2') {
									return <Text>익절</Text>
								}
								if (split[8] == '4') {
									return <Text>전종목청산</Text>
								}
								if (split[8] == '3') {
									return <Text>현종목청산</Text>
								}
								if (split[8] == '6') {
									return <Text>손절</Text>
								}
								if (split[7] == '1') {
									return <Text>MIT</Text>
								}
								if (split[7] == '10') {
									return <Text>F10</Text>
								}
								if (split[7] == '11') {
									return <Text>F11</Text>
								}
							}

							return <Text></Text>
						}

						try {
							const parsed = JSON.parse(record?.data)
							return <Text></Text>
						} catch (e) {
							// return <Text>{text}</Text>
						}
					},
				},
			]}
			apikey={`/my/history/users?t?type=VER1${
				startDate ? `&sdate=${startDate}` : ''
			}${endDate ? `&edate=${endDate}` : ''}&t=`}
		/>
	)
}

export default MyLog
